import squarify from 'squarify';

// 100 x 100 so it calculates percentages
const defaultSquareContainer = { x0: 0, y0: 0, x1: 100, y1: 100 };

export const getSquarifiedTree = (tree, container = defaultSquareContainer) => {
  return squarify(tree[0] ? tree : [tree], container);
};

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export const useMultiClick = (click, dblClick, tripleClick, delay = 200) => {
  return node => {
    return debounce(e => {
      switch (e.detail) {
        case 1:
          if (click) return click(node, e);
          break;
        case 2:
          if (dblClick) return dblClick(node, e);
          break;
        case 3:
          if (tripleClick) return tripleClick(node, e);
          break;
      }
      console.log('too many clicks... do nothing');
    }, delay);
  };
};

export const setParentIds = (tree, currentId, depth = 0) => {
  return tree.map(leaf => {
    leaf._depth = depth;
    if (currentId) {
      leaf._parentId = currentId;
    }
    if (leaf.children) {
      leaf.children = setParentIds(leaf.children, leaf._id, depth + 1);
    }
    return leaf;
  });
};

export const findRootNode = (tree, rootNodeId) => {
  if (!rootNodeId) {
    return tree;
  }
  let root;
  // using natural recursion of JSON.stringify to walk the tree to find the requested root node
  JSON.stringify(tree, (_, leaf) => {
    if (!root) {
      if (leaf instanceof Array) {
        const found = leaf.find(l => l._id === rootNodeId);
        if (found) {
          root = found;
        }
      }
      if (leaf?._id === rootNodeId) {
        root = leaf;
      }
    }
    return leaf;
  });
  return root;
};

export const flattenTreeNodes = tree => {
  let stack = [];
  for (const node of tree) {
    const { children, ...rest } = node;
    stack.push(rest);
    if (children?.length) {
      const childStack = flattenTreeNodes(children);
      stack.concat(childStack);
    }
  }
  return stack;
};

export const truncateToMaxDepth = (tree, maxDepth, currentDepth = 1) => {
  const stack = tree.map(leaf => {
    if (leaf.children?.length > 0 && currentDepth < maxDepth) {
      leaf.children = truncateToMaxDepth(leaf.children, maxDepth, currentDepth + 1);
    } else {
      const { children, ...rest } = leaf;
      return rest;
    }
    return leaf;
  });
  return stack;
};

export const emptyParent = parent => {
  return {
    ...parent,
    x0: Infinity,
    y0: Infinity,
    x1: -Infinity,
    y1: -Infinity
  };
};

function findAllIndexesMatchingParam(nodes, prop, value) {
  const stack = [];
  nodes.forEach((node, idx) => {
    if (node[prop] === value) {
      stack.push(idx);
    }
  });
  return stack;
}

// we have the luxury of knowing the children nodes are grouped in parent order
// so we can rely on that here.
export const replaceParent = (nodes, parent) => {
  let localNodes = [...nodes];
  const allChildIds = findAllIndexesMatchingParam(nodes, '_parentId', parent._id);
  if (allChildIds?.length) {
    const numToRemove = Math.abs(allChildIds[0] - allChildIds[allChildIds.length - 1]) + 1;
    const children = localNodes.splice(allChildIds[0], numToRemove, parent);
    localNodes[allChildIds[0]].children = children;
  }
  return localNodes;
};

export const generateParentContainers = (nodes, tree, rootNode) => {
  const parents = {};
  // const flatTree = flattenTreeNodes(tree);
  // console.log('made flat tree');
  // console.log(flatTree);
  // console.log('root', rootNode);
  for (const node of nodes) {
    /*     console.log(
      'pnode',
      node._id,
      node._parentId,
      node._id !== rootNode?._id,
      node._parentId !== rootNode?._id
    ); */
    if (node._id !== rootNode?._id && node._parentId !== rootNode?._id) {
      // const parentNode = flatTree.find(leaf => leaf._id === node._parentId);
      const parentNode = findRootNode(tree, node._parentId);
      if (parentNode) {
        if (!parents[node._parentId]) {
          parents[node._parentId] = emptyParent(parentNode);
        }
        parents[node._parentId].x0 = Math.min(parents[node._parentId].x0, node.x0);
        parents[node._parentId].y0 = Math.min(parents[node._parentId].y0, node.y0);
        parents[node._parentId].x1 = Math.max(parents[node._parentId].x1, node.x1);
        parents[node._parentId].y1 = Math.max(parents[node._parentId].y1, node.y1);
      }
    }
  }
  return parents;
};

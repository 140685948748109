import React, { useMemo, useState } from 'react';
import RenderChakraMarkdown from 'utils/ChakraMarkdown';
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Text,
  Box,
  HStack
} from '@chakra-ui/react';
import _ from 'lodash';

const More = props => {
  const { isOpen, text_open, open, close, button_text, header_text, size } = props;
  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={close}
      placement='bottom'
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button mr={3} fontSize={size || '12px'} onClick={open}>
          {button_text || 'more ...'}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        boxShadow='2xl'
        bg='#fafafa'
        p='6'
        rounded='md'
        w={['300px', '500px', '800px']}
        onMouseLeave={close}
      >
        <PopoverCloseButton />
        <PopoverHeader fontWeight='semibold'>{header_text}</PopoverHeader>
        <PopoverArrow />
        <PopoverBody>
          <RenderChakraMarkdown d={'inline'} key='mkdown'>
            {text_open}
          </RenderChakraMarkdown>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export function AboutPopover() {
  const text_closed = `
  BETA: We are a decentralized editorial network and co-op, an advertising-free source of *authentic news*. 
  If you were invited ...
  `;

  const text_open = `
  You were invited by someone who respects your *point of view* and *curatorial acumen*.

  ***Group Social Bookmarking*** is the core activity here. Each of us has a unique curatorial eye and we encounter online content daily that others like us should know about.  
  
  Use our ***Best of Now bookmarklet*** (upper right and on the Share page) to add any URL you are on to your group's collective feed.  
  
  ***Be selective!*** Remember you recommending content to your peers. We don't want to see everything you come across, just the 'best of now' (and 'best of forever'.)   
  
  Bookmarking can be done from any URL. Our ***News*** tab provides search of social media focused on the Regeneration Movement, e.g. *climate, biodiversity, oceans, technology, economics, etc.*. 
  `;

  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);
  return (
    <Box d='inline-block' mr={3}>
      <Text className='about_closed'>
        <Text>
          <Text as='em' fontWeight='bold'>
            BETA:
          </Text>{' '}
          We are a decentralized editorial network and co-op, an advertising-free source of
          *authentic news*.
          <br />
          If you were invited ...&nbsp;
          <More
            header_text='Best of Now'
            button_text={'more...'}
            text_open={text_open}
            isOpen={isOpen}
            open={open}
            close={close}
          />
        </Text>
      </Text>
    </Box>
  );
}

export default AboutPopover;
export { More };

import {
  PEOPLENETWORK_REQUEST_START,
  SET_PEOPLENETWORK_DATA,
  SET_PEOPLENETWORK_ERROR,
  CLEAR_PEOPLENETWORK_DATA
} from 'shared/constants/actionTypes';
import { nooCommandDirect } from './nooApi';
// import { getPersonIdFromState } from './noo';
import { derivePrefixedNodeId } from 'utils';

const peopleNetworkRequestStart = () => ({ type: PEOPLENETWORK_REQUEST_START });

const setPeopleNetworkData = data => ({
  type: SET_PEOPLENETWORK_DATA,
  payload: data
});

const setPeopleNetworkError = error => ({
  type: SET_PEOPLENETWORK_ERROR,
  payload: error
});

export const edgeTypesForUX = ['RESPECT', 'LI', 'TW', 'VALIDATE', 'CLAIM'];

export const clearPeopleNetworkError = error => ({
  type: CLEAR_PEOPLENETWORK_DATA,
  payload: error
});

const networkSearchRequest = ({ dispatch, query_name, data }) => {
  const payload = { query_name, data };
  const params = {
    payload,
    setLoading: () => {},
    setResponse: response => {
      // console.log('check response data format:', response);
      dispatch(setPeopleNetworkData(response?.result?.[0]));
    },
    setResponseError: error => {
      dispatch(setPeopleNetworkError(error));
    }
  };
  nooCommandDirect('aql', params);
};

export const getNetwork = ({ userId, limit, direction, degrees, logged_in, types }) => {
  types = types?.length > 0 ? types : ['LI'];
  const query_name = 'aqlUserNetwork';
  return async (dispatch, getState) => {
    if (!userId) {
      console.warn('missing userId, cannot get network');
      return;
    }

    const state = getState().peopleNetwork;
    if (state?.isRequesting) {
      console.warn('peopleNetwork requesting already, abort');
      return;
    }
    setPeopleNetworkData({});
    dispatch(peopleNetworkRequestStart());

    const user_id = derivePrefixedNodeId(userId, 'Person');
    const data = { user_id, limit, direction, degrees, logged_in, types };
    networkSearchRequest({ dispatch, query_name, data });
  };
};

export const getNetworkEdges = ({ groupId, direction, limit, personId }) => {
  console.log('getnet', personId);
  const query_name = 'aqlGetCommunity'; // 'aqlPeopleEdgesByGroup';
  return async (dispatch, getState) => {
    if (!groupId) {
      console.warn('missing groupId, cannot get network edges');
      return;
    }

    const state = getState().peopleNetwork;
    if (state?.isRequesting) {
      console.warn('peopleNetwork requesting already, abort');
      return;
    }

    dispatch(peopleNetworkRequestStart());

    const group_id = derivePrefixedNodeId(groupId, 'Groups');
    const person_id = derivePrefixedNodeId(personId, 'Persons');
    const data = { group_id, direction, limit, person_id };
    networkSearchRequest({ dispatch, query_name, data });
  };
};

export const getCommunity = ({ groupId, personId, direction, limit }) => {
  const query_name = 'aqlGetCommunity';
  return async (dispatch, getState) => {
    if (!groupId) {
      console.warn('missing groupId, cannot get network edges');
      return;
    }
    if (!personId) {
      console.warn('missing personId, cannot get network edges');
      return;
    }

    const state = getState().peopleNetwork;
    if (state?.isRequesting) {
      console.warn('peopleNetwork requesting already, abort');
      return;
    }

    dispatch(peopleNetworkRequestStart());

    const group_id = derivePrefixedNodeId(groupId, 'Groups');
    const person_id = derivePrefixedNodeId(personId, 'Persons');
    const data = { group_id, person_id, direction, limit };
    networkSearchRequest({ dispatch, query_name, data });
  };
};

export const stringSearchNetwork = ({ query, filters }) => {
  const query_name = 'aqlSearchPerson';

  let query_dict = typeof query == 'string' ? { desc: query } : query;
  // query_dict.about = query_dict.desc; // not here, in server aqlGenerators
  return async (dispatch, getState) => {
    if (!query) {
      console.warn('missing query (string) cannot search network');
      return;
    }

    const state = getState().peopleNetwork;
    if (state?.isRequesting) {
      console.warn('peopleNetwork requesting already, abort');
      return;
    }

    dispatch(peopleNetworkRequestStart());

    const data = { query_dict, filters };
    networkSearchRequest({ dispatch, query_name, data });
  };
};

export const getSpecificUsers = ({ personIds }) => {
  const query_name = 'aqlQueryGraphOfUsers';
  return async (dispatch, getState) => {
    if (personIds?.length <= 0) {
      console.warn('missing personIds, cannot search network');
      return;
    }

    const state = getState().peopleNetwork;
    if (state?.isRequesting) {
      console.warn('peopleNetwork requesting already, abort');
      return;
    }

    dispatch(peopleNetworkRequestStart());

    const data = { user_ids: personIds, group: null };
    networkSearchRequest({ dispatch, query_name, data });
  };
};

export const getRespected = ({ limit, filters }) => {
  const query_name = 'aqlTopRespectEdges';
  return async (dispatch, getState) => {
    const state = getState().peopleNetwork;
    if (state?.isRequesting) {
      console.warn('peopleNetwork requesting already, abort');
      return;
    }

    dispatch(peopleNetworkRequestStart());

    const data = { limit, filters };
    networkSearchRequest({ dispatch, query_name, data });
  };
};

export const getEdgesType = ({ types, limit, filters }) => {
  const query_name = 'aqlEdgesType';
  return async (dispatch, getState) => {
    const state = getState().peopleNetwork;
    if (state?.isRequesting) {
      console.warn('peopleNetwork requesting already, abort');
      return;
    }

    dispatch(peopleNetworkRequestStart());

    const data = { types, limit, filters };
    networkSearchRequest({ dispatch, query_name, data });
  };
};

export const getNominationData = ({ limit, filters }) => {
  const query_name = 'aqlNominationTimeline';
  return async (dispatch, getState) => {
    const state = getState().peopleNetwork;
    if (state?.isRequesting) {
      console.warn('peopleNetwork requesting already, abort');
      return;
    }

    dispatch(peopleNetworkRequestStart());

    const data = { limit, filters };
    networkSearchRequest({ dispatch, query_name, data });
  };
};

export const getPenumbra = ({ pids, limit, depth }) => {
  const query_name = 'aqlUsersPenumbra';
  return async (dispatch, getState) => {
    const state = getState().peopleNetwork;
    if (state?.isRequesting) {
      console.warn('peopleNetwork requesting already, abort');
      return;
    }

    dispatch(peopleNetworkRequestStart());

    const data = { pids, limit, depth };
    console.log('depth', depth);
    networkSearchRequest({ dispatch, query_name, data });
  };
};

export const getBioregion = ({ bio_ids, person_id, depth, filters, limit, max_people }) => {
  const query_name = 'aqlUsersOfBioregion';
  return async (dispatch, getState) => {
    const state = getState().peopleNetwork;
    if (state?.isRequesting) {
      console.warn('peopleNetwork requesting already, abort');
      return;
    }

    dispatch(peopleNetworkRequestStart());

    const data = { bio_ids, person_id, depth, filters, limit, max_people };
    networkSearchRequest({ dispatch, query_name, data });
  };
};

export const getShortestPath = ({ source, target }) => {
  const query_name = 'aqlShortestPath';
  return async (dispatch, getState) => {
    const state = getState().peopleNetwork;
    if (state?.isRequesting) {
      console.warn('peopleNetwork requesting already, abort');
      return;
    }

    dispatch(peopleNetworkRequestStart());

    const data = { source, target };
    networkSearchRequest({ dispatch, query_name, data });
  };
};

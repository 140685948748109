/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  // SliderMark,
  Tooltip
} from '@chakra-ui/react';

const SLIDER_DEF_VALUE = 100;

const OptionSlider = props => {
  const { option, voteValue, onUpdate, maxValue } = props;
  const normed = voteValue * 100 || SLIDER_DEF_VALUE;
  const [sliderValue, setSliderValue] = useState(voteValue * 100 || SLIDER_DEF_VALUE);
  const [showTooltip, setShowTooltip] = useState(false);

  // this is being used to determine if a) the slider has been interacted with,
  // and b) when another is, it resets back to false
  const hasBeenSlid = useRef(false);

  // this useEffect will update the slider value based on inbound props,
  // as long as the slider is not focused (hasBeenSlid === false)
  useEffect(() => {
    if (!hasBeenSlid.current && normed !== sliderValue) {
      setSliderValue(normed);
    }
  }, [normed, sliderValue]);

  // checking current values:
  /*   console.log('OptionSlider', {
    option: option.title,
    sliderValue,
    voteValue,
    normed,
    active: hasBeenSlid.current
  }); */

  const handleSliderUpdate = value => {
    setSliderValue(value);
    onUpdate && onUpdate(option, value / 100);
  };

  return (
    <Slider
      aria-label='slider-ex-1'
      // defaultValue={normed}
      value={sliderValue}
      colorScheme='cyan'
      max={maxValue || SLIDER_DEF_VALUE}
      onBlur={() => {
        // blur gets triggered when clicking on another element (slider, etc)
        hasBeenSlid.current = false;
      }}
      onFocus={() => {
        // any click on the slider gets detected and this slider now ignores inbound prop values
        // (correct behavior I think? since the slider value should reflect the normalized value? let me know if this logic is wrong)
        hasBeenSlid.current = true;
      }}
      onChange={v => {
        setSliderValue(v);
      }}
      onChangeEnd={v => handleSliderUpdate(v)}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <Tooltip
        hasArrow
        bg='cyan.500'
        color='white'
        placement='top'
        isOpen={showTooltip}
        label={`${sliderValue.toFixed(1)}%`}
      >
        <SliderThumb boxSize='22px'>
          <Box backgroundColor='cyan.100' width='20px' height='20px' borderRadius='50%' />
        </SliderThumb>
      </Tooltip>
    </Slider>
  );
};

export default OptionSlider;

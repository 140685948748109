import firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from 'shared/config/firebaseConfig';

export const firebaseApp = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();
export const firebaseAppAuth = firebaseApp.auth();

export const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
  twitterProvider: new firebase.auth.TwitterAuthProvider()
};

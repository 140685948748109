let customThemes = {
  demo: {
    colors: {
      background: '#dfe4e0', //
      headerBg: '#c9d4c3',
      topLink: '#215CFF',
      primary: '#7999ab',
      primaryDark: '#5F7886',
      secondary: '#b2bf7d',
      username: '#333',
      splashBg: 'white'
    },
    styles: {
      global: {
        body: {
          backgroundColor: 'gray.50'
          // backgroundColor: 'white'
        },
        h1: {
          lineHeight: '0.9em',
          textAlign: 'center',
          fontSize: 'med'
        },
        h2: {
          fontSize: 'md'
        },
        h3: {
          fontSize: 'sm',
          color: '#961f13'
        },
        p: {
          fontSize: 14
        }
      }
    },
    images: {
      _header_bg: 'http://standingwave.net/img/NN/ptn_brainlight_bg.png'
    }
  },
  emerge: {
    styles: {
      global: {
        body: {
          backgroundColor: '#fff'
        }
      }
    }
  },
  climate: {
    styles: {
      global: {
        body: {},
        h3: {},
        a: {}
      }
    }
  },
  cij: {
    colors: {
      background: '#DBE3a5',
      headerBg: '#9abec5',
      topLink: '#fff',
      primary: '#7999ab',
      primaryDark: '#5F7886',
      secondary: '#b2bf7d'
    },
    styles: {
      global: {
        h1: {
          lineHeight: '0.9em',
          textAlign: 'center'
        },
        p: {
          fontSize: 24
        },
        a: {
          backgroundColor: '#f00'
        }
      }
    }
  },
  psyche: {
    colors: {
      background: '#DBE3a5',
      headerBg: '#9abec5',
      topLink: '#fff',
      primary: '#7999ab',
      primaryDark: '#5F7886',
      secondary: '#b2bf7d',
      username: '#ffff00'
    },
    styles: {
      global: {
        body: {
          backgroundColor: 'gray.50'
        },
        h1: {
          lineHeight: '0.9em',
          textAlign: 'center'
        },
        h3: {
          fontSize: '10px',
          color: 'darkgrey'
        },
        p: {
          fontSize: 14
        }
      }
    },
    images: {
      // header_bg: 'https://drive.google.com/file/d/1qhivd7DM9ZNzH6kfQQUODWPrKD43R0p6/view?usp=sharing'
      // header_bg: 'http://standingwave.net/img/NN/ptn_brainlight_bg.png'
      header_bg: 'http://standingwave.net/img/NN/pollinator-banner.jpg'
    }
  },
  ph: {
    colors: {
      background: '#f0f0f0', // '#dfe4e0', // #DBE3a5',
      headerBg: '#9abec5',
      topLink: '#fff',
      primary: '#7999ab',
      primaryDark: '#5F7886',
      secondary: '#b2bf7d',
      username: '#fff'
    },
    styles: {
      _global: {
        h1: {
          lineHeight: '0.9em',
          textAlign: 'center'
        },
        h2: {
          fontSize: '30px'
        },
        p: {
          fontSize: 14
        }
      }
    },
    images: {
      _header_bg: 'http://standingwave.net/img/NN/dymaxion_map.png',
      __header_bg: 'http://standingwave.net/img/NN/tusk_twitter.png',
      header_bg: '/public/images/stegmast_banner.png'
    }
  },
  sharebnb: {
    colors: {
      background: '#fff', // '#DBECCA',
      headerBg: '#eee', // '#94D371',
      topLink: '#fff',
      primary: '#87c4c2',
      username: '#333'
    },
    styles: {
      global: {
        h1: {
          lineHeight: '0.9em',
          textAlign: 'center'
        },
        h2: {
          fontSize: '30px'
        },
        p: {
          fontSize: 24
        }
      }
    }
  }
};

customThemes.best_of = customThemes.demo;
customThemes.newday = customThemes.demo;
export default customThemes;

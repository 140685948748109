import {
  FBA_SET_LOADING,
  FBA_SET_USER,
  FBA_SET_ERROR,
  NOO_USER_LOGOUT
} from 'shared/constants/actionTypes';

import nooApi from 'api';

const initialState = {
  loading: false,
  user: null,
  token: null,
  error: null
};

const firebaseAuth = function (state = initialState, action) {
  switch (action.type) {
    case FBA_SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case FBA_SET_USER:
      const { user, token } = action.payload;
      if (token) {
        nooApi.setAuthToken(token, 'firebase');
      }
      return {
        ...state,
        user,
        token: token || state.token
      };
    case FBA_SET_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case NOO_USER_LOGOUT:
      return {
        loading: false,
        user: null,
        token: null,
        error: null
      };
    default:
      return state;
  }
};

export default firebaseAuth;

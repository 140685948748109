import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Input,
  InputLeftAddon,
  InputGroup,
  FormLabel,
  Button,
  Flex,
  Link,
  HStack,
  VStack,
  useToast,
  Text,
  Heading,
  Spinner,
  Image
} from '@chakra-ui/react';
import _, { values } from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import useInvitations from 'hooks/useInvitations';
import Select from 'react-select';
import GroupSelector from 'components/Segments/GroupSelector';
import { PopOverUser } from 'components/Segments/UserCard';
import { parseSocialLink } from 'utils/socialTools';
import { capitalize } from 'utils';
import { node_normalizer, get_profile } from 'utils/graphNormalize';

import useHeadlines from 'hooks/useHeadlines';
import NominateModal from '../Segments/NominateModal';

const matchers0 = {
  linkedin: new RegExp('linkedin.com/in/([a-zA-Z0-9+-_]+)($|/)'),
  facebook: new RegExp('facebook.com/([a-zA-Z0-9+-_/]+)$'),
  twitter: new RegExp('twitter.com/([a-zA-Z0-9+-_/]+)$')
};

const matchers = {
  linkedin: new RegExp('linkedin.com/in/([a-zA-Z0-9+-._]+)($|/)'),
  weco: new RegExp('weco.io/u/([a-zA-Z0-9+-._]+)($|/)'),
  facebook: new RegExp('facebook.com/([a-zA-Z0-9+-_.]+)($|/)'),
  twitter: new RegExp('twitter.com/([a-zA-Z0-9+-_.]+)($|/)'),
  default: new RegExp('.*/([a-zA-Z0-9+-_.]+)($|/)')
};

const sample = {
  social: '',
  facebook: 'biraci.yawanawa',
  linkedin: 'in/gfriend/',
  github: 'noonation',
  google: 'person/c101',
  youtube: '@JonRamer1234',
  mastodon: '@jeffjarvis@mastodon.social',
  mathstodon: '@liquor_american@universeodon.com',
  twitter: 'paulg'
};

const ServiceLink = props => {
  const link = props.link;
  const service = props.service;
  return (
    <span>
      {link ? (
        <Link
          href={link}
          target='_lookup'
          color='#226'
          textDecoration='underline'
          fontWeight='bold'
          isExternal
        >
          {service}
        </Link>
      ) : (
        <FormLabel htmlFor='linkedin'>{service}</FormLabel>
      )}
    </span>
  );
};

const service_searches = {
  li:
    'https://www.linkedin.com/search/results/all/?keywords=ZQZQZQ&origin=GLOBAL_SEARCH_HEADER&sid=*1D',
  fb: 'https://www.facebook.com/search/top?q=ZQZQZQ',
  tw: 'https://twitter.com/search?q=ZQZQZQ&src=typed_query'
};

const InputUser = props => {
  const { params } = useParams(); // onSubmit
  const [localData, setLocalData] = useState({}); //email:  "", phone:  "",
  const [response, setResponse] = useState(null);
  const [groupId, setGroupId] = useState('Groups/cfee6f1b-7a67-43eb-b214-4b47c06a24d8');
  const dispatch = useDispatch();
  const { isAuthorized, nooUser } = useNoo('InputUser');
  const {
    nooCommandDirect,
    nooCommand,
    nooCommandResponse,
    nooCommandResponseError,
    nooCommandIsProcessing
  } = useNooApi();
  const {
    isBookmarkingHeadline,
    bookmarkHeadlineResponse,
    bookmarkHeadlineResponseError,
    lastBookmarkedUrlData,
    clearBookmarkUrlData,
    lastBookmarkedUrlMetadata,
    bookmarkFetchedHeadline,
    checkUrlForBookmarking,
    getOpenGraph,
    bookmarkError
  } = useHeadlines();

  const { createInvitation, invitationLoading } = useInvitations();
  const [localErrors, setLocalErrors] = useState(null);
  const [tagOptions, setTagOptions] = useState([]);
  const [serviceLinks, setServiceLinks] = useState({});
  const [matchingUsers, setMatchingUsers] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [allSites, setAllSites] = useState({});
  const [currentSvc, setCurrentSvc] = useState({});
  const [validUser, setValidUser] = useState({});
  const [fetching, setFetching] = useState(false);
  const [checked, setChecked] = useState(false);
  const [existing, setExisting] = useState(null);
  const toast = useToast();

  const checkUrl = useCallback(
    url => {
      if (currentSvc) {
        console.log('checking OpenGraph', url);
        // getOpenGraph(url);
        const skipcheck = true;
        setFetching(true);
        checkUrlForBookmarking(url, skipcheck);
      }
    },
    [checkUrlForBookmarking, currentSvc]
  );

  const updateSites = useCallback(
    (svc, data, reset) => {
      let sites = { ...allSites };
      let it = reset ? {} : sites[svc] || {};
      sites[svc] = { ...it, ...data };
      setAllSites(sites);
    },
    [allSites]
  );

  const normUser = useCallback(userDict => {
    const svc = userDict.svc;
    let normed = { ...userDict };
    let temp;
    let parts;
    let fullname;
    switch (svc) {
      case 'youtube':
        normed.fullname = normed.fullname || normed.title || normed.username;
        break;
      case 'twitter':
        temp = userDict.title.replace(/\/ X$/, '').trim();
        fullname = temp.replace('(@' + userDict.username + ')', '').trim();
        normed.fullname =
          normed.fullname || fullname != 'Profile' ? fullname : null || normed.username;
        if (normed?.description.toLowerCase().indexOf('detected that javascript') > 0)
          normed.description = '';
        break;
      case 'mastodon':
        temp = userDict.title.trim();
        fullname = temp.replace('(' + userDict.username + ')', '').trim();
        normed.fullname = normed.fullname || fullname || normed.username;
        parts = userDict.username.split('@');
        normed.server = parts[parts.length - 1];
        break;
      case 'linkedin':
        // title Aki Karja - New York, New York, United States | Professional Profile | LinkedIn
        temp = userDict.title.replace(/\| LinkedIn$/, '').trim();
        if (
          temp.toLowerCase().indexOf('sign up') >= 0 ||
          temp.toLowerCase().indexOf('log in') >= 0
        ) {
          normed.title = '';
          normed.description = '';
          // normed.image = '';
          normed.fullname = normed.username;
        } else {
          parts = temp.split(' - ');
          normed.fullname = normed.fullname || parts[0];
          normed.description = normed.description || parts[1];
        }
        break;
      case 'facebook':
        temp = userDict.title.replace('Facebook', '').trim();
        if (temp.toLowerCase().indexOf('log in') >= 0) {
          normed.title = '';
          normed.description = '';
          // normed.image = '';
          normed.fullname = normed.username;
        }
        break;
      case 'github':
        temp = userDict.title.replace('- Overview', '').trim();
        normed.fullname = normed?.fullname || temp || normed.username;
        break;
    }
    return normed;
  }, []);

  const checkPerson = useCallback(
    (svc, username) => {
      console.log('checking existing ', svc, username);
      // const { svc } = localData;
      const basic = { svc, username }; // : localData[svc]
      const payload = {
        query_name: 'aqlMatchUsernameService',
        data: basic
      };
      const params = {
        payload,
        setLoading: () => {},
        setResponse: response => {
          // const data = response.result[0];
          const person = response?.result ? response?.result[0] : {};
          if (person && person?.data) {
            let user = person.data[svc] || basic;
            user.svc = svc;
            const profile = get_profile(person.data);
            let normed = node_normalizer(profile);
            normed._id = person._id;
            setValidUser(user);
            setExisting(normed);
          } else {
            console.log('not found', response);
            setExisting(null);
          }
          setChecked(true);
        },
        setResponseError: error => {
          console.log('error ', error);
          setChecked(true);
        }
      };
      nooCommandDirect('aql', params);
    },
    [nooCommandDirect]
  );

  useEffect(() => {
    const site = allSites[currentSvc];
    // console.log('b4', site, !site?.site_name, lastBookmarkedUrlData);
    if (lastBookmarkedUrlData && !site?.site_name) {
      updateSites(currentSvc, lastBookmarkedUrlData, false);
      const vuser = { ...validUser, ...lastBookmarkedUrlData };
      const normed = normUser(vuser);
      setValidUser(normed);
      setFetching(false);
    }
  }, [allSites, currentSvc, lastBookmarkedUrlData, normUser, updateSites, validUser]);

  useEffect(() => {
    console.log('bkmk error', bookmarkError);
  }, [bookmarkError]);

  const usernameFromPath = (svc, url, path) => {
    const matcher = matchers[svc] || matchers.default;
    const test = matcher.exec(url);
    return test?.length > 1 ? test[1] : null;
  };

  const onFieldUpdate = e => {
    const tgt = e.target;
    const field = tgt.name;
    const value = tgt.value;

    const myLocalData = {
      ...localData
    };
    switch (field) {
      case 'phone':
        // console.log('setting', value, value.split('').reverse().join(''));
        myLocalData.phone = value.split('').reverse().join('');
        break;
      case 'groups':
        let grps = new Set(myLocalData.groups || []);
        if (tgt.checked) {
          grps.add(value);
        } else {
          grps.delete(value);
        }
        myLocalData.groups = Array.from(grps);
        break;
      case 'social':
        const url = value;
        if (field == 'social' && url?.length == 0) {
          setLocalData({});
          setValidUser(null);
          setExisting(null);
          setChecked(false);
          return;
        }
        const { svc, path } = parseSocialLink(url);
        if (svc) setCurrentSvc(svc);
        clearBookmarkUrlData();
        setChecked(false);
        setExisting(null);

        myLocalData.social = value;
        if (svc && path) {
          const username = usernameFromPath(svc, url, path);
          myLocalData[svc] = username;
          myLocalData.svc = svc;
          myLocalData.url = url;
          // updateSites(svc, { url, path }, true);
          let sites = { ...allSites };
          sites[svc] = { url, path, username, svc };
          setValidUser(sites[svc]);
          setAllSites(sites);
          checkPerson(svc, username);
        } else if (value?.length > 0) {
          setValidUser({});
          toast({
            title: 'Invalid social URL',
            description: 'It should be a profile page',
            status: 'error',
            duration: 10000,
            isClosable: true
          });
        }
        break;
      default:
        myLocalData[field] = value;
    }
    setLocalData(myLocalData);
    // checkUrl(url);
  };

  useEffect(() => {
    // console.log('checking ?', checked, validUser?.fullname, localData?.url);
    if (checked && !validUser?.fullname && !fetching) checkUrl(localData?.url);
  }, [checked, validUser, checkUrl, localData, fetching]);

  const svc_links = useMemo(() => {
    let todo = Object.entries(allSites);
    todo.sort();
    return todo.map(([key, value]) => {
      return <ServiceLink service={key} link={value.url} key={key} />;
    });
  }, [allSites]);

  const onTags = e => {
    const myLocalData = {
      ...localData
    };
    myLocalData.tags = e;
    setLocalData(myLocalData);
  };

  const User = props => {
    let attrs = {};
    const fields = 'svc fullname username description server'.split(' ');
    let items = [];
    const img = validUser.image;
    const Img = img ? <Image src={img} height={'50px'} border='1px solid lightgray' /> : '';
    fields.forEach(field => {
      const val = validUser[field];
      let display = field == 'svc' ? val : field;
      let it = (
        <Text as='span' fontSize={['.5em', '.7em']}>
          {field == 'svc' ? Img : val}
        </Text>
      );
      if (field == 'fullname') {
        it = (
          <Input
            type='text'
            placeholder='their name'
            name='fullname'
            defaultValue={val}
            onBlur={onFieldUpdate}
          />
        );
      } else if (field == 'image') {
        it = <Image src={val} height={'50px'} border='1px solid lightgray' />;
      }
      if (val) {
        items.push(
          <HStack key={field} width={'100%'}>
            <Text fontSize={['.8em', '1.1em']}>{capitalize(display)}</Text>
            {it}
          </HStack>
        );
      }
    });

    //         {avatar && <img width={'100px'} src={avatar} title={localData?.fullname} />}
    return (
      <VStack sx={{ bgColor: 'white', padding: '3px', border: '1px solid lightgray' }}>
        {items}
      </VStack>
    );
  };

  const fieldSx = useMemo(() => ({ mb: '0.5em' }), []);
  /* 
        {< FormLabel htmlFor='phone'>Phone Number</ FormLabel>}
      <Input
        type='text'
        placeholder='Phone Number'
        name='phone'
        value={localData?.phone}
         onChange={onFieldUpdate}
        sx={fieldSx}
      />

      {< FormLabel htmlFor='email'>Email</ FormLabel>}
      <Input
        type='text'
        placeholder='Email'
        name='email'
        value={localData?.email}
         onChange={onFieldUpdate}
        sx={fieldSx}
      />
      */
  const onChange = e => {
    const myLocalData = {
      ...localData
    };
    myLocalData.social = e.target.value;
    setLocalData(myLocalData);
  };
  const onSubmit = e => {
    onChange(e);
    if (e.key == 'Enter' || e.key == 'Tab') onFieldUpdate(e);
  };

  const onAdd = e => {
    const svc = localData.svc;

    if (validUser) {
      let target = { person: { data: { [svc]: validUser } } };
      target.person_id = nooUser?.person?._id;
      console.log('submitting vouch', target);
      dispatch(nooCommand('createVouch', { data: target }));
    } else {
      console.log('nothing submitted, no valid user or no submit cb');
    }
  };

  return (
    <VStack sx={{ w: '100%' }}>
      <Box sx={{ width: '100%', m: '0 auto', backgroundColor: '#eee' }}>
        <Flex></Flex>

        <VStack>
          <InputGroup>
            <InputLeftAddon>URL</InputLeftAddon>
            <Input
              type='text'
              variant='outline'
              placeholder='URL from social site'
              name='social'
              defaultValue={localData?.social}
              /* onBlur={onFieldUpdate} */
              onChange={onChange}
              onKeyDown={onSubmit}
              /* onBlur={onNameChange} */
              sx={fieldSx}
              backgroundColor={'white'}
            />
          </InputGroup>
          {fetching && <Spinner></Spinner>}
          {validUser?.username && <User />}
        </VStack>

        {!existing && (
          <Button
            onClick={onAdd}
            sx={{ cursor: 'pointer' }}
            type='submit'
            disabled={fetching || !validUser?.username}
          >
            Add them {localData?.fullname}
          </Button>
        )}
        {existing && (
          <NominateModal
            target={existing}
            preset={{ label: props.bioregion || '' }}
            description={validUser?.description || localData.url}
          />
        )}
        {localErrors &&
          localErrors.map((LE, idx) => {
            return (
              <p key={idx} sx={{ m: 0, p: 0, color: '#dd0000' }}>
                {LE}
              </p>
            );
          })}
      </Box>
    </VStack>
  );
};

export default InputUser;

import React from 'react';
import RenderChakraMarkdown from 'utils/ChakraMarkdown';
import {
  Text,
  Flex,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading
} from '@chakra-ui/react';

import netcoopAbout from 'components/Segments/AboutPages/NetcoopAbout';

const MarkdownAccordion = props => {
  const mkdown = props.markdown || netcoopAbout;
  const parts = mkdown.split(/\n### /);
  const index = props.index || 0;

  const items = [];

  const OneItem = props => {
    return (
      <AccordionItem>
        <AccordionButton>
          <Heading as='h4' fontSize={'lg'}>
            {props.heading}
          </Heading>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <RenderChakraMarkdown key='body'>{props.body}</RenderChakraMarkdown>
        </AccordionPanel>
      </AccordionItem>
    );
  };
  parts.forEach((part, i) => {
    let atoms = part.split('\n');
    const heading = atoms.shift();
    const body = atoms.join('\n');
    if (heading && body) items.push(<OneItem heading={heading} body={body} />);
  });

  return (
    <Accordion
      className='accordion'
      defaultIndex={index}
      allowToggle
      width={['90vw', '82vw', '60vw']}
    >
      {items}
    </Accordion>
  );
};

export default MarkdownAccordion;

import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
// import TreemapGoogleCharts from './TreemapGoogleCharts';
import Treemap from '../DataViz/TreeMap'; // Kent's version
import PopoverNode from '../DataViz/TreeMap/PopoverNode';
import { Flex, Box, Text, Spinner, Heading, HStack, useDisclosure } from '@chakra-ui/react';
import styled from '@emotion/styled';
// import _ from 'lodash';
import { DetailsDrawer } from '../Decisions/OptionDetails';

import useNoo from 'hooks/useNoo';
import useGroups from 'hooks/useGroups';
import useDecisions from 'hooks/useDecisions';
import usePeopleNetwork from 'hooks/usePeopleNetwork';
import {
  objectsFromPaths,
  nodesFromPaths,
  formatDataForTreemap,
  convert2googlecharts,
  pathTo,
  colorTree,
  findNode,
  peopleQuestionTest,
  testFindNode
} from 'utils/treemapUtils';
import { add } from 'lodash';
import DecisionGraph from 'components/Decisions/DecisionGraph';

const DecisionTreeMap = props => {
  const { token, nooUser, currentNetworkData } = useNoo('DecisionTreeMap');
  const network = currentNetworkData;
  const netname = network.id;
  const render = 'reactgooglecharts';
  const [inDecision, setInDecision] = useState(props.question_id);
  const { currentGroupId, setGroupId, groupSelector, currentGroupName } = useGroups({
    from: 'DecisionTreeMap'
  });
  const {
    currentDecisionId,
    currentDecision,
    getDecisionById,
    getOptionById,
    currentDecisionError
  } = useDecisions();
  const {
    currentNetwork: graph,
    isRequesting: fetching,
    currentProfile,
    // currentNetworkRaw,
    getNetwork,
    getNetworkEdges,
    searchNetwork,
    getSpecificUsers,
    setPeopleNetworkData
  } = usePeopleNetwork();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [treeData, setTreeData] = useState(null);
  const [treeKent, setTreeKent] = useState(null);
  const [nodeData, setNodeData] = useState({});
  const [nodesKent, setNodesKent] = useState({});
  const [parentId, setParentId] = useState(null);
  const [idLookup, setIdLookup] = useState({});
  const [wholeTree, setWholeTree] = useState(null);
  const [wholeGoogle, setWholeGoogle] = useState(null);
  const [popoverNode, setPopoverNode] = useState(null);
  const [entered, setEntered] = useState([]);
  const [mouseX, setMouseX] = useState(null);
  const [mouseY, setMouseY] = useState(null);
  const [currentId, setCurrentId] = useState('Questions/1f876b0b-67c2-47c4-8ce8-a92dc5ddf65c');
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    if (currentDecisionError) {
      console.log('Error getting decision:', currentDecisionError);
      setInDecision(null);
      setCurrentId(null);
    }
  }, [currentDecisionError]);

  useEffect(() => {
    console.log('isopen changed', isOpen);
  }, [isOpen]);
  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);

  const Breadcrumbs = () => {
    const items = [];
    items.push(
      <Text as='span' key={'empty'} fontSize={['.5em', '.7em']}>
        {' '}
      </Text>
    );
    if (breadcrumbs.length > 0) {
      breadcrumbs.forEach(idd => {
        const ndata = findNode(wholeTree, idd, '_id');
        if (ndata) {
          items.push(
            <Text
              as='span'
              key={ndata._id}
              data-id={ndata._id}
              fontSize={['.5em', '.7em']}
              onClick={changeId}
            >
              {'< ' + ndata.name}
            </Text>
          );
        }
      });
    }
    return <Box>{items}</Box>;
  };

  const changeId = e => {
    const tgt = e.target;
    const idd = tgt.dataset['id'];
    setCurrentId(idd);
    // props.rollup(idd);
  };
  useEffect(() => {
    if (currentId && wholeTree) {
      const path = pathTo(currentId, wholeTree);
      let new_breads = path ? path.split('.').filter(x => x) : []; // remove empty root
      if (!(currentId in new_breads)) new_breads.push(currentId);
      if (JSON.stringify(new_breads) != JSON.stringify(breadcrumbs)) {
        setBreadcrumbs(new_breads);
      }
    }
  }, [currentId, setBreadcrumbs, wholeTree, breadcrumbs]);

  const loadDecisionData = useCallback(
    decisionId => {
      if (decisionId && currentDecisionId != decisionId) {
        getDecisionById(decisionId);
      }
    },
    [getDecisionById, currentDecisionId]
  );

  const loadOptionData = useCallback(
    optionId => {
      onOpen();
      if (optionId) {
        console.log('getting option', optionId);
        getOptionById(optionId);
      }
    },
    [getOptionById, onOpen]
  );

  useEffect(() => {
    const colors = ['green', 'blue', 'purple', 'yellow'];
    const title = currentDecision?.question?.data?.title;
    if (title && peopleQuestionTest.test(title)) {
      // don't make tree for people questions}
      return;
    }

    if (currentDecision && !wholeTree) {
      const paths = currentDecision.paths; // decision tree paths thru this decision
      const questions = currentDecision.questions;
      const options = currentDecision.options;
      const options_other = currentDecision.options_other;
      const option_scores = currentDecision.option_scores;
      const options_by_question = currentDecision.options_by_question;
      // get tree of ids from paths
      const tree = objectsFromPaths(paths);
      // get node data for each id
      const treeNodes = nodesFromPaths(
        paths,
        questions,
        options,
        options_other,
        option_scores,
        options_by_question
      );
      const noodes = { ...nodesKent.nodes };
      let changed = false;
      let added = 0;
      Object.entries(treeNodes.nodes).forEach(([idd, values]) => {
        if (!(idd in noodes)) {
          noodes[idd] = values;
          added += 1;
          changed = true;
        }
      });
      const lookup = { ...nodesKent.lookup };
      Object.entries(treeNodes.lookup).forEach(([idd, values]) => {
        if (!(idd in lookup)) {
          lookup[idd] = values;
          if (typeof values == 'string') lookup[values] = idd;
          added += 1;
          changed = true;
        }
      });
      if (changed) setNodesKent({ nodes: noodes, lookup });
      // format for any expectations
      formatDataForTreemap(tree, treeNodes);
      colorTree(tree);
      setTreeKent(tree[0]);

      const { rows, nodes, name_by_id } =
        render == 'reactgooglecharts'
          ? convert2googlecharts(
              paths,
              questions,
              options,
              options_other,
              option_scores,
              options_by_question
            )
          : null;

      setNodeData(nodes);
      setIdLookup(name_by_id);
      setTreeData(rows);

      if (currentDecisionId == 'Questions/1f876b0b-67c2-47c4-8ce8-a92dc5ddf65c') {
        setWholeTree(tree[0]);
        setWholeGoogle(rows);
      }

      const parent = currentDecision.question?.data?.parent_id;
      if (parent) {
        setParentId(parent);
      }
    }
  }, [currentDecision, currentDecisionId, nodesKent.lookup, nodesKent.nodes, wholeTree]);

  useEffect(() => {
    if (nooUser) {
      if (inDecision && !currentDecisionId) {
        console.log('loading ind', inDecision);
        const qId = 'Questions/' + inDecision;
        loadDecisionData(qId);
        // getNetworkEdges({ groupId: gId, direction, limit: maxNodes });
      }
    }
  }, [inDecision, loadDecisionData, nooUser, searchNetwork, currentDecisionId]);

  const cellTooltip = (row, size, value) => {
    const rowData = treeData[row + 1];
    const name = rowData[0];
    const ndata = nodeData[name];

    let code = '';
    if (ndata) {
      code +=
        '<div style="background:#fd9; padding:10px; border-style:solid">' +
        '<h4><b>' +
        name +
        '</b></h4>' +
        '<span style="font-family:Courier">' +
        ndata.data.description +
        '</span>' +
        '</br><span style="font-family:Courier">Score: ' +
        ndata.total.toFixed(2) +
        '</span>';
      if (ndata.children) {
        let names = ndata.children.map(oid => {
          const nm = idLookup[oid];
          return nm;
        });
        code +=
          '</br><span style="font-family:Courier">Subdomains: ' +
          names.sort().join(', ') +
          '</span>';
      }
      code += '</br><span style="font-family:Courier">_id: ' + ndata._id + '</span>';
      code += '</br>' + ' </div>';
    }
    return code;
  };

  const gcharts = render == 'reactgooglecharts';

  const clickCallback = node => {
    // testFindNode(wholeTree); // works
    console.log(node);
    const ndata = findNode(wholeTree, node._id, '_id');
    const qid = ndata.question_id || ndata._id;
    setTreeData(null);
    getDecisionById(qid);
  };

  /*   {wholeGoogle && (
    <TreemapGoogleCharts data={wholeGoogle} tooltip={cellTooltip} cb={clickCallback} />
  )} */

  const onPeopleClick = e => {
    const title = currentDecision.question?.data.title + ' People';
    const qid = nodesKent.lookup[title];
    console.log('popel', qid);
    if (qid) {
      console.log('found ', title, qid);
      getDecisionById(qid);
    }
  };
  const clearEntered = () => {
    setEntered([]);
  };

  const has_people = () => {
    const title = currentDecision?.question?.data?.title + ' People';
    return nodesKent?.lookup && nodesKent?.lookup[title] ? true : false;
  };

  const handleEntered = (node, add) => {
    // manage stack of entered blocks
    // add = true if adding, false if removing
    let current = [...entered];
    // first remove it if it's there already
    let changed = false;
    current = current.filter(val => val !== node._id);
    changed = current.length != entered.length;
    if (add) {
      current.push(node._id);
      changed = true;
    }
    if (changed) {
      // console.log('set ent', current);
      setEntered(current);
    }
  };

  const doPop = useCallback(
    node => {
      setPopoverNode(node);
    },
    [setPopoverNode]
  );
  const handleMouseover = (node, e) => {
    if (node._id == e.target.dataset.id) {
      setMouseX(e.pageX);
      setMouseY(e.pageY);
      doPop(node);
      handleEntered(node, true);
      // e.target.style.border = '3px solid white ';
    }
  };

  const clearPopover = () => {
    setPopoverNode(null);
  };

  function handleMouseout(node, e) {
    // console.log('mouseout', node.name);
    if (popoverNode?._id == e.target.dataset.id) {
      // console.log('setting null', node._id, popoverNode?._id, e.target.dataset.id);
      // clearPopover()
    }
    // if (e) e.target.style.border = '1px solid white ';
    handleEntered(node, false);
  }

  const handleDblClick = (node, e) => {
    if (node._id.indexOf('Questions') == 0) {
      setCurrentId(node._id);
    }
    console.log('dbl', node._id);
  };

  const handleRightClick = (node, e) => {
    console.log('right click', node._id, e);
    e.preventDefault(); // otherwise context menu comes up
    if (breadcrumbs) {
      const newId = breadcrumbs[breadcrumbs.length - 2]; // last one is current
      setCurrentId(newId);
      // props.rollup(newId);
    }
  };
  const handleClick = (node, e) => {
    e.preventDefault(); // otherwise context menu comes up
    let opt_id = node._id.indexOf('Options/') == 0 ? node._id : node.option;
    console.log('click', node.name, node._id, node.depth, 'opt', opt_id, e.target.dataset.id);

    if (opt_id && e.target.dataset.id == node._id) {
      console.log('opening', node.name, isOpen, opt_id, e.target.dataset.id, node._id);
      loadOptionData(opt_id);
      onOpen();
    }
  };

  useEffect(() => {
    if (currentId != currentDecision?.question?._id) {
      console.log('loading curr ', currentId);
      loadDecisionData(currentId);
    }
  }, [currentDecision?.question?._id, currentId, loadDecisionData]);

  return (
    <Box>
      {fetching && <Spinner />}
      <div className='App-row listing' bg='white'>
        <Text>
          Double-click to drill down. Right-click to roll up. Reselect Climate Ontology (right) if
          necessary.
        </Text>
        <HStack>
          <Heading as='h2' fontSize={'20px'}>
            {currentDecision?.question?.data?.title || '...'}
          </Heading>
          {has_people() && (
            <Text sx={{ fontSize: '10px', cursor: 'pointer' }} onClick={onPeopleClick}>
              (Recommend Participants)
            </Text>
          )}
        </HStack>

        {wholeTree && (
          <Box
            width={windowWidth * 0.57 + 'px'}
            height={windowWidth * 0.57 * 0.7 + 'px'}
            onMouseLeave={clearEntered}
            onMouseOut={clearEntered}
          >
            {popoverNode && (
              <Box
                pos='absolute'
                top={mouseY}
                left={mouseX}
                zIndex={1000}
                /* onMouseOut={handleMouseout} */
              >
                (
                <PopoverNode key={popoverNode.id} node={popoverNode} />)
              </Box>
            )}
            <Breadcrumbs />
            <Treemap
              data={wholeTree}
              dblclick={handleDblClick}
              rightclick={handleRightClick}
              click={handleClick}
              labelclick={handleDblClick}
              mouseenter={handleMouseover}
              mouseleave={handleMouseout}
              /* node_id={currentDecisionId} */
              rootNodeId={currentId}
              showLabels={true}
              maxDepth={3}
            />
            <DetailsDrawer isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
            <DecisionGraph />
          </Box>
        )}
        {/* {<TreemapGoogleCharts data={wholeGoogle} tooltip={cellTooltip} cb={clickCallback} />} */}
      </div>
    </Box>
  );
};

export default DecisionTreeMap;

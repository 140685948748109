import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { truncateWords } from 'utils';

const TreeMapLabel = ({ node, click, mouseover, mouseout }) => {
  return (
    <Flex
      alignItems='center'
      justifyContent='center'
      p='3px'
      backgroundColor='#eee'
      height='14px'
      fontSize='10px'
      textTransform='uppercase'
      border='0px solid #999'
      onClick={e => click(node, e)}
      cursor={click ? 'pointer' : 'normal'}
      data-id={node._id}
      onMouseOver={e => mouseover && mouseover(node, e)}
      onMouseOut={e => mouseout && mouseout(node, e)}
    >
      <Text>{truncateWords(node.name, 30)}</Text>
    </Flex>
  );
};

export default TreeMapLabel;

import React from 'react';
import { Box, Flex, Button, Input, Textarea } from '@chakra-ui/react';

const MessageInputForm = props => {
  const { color = 'white', backgroundColor = 'teal.400', onSend, maxHeight = '5em' } = props;

  const [msg, setMsg] = React.useState('');

  const handleInputChange = e => {
    setMsg(e.target.value);
  };

  const sendMessage = e => {
    if (onSend && typeof onSend === 'function') {
      onSend(msg);
    } else {
      console.warn('no onSend function');
    }
    setMsg('');
  };

  return (
    <Flex gap={2}>
      <Textarea
        name='message'
        value={msg}
        onChange={handleInputChange}
        placeholder='type something....'
        height={maxHeight}
        resize='none'
      />
      <Button backgroundColor={backgroundColor} color={color} size='sm' onClick={sendMessage}>
        Send
      </Button>
    </Flex>
  );
};

export default MessageInputForm;

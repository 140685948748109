import {
  ADD_CHAT_DATA,
  DROP_CHAT_DATA,
  UPDATE_CHAT_DATA,
  CHAT_IS_REQUESTING,
  CHAT_REQUEST_ERROR,
  CHAT_SUBSCRIBE,
  CHAT_UNSUBSCRIBE
} from 'shared/constants/actionTypes';

export const addChatData = chatData => ({
  type: ADD_CHAT_DATA,
  payload: chatData
});

export const updateChatData = chatData => ({
  type: UPDATE_CHAT_DATA,
  payload: chatData
});

export const dropChatData = (chatId, contentId) => ({
  type: DROP_CHAT_DATA,
  payload: { chatId, contentId }
});

const chatIsRequesting = () => ({
  type: CHAT_IS_REQUESTING
});

export const chatRequestError = error => ({
  type: CHAT_REQUEST_ERROR,
  payload: error
});

export const chatSubscribe = id => ({
  type: CHAT_SUBSCRIBE,
  payload: id
});

export const chatUnsubscribe = id => ({
  type: CHAT_UNSUBSCRIBE,
  payload: id
});

const getChat = async (url, params, getState, dispatch) => {
  const personId = getState().noo?.nooUser?.person?._id;
  const token = getState().firebaseAuth?.token;
  if (!personId) {
    return dispatch(chatRequestError('no personId found, unauthorized request'));
  }

  // TODO: determine if we need a google token here or not,
  // may not...
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  if (token) {
    headers.Bearer = token;
  }

  const config = {
    method: 'POST',
    headers,
    body: JSON.stringify({ personId, ...params })
  };
  try {
    const response = await fetch(url, config);
    const result = await response.json();
    if (result) {
      const { error, data } = result;
      if (error) {
        return dispatch(chatRequestError(error));
      }
      if (!data) {
        return dispatch(chatRequestError('no chat data found'));
      }
      return dispatch(addChatData(data));
    } else {
      return dispatch(chatRequestError('unknown error occurred'));
    }
  } catch (e) {
    console.error('ERROR calling server middleware', e);
    dispatch(chatRequestError(e));
  }
};

export const getChatById = (chatId, chatParams) => {
  return async (dispatch, getState) => {
    if (!chatId || !chatParams) {
      console.error('ERROR no id and/or params');
      return;
    }
    dispatch(chatIsRequesting());
    return getChat('/service/chat/by-id', { chatId, ...chatParams }, getState, dispatch);
  };
};

export const getChatByContentId = (contentId, chatParams) => {
  return async (dispatch, getState) => {
    if (!contentId || !chatParams) {
      console.error('ERROR no id and/or params');
      return;
    }
    dispatch(chatIsRequesting());
    return getChat('/service/chat/by-content-id', { contentId, ...chatParams }, getState, dispatch);
  };
};

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Flex, FormLabel, Textarea, Spinner } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// import styled from '@emotion/styled';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import Results from './Results/DecisionsOld';
import UserAuth from 'components/User/UserAuth';
// import SimpleGrid from './simple_grid';
import formatResponse from 'utils/formatResponse';
// import FontAwesomeIcon from 'utils/FontAwesomeLoader';
import GroupSelector from 'components/Segments/GroupSelector';
import { norm_id } from 'utils';
import { ContentSubpanel, WhiteBox } from 'components/Segments/Styled';

/* FOR r IN Resources
  FOR p IN Persons
    FILTER 'sharebnb' in p.subnets && r.personId == p._id 
    RETURN r */

const SearchDecisions = props => {
  const dispatch = useDispatch();
  const { isAuthorized, nooUser } = useNoo();

  const { nooCommand, nooCommandResponse, nooCommandIsProcessing } = useNooApi();

  const [response, setResponse] = useState('{}');

  const [responseHeight, setResponseHeight] = useState('100px');
  const responseRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const { group_id } = useParams();
  const [groupId, setGroupId] = useState(group_id ? norm_id(group_id, 'group') : null);

  const onCallAqlSearch = useCallback(() => {
    console.log('call groupid', groupId);
    if (groupId) {
      const payload = { query_name: 'aqlGetDecisions', data: { group_id: groupId } };
      setIsProcessing(true);
      dispatch(nooCommand('aql', { payload }));
    }
  }, [dispatch, nooCommand, groupId]);

  useEffect(() => {
    if (isAuthorized) {
      onCallAqlSearch();
    }
  }, [onCallAqlSearch, isAuthorized]);

  useEffect(() => {
    const textAreaRef = responseRef.current;
    if (textAreaRef !== null) {
      const scrollHeight = textAreaRef.scrollHeight;
      if (scrollHeight > 100) {
        setResponseHeight(scrollHeight);
      }
    }
  }, [responseRef]);

  useEffect(() => {
    if (nooCommandResponse?.command === 'aql') {
      setIsProcessing(false);
      setResponse(nooCommandResponse.data);
    }
  }, [nooCommandResponse?.command, nooCommandResponse?.data]);
  // <Heading as="h1">{`Search Decisions`}</Heading>

  const onSelectGroup = event => {
    const group_id = event.target.value;
    setGroupId(group_id);
    console.log('group ', group_id);
  };

  return (
    <Box>
      <UserAuth unauthorized='signin'>
        <Box className='App-row'>
          <ContentSubpanel width={['100%', '85%']}>
            <Flex>
              <h3>Choose Group</h3>
              <GroupSelector
                callback={onSelectGroup}
                label={'Choose Group'}
                selected={groupId}
                render={'selector'}
                nooUser={nooUser}
                filters={['all']}
              />
            </Flex>
            {isProcessing && (
              <Box float='right'>
                <Spinner />
              </Box>
            )}

            <Box className='debug'>
              <FormLabel htmlFor='grpcResponse'>GRPC Response:</FormLabel>
              <Textarea
                ref={responseRef}
                id='grpcResponse'
                name='grpcResponse'
                style={{ height: responseHeight }}
                readOnly={true}
                value={formatResponse(response)}
              />
            </Box>
            <WhiteBox>
              <Results data={response} />
            </WhiteBox>
          </ContentSubpanel>
        </Box>
      </UserAuth>
    </Box>
  );
};

export default SearchDecisions;

const matchers = {
  linkedin: new RegExp('linkedin.com/in/([a-zA-Z0-9+-_]+)($|/)'),
  facebook: new RegExp('facebook.com/([a-zA-Z0-9+-_/]+)$'),
  twitter: new RegExp('twitter.com/([a-zA-Z0-9+-_/]+)$')
};
export const parseSocialLink = url => {
  try {
    const u_obj = new URL(url);
    // console.log(u_obj);
    const atoms = u_obj.host.split('.');
    const svc = atoms[atoms.length - 2];
    const path = u_obj.pathname.replace(/^\//, '');
    if (path.length != u_obj.pathname.length - 1) console.log('suspect path', path, u_obj.pathname);
    return { svc, path };
  } catch (e) {
    console.log('bad url', url);
    return { svc: null, path: null };
  }
};
const get_id = function (service, text) {
  // parses links from services to get usernames
  const base = text.split('?')[0]; // remove trailing params
  const parts = base.split('/');
  if (parts.length <= 2) {
    // just a username but possibly with a trailing /
    return parts[0];
  } else {
    const matcher = matchers[service];
    if (matcher) {
      const test = matcher.exec(base);
      if (test) {
        const name = test[1].replace('/', '');
        return name;
      }
    }
  }
  alert(
    `Sorry, but ${text}  --- \n\n is not a valid user page for ${service}. Be sure to click on their name.`
  );
  return null;
};

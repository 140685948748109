import { FAN_AUTHORIZE, FAN_LOGOUT, FAN_SET_LOGIN_URL } from 'redux/actions/fanAuth';
import { NOO_USER_LOGOUT } from 'shared/constants/actionTypes';

import nooApi from 'api';

const initialState = {
  user: false, // just a boolean for now, until we decide to have a fan user object or not
  did: '',
  deepLinkLogin: '',
  token: ''
};

const fanAuth = function (state = initialState, action) {
  // const _state = { ...state };
  switch (action.type) {
    case FAN_AUTHORIZE:
      // capture the did in the client-side redux store, to use in fan/ott call later:
      const { did, userData } = action.payload ?? {};
      return {
        ...state,
        user: userData || false,
        did
      };
    case FAN_SET_LOGIN_URL:
      return {
        ...state,
        deepLinkLogin: action.payload.deepLink,
        token: action.payload.token
      };
    case NOO_USER_LOGOUT:
    case FAN_LOGOUT:
      nooApi.setAuthToken();
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default fanAuth;

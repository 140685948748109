const markdown = `
### NooNAO
### Toward Wise Emergent Coordination for a Less Bad Future
#### *Networked Adaptive Organisms* (NAOs)
#### [Network Cooperatives](https://docs.google.com/presentation/d/1-gPfGn2zdEcBjW6ZNd0rDeTbURt9HSCH5sr2rR9Yu0Q/edit#slide=id.g1a4567fb90a_0_0), [Bioregional Mycelia](https://docs.google.com/document/d/1m6Wf2z2ZPf1hX6xs6-BoHHA1_LtsahQteAcBgFQwPzM/edit), [Portable Communities](https://docs.google.com/presentation/d/19E6VndzUNgYzN5chAZVHH2_nL2ORFcDW0bPX9ejJYNk/edit#slide=id.g1a4567fb90a_0_0), [Social Brains](https://docs.google.com/document/d/1Rlu9vZhEN1S22Mc3NO6yFz5HYkSY9rH2fSUF3EivkWo/edit)

![](/public/images/color_venn_simple_500.png)
Everyone talks about global brains and mycelial networked societies. Let's grow some!

The [metacrisis](https://civilizationemerging.com/media/whats-really-driving-global-problems-in-the-world/) is not something we can 'fix'. But how we navigate it is up to us. How can humanity thread a path through to the best possible future?

[Coordination failure](https://conceptually.org/concepts/coordination-problems) is the heart of much of the metacrisis. Think arms races, capitalism, nation states, plastics, overfishing, the tragedy of the commons. Narrow self-interest makes optimal collective benefit unreachable. 

So can we go up a level in emergent complex coordination? Are we capable of collective wisdom?

Well, networks have a unique capacity for that. Think neurons and synapses, mycelial networks, etc.
> “The only organization capable of unprejudiced growth, or unguided learning, is a network. All other topologies limit what can happen.” - Kevin Kelly

We all talk about networks, but most of what we know about each other is out of the reach of software. Our approach is to change that, to facilitate the formation of large-scale software-accessible networks of trust and respect, and the use thereof for new forms of emergent coordination.     
    
We can't expect all of humanity to miraculously go up a level in time to avoid catastrophe. That won't happen. But the *Regenerative Movement*, manifested as global-scale fractal respect networks, can become a superorganism capable of the kinds of coordination we desperately need, and invent a better future.

That is, of course, a tall order.

What ***NAO***?.

Per [Gall's Law](http://principles-wiki.net/principles:gall_s_law), our approach is to facilitate "simple systems that work"  to allow for growing and co-evolving the complex systems we need.

We propose ***Networked Adaptive Organisms***. Think of them as [DAOs](https://ethereum.org/en/dao/) whose populations are *Trust Networks*, with an innate capacity for "extreme decisioning" capable of otherwise impossible sensemaking, coordination, and action.

In particular, we aim to manifest a working implementation of a ***self-owning and self-governing social graph*** as a ***platform cooperative***, able to capture collective value at scale, and to allocate that value emergently, as a fractal commons.

The concept of the *[Global Brain](https://en.wikipedia.org/wiki/Global_brain)* has been around since at least de Chardin and Vernadsky's [Noosphere](https://en.wikipedia.org/wiki/Noosphere), Otlet's [*Mundaneum*](https://daily.jstor.org/internet-before-internet-paul-otlet/), Bush's [*Memex*](https://archive.org/details/as-we-may-think), Engelbart's [*Dynamic Knowledge Repository*](https://www.dougengelbart.org/pubs/augment-3906.html), and Rheingold's [*Smart Mobs*](https://www.ideaconnection.com/interviews/00006-Smart-Mobs-The-Next-Social-Revolution.html). 

Our current closest facsimiles are Facebook, LinkedIn, Twitter, where the synapses are pitifully inadequate *1 bit connections* (*friend, connection, follow*) and the neural pathways are controlled by corporate algorithms whose only imperative is profit. 

We have what we need to go way beyond that. We can and must dramatically improve on our collective capacity for a *[social brain](https://docs.google.com/document/d/1Rlu9vZhEN1S22Mc3NO6yFz5HYkSY9rH2fSUF3EivkWo)*, and use and govern it emergently.

#### Prototypes
Explore some initial implementations:

* [People Discovery](https://nao.is/search_network) on NAO.is
* [Best of Now](https://bestofnow.net/) — a group social bookmarking app.
* [GreenCheck](https://greencheck.world) — proof of personhood via *three-friend authentication*
* [WhoKnows](https://whoknows.noo.network) — People stigmergy, marking allies with domain expertise, especially place-based regeneration (aka bioregional)
* Private labeling e.g. [Climate Swarm](https://climate.noo.network/), or Emerge Network gathering](https://emerge.noo.network/) in Austin 2022
 

### Core concepts

#### Tech For Good Stack
Here's [our tech stack for making this vision a reality](https://standingwave.net/apps/simple_tech_stack/). Be sure to watch the short Overview video.

![](http://standingwave.net/img/NN/WorldWiseWebStack_thumb.jpg)


#### Graph database
***Graph databases*** are a relatively new approach optimized for representing networks. They provide lots of tools and capabilities that make using networks much more intuitive and natural than relational databases. We use [ArangoDB](https://www.arangodb.com/). 

#### Neurons, identity, proof of personhood
In the *social brain* analogy, humans are neurons and their relationships are synapses. For an individual human to participate, they need a consistent identifier and the ability to attest that such identifier is unique for them, their *proof of humanity*.

Our [GreenCheck](https://greencheck.world/about) is an MVP to that end. It uses *three-friend authentication* for the attestation, because our friends know us best. 
 
#### Human curation and digitization
We all have deep and valuable ***knowledge about each other and our domains of expertise***. As long as this remains in our heads, it's not accessible to software.

GreenCheck allows *[praising](https://greencheck.world/respect)* of others to capture our respect for them across multiple dimensions, a *relationship edge* labeled with a [*simple floating-point vector*](https://www.positioniseverything.net/cpp-multidimensional-vector) from *praiser* to *praisee*. 

Such a vector makes a dramatically richer ***synapse*** than the 1-bit connections of Facebook, LinkedIn, etc. And we can be in control of what algorithms use it.

Let's make it easy and fun to inform our shared network with high-fidelity representations of our respect relationships.

#### Trust Networks by Invitation
One thing that's both easy and quick: Grow ***highly accountable social graphs*** by invitation, where links between A and B are such high-fidelity representations of what A thinks of B. 

Those links can be used in many ways, particularly as ***delegations or proxies*** by domain, a critical component of collective alignment / consensus / decisioning / action. 

Network analytics also provide many possible tools, such as ***domain-specific authoritativeness*** (aka ***mojo***) based on one's network centrality in domain-specific subnetwork.

Because of the invitation requirement, every participant has a ***chain of accountability*** to whom they are responsible.  

Trust Networks are analogous to cryptographic *[**webs of trust**](https://en.wikipedia.org/wiki/Web_of_trust)*, and they provide a powerful ***substrate for innovation***.

#### Extreme decisioning
Such Trust Networks have an innate capacity for new forms of collective decision-making. That derives from:

* ***Continuous representation*** using the delegation/proxy links
* Weighting of influence based on ***authoritativeness***
   
   
What we mean by "extreme":

* **Real-time** — because everyone is represented until they participate.  
* Any **scale**
* Any **domain** 
* **Trusted process** — because it's transparent, composable, and measurably optimal
* Agile, fractal, emergent
   
    
    
See our 2005 LANL whitepaper: [Smartocracy: Social Networks for Collective Decision-Making](https://markorodriguez.files.wordpress.com/2011/01/smartocracy-hicss2007.pdf)) and its prior art from two of the co-authors, [Societal-Scale Decision-Making Using Social Networks](https://steinbock.org/pubs/steinbock-ssdm.pdf). These predated the term [Liquid Democracy](https://en.wikipedia.org/wiki/Liquid_democracy) by five years.

#### Portable Communities

Portable communities are a huge unmet need and opportunity

To quote Richard Bartlett, co-founder of Loomio:
> I spent a decade and a million bucks to conclude the only really critical gap in collective organising software is to synchronise group membership across multiple platforms.

How can online communities be **persistent**, **composable**, and **application-independent**? 

We are working on an [alliance for a Portable Communities Protocol](/markdown/cp) which we think is critical infrastructure for the global federation envisioned.

#### Collective Ownership and Governance
The combination of new decisioning and DAOs makes it simple for such Trust Networks to own and govern themselves, as ***network cooperatives***.

#### Web3 and DWeb
Such a vision must eventually be embedded in the [Decentralized Web](https://dwebcamp.org/dweb-101/) and the massive changes in human organization and coordination empowered by DAOs and tokenomics. Our strategy is to design and build without dependence on those but  mirroring with and migrating to them as they become more widely accessible. Our sense is that the NAO has potential to be among the utilities driving mainstream adoption of Web3.






#### NooNet:
NooNAO is an initiative of NooNet, a fiscally-sponsored project of [Planetwork](https://www.planetwork.net/), a 501(c )3. Donations are tax-deductible.     
#### Contact 
Email 
[Brad DeGraf](mailto:bdegraf@gmail.com)  and/or [Charles Blass](mailto:cb@lovevolv.org)
![](/public/images/color_venn_text_alden_500.png) 

[Stoa Sensemaking](https://www.youtube.com/watch?v=2fAy18JawYI&list=RDCMUCfI5jzpoUbwP4wkmQ6ZNqbA&start_radio=1)
`;

export default markdown;

import {
  BOOKMARK_HEADLINE_REQUEST_START,
  BOOKMARK_HEADLINE_COMPLETE,
  BOOKMARK_HEADLINE_ERROR,
  SET_BOOKMARKED_URL_DATA,
  CLEAR_BOOKMARKED_URL_DATA,
  BOOKMARK_GET_OPENGRAPH
} from 'shared/constants/actionTypes';
import { nooCommandDirect, getNooBundle } from './nooApi';

import nooApi from 'api';

// const _QUERY_NAME = 'aqlGetDocumentByUrl';
const og_api_key = 'c3a7ac10-909b-43a2-8fc5-cddb681cf09b'; // 'd475258c-0d75-41e7-81a8-ae5a6dbd0760';
const normalize_url_data = data => data?.hybridGraph || data;

const setBookmarkedUrlData = (data, meta) => ({
  type: SET_BOOKMARKED_URL_DATA,
  payload: { data, meta }
});

const bookmarkComplete = data => ({
  type: BOOKMARK_HEADLINE_COMPLETE,
  payload: data
});

const bookmarkError = error => ({
  type: BOOKMARK_HEADLINE_ERROR,
  payload: error
});

const bookmarkHeadlineRequestStart = () => ({
  type: BOOKMARK_HEADLINE_REQUEST_START
});

export const clearBookmarkUrlData = () => ({
  type: CLEAR_BOOKMARKED_URL_DATA
});

const fetchOpenGraphData = async url => {
  const enc = encodeURIComponent(url);
  const call = 'https://opengraph.io/api/1.1/site/' + enc + '?app_id=' + og_api_key;
  // console.log('opengraph CALL', call);
  const response = await fetch(call);
  const data = await response.json();
  if (data) {
    return { data };
  }
  // not sure we should throw a hard error here?
  // throw new Error('error getting the headline data from opengraph');
  return { data: null, error: 'error getting the headline data from opengraph' };
};

export const checkUrlForBookmarking = (url, skipcheck) => {
  return async (dispatch, getState) => {
    if (!url) {
      console.error('ERROR no url');
      return;
    }
    dispatch(bookmarkHeadlineRequestStart());
    const personId = getState().noo?.nooUser?.person?._id;

    // DEVNOTE: this whole function block below should be moved to the server,
    // and have the server make the AQL call to "aqlGetDocumentByUrl",
    // and follow up with the OpenGraph call/response
    // client shouldn't care about where the headline comes from

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    const googleToken = nooApi.getGoogleToken();
    if (googleToken) {
      headers.Bearer = googleToken;
    }

    const config = {
      method: 'POST',
      headers,
      body: JSON.stringify({ url, personId, bundle: getNooBundle() })
    };
    // console.log('fetch', config);
    try {
      const endpoint = skipcheck ? 'fetch-opengraph' : 'bookmark-check-url';
      const response = await fetch('/cmd/headlines/' + endpoint, config);
      const data = await response.json();
      if (data) {
        const { error, urlData, meta } = data;
        if (error) {
          console.log('ERROR', error);
          return dispatch(bookmarkError(error));
        }
        return dispatch(setBookmarkedUrlData(urlData, meta));
      } else {
        return dispatch(bookmarkError('unknown error occurred'));
      }
    } catch (e) {
      console.error('ERROR calling server middleware', e);
      dispatch(bookmarkError(e));
    }
  };
};

export const getOpenGraph = url => {
  // for going directly there without checking db first, untested, using skipcheck instead
  return async (dispatch, getState) => {
    if (!url) {
      console.error('ERROR no url');
      return;
    }
    dispatch(bookmarkHeadlineRequestStart());
    const personId = getState().noo?.nooUser?.person?._id;

    // DEVNOTE: this whole function block below should be moved to the server,
    // and have the server make the AQL call to "aqlGetDocumentByUrl",
    // and follow up with the OpenGraph call/response
    // client shouldn't care about where the headline comes from

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    const config = {
      method: 'POST',
      headers,
      body: JSON.stringify({ url })
    };
    console.log('fetching', config);
    try {
      const response = await fetch('/cmd/headlines/fetch-opengraph', config);
      const data = await response.json();
      if (data) {
        const { error, urlData, meta } = data;
        if (error) {
          return dispatch(bookmarkError(error));
        }
        return dispatch(setBookmarkedUrlData(urlData, meta));
      } else {
        return dispatch(bookmarkError('unknown error occurred'));
      }
    } catch (e) {
      console.error('ERROR calling server middleware', e);
      dispatch(bookmarkError(e));
    }
  };
};

export const bookmarkFetchedHeadlineForUser = destinationData => {
  return async (dispatch, getState) => {
    const lastBookmarkedUrlData = getState()?.headlines?.lastBookmarkedUrlData;
    const lastBookmarkedUrlMetadata = getState()?.headlines?.lastBookmarkedUrlMetadata;
    if (!lastBookmarkedUrlData) {
      console.error('ERROR bookmark requested but no URL data found');
      return; // dispatch(bookmarkError('ERROR bookmark requested but no URL data found'));
    }
    const personId = getState().noo?.nooUser?.person?._id;

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    const googleToken = nooApi.getGoogleToken();
    if (googleToken) {
      headers.Bearer = googleToken;
    }

    dispatch(bookmarkHeadlineRequestStart());
    const config = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        headline: lastBookmarkedUrlData,
        personId,
        meta: lastBookmarkedUrlMetadata,
        destinationData: destinationData,
        bundle: getNooBundle()
      })
    };
    try {
      const response = await fetch('/cmd/headlines/bookmark', config);
      const data = await response.json();
      // console.log('got response from bookmark', response);
      dispatch(bookmarkComplete(data));
    } catch (e) {
      console.error('ERROR calling server middleware', e);
      dispatch(bookmarkError(e));
    }
  };
};

// leaving this here for now as an example of how to use nooCommandDirect
// to skip the broadcasting of nooCommand
export const sampleNooCommandDirectCall = url => {
  return async (dispatch, getState) => {
    const payload = { query_name: 'aqlGetDocumentByUrl', url, data: { url } }; // aqlSearch needs it in payload, aqlQueryGenerators needs in data
    const params = {
      payload,
      setLoading: () => {},
      setResponse: async data => {
        const docs = data?.result?.[0]?.document;
        if (docs?.length) {
          const doc = docs[0];
          let urlData = normalize_url_data(doc.data);
          // console.log('headline was matched, set the data');
          console.log(urlData);
          dispatch(
            setBookmarkedUrlData(urlData, { existsInDb: true, fetchedUrl: url, dbRecord: doc._id })
          );
        } else {
          console.log('headline was NOT matched, go get OG and stuff');
          const { data, error } = await fetchOpenGraphData(url);
          if (error) {
            return console.error(error || 'error getting the headline data from opengraph');
          }
          if (data) {
            if (data.error) {
              return dispatch(bookmarkError(data.error?.message || 'error fetching data from OG'));
            } else {
              const meta = { existsInDb: false, ogFetchOnly: true, fetchedUrl: url };
              const urlData = normalize_url_data(data);
              if (urlData.url != url) {
                // original url had cruft
                console.log('MISMATCHED URL. NEED TO RESTART WITH RETURNED URL. TBD');
                meta.mismatchedUrl = true;
              }
              return dispatch(setBookmarkedUrlData(urlData, meta));
            }
          }
          console.error('unknown error resolving URL');
        }
      },
      setResponseError: error => console.error(error)
    };
    nooCommandDirect('aql', params);
  };
};

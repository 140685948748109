import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Box, Image, Text, Link } from '@chakra-ui/react';
import SortableTable from 'components/Segments/SortableTable';
import PostLink from 'components/Invitations/PostLink';
import useSearchResults, { ResultLink } from 'hooks/useSearchResults';
import { truncateTimestamp } from 'utils';

const SearchResultsProfiles = props => {
  const kind = 'headlines';
  // group_id was pushed from SearchHeadlines, but doesn't appear to be used in SearchResults anywhere?
  const { data, topic, group_id } = props;
  const [parsedData, setParsedData] = useState(null);

  const {
    defaultColumnProperties,
    map_column_title,
    map_column_sx,
    get_columns
  } = useSearchResults(kind);

  useEffect(() => {
    if (data) {
      const it = data?.result?.[0] || data;
      setParsedData(it);
    }
  }, [data, setParsedData]);

  const headlines = useMemo(() => {
    if (kind === 'headlines' && parsedData?.headlines) {
      const find_url = new RegExp('//(.*?)/');
      return (
        parsedData.headlines
          .filter(headline => headline.doc)
          .map((headline, index) => {
            const {
              id,
              count,
              doc: { data, url, ts }
            } = headline;
            const timestamp = truncateTimestamp(ts);
            if (data) {
              const { title, og, twitter, description } = data;
              const fallback = find_url.test(url) ? find_url.exec(url)[1] : '';
              const source = og?.site_name || twitter?.site || fallback;
              const image = og?.image || twitter?.image || null;
              const imgsrc = image ? image.identifier || image.secure_url || image : null;
              const desc = description || og?.description || twitter?.description || '';
              return {
                count: (
                  <Text
                    as='span'
                    textAlign='center'
                    fontSize={['12px', '13px', '14px']}
                    m='0'
                    p='0'
                    display='block'
                  >
                    {count}
                  </Text>
                ),
                countSorter: -count,
                titleSorter: title,
                title: (
                  <Box>
                    <Link
                      key={index}
                      className='headline'
                      href={url}
                      target='_noo'
                      title={desc}
                      fontFamily='helvetica, arial' // "noto serif", // `"Roboto Slab", Georgia, Times, "Times New Roman", serif`, //
                      textDecoration='none'
                      color='#333'
                      fontSize={['0.9em', '1.1em', '1.2em']}
                      lineHeight='1.1em'
                      padding={['8px 0 0', '10px 0 0']}
                      display='block'
                    >
                      {title}
                    </Link>
                    {source && typeof source === 'string' ? (
                      <Text
                        fontSize={['.8em', '.9em', '1em']}
                        fontStyle='italic'
                        paddingTop={[0, '5px']}
                      >
                        {source}
                      </Text>
                    ) : null}
                  </Box>
                ),
                description: desc,
                source,
                image: (
                  <Box
                    p='0px'
                    m='0px'
                    height={['4em', '100%']}
                    width={['5em', '100%']}
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    {imgsrc && (
                      <Image
                        src={imgsrc}
                        alt={id}
                        title={id}
                        height={['auto', '100%', '100%']}
                        maxHeight={['100%', 'auto', 'auto']}
                        maxWidth={['auto', '150px', '200px']}
                      />
                    )}
                  </Box>
                ),
                ts: (
                  <Text
                    as='span'
                    textAlign='center'
                    fontSize={['12px', '13px', '14px']}
                    m='0'
                    p='0'
                    display='block'
                  >
                    {timestamp}
                  </Text>
                ),
                tsSorter: ts,
                post: <PostLink group={topic} />,
                url,
                hl: title // for deduping
              };
            }
            return null;
          })
          // lastly, filter out any falsy's from map()
          .filter(result => !!result)
          .filter(
            (value, index, self) =>
              index === self.findIndex(d => d.url === value.url || d.hl === value.hl)
          )
      );
    }
    return null;
  }, [kind, parsedData?.headlines, topic]);

  const cols = useMemo(() => {
    if (headlines?.length) {
      const cols = get_columns(headlines[0])
        .map(key => {
          return {
            ...defaultColumnProperties,
            key: key,
            name: map_column_title(_.capitalize(key), kind),
            sx: map_column_sx(key, kind)
          };
        })
        .map(col => {
          // set all padding-bottom for columns to 0:
          col.sx = {
            pb: 0
          };
          return col;
        });
      // override the count column styles:
      cols[0].sx = {
        p: [0, '0 3px'],
        m: 0
      };
      // override the image column styles:
      cols[1].sx = {
        p: 0,
        m: 0
      };
      // override the time column styles:
      cols[2].sx = {
        p: [0, '0 3px'],
        m: 0
      };
      return cols;
    }
    return [];
  }, [defaultColumnProperties, get_columns, headlines, map_column_sx, map_column_title]);

  if (!_.size(headlines)) {
    return null;
  }

  return (
    <Box mt='20px'>
      <SortableTable columns={cols} rows={headlines} />
    </Box>
  );
};

export default SearchResultsProfiles;

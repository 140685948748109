import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
// @ts-ignore
import { getSquarifiedTree, useMultiClick } from './utils';
import TreeMapLabel from './TreeMapLabel';
import { gen_color } from 'utils';

const TreeMapLeaf = ({
  leaf,
  click,
  dblclick,
  rightclick,
  labelclick,
  clickdelay = 250,
  mouseover,
  mouseout,
  depth
}) => {
  const multiClickHandler = useMultiClick(click, dblclick, undefined, clickdelay);
  const top = leaf.y0;
  const left = leaf.x0;
  const height = leaf.y1 - top;
  const width = leaf.x1 - left;
  const clickHandler = multiClickHandler(leaf);

  let newLeaf = undefined;

  if (leaf.children?.length) {
    const blankChildren = leaf.children.map(child => {
      const { x0, x1, y0, y1, normalized, ...rest } = child;
      return child;
    });
    newLeaf = { ...leaf, children: blankChildren };
  }
  const childrenSquarified = newLeaf ? getSquarifiedTree(newLeaf) : [];

  // flex outer container to easily position the internal text etc

  return (
    <Flex
      cursor='pointer'
      position='absolute'
      border={depth == 0 ? `.5px solid white` : '.5px solid #999'}
      bg={`${leaf.color}`}
      width={`${width}%`}
      height={`${height}%`}
      top={`${top}%`}
      left={`${left}%`}
      alignItems='center'
      justifyContent='center'
      onClick={clickHandler}
      onDoubleClick={clickHandler}
      onMouseEnter={e => mouseover && mouseover(leaf, e)}
      onMouseLeave={e => mouseout && mouseout(leaf, e)}
      onContextMenu={e => rightclick && rightclick(leaf, e)}
      data-id={leaf._id}
    >
      {childrenSquarified ? (
        <Box
          width='100%'
          height='100%'
          position='relative'
          backgroundColor={leaf.color}
          data-id={leaf._id}
        >
          {depth == 0 && (
            <TreeMapLabel
              click={labelclick}
              node={leaf}
              mouseover={mouseover}
              mouseout={mouseout}
            />
          )}
          <Box width='100%' height={`calc(100% - 14px)`} position='relative' data-id={leaf._id}>
            {childrenSquarified.map((child, idx) => {
              return (
                <TreeMapLeaf
                  labelclick={labelclick}
                  key={idx}
                  leaf={child}
                  clickdelay={clickdelay}
                  click={click}
                  dblclick={dblclick}
                  rightclick={rightclick}
                  mouseover={mouseover}
                  mouseout={mouseout}
                  depth={depth + 1}
                  data-id={child._id}
                />
              );
            })}
          </Box>
        </Box>
      ) : (
        <Text>{leaf.name}</Text>
      )}
    </Flex>
  );
};

export default TreeMapLeaf;

import {
  DECISIONS_REQUEST_START,
  DECISION_REQUEST_START,
  SET_DECISIONS_DATA,
  SET_DECISIONS_ERROR,
  SET_DECISION_DATA, // decision details
  SET_DECISION_ERROR,
  SET_CURRENT_OPTION_DATA,
  //   ADD_OPTION_TO_DECISION,
  ADD_OPTION_TO_DECISION_ERROR,
  //   ADD_ANSWER_TO_DECISION,
  ADD_ANSWER_TO_DECISION_ERROR,
  UPDATE_VOTE_IN_CURRENT_DECISION,
  CLEAR_ALL_DECISIONS_DATA,
  CLEAR_DECISION_DATA,
  SET_CURRENT_GROUP_ID
} from 'shared/constants/actionTypes';

const initialState = {
  isRequesting: false,
  list: null,
  error: null,
  currentDecision: null,
  currentDecisionError: null,
  currentOption: null,
  addOptionError: null,
  addAnswerError: null
};

const decisions = function (state = initialState, action) {
  switch (action.type) {
    case DECISIONS_REQUEST_START:
      console.log('DECISIONS_REQUEST_START', action);
      return {
        ...initialState,
        isRequesting: true,
        error: null,
        currentDecisionError: null,
        addOptionError: null,
        addAnswerError: null,
        list: null,
        currentDecision: null,
        currentOption: null
      };

    case DECISION_REQUEST_START:
      console.log('DECISION_REQUEST_START', action);
      return {
        ...state,
        isRequesting: true,
        currentDecisionError: null,
        addOptionError: null,
        addAnswerError: null,
        currentDecision: null,
        currentOption: null
      };

    case SET_DECISIONS_DATA:
      // console.log('SET_DECISIONS_DATA', action);
      return {
        ...state,
        isRequesting: false,
        list: action.payload?.decisions,
        paths: action.payload?.paths,
        currentDecision: null, // reset "current decision" when loading decisions list
        currentOption: null
      };

    case SET_DECISIONS_ERROR:
      console.log('SET_DECISIONS_ERROR', action);
      return {
        ...state,
        isRequesting: false,
        list: null,
        error: action.payload
      };

    case SET_DECISION_DATA:
      // console.log('SET_DECISION_DATA', action);
      return {
        ...state,
        isRequesting: false,
        currentDecision: action.payload,
        currentOption: null
      };

    case SET_DECISION_ERROR:
      console.log('SET_DECISION_ERROR', action);
      return {
        ...state,
        isRequesting: false,
        currentDecision: null,
        currentOption: null,
        currentDecisionError: action.payload
      };

    case SET_CURRENT_OPTION_DATA:
      return {
        ...state,
        currentOption: action.payload
      };

    case ADD_OPTION_TO_DECISION_ERROR:
      console.log('ADD_OPTION_TO_DECISION_ERROR', action);
      return {
        ...state,
        isRequesting: false,
        addOptionError: action.payload
      };

    case ADD_ANSWER_TO_DECISION_ERROR:
      console.log('ADD_ANSWER_TO_DECISION_ERROR', action);
      return {
        ...state,
        isRequesting: false,
        addAnswerError: action.payload
      };

    // TODO: not ready yet, too many moving parts for discrete update.
    case UPDATE_VOTE_IN_CURRENT_DECISION:
      console.log('UPDATE_VOTE_IN_CURRENT_DECISION', action);
      const { optionId, vote } = action.payload;
      // this light destructure is not triggering the redux subscribers:
      // const newState = { ...state };

      // need to do JSON dance for now to ensure "new object" for the redux subscribers
      // TODO: make a new thing called currentAnswerSet or something and subscribe to that in the hook,
      // should pick this up.
      const newState = JSON.parse(JSON.stringify(state));
      if (optionId && newState.currentDecision?.my_answer?.[0]?.data.vote) {
        newState.currentDecision.my_answer[0].data.vote[optionId] = vote;
        let updatedOption = false;
        newState.currentDecision.options = newState.currentDecision.options.map(option => {
          if (option._id === optionId) {
            console.log('found it!', option);
            updatedOption = true;
            if (vote) {
              console.log('is positive, increase count and total');
              option.count += 1;
              option.total += vote;
            } else {
              console.log('nope, negative vote.');
              option.count -= 1;
              option.total -= vote;
            }
            console.log('option after', option);
          }
          return option;
        });
        newState.currentDecision.options = JSON.parse(
          JSON.stringify(newState.currentDecision.options)
        );
        if (!updatedOption) {
          console.error('failed to update the option data!', newState.currentDecision.options);
        }
      }
      return newState;

    case CLEAR_DECISION_DATA:
      return {
        ...state,
        currentDecision: null,
        currentDecisionError: null,
        addAnswerError: null,
        addOptionError: null
      };

    case CLEAR_ALL_DECISIONS_DATA:
      return {
        ...state,
        list: null,
        error: null,
        currentDecision: null,
        currentDecisionError: null,
        addAnswerError: null,
        addOptionError: null
      };

    case SET_CURRENT_GROUP_ID:
      if (!action.payload) {
        return {
          ...state,
          currentDecision: null,
          currentDecisionError: null
        };
      }
      return state;

    default:
      return state;
  }
};

export default decisions;

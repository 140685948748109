import React, { useCallback, useEffect, useState } from 'react';
import { Button, Box, Spinner, Text, Heading } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import useInvitations from 'hooks/useInvitations';
import UserAuth from 'components/User/UserAuth';
import FontAwesomeIcon from 'utils/FontAwesomeLoader';

const InviteAccept = () => {
  const dispatch = useDispatch();
  const { invitationId, acceptInvitation, acceptResponse } = useInvitations();
  const { isAuthorized, currentNetworkData } = useNoo('InviteAccept');
  const [hasCheckedInviteId, setHasCheckedInviteId] = useState(false);
  const [invitationIsOk, setInvitationIsOk] = useState(false);
  const [invitor, setInvitor] = useState(null);
  const [inviteGroup, setInviteGroup] = useState(null);
  const { nooCommand, nooCommandResponse, nooCommandIsProcessing } = useNooApi();

  useEffect(() => {
    if (invitationId && isAuthorized && !hasCheckedInviteId) {
      setHasCheckedInviteId(true);
      dispatch(nooCommand('checkInvitation', { invitationId }));
    }
  }, [dispatch, hasCheckedInviteId, invitationId, nooCommand, isAuthorized]);

  useEffect(() => {
    if (nooCommandResponse?.command === 'checkInvitation') {
      const data = nooCommandResponse.data?.result[0];
      console.log('checkInvitation response', data);
      if (data && data.invitation) {
        console.log("YAY it's ok");
        setInvitationIsOk(true);
        const edges = data.edges || [];
        edges.forEach(edge => {
          const _id = edge._id;
          if (_id.indexOf('Groups') == 0) {
            setInviteGroup(edge);
          } else if (_id.indexOf('Persons') == 0) {
            setInvitor(edge);
          }
        });
      }
    }
  }, [nooCommandResponse?.command, nooCommandResponse?.data]);

  const onInviteClick = useCallback(
    e => {
      acceptInvitation();
    },
    [acceptInvitation]
  );

  if (acceptResponse) {
    return (
      <Box>
        <Heading as='h1'>Invitation accepted!</Heading>
        <Text>
          You are now part of the {currentNetworkData?.display || 'unknown network name'} network.
        </Text>
      </Box>
    );
  }

  if (!invitationId) {
    return (
      <Box>
        <Heading as='h1'>Sorry, we didnt find an invitation ID to process.</Heading>
        <Text>
          <a href='/'>Go back to home</a>
        </Text>
      </Box>
    );
  }

  if (isAuthorized && !invitationIsOk) {
    let content;
    if (!hasCheckedInviteId || nooCommandIsProcessing || !nooCommandResponse?.command) {
      content = (
        <Box>
          <Heading as='h2'>Checking invitation one moment please....</Heading>
          <Spinner />
        </Box>
      );
    } else {
      content = <Heading as='h2'>Sorry, the invitation is not valid.</Heading>;
      console.error('oiasdnfioansoidfnaosidfnoiasfd');
      console.log([
        nooCommandResponse?.command,
        hasCheckedInviteId,
        nooCommandIsProcessing,
        !!invitationId
      ]);
    }
    return <Box>{content}</Box>;
  }

  return (
    <Box>
      <UserAuth unauthorized='signin' redirectTo='/invite/accept'>
        <Heading as='h1'>
          You have been invited by {invitor?.data?.profile?.displayName || 'someone'} to join the{' '}
          {inviteGroup?.data?.fullname || 'unknown'} group of the{' '}
          {currentNetworkData?.display || 'unknown network name'} network!
        </Heading>
        <Button
          onClick={onInviteClick}
          leftIcon={FontAwesomeIcon({
            icon: ['fad', 'comment-smile'],
            style: { marginTop: '1px' }
          })}
        >
          Click here to accept your invitation
        </Button>
        <Text fontSize='8px'>[{invitationId}]</Text>
      </UserAuth>
    </Box>
  );
};

export default InviteAccept;

import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { Box } from '@chakra-ui/react';
import SortableTable from 'components/Segments/SortableTable';
import useSearchResults, { ResultLink } from 'hooks/useSearchResults';

const SearchResultsProfiles = props => {
  const kind = 'decisions';
  const { data, topic } = props;
  const [parsedData, setParsedData] = useState(null);

  const {
    defaultColumnProperties,
    map_column_title,
    map_column_sx,
    get_columns
  } = useSearchResults(kind);

  useEffect(() => {
    if (data) {
      const it = data?.result?.[0] || data;
      setParsedData(it);
    }
  }, [data, setParsedData]);

  const decisions = useMemo(() => {
    if (parsedData?.decisions) {
      return parsedData.decisions
        .filter(decision => decision?.data)
        .map((decision, index) => {
          const { data } = decision;
          if (data) {
            const title = data.title || data.prompt;
            return {
              titleSorter: title,
              title: (
                <ResultLink
                  as={RouterLink}
                  key={index}
                  className='decision'
                  to={`/decision-old/${decision._key}`}
                  title={data.description}
                >
                  {title}
                </ResultLink>
              ),
              description: data.description
            };
          }
        });
    }
    return null;
  }, [parsedData]);

  const cols = useMemo(() => {
    if (decisions?.length) {
      return get_columns(decisions[0]).map(key => {
        return {
          ...defaultColumnProperties,
          key: key,
          name: map_column_title(_.capitalize(key), kind),
          sx: map_column_sx(key, kind)
        };
      });
    }
    return [];
  }, [defaultColumnProperties, get_columns, decisions, map_column_sx, map_column_title]);

  if (!_.size(decisions)) {
    return null;
  }

  return (
    <Box mt='20px'>
      <SortableTable columns={cols} rows={decisions} />
    </Box>
  );
};

export default SearchResultsProfiles;

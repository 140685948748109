import React, { useMemo, useState, useCallback } from 'react';
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  HStack,
  VStack,
  Link,
  Tooltip,
  Flex,
  Image,
  Text,
  Heading,
  Box
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import TagPersonModal from '../Segments/TagPersonModal';
import NominateModal from '../Segments/NominateModal';
import ValidatePersonModal from '../Segments/ValidatePersonModal';
import _ from 'lodash';
import styled from '@emotion/styled';
import { node_normalizer, get_profile } from 'utils/graphNormalize';
import { normalize_id, norm_mastodon, capitalize } from 'utils';
import { useHistory } from 'react-router-dom';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import usePeopleNetwork from 'hooks/usePeopleNetwork';
import _SERVICES, { _SERVICE_PREFIXES } from 'shared/constants/socialServices';

import { useEffect } from 'react';
import { nooCommandDirect } from 'redux/actions/nooApi';

export const ImageConstrainer = styled.div`
  > img {
    max-width: 100%;
    border: 1px solid #000;
    margin-top: 10px;
  }
`;

const service_lookup = { linkedin: 'li', facebook: 'fb', twitter: 'tw' };

export const ServiceLink = props => {
  const { svc, username, img, sx } = props;
  let url = 'https://' + _SERVICES[svc];
  if (username) url += '/' + (_SERVICE_PREFIXES[svc] || '') + username;
  let disp = capitalize(svc);
  return (
    <Link target='_noo' href={url} fontSize='14px' sx={sx}>
      {img ? <Image src={img}></Image> : disp}
    </Link>
  );
};

const SocialLinks = props => {
  const { order, normed } = props;
  const displayData = [];
  order.forEach(field => {
    if (normed[field]) {
      if (Object.keys(_SERVICES).includes(field)) {
        const it = normed[field];
        let uname = typeof it == 'string' ? it : it.username || it.handle;
        if (uname) {
          uname = uname.replace(/@$/, ''); // some erroneous ending @s
          displayData.push({ type: field, value: uname, url: normed.url });
        }
      }
    }
  });
  const dd = displayData.map((thing, idx) => {
    if (Object.keys(_SERVICES).includes(thing.type)) {
      const svc = thing.type;
      try {
        const username = thing.value;
        let url = 'https://' + _SERVICES[svc] + '/' + (_SERVICE_PREFIXES[svc] || '') + username;
        if (svc == 'mastodon') {
          url = thing.url;
        }
        const disp =
          {
            linkedin: 'LinkedIn',
            semantic: 'Semantic Scholar'
          }[svc] || capitalize(svc);

        return (
          <div key={idx}>
            <Button
              size='xs'
              alt={thing.type}
              mr='8px'
              mb='8px'
              borderRadius='4px'
              backgroundColor='cyan.200'
              color='cyan.900'
            >
              <Link target='_noo' href={url} fontSize='14px'>
                {disp}
              </Link>
            </Button>
          </div>
        );
      } catch (e) {
        console.log('error making button, thing', thing, 'errr', e);
      }
    }
  });
  return dd;
};

const Attributes = props => {
  const { order, normed } = props;
  const displayData = [];
  order.forEach(field => {
    if (normed[field] && !Object.keys(_SERVICES).includes(field)) {
      if (!['image', 'avatar'].includes(field))
        displayData.push({ type: field, value: normed[field] });
    }
  });
  const dd = displayData.map((node, idx) => {
    const sx =
      node.type == 'bioregion'
        ? { fontWeight: 'bold', fontStyle: 'italic' }
        : node.type == 'location'
        ? { fontStyle: 'italic' }
        : {};
    return (
      <Box backgroundcolor={'red'} sx={sx} key={idx}>
        {node.value}
      </Box>
    );
  });
  return dd;
};
const inline = { display: 'inline' };
const ShortPath = props => {
  const arrow = '=>';
  const labels = 'ABCDEFGHIJKLMNOP';
  const { path, vis } = props;
  let dd = [];
  try {
    dd = path.map((node, idx) => {
      const title = idx == path.length - 1 && vis == 'none' ? 'PRIVATE' : node.fullname;
      const display = title[0].toUpperCase();
      return (
        <Text sx={inline} key={idx}>
          <Tooltip label={title}>
            <Link
              as={RouterLink}
              to={'/user/' + node.id.replace('Persons/', '')}
              sx={{
                ...inline,
                borderRadius: '10px',
                backgroundColor: '#73a1b1',
                color: 'white',
                padding: ' 0px 5px 0px 5px'
              }}
              title={title}
            >
              {display}
            </Link>
          </Tooltip>
          {idx < path.length - 1 && <Text sx={inline}>{' > '}</Text>}
        </Text>
      );
    });
  } catch (e) {
    console.log('short path error', e);
    dd = [];
  }
  return dd;
};

const UserCard = props => {
  const default_order = [
    ...['avatar', 'fullname', 'description', 'location', 'bioregion'],
    ...Object.keys(_SERVICES)
  ];
  const [shortest, setShortest] = useState(null);

  const alts = { fullname: 'displayName' };
  let { data, fields, order, node } = props;
  if (!data) {
    return null;
  }
  const { currentNetworkData, nooUser } = useNoo('UserCard');
  const isGreencheck = currentNetworkData?.id === 'greencheck';
  const isClimate = currentNetworkData?.id === 'climate';
  const isWho = currentNetworkData?.id === 'whoknows';
  const showPraise = isGreencheck || isClimate;
  const showNominate = isWho;
  const idd = props.id || props._id;
  order = order || default_order;
  const profile = node || get_profile(data);
  const normed = node_normalizer(profile);
  const visibility = normed.vis;
  const imageUrl = normed.avatar || normed.image;

  const _getShortestPath = (source, target, nooCommandDirect, onSuccess, onError, loading) => {
    const payload = {
      query_name: 'aqlShortestPath',
      data: { source, target }
    };
    const params = {
      payload,
      setLoading: loading || (() => {}),
      setResponse: response => {
        onSuccess(response);
      },
      setResponseError: error => {
        console.log('error ', error);
        onError(error);
      }
    };
    nooCommandDirect('aql', params);
  };

  const getShortest = useCallback(
    args => {
      const source = nooUser?.person?._id;
      const target = data.id || data._id;
      const onSuccess = response => {
        const path = response.result[0];
        let shortest = [];
        path.forEach(pair => {
          const vert = pair[0];
          const data = vert.data;
          let fullname =
            data.profile?.fullname ||
            data.profile?.displayName ||
            data.linkedin?.fullname ||
            data.fullname ||
            data.title;
          if (vert._id.indexOf('Groups/') == 0) fullname += ' group';
          shortest.push({ fullname, id: vert._id });
        });
        setShortest(shortest);
      };
      const onError = error => {
        console.log('error ', error);
      };
      _getShortestPath(source, target, nooCommandDirect, onSuccess, onError);
    },
    [data, nooUser?.person?._id]
  );

  const bioregion = normed.bioregion;
  return (
    <ImageConstrainer sx={{ borderTop: '4px solid #eee', pt: '10px', mt: '10px' }}>
      {imageUrl && <img src={imageUrl} title={normed.name} />}
      <Attributes order={order} normed={normed} />
      <SocialLinks order={order} normed={normed} />
      <Button backgroundColor='#cc99cc' mr='8px' mb='8px' onClick={getShortest}>
        Shortest Path
      </Button>
      {shortest && <ShortPath path={shortest} vis={visibility} />}
      {!showPraise && !showNominate && (
        <Flex>
          <Tooltip label='Coming soon: validate'>
            <Button
              size='xs'
              alt={'Coming Soon'}
              onClick={e => e.preventDefault()}
              mr='8px'
              mb='8px'
              borderRadius='4px'
              backgroundColor='cyan.200'
              color='cyan.900'
            >
              Validate
            </Button>
          </Tooltip>
          <Tooltip label='Coming soon: delegate'>
            <Button
              size='xs'
              alt={'Coming Soon'}
              onClick={e => e.preventDefault()}
              mr='8px'
              mb='8px'
              borderRadius='4px'
              backgroundColor='cyan.200'
              color='cyan.900'
            >
              Delegate
            </Button>
          </Tooltip>
        </Flex>
      )}
      <HStack>
        {(showPraise || showNominate) && <ValidatePersonModal target={normed} />}
        {showPraise && <TagPersonModal target={normed} />}
        {showNominate && (
          <NominateModal target={normed} preset={isWho ? { label: bioregion } : null} />
        )}
      </HStack>
    </ImageConstrainer>
  );
};

export function PopOverUser(props) {
  const history = useHistory();
  const clickUser = e => {
    // setKeepOpen(!keepOpen);
    const personId = e.target.dataset.id.replace('Persons/', '');
    history.push('/user/' + personId);
  };
  const def_order = [
    ...['avatar', 'description', 'location', 'bioregion'],
    ...Object.keys(_SERVICES)
  ];

  const { node, _id, shrink, order = def_order } = props;
  let { tooltip } = props;
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);
  // const ukey = node?._id ? node._id.replace('Persons/', '') : '';
  const idd = _id || node?._id || node?.id;
  const ukey = idd ? idd.replace('Persons/', '') : '';
  const onClick = props.onClick || clickUser;
  const fullname = node.fullname || node.displayName || node.name || '';
  const fn = fullname.length > 20 ? fullname.slice(0, 17) + '...' : fullname;
  node.fullname = fullname;
  tooltip = tooltip && tooltip?.length > 0 ? tooltip : fullname != fn ? fullname : '';
  return (
    <>
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={close}
        placement='bottom'
        closeOnBlur={true}
      >
        <PopoverTrigger>
          <Button
            data-id={_id}
            data-fullname={fullname}
            mr={5}
            onClick={open}
            onMouseEnter={props.onHover}
            // onClick={props.onClick || clickUser}
            size='sm'
            m='4px 6px'
            backgroundColor='gray.200'
            color='blue.700'
            _hover={{
              backgroundColor: 'cyan.600',
              color: 'cyan.100'
            }}
          >
            <Tooltip label={tooltip}>{fn}</Tooltip>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          bg='#eee'
          borderColor='#333'
          borderWidth='1px'
          width={shrink ? 'auto' : undefined}
        >
          <PopoverCloseButton />
          <PopoverHeader fontWeight='semibold' pr={shrink ? '10' : undefined}>
            <Link as={RouterLink} to={'/user/' + ukey}>
              {fullname}
            </Link>
            <div>
              {' '}
              <Link as={RouterLink} to={'/search_network/?user=' + ukey} fontWeight='normal'>
                {'(network)'}
              </Link>
            </div>
          </PopoverHeader>
          <PopoverArrow />
          <PopoverBody>
            <UserCard data={node} order={order} node={node} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
}

export function Claims(props) {
  const { nooCommandDirect } = useNooApi();
  const [display, setDisplay] = useState([]);
  const [claimed, setClaimed] = useState([]);
  const { personId, data_cb } = props;

  useEffect(() => {
    if (personId) {
      const payload = {
        query_name: 'aqlEdgesType',
        data: { types: ['CLAIM'], filters: { source: personId } }
      };
      const params = {
        payload,
        setLoading: () => {},
        setResponse: response => {
          const data = response.result[0];
          const targets = data.edges.map(edge => {
            return edge._to;
          });
          const profiles = _.filter(data.profiles, profile => {
            return targets.includes(profile._id);
          });
          setClaimed(profiles);
          if (data_cb) data_cb(profiles);
        },
        setResponseError: error => {
          console.log('error ', error);
          setClaimed(null);
        }
      };
      nooCommandDirect('aql', params);
    }
  }, [personId, nooCommandDirect, data_cb]);

  const OProfile = props => {
    const norm_fullname = fn => {
      return fn.replace(' ', '-');
    };
    const { svc, record, uid } = props;
    const username = record.username;
    let url = 'https://' + _SERVICES[svc] + '/' + (_SERVICE_PREFIXES[svc] || '') + username;
    if (url.indexOf('{fullname}') > -1) {
      // semantic scholar
      url = url.replace('{fullname}', norm_fullname(record.fullname));
    }
    if (svc == 'mastodon') {
      url = record.url;
    }
    const disp = svc.toUpperCase();
    const imageUrl = record.image;
    const prefix = svc == 'mastodon' && record.server ? record.server + '/ ' : '';
    return (
      // <ImageConstrainer sx={{ borderTop: '4px solid #eee', pt: '10px', mt: '10px' }}>
      <Link target='_noo' key={uid} href={url} fontSize='14px'>
        <HStack>
          <Text width={'22%'} fontSize='14px'>
            {disp}
          </Text>
          {imageUrl && (
            <Image
              src={imageUrl}
              title={prefix + username}
              sx={{ width: '50px', height: '50px' }}
            />
          )}
          <Text width={'28%'}>{record.fullname}</Text>
          <Text width={'30%'}>{prefix + '@' + record.username}</Text>
        </HStack>
      </Link>
      // </ImageConstrainer>
    );
  };

  const oneProfile = useCallback((record, i) => {
    const data = record?.data;
    let rtrn = null;
    if (data) {
      Object.entries(_SERVICES).forEach(pair => {
        const svc = pair[0];
        const dta = data[svc];
        if (dta) {
          const key = (dta.server || svc) + i;
          rtrn = <OProfile svc={svc} record={dta} uid={key} key={key} />;
        }
      });
    }

    return rtrn;
  }, []);

  useEffect(() => {
    if (claimed) {
      let items = [];
      claimed.forEach((one, i) => {
        const it = oneProfile(one, i);
        if (it) items.push(it);
        // items.push(<Text>{one._id}</Text>);
      });
      if (items) {
        setDisplay(items);
      }
    }
  }, [claimed, oneProfile]);

  return (
    <>
      {display?.length > 0 && (
        <Heading as='h2' fontSize='20px'>
          Claimed
        </Heading>
      )}
      <VStack align='left' maxWidth={'600px'}>
        {display}
      </VStack>
    </>
  );
}

export default UserCard;

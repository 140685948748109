import React, { useMemo, useState } from 'react';
import { AvatarGroup, Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import Avatar from './Avatar';
import UserName from './UserName';

// TODO: custom default icon?

const ParticipantList = props => {
  const { participants, size, divider, max, spacing = -4 } = props;

  // re-map participant id => info dict:
  const users = useMemo(
    () =>
      participants ? Object.entries(participants).map(pair => ({ _id: pair[0], ...pair[1] })) : [],
    [participants]
  );

  const [fullView, setFullView] = useState(false);

  const toggleFullView = () => {
    setFullView(!fullView);
  };

  if (!users) {
    return null;
  }

  if (fullView) {
    return (
      <Box position='absolute' width='102%' height='102%' zIndex={10} backgroundColor='white'>
        <Heading as='h1' fontWeight='400' fontSize='xl' mb={2}>
          Participants:
        </Heading>
        {users.map((u, idx) => {
          const { name, avatar, online, away } = u;
          return (
            <Flex key={idx} gap={2} borderTop='1px solid #eee' pt='3px' mt='3px'>
              <Avatar name={name} size={size} src={avatar} online={online} away={away} />
              <Box>
                <UserName name={name} />
                <Text as='em'>Other stuff... bio or something</Text>
              </Box>
            </Flex>
          );
        })}
        <Button onClick={toggleFullView} size='sm' float='right' mt='4px'>
          ... back to chat
        </Button>
      </Box>
    );
  }

  return (
    <Box onClick={toggleFullView} cursor='pointer'>
      {divider === 'top' ? <Box h='1px' borderBottom='1px solid #eee' pb='4px' mb='8px' /> : null}
      <AvatarGroup size={size} max={max} spacing={spacing}>
        {users.map((u, idx) => {
          const { name, avatar, online, away } = u;
          return (
            <Avatar key={idx} name={name} size={size} src={avatar} online={online} away={away} />
          );
        })}
      </AvatarGroup>
      {divider === 'bottom' ? (
        <Box h='1px' borderBottom='1px solid #eee' pb='4px' mb='8px' />
      ) : null}
    </Box>
  );
};

export default ParticipantList;

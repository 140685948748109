import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import QRCode from 'react-qr-code';
import { Button, VStack, Heading, Text, Box } from '@chakra-ui/react';
import useNoo from 'hooks/useNoo';
import useFirebaseAuth from 'hooks/useFirebaseAuth';
import useFanAuth from 'hooks/useFanAuth';
import useAuthRedirect from 'hooks/useAuthRedirect';
import FirebaseEmailLogin from './FirebaseEmailLogin';

// show or hide the red FAN error text in the UI
const showFanPollError = true;

const Register = () => {
  const { signInWithGoogle, signInWithTwitter } = useFirebaseAuth();
  const { redirectOnAuth } = useAuthRedirect();
  const { deepLinkLogin, checkForOtt } = useFanAuth();
  const { googleUser, fanUser, fanOttError, currentNetwork: netname, siteSettings } = useNoo(
    'Register'
  );
  const isMastodon = netname == 'ph';

  // eslint-disable-next-line no-undef
  const [showEmailSignin, setShowEmailSignin] = useState(false);
  const [status, setStatus] = useState('');
  const [checkFanError, setCheckFanError] = useState('');

  useEffect(() => {
    if (googleUser) {
      redirectOnAuth();
    }
  }, [googleUser, redirectOnAuth]);

  useEffect(() => {
    let pollInterval;
    if (!fanUser && !checkFanError && !fanOttError && !isMobile) {
      // not using async/await on purpose, no need to block the thread while polling
      pollInterval = setInterval(() => {
        checkForOtt(setCheckFanError);
      }, 2000);
    }

    return () => {
      if (pollInterval) clearInterval(pollInterval);
    };
  }, [fanUser, fanOttError, checkFanError, setCheckFanError, checkForOtt]);

  const fanQrCode = useMemo(() => {
    if (!isMobile) {
      if (deepLinkLogin) {
        return (
          <Box>
            <Heading as='h2' sx={{ fontWeight: 'normal', fontSize: '18px', mt: '1em' }}>
              Login with your FAN App on your phone:
            </Heading>
            <Box sx={{ p: '8px', backgroundColor: '#fff', maxW: '256px', m: '0 auto' }}>
              <QRCode
                value={deepLinkLogin}
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                viewBox={`0 0 256 256`}
              />
            </Box>
          </Box>
        );
      }
      return (
        <Box sx={{ maxW: '260px' }}>
          <Heading as='h3'>
            There was an error generating the QR Code for FAN Login, sorry. Please try later!
          </Heading>
        </Box>
      );
    }

    return null;
  }, [deepLinkLogin]);

  const fanLogin = async () => {
    if (deepLinkLogin) {
      setStatus('FAN url: ' + deepLinkLogin);
      window.open(deepLinkLogin, '_blank');
    } else {
      console.log('failed to generate the fan login URL!');
    }
  };

  // const email_enabled = false;
  return (
    <VStack className='App-row'>
      <Heading as='h1'>{'Sign in'}</Heading>
      {!showEmailSignin && (
        <VStack spacing='4'>
          <VStack maxWidth={'500px'} textAlign='left'>
            <Text>
              <strong>Important: </strong>
              For now, we prefer Google login. If you do use a personal email, we&apos;re trusting
              you to only use real email addresses. We don&apos;t yet validate those with 2FA. We{' '}
              <strong>DO NOT </strong>
              share or otherwise use your login information.
            </Text>
          </VStack>
          <Button onClick={signInWithGoogle}>Sign in with Google</Button>
          {isMastodon && <Button onClick={signInWithTwitter}>Sign in with Twitter</Button>}
          <Button onClick={() => setShowEmailSignin(true)}>Sign in with your personal Email</Button>
          {isMobile ? <Button onClick={fanLogin}>Login with FAN</Button> : null}
          {!isMobile ? fanQrCode : null}
          <Text>Other methods coming soon.</Text>
          {status && (
            <Box width='50%'>
              <Text>{status}</Text>
              <Text>{JSON.stringify(siteSettings, null, 2)}</Text>
            </Box>
          )}
        </VStack>
      )}
      {showEmailSignin && (
        <Box>
          <Heading as='h3' size='lg' fontWeight='normal' textAlign='center'>
            Login or Create an account
          </Heading>
          <FirebaseEmailLogin />
          <Button onClick={() => setShowEmailSignin(false)}>
            ... or, back to other options to sign in
          </Button>
        </Box>
      )}
      {(fanOttError || checkFanError) && showFanPollError && (
        <Text maxWidth={'500px'} textAlign='center' color='red' fontSize='10px'>
          There was an error checking the FAN token for login.
          <br />
          If you were trying to login with FAN, please refresh the page and try again.
        </Text>
      )}
    </VStack>
  );
};

export default Register;

// firebase config

const firebaseConfig = {
  apiKey: 'AIzaSyAcwui5yfAkbedIZvW43AoAVux6UFlef6o',
  authDomain: 'insightnetwork.firebaseapp.com',
  databaseURL: 'https://insightnetwork.firebaseio.com',
  projectId: 'insightnetwork',
  storageBucket: 'insightnetwork.appspot.com',
  messagingSenderId: '961331983038',
  appId: '1:961331983038:web:0a50fec097a89978c1e4ef',
  measurementId: 'G-P3F2PZY0ZE'
};

export default firebaseConfig;

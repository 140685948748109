import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Tooltip,
  Button,
  Box,
  Text,
  HStack
} from '@chakra-ui/react';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import EditableVector from 'components/Segments/EditableVector';

import _ from 'lodash';
import { inputs } from '@theme-ui/preset-tailwind';

const TagPersonModal = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inEdges, setInEdges] = useState(null);
  const [respectEdges, setRespectEdges] = useState(null);
  const [message, setMessage] = useState('');
  // const dispatch = useDispatch();
  const { nooUser } = useNoo('TagPersonModal');
  const { nooCommandDirect } = useNooApi();
  const valid_marker = (nooUser?.claimed || []).length > 1; // (nooUser?.valids || []).length > 0 &&
  const disabled = !valid_marker;

  const { target, prefix } = props;
  const fullname = target.fullname;
  const targetId = target._id || target.id;
  const sourceId = nooUser?.person?._id;

  const firstOpen = () => {
    onOpen();
    setInEdges(null);
    const person_id = target?.id || target?._id;
    if (person_id) {
      const payload = { query_name: 'aqlRespectEdges', data: { person_id } };
      const params = {
        payload,
        setLoading: () => {},
        setResponse: response => {
          const data = response.result[0];
          setInEdges(data.edges);
        },
        setResponseError: error => {
          console.log('error ', error);
        }
      };
      nooCommandDirect('aql', params);
    }
  };

  const endorsements = useMemo(() => {
    if (inEdges) {
      let out = {};
      inEdges.forEach(one => {
        if (one.data) {
          out[one._from] = one.data;
        }
      });
      return out;
    }
    return null;
  }, [inEdges]);

  const updateTags = data => {
    setRespectEdges(data);
  };

  useEffect(() => {
    let msg = '';
    if (!valid_marker)
      msg += 'You are not yet authorized because you have not yet claimed at least two accounts';
    if (!valid_marker) {
      msg = 'Sorry. ' + msg;
    }
    setMessage(msg);
  }, [valid_marker]);

  const onSubmit = e => {
    const mine = respectEdges[sourceId];
    console.log('submitting', JSON.stringify(mine));
    if (mine) {
      const params = {
        from_id: sourceId,
        to_id: targetId,
        type: 'RESPECT',
        target_data: null,
        edge_data: mine,
        setLoading: () => {},
        setResponse: response => {
          console.log('updated edge response ', response);
        },
        setResponseError: error => {
          console.log('error ', error);
        }
      };
      nooCommandDirect('createEdge', params); // This only overwrites my edge on submit, because noone else can change that edge
      // update the tag cloud
    }

    onClose();
  };

  return (
    <Box>
      <Button onClick={firstOpen} bgColor={'#89df6f'} title={'Praise ' + fullname}>
        {typeof prefix == 'string' ? prefix : 'Praise'}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>What do you love about {fullname}?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Tell us what domains they are expert in, their relative expertise in each, what sets
              them apart.
            </Text>

            <EditableVector
              sourceId={sourceId}
              endorsements={endorsements}
              onTagChange={updateTags}
              prompt={'Add a word or phrase'}
              allowTaxos={false}
            />
          </ModalBody>

          <ModalFooter>
            <Tooltip label={message}>
              <span>
                <Button colorScheme='blue' mr={3} onClick={onSubmit} disabled={disabled}>
                  Submit
                </Button>
              </span>
            </Tooltip>
            <Button variant='ghost' onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TagPersonModal;

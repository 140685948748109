import React, { useState, useEffect, useRef, useCallback } from 'react';
import useNoo from 'hooks/useNoo';
import { useDispatch } from 'react-redux';
import GroupSelector from 'components/Segments/GroupSelector';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { Flex, Box, Input, Textarea, Button, Heading } from '@chakra-ui/react';
import styled from '@emotion/styled';
import useNooApi from 'hooks/useNooApi';
import UserAuth from 'components/User/UserAuth';
import PeopleSelector from 'components/Segments/PeopleSelector';

const EdgeUpdate = () => {
  const [sourceId, setSourceId] = useState(null);
  const [targetId, setTargetId] = useState(null);
  const [inputs, setInputs] = useState({
    data: null,
    type: null,
    _from: null,
    _to: null
  });
  const { nooUser } = useNoo('EdgeUpdate');
  const dispatch = useDispatch();
  const {
    nooCommand,
    nooCommandResponse,
    nooCommandResponseError,
    nooCommandIsProcessing
  } = useNooApi();

  const updateInputs = e => {
    const myInputs = { ...inputs };
    console.log('INPUT', e.target);
    const val = e.target.value;
    myInputs[e.target.name] = val && val.length > 0 ? val : null;
    if (e.target.name == 'data') {
      try {
        myInputs[e.target.name] = JSON.parse(e.target.value);
        console.log('valid', myInputs[e.target.name]);
      } catch (error) {
        console.log('INVALID data, does not JSON.parse', e.target.value);
        return;
      }
    }
    setInputs(myInputs);
  };

  const onSubmit = event => {
    if (!disabled) {
      // tautology bc could not get here

      let update_data = {};
      _.each(inputs, (val, key) => {
        if (key != 'edge_id' && val != null) {
          update_data[key] = val == 'null' ? null : val;
        }
      });

      dispatch(
        nooCommand('updateEdge', {
          edge_id: inputs.edge_id,
          update_data
        })
      );
    } else {
      console.log('INCOMPLETE', sourceId, targetId, inputs.type);
    }
  };
  const others = _.some(inputs, (v, k) => {
    return k != 'edge_id' && v;
  });
  console.log('others', others);
  let disabled = inputs.edge_id && others ? false : true;
  return (
    <Box>
      <UserAuth unauthorized='signin'>
        <Box
          sx={{
            backgroundColor: '#eee',
            marginTop: '10px',
            padding: '10px',
            border: '1px solid #444'
          }}
        >
          <Heading as='h3'>Update Edge</Heading>
          <Box sx={{ flex: '1 1 auto' }}>
            <Input
              sx={{ height: '30px', padding: '1px' }}
              type='text'
              id='edge_id'
              name='edge_id'
              placeholder='enter edge id'
              onChange={updateInputs}
            />
          </Box>
          <Box sx={{ flex: '1 1 auto' }}>
            <Input
              sx={{ height: '30px', padding: '1px' }}
              type='text'
              id='_from'
              name='_from'
              placeholder='enter source_id'
              onChange={updateInputs}
            />
          </Box>
          <Box sx={{ flex: '1 1 auto' }}>
            <Input
              sx={{ height: '30px', padding: '1px' }}
              type='text'
              id='_to'
              name='_to'
              placeholder='enter target_id'
              onChange={updateInputs}
            />
          </Box>

          <Box sx={{ flex: '1 1 auto' }}>
            <Input
              sx={{ height: '30px', padding: '1px' }}
              type='text'
              id='data'
              name='data'
              placeholder='enter data'
              onChange={updateInputs}
            />
          </Box>
          <Box sx={{ flex: '1 1 auto' }}>
            <Input
              sx={{ height: '30px', padding: '1px' }}
              type='text'
              id='type'
              name='type'
              placeholder='enter type'
              onChange={updateInputs}
            />
          </Box>

          <Box>
            <Button
              variant='pill'
              sx={{ height: '30px', padding: '3px' }}
              onClick={onSubmit}
              disabled={disabled}
            >
              {'Submit'}
            </Button>
          </Box>
        </Box>
      </UserAuth>
    </Box>
  );
};

export default EdgeUpdate;

const markdown = `
***Perspectiva*** is a *decentralized editorial network*, arising out of the *[Emerge Network](https://www.whatisemerging.com/)*, focused on regeneration and emergence, and leveraging the latest in decentralized curation and communication.

### Decentralized Editorial Network
We all have people whose knowledge, worldview, and curatorial sense we admire. *Perspectiva* leverages that at scale to:

*  Form an invitation-only fractal *trust network* of those great people, organized by domain of expertise
*  Harvest auto-curated feeds from existing public behavior on social media
*  Further curate those with minimal effort into high quality, domain-specific *Perspectiva* feeds. 
*  Publish / Syndicate the results
*  Reach large audiences through the collective marketing reach of its participants
*  Function as a Commons whose collective value is governed by its participants and distributed via token economics.

Starting with acknowledged subject matter experts in any domain, each domain-specific subnetwork grows by invitation to form a highly vetted and accountable *emergent editorial board*. Their light touch collective curation is *the* antidote to *fake news*.

***Perspectiva*** is intended as cooperative made up of its editorial members and its readers. It has high value because of the quality of its participants and its output. That value can be monetized many ways, and distributed to the members of the coop using [token economics](https://www.oreilly.com/library/view/what-is-the/9781492072973/ch01.html). 

### Web3 / DAOs / Crypto
The *trust networks* of editorial participants are formed as participants make their *trust relationships* *machine-readable* by inviting and vouching for others.

The result is comparable to cryptographic *[webs of trust](https://en.wikipedia.org/wiki/Web_of_trust)*, capable of emergent collective decision-making and governance. Here's the [2005 Smartocracy whitepaper](https://markorodriguez.files.wordpress.com/2011/01/smartocracy-hicss2007.pdf) on the science behind it.

As such, it is ideal for manifestation as overlapping *decentralized autonomous organizations* that can maximize their collective value and equitably distribute the results. 

The current DAO landscape is a zero-sum game, with each DAO fighting over the same few early adopters. *Perspectiva* federates many overlapping domains of expertise that can share participants and the work thereof. It is natural to form one or more DAOs therefrom, backed by the real and significant assets: authentic news and audiences. The Smartocracy embedded in the networks also solves a major source of dysfunction in current DAOs, *governance*.

### Trojan Horse
We believe such a network is ideally suited for the emerging domain of *decentralized governance*. By starting simple with a social bookmarking tool, we can form overlapping trust networks and experiment with how to leverage them.

### Components

***BestOfNow*** is quite simple. It is made up of:

1. A [*Trust Network*](#trust-networks) formed as described above, starting from one or more acknowledged sages on hundreds of subjects. These act as domain-focused *decentralized editorial boards*.
2. Social Media *antennae* (crawlers) watching 100s of Twitter lists focused on specific topics
1. Auto-curated feeds distilling all recent activity from those, as low-noise starting point. 
1. A Social Bookmarker used by #1 to cherry-pick and rank the best of #3
2. Numerous *export* options for publishing/syndicating/monetizing the results of #4
3. Token economics for crediting contribution and distributing assets.


### Current state
NooNet has a fully functional MVP of #1-4 above. We expect to begin growing the editorial network in February 2022. To scale as envisioned will require migration of crawling and hosting capability. For instance, we are currently crawling about 20 topics. We expect to be crawling 100s as soon as possible. 

We have a Holochain prototype of #2 above that can easily decentralize the crawling and auto-curation load.

Initial syndication / distribution will start through partners such as [Tru](https://www.tru.net/), [Factr](https://factr.com/), [Connective Networks](https://www.connective.network/), and [Open Impact](https://www.openfuturecoalition.org/).

### Contact

*Perspectiva* is a fiscally sponsored project of [Planetwork NGO](https://www.planetwork.net/about), a California 501(c)3. 

Email: noosociety@protonmail.com

    



    
              
`;

export default markdown;

import React from 'react';
import { TagCloud } from 'react-tagcloud';
import { Box } from '@chakra-ui/react';

const data = [
  { value: 'jQuery', count: 25 },
  { value: 'MongoDB', count: 18 },
  { value: 'JavaScript', count: 38 },
  { value: 'React', count: 30 },
  { value: 'Nodejs', count: 28 },
  { value: 'Express.js', count: 25 },
  { value: 'HTML5', count: 33 },
  { value: 'CSS3', count: 20 },
  { value: 'Webpack', count: 22 },
  { value: 'Babel.js', count: 7 },
  { value: 'ECMAScript', count: 25 },
  { value: 'Jest', count: 15 },
  { value: 'Mocha', count: 17 },
  { value: 'React Native', count: 27 },
  { value: 'Angular.js', count: 30 },
  { value: 'TypeScript', count: 15 },
  { value: 'Flow', count: 30 },
  { value: 'NPM', count: 11 }
];
/* CSS:
@keyframes blinker {
  50% { opacity: 0.0; }
}
*/
const options = {
  luminosity: 'light',
  hue: 'blue'
};

const customRenderer = (tag, size, color) => (
  <span
    key={tag.value}
    style={{
      animation: 'blinker 3s linear infinite',
      animationDelay: `${size * 0.3}s`, // `${Math.random() * 2}s`, // '.1s', //
      fontSize: `${size / 1}em`,
      // border: `2px solid ${color}`,
      margin: '3px',
      padding: '3px',
      display: 'inline-block'
      // color: 'white'
    }}
  >
    {tag.value}
  </span>
);

//
// minSize={12} maxSize={35}
function SimpleCloud(props) {
  const myData = props.data || data;
  return (
    <Box className='cloud-wrapper'>
      <TagCloud
        tags={myData}
        onClick={props.onClick}
        colorOptions={options}
        disableRandomColor={false}
        // className='simple-cloud'
        minSize={12}
        maxSize={20}
        shuffle={false}
        // renderer={customRenderer}
      />
    </Box>
  );
}

export default SimpleCloud;

import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const processBody = (body, paragraphMargin) => {
  if (typeof body === 'string') {
    const lines = body.split(/\n/);
    if (lines.length) {
      const stack = lines
        .map((line, idx) => {
          if (line) {
            return (
              <Text key={idx} mb={idx < lines.length - 1 ? paragraphMargin : 0}>
                {line}
              </Text>
            );
          }
          return null;
        })
        .filter(line => !!line);
      return stack;
    }
  }
  return body;
};

const MessageBody = props => {
  const {
    body,
    color = 'gray.500',
    size = 'sm',
    fontStyle = 'inherit',
    // padding = '2',
    paragraphMargin = '2'
  } = props;

  // TODO: what do we do if there's a system problem, giving an empty message?
  // throw an error, display "message not found"?
  if (!body) {
    return null;
  }

  const _body = processBody(body, paragraphMargin);

  if (typeof _body !== 'string') {
    return (
      <Box fontSize={size} color={color} fontStyle={fontStyle}>
        {_body}
      </Box>
    );
  }

  return (
    <Text fontSize={size} color={color} fontStyle={fontStyle}>
      {_body}
    </Text>
  );
};

export default MessageBody;

import _ from 'lodash';
import { linearInterp, shuffle, isObject, isHtml } from 'utils';
import _SERVICES from 'shared/constants/socialServices';
import { _DEFAULT_AVATAR } from 'shared/constants/misc';

// Convert standard graph search results to react-force-graph and react-d3-graph format
const _DEFAULT_NODE_SIZE = 100;
const _DEFAULT_MOJO = 50;
const _DEFAULT_WEIGHT = 1;
const _DEFAULT_INS = [];
const _MIN_SIZE = 10;
const _MAX_SIZE = 100;

const print_links = lnks => {
  // for debugging
  let test = _.map(lnks, link => {
    return link.source + ' | ' + link.target;
  });
  test.sort();
  test.forEach((one, idx) => console.log(idx, one));
};

const get_ins_and_outs = (nodes, links) => {
  let counts = [];
  nodes.forEach(node => {
    const sources = _.filter(links, link => {
      return link.target == node.id;
    });
    node.ins = _.map(sources, 'source');
    node.in_count = node.ins.length;
    counts.push(node.ins.length);
    const targets = _.filter(links, link => {
      return link.source == node.id;
    });
    node.outs = _.map(targets, 'target');
  });
  const max_ins = _.max(counts);
  const min_ins = _.min(counts);
  nodes.forEach(node => {
    node.weight = linearInterp(node.in_count, min_ins, max_ins, 0, 1, 0.6);
    node.size = linearInterp(node.weight, 0, 1, _MIN_SIZE, _MAX_SIZE, 1);
  });
};

export const combine_graphs = (graph1, graph2, maxNodes) => {
  const pair = link => {
    return [link.source, link.target].join('|');
  };
  let nodes = _.unionBy(graph1.nodes, graph2.nodes, 'id');
  let links = _.unionBy(graph1.links, graph2.links, pair);
  _.sortBy(links, link => {
    return [link.source, link.target];
  });
  return { nodes, links };
};

export const graph_normalize = ({
  data,
  filters,
  maxNodes,
  integer_ids,
  allowlist = [],
  obfuscate
}) => {
  // TBD: HANDLE NODES WITHOUT EDGES BETTER
  // input as standard search results from graph requests
  // allowlist sb logged in user's connections. obfuscate is set in each app/network the config/index.js
  const center = data?.person_id;
  const { nodes, profiles } = { ...data };
  let { edges } = { ...data };
  // filters look like  {exclude: {'provisional': true}} // need to generalize for include, and comparison e.g. > or startswith...
  const nodes_in = nodes || profiles;
  let bad = 0;
  let all_nodes = _.map(
    _.filter(nodes_in, node => (node ? true : false)), // filter out missing nodes, shouldn't happen, but...
    node => {
      const normed = node_normalizer(get_profile(node), allowlist, obfuscate);
      if (!normed.fullname) {
        bad += 1;
      }
      if (normed.id == center) {
        normed.priority = 1;
      }
      return normed;
    }
  );
  if (bad > 0) console.log('BAD', bad);
  let key_lookup = {}; // ids are being coerced to integers
  if (integer_ids) {
    all_nodes.forEach((node, idx) => {
      node.id = idx;
      key_lookup[node.key] = idx;
    });
  }
  if (isObject(edges)) {
    // new format {to: [froms], to: [froms]} // more compact. Might want to make {from: [tos]...}
    let out = [];
    Object.entries(edges).forEach(([_to, _froms]) => {
      _froms.forEach(_from => {
        out.push([_from, _to]);
      });
    });
    edges = out;
  }
  const all_links = _.map(edges, edge => {
    return edge_normalizer(edge);
  });
  if (integer_ids) {
    all_links.forEach(link => {
      link.source = key_lookup[link.source];
      link.target = key_lookup[link.target];
    });
  }
  get_ins_and_outs(all_nodes, all_links);
  all_nodes = _.sortBy(all_nodes, [
    function (nd) {
      return [nd.in_count || -1, nd.location]; // handles incoming connections with no loc or ins
    }
  ]).reverse();

  if (filters) {
    Object.entries(filters.exclude).forEach(pair => {
      all_nodes = all_nodes.filter(node => {
        return node[pair[0]] != pair[1];
      });
    });
  }

  if (maxNodes && maxNodes < all_nodes.length) {
    all_nodes = all_nodes.slice(0, maxNodes);
  }

  let all_ids = _.uniq(_.map(all_nodes, 'id')).filter(one => (one ? true : false)); // get all valid ids

  let links_out = all_links.filter(
    one => _.includes(all_ids, one.source) && _.includes(all_ids, one.target)
  );
  let links_uniq = _.uniqBy(links_out, function (edg) {
    let pair = [edg.source, edg.target];
    // pair.sort(); // TBD: this ensure only one edge per pair. Don't do it here
    return pair.join('|'); // edg.source; //
  }); // remove duplicate edges if any
  let all_endpoints = _.uniq(
    _.concat(_.map(links_out, 'source'), _.map(links_out, 'target'))
  ).sort();
  let nodes_out = _.filter(all_nodes, node => {
    // remove unconnected nodes
    return true; // allow unconnected nodes // _.includes(all_endpoints, node.id); // don't allow unconnected //
  });
  const graph = { nodes: nodes_out, links: links_uniq, directed: true, focusedNodeId: null };
  return graph;
};

export const get_profile = node => {
  const basics = [
    'username',
    'fullname',
    'desc',
    'description',
    'loc',
    'location',
    'image',
    'avatar'
  ];
  const data = node.data || node;
  const services = Object.keys(_SERVICES).concat(['li', 'tw', 'fb']);
  let profile = data?.profile || data?.firebase || {};
  services.forEach(svc => {
    // fill in any missing fields from svcs
    const it = data[svc];
    if (it && typeof it == 'object') {
      Object.entries(it).forEach(([k, v]) => {
        if (!profile[k]) {
          profile[k] = v;
          if (k == 'fullname') profile.fullname += ' (' + svc + ')';
        }
      });
      let uname =
        svc == 'mastodon' ? [data[svc].username, data[svc].server].join('@') : data[svc].username;
      profile[svc] = uname;
    }
  });
  Object.entries(data).forEach(([k, v]) => {
    if (v && basics.includes(k)) {
      profile[k] = profile[k] || v;
    }
  });
  const idd = node._id || node.id;
  if (idd) {
    profile._id = idd;
  }
  if (node.provisional) {
    profile.provisional = node.provisional;
  }
  if (data.bioregion) {
    profile.bioregion = data.bioregion?.name || data.bioregion;
  }

  return profile;
};

export const edge_normalizer = edge => {
  if (typeof edge[0] == 'string' && typeof edge[1] == 'string') {
    // just a pair of ids
    const typ = edge[2];
    const owned = edge[3];
    edge = { source: edge[0], target: edge[1] };
    if (typ) {
      edge.type = typ;
    }
    if (owned) {
      edge.owned = true;
    }
  }
  const to_seconds = date => {
    return Math.floor(Date.parse(date) / 1000);
  };
  const lookup = {
    source: { possibles: ['source', 'from', '_from'] },
    target: { possibles: ['target', '_to', 'to'] },
    type: { possibles: ['type', 'kind'] },
    id: { possibles: ['_id', 'id'] },
    group: { possibles: ['group', 'groupId', 'group_id'] },
    owned: { possibles: ['owned'] },
    ts_create: { possibles: ['createDate'], process: to_seconds },
    ts_mod: { possibles: ['modDate'], process: to_seconds },
    qualifier: { possibles: ['qualifier'] }
  };
  var out = {};
  Object.keys(lookup).forEach(field => {
    const which = lookup[field];
    const possibles = which.possibles;
    let match = _.find(possibles, one => {
      return edge[one] ? true : false;
    });
    let val = match ? edge[match] : null;
    val = !val && which.default ? which.default : val;
    if (val) {
      out[field] = which.process ? which.process(val) : val;
    }
  });
  return out;
};

export const node_normalizer = (node, allowlist, obfuscate) => {
  let out = {};
  const profile_fields = ['profile'].concat(Object.keys(_SERVICES));
  try {
    let lookup = {
      name: { possibles: ['nm', 'name', 'fn', 'fullname', 'displayName', 'username'] },
      fullname: {
        possibles: ['fn', 'fullname', 'displayName', 'label', 'name', 'username', 'title']
      },
      description: { possibles: ['desc', 'description'] },
      location: { possibles: ['loc', 'location'] },
      avatar: { possibles: ['avatar', 'image', 'img', 'photoURL'] },
      image: { possibles: ['avatar', 'image', 'img', 'photoURL'], default: _DEFAULT_AVATAR },
      followers: { possibles: ['followers', 'indegree'] },
      mojo: { possibles: ['mj', 'mojo'], default: _DEFAULT_MOJO },
      weight: { possibles: ['w', 'weight'], default: _DEFAULT_WEIGHT },
      size: { possibles: ['size'], default: _DEFAULT_NODE_SIZE },
      ins: { possibles: ['ins'], default: _DEFAULT_INS },
      status: { possibles: ['status'], default: 'neutral' },
      index: { possibles: ['idx', 'index'], default: -1 },
      id: { possibles: ['_id', 'id'] }, // CAREFUL with this. Twitter id needs to come after node _id
      key: { possibles: ['_id', 'key', 'id'] }, // CAREFUL with this. Twitter id needs to come after node _id
      handle: { possibles: ['handle', 'username'] },
      provisional: { possibles: ['provisional'] },
      linkedin: { possibles: ['linkedin', 'li'] },
      facebook: { possibles: ['facebook', 'fb'] },
      twitter: { possibles: ['twitter', 'tw'] },
      server: { possibles: ['server'] }, // mastodon
      type: { possibles: ['type', 'kind'] },
      priority: { possibles: ['priority'] },
      listed_count: { possibles: ['listed_count'] },
      bioregion: { possibles: ['bioregion'] },
      url: { possibles: ['url', 'uri'] },
      vis: { possibles: ['vis'] }
    };
    Object.keys(_SERVICES).forEach(key => {
      if (!lookup[key]) {
        lookup[key] = { possibles: [key] };
      }
    });

    Object.keys(lookup).forEach(field => {
      const which = lookup[field];
      const possibles = which.possibles;
      let match = _.find(possibles, one => {
        return node[one] ? true : false;
      });
      let val = match ? node[match] : null;
      val = !val && which.default ? which.default : val;
      if (val) {
        out[field] = val;
        if (isHtml(val)) {
          // field == 'description' &&
          // e.g. Mastodon description
          if (field != 'description') console.log('HTML element not description', field, val);
          try {
            var el = document.createElement('html');
            el.innerHTML = val;
            out[field] = el.textContent;
          } catch (e) {
            console.error('failed parsing HTML description');
          }
        }
      }
    });
    const to_obfuscate = ['fullname', 'name', 'handle'];
    if (allowlist && obfuscate) {
      if (!allowlist.includes(out.id)) {
        to_obfuscate.forEach(field => {
          const shuffled = shuffle(out[field]);
          out[field] = shuffle(out[field]);
        });
      }
    }

    if (out.provisional && !out.fullname?.length > 0) {
      out.fullname = 'Invited';
    }
  } catch (e) {
    console.log('Node normalizer failed, with error ', e);
    out = node;
  }
  return out;
};

import { NOOUI_SET_CURRENT_THEME, NOO_SET_CURRENT_NETWORK } from 'shared/constants/actionTypes';
import { getNetworkData } from 'shared/config';

const DEFAULT_THEMENAME = 'tailwind';

const themeMap = {
  demo: DEFAULT_THEMENAME,
  sharebnb: DEFAULT_THEMENAME // 'swiss'
};

const initialState = {
  currentTheme: null, // Themes[DEFAULT_THEMENAME],
  currentThemeName: DEFAULT_THEMENAME,
  currentThemeMapName: 'demo'
};

const nooUi = function (state = initialState, action) {
  // const _state = { ...state };
  switch (action.type) {
    case NOOUI_SET_CURRENT_THEME:
      console.log('nooUi:: ' + NOOUI_SET_CURRENT_THEME);
      console.log(action.payload);
      const myThemeName = action?.payload || DEFAULT_THEMENAME;
      const currentTheme = {}; // Themes[myThemeName] || Themes[DEFAULT_THEMENAME];
      return {
        ...state,
        currentTheme,
        currentThemeName: myThemeName
      };

    case NOO_SET_CURRENT_NETWORK:
      const network = action.payload;
      const netData = getNetworkData(network);
      const myThemeMapName = network || DEFAULT_THEMENAME;
      const myThemeMap = themeMap[myThemeMapName];
      const currentThemeMap = null; // Themes[myThemeMap] || Themes[DEFAULT_THEMENAME];
      return {
        ...state,
        currentTheme: currentThemeMap || {},
        currentThemeName: netData.id || state.currentThemeName,
        currentThemeMapName: myThemeMapName
      };

    default:
      return state;
  }
};

export default nooUi;

import React, { useMemo, useState, useCallback } from 'react';
import { Box, InputGroup, InputLeftElement, Icon, Text } from '@chakra-ui/react';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList
} from '@choc-ui/chakra-autocomplete';

const ACEmptyState = ({ msg }) => {
  const styles = {
    fontSize: 'sm',
    align: 'center',
    justify: 'center',
    fontStyle: 'italic'
  };
  return (
    <Box {...styles}>
      <Text>{msg}</Text>
    </Box>
  );
};

const AutoCompleteWrapper = props => {
  const {
    isSearching,
    searchMsg = 'searching...',
    typingMsg = 'keep typing...',
    inputPlaceholder = 'Name as you know them...',
    options,
    onSelectOption,
    showSearchIcon = true,
    clearOnBlur = false,
    clearOptions = () => {},
    searchOptions,
    searchOnSpace = true,
    searchOnLength = 0,
    disabled = false
  } = props;

  if (!searchOptions) {
    throw new Error('no searchOptions function found, required for autocomplete to work');
  }

  const [acInput, setAcInput] = useState('');
  const [lastSearch, setLastSearch] = useState('');

  const onFieldUpdate = useCallback(
    e => {
      const { value } = e.target;
      if (value) {
        // makes sure we don't search on the same root
        // i.e. "mark" (lastSearch) vs "mark mcd" (current value) have the same search root of "mark"
        if (!lastSearch || lastSearch.indexOf(value) < 1) {
          if (searchOnSpace && value?.split(' ').length > 1) {
            searchOptions(value);
            setLastSearch(value);
          } else if (searchOnLength && value.length >= searchOnLength) {
            searchOptions(value);
            setLastSearch(value);
          }
        }
      } else {
        // console.warn(
        //   'empty value on fullname, likely input field was cleared, so clear the people list'
        // );
        clearOptions();
        setLastSearch('');
      }

      setAcInput(value);
    },
    [lastSearch, clearOptions, searchOnLength, searchOnSpace, searchOptions]
  );

  const ACInput = useMemo(() => {
    if (showSearchIcon) {
      return (
        <InputGroup>
          <InputLeftElement pointerEvents='none' color='inherit' fontSize='1.2em'>
            <Icon boxSize='14px' viewBox='0 0 24 24' focusable='false'>
              <path
                fill='currentColor'
                d='M23.384,21.619,16.855,15.09a9.284,9.284,0,1,0-1.768,1.768l6.529,6.529a1.266,1.266,0,0,0,1.768,0A1.251,1.251,0,0,0,23.384,21.619ZM2.75,9.5a6.75,6.75,0,1,1,6.75,6.75A6.758,6.758,0,0,1,2.75,9.5Z'
              ></path>
            </Icon>
          </InputLeftElement>
          <AutoCompleteInput
            name='acInput'
            value={acInput}
            onChange={onFieldUpdate}
            placeholder={inputPlaceholder}
            onBlur={clearOnBlur && clearOptions}
            size='sm'
            isDisabled={disabled}
          />
        </InputGroup>
      );
    }
    return (
      <AutoCompleteInput name='acInput' onChange={onFieldUpdate} placeholder={inputPlaceholder} />
    );
  }, [
    acInput,
    clearOnBlur,
    clearOptions,
    disabled,
    inputPlaceholder,
    onFieldUpdate,
    showSearchIcon
  ]);

  return (
    <AutoComplete
      emptyState={<ACEmptyState msg={isSearching ? searchMsg : typingMsg} />}
      onSelectOption={onSelectOption}
    >
      {ACInput}
      <AutoCompleteList>
        {options?.map((option, idx) => {
          return (
            <AutoCompleteItem key={`option-${idx}`} value={option.value} label={option.label}>
              {option.label}
            </AutoCompleteItem>
          );
        })}
      </AutoCompleteList>
    </AutoComplete>
  );
};

export default AutoCompleteWrapper;

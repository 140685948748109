import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFanAuth from 'hooks/useFanAuth';

const FanClaim = () => {
  const { ott } = useParams();
  console.log('fan claim', ott);
  const { claimOtt } = useFanAuth();
  const [error, setError] = useState('');

  useEffect(() => {
    async function run() {
      if (ott) {
        await claimOtt(ott, setError);
      } else {
        setError('no ott found, nothing to do.');
      }
    }
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return error ? <p>{error}</p> : <h1>fan login, one moment please...</h1>;
};

export default FanClaim;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  FormLabel,
  Heading,
  HStack,
  Input,
  Text,
  VStack
} from '@chakra-ui/react';

import useFirebaseAuth from 'hooks/useFirebaseAuth';
import { setUser } from 'redux/actions/firebaseAuth';
import { useCallback } from 'react';

const FirebaseUserInfo = () => {
  const {
    user,
    updateUserEmail,
    updateUserPassword,
    updateUserProfile
    // sendVerificationEmail
  } = useFirebaseAuth();
  const dispatch = useDispatch();
  const [anythingChanged, setAnythingChanged] = useState(false);
  const [localData, setLocalData] = useState({});
  const [error, setError] = useState('');

  const handleError = e => setError(e.toString());

  const handleInfoUpdate = () => {
    if (localData.displayName) {
      updateUserProfile(
        localData.displayName,
        successMsg => {
          console.log('SUCCESS:: ', successMsg);
          setAnythingChanged(false);
        },
        handleError
      );
    } else {
      setError('You need to set a display name.');
    }
  };

  const handleEmailUpdate = () => {
    if (localData.email) {
      updateUserEmail(
        localData.email,
        successMsg => {
          console.log('SUCCESS:: ', successMsg);
          setAnythingChanged(false);
        },
        handleError
      );
    } else {
      setError('You need to add an email address.');
    }
  };

  const handlePasswordUpdate = () => {
    if (localData.password && localData.passwordv && localData.password === localData.passwordv) {
      updateUserPassword(
        localData.password,
        successMsg => {
          console.log('SUCCESS:: ', successMsg);
          setAnythingChanged(false);
        },
        handleError
      );
    } else {
      setError('Make sure your passwords match.');
    }
  };

  const onFieldUpdate = e => {
    const {
      target: { name, value }
    } = e;
    setLocalData({
      ...localData,
      [name]: value
    });
    setAnythingChanged(true);
    setError('');
  };

  return (
    <Box borderTop='1px solid #eee;' mt='12px' pt='12px'>
      <Heading as='h1' size='md' fontWeight='normal'>
        {!user.displayName ? 'Set' : 'Update'} your user information
      </Heading>
      <HStack my='10px' alignItems='flex-start' width='540px'>
        <FormLabel htmlFor='displayName' width='80px' mt='8px'>
          Name
        </FormLabel>
        <HStack flex='1'>
          <Input
            type='text'
            placeholder='display name'
            name='displayName'
            value={localData.displayName || user.displayName}
            onChange={onFieldUpdate}
          />
          <Button width='180px' onClick={() => handleInfoUpdate()} disabled={!anythingChanged}>
            Save Info
          </Button>
        </HStack>
      </HStack>

      <HStack my='10px' alignItems='flex-start' width='540px'>
        <FormLabel htmlFor='displayName' width='80px' mt='8px'>
          Email
        </FormLabel>
        <HStack flex='1'>
          <Input
            type='text'
            placeholder='email'
            name='email'
            value={localData.email || user.email}
            onChange={onFieldUpdate}
          />
          <Button width='180px' onClick={() => handleEmailUpdate()} disabled={!anythingChanged}>
            Save Email
          </Button>
        </HStack>
      </HStack>

      <HStack my='10px' alignItems='flex-start' width='540px'>
        <FormLabel htmlFor='displayName' width='80px' mt='8px'>
          Password
        </FormLabel>
        <HStack flex='1' alignItems='flex-start'>
          <VStack flex='1'>
            <Input
              type='password'
              placeholder='password'
              name='password'
              value={localData.password || ''}
              onChange={onFieldUpdate}
            />
            <Input
              type='password'
              placeholder='verify password'
              name='passwordv'
              value={localData.passwordv || ''}
              onChange={onFieldUpdate}
            />
          </VStack>
          <Button
            width='180px'
            onClick={() => handlePasswordUpdate()}
            disabled={
              !anythingChanged || !localData.password || localData.password !== localData.passwordv
            }
          >
            Set New Password
          </Button>
        </HStack>
      </HStack>

      {error ? (
        <Alert flexDirection='row' status='error' variant='subtle' my='12px' borderRadius='8px'>
          <AlertDescription>
            {typeof error === 'string' ? (
              <Text>{error}</Text>
            ) : (
              error.map((e, idx) => {
                return <Text key={idx}>{e}</Text>;
              })
            )}
          </AlertDescription>
        </Alert>
      ) : null}
    </Box>
  );
};

export default FirebaseUserInfo;

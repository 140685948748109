import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Box, Image, Button } from '@chakra-ui/react';
import { nodesAndEdgesNoGenerations } from 'utils/nodesAndEdges';
import SortableTable from 'components/Segments/SortableTable';
import useSearchResults, { ResultLink } from 'hooks/useSearchResults';

const SearchResultsFriends = props => {
  const kind = 'friends';
  const { data, topic, onclick } = props;
  const [parsedData, setParsedData] = useState(null);
  const [profiles, setProfiles] = useState(null);
  const {
    defaultColumnProperties,
    map_column_title,
    map_column_sx,
    get_columns
  } = useSearchResults(kind);

  useEffect(() => {
    console.log('data', data);
    if (data) {
      const it = data?.result?.[0] || data;
      setParsedData(it);
    }
  }, [data, setParsedData]);

  const friendsData = useMemo(() => {
    const clickPriority = e => {
      const username = e.target.dataset.username;
      reorderProfiles(username);
      if (onclick) onclick(friendsData[username]);
    };
    const reorderProfiles = username => {
      Object.values(friendsData).forEach(one => {
        if (one.username == username) {
          one.priority = 0;
          one.promoteSorter = 0;
        } else {
          one.priority += 1;
          one.promoteSorter += 1;
        }
      });
      setProfiles(Object.values(friendsData));
    };
    if (kind === 'friends' && parsedData) {
      let lookup = {};
      parsedData.forEach(friend => {
        let { username, fullname, image, mutual_count, mutuals, priority } = friend;
        priority = priority || 1; // SET INITIAL PRIORITY BASED ON MUTUALS AND GENDER
        const count = mutual_count || mutuals || 0;
        lookup[username] = {
          priority,
          promote: (
            <Button data-username={username} onClick={clickPriority} title={priority}>
              +
            </Button>
          ),
          promoteSorter: priority,
          count,
          countSorter: -count,
          fullnameSorter: fullname,
          fullname,
          username,
          image,
          img: (
            <Box>
              {image && (
                <Image src={image} alt={username} title={username} height={'50px'} width={'50px'} />
              )}
            </Box>
          )
        };
      });
      return lookup;
    }
    return null;
  }, [parsedData, kind, onclick, setProfiles]);

  useEffect(() => {
    if (friendsData) {
      setProfiles(Object.values(friendsData));
    }
  }, [friendsData]);

  const cols = useMemo(() => {
    if (profiles?.length) {
      if (kind == 'friends') {
        return get_columns(profiles[0]).map(key => {
          return {
            ...defaultColumnProperties,
            key: key,
            name: map_column_title(_.capitalize(key), kind),
            sx: map_column_sx(key, kind)
          };
        });
      }
    }
    return [];
  }, [defaultColumnProperties, get_columns, profiles, map_column_sx, map_column_title]);

  if (!_.size(profiles)) {
    return null;
  }

  return (
    <Box mt='20px'>
      <SortableTable columns={cols} rows={profiles} />
    </Box>
  );
};

export default SearchResultsFriends;

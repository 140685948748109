import * as React from 'react';
import { ChakraProvider, CSSReset, extendTheme } from '@chakra-ui/react';
import 'focus-visible/dist/focus-visible';
import useNooUi from 'hooks/useNooUi';

const Provider = props => {
  const { children } = props;
  const { combinedTheme } = useNooUi();

  return (
    <ChakraProvider theme={extendTheme(combinedTheme)}>
      <CSSReset />
      {children}
    </ChakraProvider>
  );
};

export default Provider;

import React from 'react';
import {
  HStack,
  Text,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  Link
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const Videos = props => {
  return (
    <Accordion className='accordion' allowToggle width={['90vw', '82vw', '60vw']}>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Text fontSize='lg' fontWeight={'bold'}>
              Tutorial Videos
            </Text>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <HStack>
            <Link
              key={1}
              href='https://www.loom.com/share/4ee02b40e84a4c178fe50df03632c438'
              isExternal
              rel='noreferrer'
            >
              <Button sx={{ backgroundColor: '#eee' }}>Big Picture</Button>
            </Link>
            <Link
              key={2}
              href='https://www.loom.com/share/bb8427ff1a4841078834a33f59fc8195'
              isExternal
              rel='noreferrer'
            >
              <Button sx={{ backgroundColor: '#eee' }}>Claiming</Button>
            </Link>
            <Link
              key={3}
              href='https://www.loom.com/share/5b23acd0680246a285dae46c96c98ac9'
              isExternal
              rel='noreferrer'
            >
              <Button sx={{ backgroundColor: '#eee' }}>Praising</Button>
            </Link>
            <Link
              key={4}
              href='https://www.loom.com/share/3b05046f5d764b1bb2f8027ce7a7e2a4'
              isExternal
              rel='noreferrer'
            >
              <Button sx={{ backgroundColor: '#eee' }}>Validating</Button>
            </Link>

            <Button sx={{ backgroundColor: '#eee' }} disabled>
              More to come...
            </Button>
          </HStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default Videos;

import React from 'react';
// import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
// import ReactMarkdown from 'react-markdown';
import {
  Box,
  Flex,
  Heading,
  Link,
  Text,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react';
import RenderChakraMarkdown from 'utils/ChakraMarkdown';
import useNoo from 'hooks/useNoo';
import SignInOrRegister from 'components/Segments/SignInOrRegister';
import InvitationsAccept from 'components/Invitations/InvitationsAccept';

// function sleep(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

function useQuery() {
  var params = new URLSearchParams(useLocation().search);
  var lookup = {};
  for (var pair of params.entries()) {
    lookup[pair[0]] = pair[1];
  }
  return lookup;
}

const Splash = () => {
  const { googleUser: user, currentNetworkData } = useNoo('Splash');
  const network = currentNetworkData;

  // TODO: if using invitations here, update to useInvitations() hook
  // const onCallAcceptInvitation = () => {
  // 	nooApi.acceptInvitation(user, invitationId, setAcceptLoading, setAcceptResponse);
  // };

  let params = useQuery();
  var invitationId = params.invite;

  if (invitationId) {
    localStorage.setItem('invitationId', invitationId);
  } else {
    // TBD: check local storage and if there, check it's still valid by reading it
    const stored = localStorage.getItem('invitationId');
    if (stored) {
      /* CHECK INVITE IS LEGIT HERE, AND SET OR REMOVE ON RESPONSE, BUT I DON'T KNOW HOW TO HANDLE THE RESPONSE
      // TODO: if using noo Api here, update to useNooApi() hook
			const aqlQuery = 'return document("' + invitationId + '")';
			localStorage.setItem('invitation', invite);
			nooApi.aqlSearch({user, aqlQuery, setLoading, setResponse});
			*/
      invitationId = stored;
    }
  }

  const display = network?.display;
  const paras = network.welcome;
  const markdown = network.markdown;
  var items = [];
  if (markdown) {
    items.push(<RenderChakraMarkdown key='mkdown'>{markdown}</RenderChakraMarkdown>);
  } else if (paras) {
    _.forEach(paras, (value, key) => {
      items.push(
        <Text key={key} fontSize={['.8em', '1.2em']}>
          {value}
        </Text>
      );
    });
  }

  const markdown_para1 = `
  There are several useful tools for finding people to follow, e.g.: *<a href="https://fedifinder.glitch.me/">Fedifinder</a>, [DeBirdify](https://debirdify.pruvisto.org/), [GetStarted](https://getstarted.social/), [OpenCheck](https://opencheck.is/people), [Movetodon](https://www.movetodon.org/)*, ...

Try them out. Most are focused on finding any of your Twitter connections (follows, followers, lists) who have Mastodon accounts.

Our approach is to find the best Twitter people on any subject and make them easy to find and follow on Mastodon. Building on over a decade of working with Twitter data, we have a lot of tools for identifying, clustering, and tracking great people. So we've applied those to noticing when any of over 1M domain-respected Twitter users add a Mastodon id to their profile, and making them findable here.

On top of that, we have lots of analytics to layer on, like how people are connected to each other, to enhance discovery.

This is an early proof-of-concept. We'll be adding new tools, and welcome suggestions via our Discord server (soon).
`;

  const markdown_para2 = `
Your goal is to collect people you want to follow, export them to a CSV file, and import that on your Mastodon instance.

See this [How To video](https://standingwave.net/img/NN/StigMastodon_tutorial_480.mov)

CAVEAT: CSV importing can be unreliable. Your selections (checked users) should persist in your local storage (even across multiple sessions) unless/until you Clear Checked. Meanwhile you can click on the Mastodon link of each person's profile card and follow them directly.

Sign in with a Gmail account above if you haven't already (more methods soon, see Privacy below.)

Click the Network tab above and start to explore. 

Search for specific people, and you will get them and others they are connected to meaningfully on Twitter.

Search against the Twitter profile description or location. Best to use short stems of words because they can match more, e.g. *philoso* matches *philosophy, philosopher*, ...

Click the checkboxes for any you want to follow as you meander around. All checks are visible under Show Selected. Download your current selections as a CSV file and [import on your Mastodon instance](https://www.tutonaut.de/en/mastodon-twitter-follower-und-follows-importieren/).

We'll be improving search as quickly as possible.
`;
  const markdown_para3 = `
We don't store any of your activity here. All selections are kept in your browser localStorage and exported for your use. We will soon be offering an opt-in storage in our database and other tools for collective analytics etc. We will not spam you or share your email with anyone.
`;
  const markdown_para4 = `
To quote [Wikipedia](https://en.wikipedia.org/wiki/Stigmergy):
> Stigmergy is a mechanism of indirect coordination, through the environment, between agents or actions. The principle is that the trace left in the environment by an individual action stimulates the performance of a succeeding action by the same or different agent. 


Ants and other insects function as superorganisms through *stigmergy*, e.g. individuals deposit pheromones in their environment that cause larger collective behavior in their community. Termites build complex colonies, ants solve the *traveling salesman problem*, ...

We are interested in and playing with *human stigmergy*, people *marking* people as a means of creating interesting superorganism behavior, like better collective sensemaking, emergent coordination, ... 

We figure the once-in-a-decade shift that is happening now from Twitter to elsewhere is a great opportunity to experiment. 

More on that later.
`;
  const markdown_para5 = `
[NooNet](https://noo.network/) and [CommonSensemakers](https://www.csensemakers.com/)
`;

  {
    /* <AccordionItem>
<h2>
  <AccordionButton>
    <Box as='span' flex='1' textAlign='left'>
      <Text fontSize='lg' fontWeight={'bold'}>
        Who
      </Text>
    </Box>
    <AccordionIcon />
  </AccordionButton>
</h2>
<AccordionPanel pb={4}>
  <RenderChakraMarkdown key='mkdown' fontSize='2px'>
    {markdown_para5}
  </RenderChakraMarkdown>
</AccordionPanel>
</AccordionItem> */
  }

  return (
    <Box>
      <Heading as='h2' size='lg' textAlign='center' mt='1em'>
        StigMastodon
      </Heading>
      <Heading as='h4' size='md' fontWeight='normal' fontStyle='italic' textAlign='center' mb='1em'>
        Find the best people to follow on Mastodon.
      </Heading>
      {!user && (
        <Box
          textAlign='center'
          backgroundColor='cyan.400'
          color='white'
          width={['85%', '68%', '47%', '35%']}
          m='1em auto'
          padding='0.5em 0'
          borderRadius='3px'
          sx={{
            a: { color: 'cyan.900', fontWeight: 'bold' }
          }}
        >
          <SignInOrRegister showHeader={false} />
        </Box>
      )}
      <Text
        margin='1em auto'
        width={['90vw', '82vw', '60vw']}
        borderRadius='lg'
        boxShadow='0px 1px 4px 0px rgba(0, 0, 0, 0.5)'
        padding='1em'
        textAlign='left'
        backgroundColor='cyan.800'
        color='white'
        fontSize='sm'
      >
        [<strong>BETA:</strong> We built this for ourselves. In this draft form, it&apos;s a little
        rough, but maybe you&apos;re find it useful too. It works best in a browser; on mobile in
        landscape. Please make suggestions and report issues to stigmastodon @ protonmail dot com.]
      </Text>
      <InvitationsAccept align='center' />
      <VStack>
        <Text width={['90vw', '82vw', '60vw']}>
          Build a great Mastodon timeline using this tool. It&apos;s the simplest, fastest way to
          follow a broad range of great people. Peruse dozens of topics, follow a few from any that
          interest you, and your timeline will be diverse and active.
        </Text>
        <Accordion className='accordion' allowToggle width={['90vw', '82vw', '60vw']}>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  <Text fontSize='lg' fontWeight={'bold'}>
                    What makes this different?
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Text paddingBottom='10px'>
                We monitor over 1M domain experts on social media on hundreds of topics and make
                them easy to find and follow if they start using Mastodon.
              </Text>
              <Text paddingBottom='10px'>
                There are several useful alternative tools for finding people to follow, e.g.:&nbsp;
                <a
                  color={'#f00'}
                  fontWeight='bold'
                  fontSize='36px'
                  href='https://fedifinder.glitch.me/'
                  target='_blank'
                  rel='noreferrer'
                >
                  Fedifinder
                </a>
                ,&nbsp;
                <a href='https://debirdify.pruvisto.org/' target='_blank' rel='noreferrer'>
                  DeBirdify
                </a>
                ,&nbsp;
                <a href='https://getstarted.social/' target='_blank' rel='noreferrer'>
                  GetStarted
                </a>
                ,&nbsp;
                <a href='https://opencheck.is/people' target='_blank' rel='noreferrer'>
                  OpenCheck
                </a>
                ,&nbsp;
                <a href='https://www.movetodon.org/' target='_blank' rel='noreferrer'>
                  Movetodon
                </a>
                , ...
              </Text>
              <Text paddingBottom='10px'>
                Try those out. Most are focused on finding any of your Twitter connections (follows,
                followers, lists) who have Mastodon accounts.
              </Text>
              <Text paddingBottom='10px'>
                Our approach is different. Building on over a decade of working with social data, we
                have a lot of tools for identifying, clustering, and paying attention to great
                people. So we&apos;ve applied those to noticing when any of them add a Mastodon id
                to their profile, and making them easily findable here.
              </Text>
              <Text paddingBottom='10px'>
                On top of that, we have lots of analytics to layer on, like how people are connected
                to each other, to enhance discovery. This is an early proof-of-concept. We&apos;ll
                be adding new tools, and welcome suggestions via our Discord server (soon).
              </Text>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  <Text fontSize='lg' fontWeight={'bold'}>
                    How to use this
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <RenderChakraMarkdown key='mkdown' fontSize='2px'>
                {markdown_para2}
              </RenderChakraMarkdown>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  <Text fontSize='lg' fontWeight={'bold'}>
                    Privacy
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <RenderChakraMarkdown key='mkdown' fontSize='2px'>
                {markdown_para3}
              </RenderChakraMarkdown>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  <Text fontSize='lg' fontWeight={'bold'}>
                    Why Stig?
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <RenderChakraMarkdown key='mkdown' fontSize='2px'>
                {markdown_para4}
              </RenderChakraMarkdown>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  <Text fontSize='lg' fontWeight={'bold'}>
                    Who
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Link href='https://mastodon.green/@bdegraf' target='_blank' rel='noreferrer'>
                bdegraf@mastodon.green
              </Link>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </VStack>
    </Box>
  );
};

export default Splash;

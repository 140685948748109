export const FAN_AUTHORIZE = 'FAN_AUTHORIZE';
export const FAN_LOGOUT = 'FAN_LOGOUT';
export const FAN_SET_LOGIN_URL = 'FAN_SET_LOGIN_URL';

export const authorizeFan = payload => ({
  type: FAN_AUTHORIZE,
  payload
});

export const logoutFan = () => ({
  type: FAN_LOGOUT
});

export const setDeepLinkLogin = url => ({
  type: FAN_SET_LOGIN_URL,
  payload: url
});

import React, { useState, useEffect, useRef, useCallback } from 'react';
import useNoo from 'hooks/useNoo';
import { useDispatch } from 'react-redux';
import GroupSelector from 'components/Segments/GroupSelector';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { Flex, Box, Input, Textarea, Button, Heading } from '@chakra-ui/react';
import styled from '@emotion/styled';
import useNooApi from 'hooks/useNooApi';
import UserAuth from 'components/User/UserAuth';

const GroupCreate = () => {
  const [groupId, setGroupId] = useState(null);
  const [inputs, setInputs] = useState({ title: null, description: null });
  const { nooUser } = useNoo('GroupCreate');
  const dispatch = useDispatch();
  const {
    nooCommand,
    nooCommandResponse,
    nooCommandResponseError,
    nooCommandIsProcessing
  } = useNooApi();

  const onSelectGroup = event => {
    const group_id = event.target.value;
    setGroupId(group_id);
  };

  const updateInputs = e => {
    const myInputs = { ...inputs };
    myInputs[e.target.name] = e.target.value;
    setInputs(myInputs);
  };

  const onSubgroupSubmit = event => {
    const new_name = inputs.title;
    const new_desc = inputs.description;
    const parent = groupId;
    if (parent && new_name && new_desc) {
      const to_data = { fullname: new_name, description: new_desc };
      const from_id = parent;
      console.log('making subgroup ', to_data, 'of', parent);
      dispatch(nooCommand('createGroupEdge', { from_id, to_data }));
    }
  };

  let disabled = groupId && inputs.title && inputs.description ? false : true;
  return (
    <Box>
      <UserAuth unauthorized='signin'>
        <Box
          sx={{
            backgroundColor: '#eee',
            marginTop: '10px',
            padding: '10px',
            border: '1px solid #444'
          }}
        >
          <Heading as='h3'>Create Group</Heading>
          <Flex>
            <h3>Choose Parent Group</h3>
            <GroupSelector
              callback={onSelectGroup}
              label={'Choose Parent (if any)'}
              selected={groupId}
              render={'selector'}
              nooUser={nooUser}
              filters={['owner']}
              showAnyOption={true}
            />
          </Flex>

          <Box sx={{ flex: '1 1 auto' }}>
            <Input
              sx={{ height: '30px', padding: '1px' }}
              type='text'
              id='title'
              name='title'
              placeholder='enter title'
              onChange={updateInputs}
            />
          </Box>
          <Box sx={{ flex: '1 1 auto' }}>
            <Textarea
              id='description'
              name='description'
              placeholder='enter description'
              onChange={updateInputs}
            />
          </Box>

          <Box>
            <Button
              variant='pill'
              sx={{ height: '30px', padding: '3px' }}
              onClick={onSubgroupSubmit}
              disabled={disabled}
            >
              {'Submit'}
            </Button>
          </Box>
        </Box>
      </UserAuth>
    </Box>
  );
};

export default GroupCreate;

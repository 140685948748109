import React, { useEffect, useMemo, useState } from 'react';
// import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
// import ReactMarkdown from 'react-markdown';
import { Box, Flex, Heading, Link, Text, VStack, HStack } from '@chakra-ui/react';
import useNoo from 'hooks/useNoo';
import QuestionsList from 'components/Decisions/QuestionsList';
import OptionsList from 'components/Decisions/OptionsList';
import OptionDetails from 'components/Decisions/OptionDetails';
import useDecisions from 'hooks/useDecisions';
import useGroups from 'hooks/useGroups';

const OneDecision = props => {
  const { nooUser } = useNoo('OneDecision');
  const { group_in, decision_in, menu } = props; // defaults to no decision selector. pass in truthy menu
  const { currentGroupId, setGroupId } = useGroups({
    from: 'OneDecision'
  });

  const {
    decisions,
    currentDecisionId,
    getDecisionsByCurrentGroup,
    getDecisionById,
    isActive
  } = useDecisions();

  useEffect(() => {
    if (group_in != currentGroupId) {
      // console.log('setting group', group_in);
      setGroupId(group_in);
    }
  }, [currentGroupId, decisions, group_in, setGroupId]);

  // const currentGroupId = 'Groups/0c73e7c3-47d5-4fcf-9fa4-6048a3d99ef7';
  useEffect(() => {
    // console.log('effecct', decision_in, currentDecisionId, currentGroupId, nooUser);
    if (nooUser && currentGroupId && decision_in && currentDecisionId != decision_in) {
      //  && decisions
      getDecisionById(decision_in);
      // console.log('getting decision', decision_in);
    }
  }, [currentDecisionId, currentGroupId, decision_in, decisions, getDecisionById, nooUser]);

  // console.log('curr', currentDecisionId, currentGroupId, decisions);
  return (
    <Box>
      {currentGroupId && props.menu && <QuestionsList groupId={currentGroupId} />}
      <Flex sx={{ gap: '20px' }}>
        {currentDecisionId && <OptionsList focus={props.focus} />}
        <OptionDetails />
      </Flex>
    </Box>
  );
};

export default OneDecision;

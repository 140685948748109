import { useCallback, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import useNoo from 'hooks/useNoo';

const URL = 'http://localhost:3001';
// const socket = io();
const socket = io({ autoConnect: false, uri: URL });

let connectionInProcess = false;

const setupListeners = (socket, anyHandler) => {
  socket.onAny((event, ...args) => {
    anyHandler(event, args);
  });
};

const teardownListeners = socket => {
  socket.offAny();
};

const listeners = {};

const useSocketIo = ({ autoConnect = true, connectCallback }) => {
  const { personId } = useNoo();
  const [socketIsConnected, setSocketIsConnected] = useState(!!socket?.connected);
  const [serviceIsConnecting, setServiceIsConnecting] = useState(connectionInProcess);

  const anyHandler = (eventName, args) => {
    if (listeners[eventName]) {
      console.log('handle event: ', eventName, args);
      listeners[eventName].apply(null, args);
    } else {
      console.warn(
        `no handler registered for eventName: ${eventName}`,
        args,
        Object.keys(listeners)
      );
    }
  };

  const connect = useCallback(
    done => {
      socket.on('connect', () => {
        console.log('socket connect :: event callback');
        connectionInProcess = false;
        setServiceIsConnecting(false);
        setSocketIsConnected(true);
        listeners['sys:connected'] = () => {
          console.log('got the sys.connected msg');
        };
        window.KF = socket;
        setupListeners(socket, anyHandler);
        connectCallback && connectCallback();
      });

      socket.on('disconnect', () => {
        console.log('socket service disconnect event received');
        setSocketIsConnected(false);
        teardownListeners(socket);
      });

      if ((!socket?.connected || !socket.auth?.personId) && !connectionInProcess && personId) {
        console.warn(
          ' >>>>>>>>>>> useSocket .... trying to connect! do we have a personId??',
          personId,
          socket?.connected,
          socket?.auth?.personId
        );
        connectionInProcess = true;
        setServiceIsConnecting(true);

        socket.auth = { personId };

        if (socket.connected) {
          socket.disconnect();
        }

        socket.connect();

        if (done) {
          done();
        }
      }
    },
    [connectCallback, personId]
  );

  useEffect(() => {
    if (autoConnect && !socket?.connected) {
      connect();
    }
  }, [autoConnect, connect]);

  const addListeners = listenStack => {
    if (listenStack) {
      Object.entries(listenStack).forEach(([eventName, handler]) => {
        // if (listeners[eventName]) {
        // TODO: support multiple handlers per event (if required)
        // console.warn(`listener for ${eventName} already exists, overwriting with new handler...`);
        // }
        listeners[eventName] = handler;
      });
    }
  };

  // TODO: later if supporting multiple handlers per event, will need to be able to
  // remove individual handler vs. just removing
  const removeListeners = listenStack => {
    console.log('removeListeners stack: ', listenStack);
    if (listenStack) {
      Object.keys(listenStack).forEach(eventName => delete listeners[eventName]);
    }
  };

  return {
    isConnected: socketIsConnected,
    isConnecting: serviceIsConnecting,
    socket,

    connect,
    addListeners,
    removeListeners
  };
};

export default useSocketIo;

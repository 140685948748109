import React, { useEffect, useState, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import DecisionCreateModal from 'components/Segments/DecisionCreateModal';
import OptionsList from 'components/Decisions/OptionsList';
import {
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Heading,
  Button,
  HStack,
  Checkbox,
  Input,
  Spinner,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react';
import { bioregionIdFromName, capitalize } from 'utils';
import PeopleSelector from 'components/Segments/PeopleSelector';
import InputUser from 'components/Invitations/InputUser';
import _SERVICES from 'shared/constants/socialServices';

import useDecisions from 'hooks/useDecisions';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';

const RegionData = props => {
  const { node, network } = props;
  const [questionExperts, setQuestionExperts] = useState();
  const [questionProjects, setQuestionProjects] = useState();
  const [memberIds, setMemberIds] = useState([]);
  const [center, setCenter] = useState(props.center);
  const [filterUser, setFilterUser] = useState(false);
  const [filterText, setFilterText] = useState(false);
  const [textFilter, setTextFilter] = useState(null);
  const [localData, setLocalData] = useState({ desc: '' });

  const { nooUser } = useNoo();
  const { nooCommandDirect } = useNooApi();
  const default_tab = 1;
  const bioregion_name = node?.name || '';

  const {
    decisions,
    currentDecisionId,
    clearCurrentDecision,
    getDecisionById,
    currentDecision
  } = useDecisions();

  const loadDecisionData = useCallback(
    decisionId => {
      if (decisionId && currentDecisionId != decisionId) {
        getDecisionById(decisionId);
      }
    },
    [getDecisionById, currentDecisionId]
  );

  // input change
  const onFieldUpdate = e => {
    let { value: val, name: field } = e.target;
    const myLocalData = {
      ...localData
    };
    if (val != null) {
      myLocalData[field] = val;
      setLocalData(myLocalData);
      console.log('mylocal', myLocalData);
    } else {
      console.log('invalid value', field, val);
    }
  };
  useEffect(() => {
    if (node && decisions) {
      const match = decisions.filter(one => one.data.title == node.name + ' Experts');
      const it = match ? match[0] : null;
      const qid = questionExperts?._id;
      if (it && (!qid || it._id != qid || currentDecision?.question?._id != it._id)) {
        setQuestionExperts(it);
        loadDecisionData(it._id);
      } else if (!it) {
        setQuestionExperts(null);
        clearCurrentDecision();
      }
    }
  }, [questionExperts, node, decisions, loadDecisionData, clearCurrentDecision, currentDecision]);
  useEffect(() => {
    const question = currentDecision?.question || {};
    const data = question.data || {};
    const opts = currentDecision?.options || [];
    let pids = [];
    if (data.type == 'people' && opts.length > 0) {
      pids = opts
        .map(one => {
          return one.data?.data?._id;
        })
        .filter(x => x);
    }
    setMemberIds(pids);
  }, [currentDecision, questionExperts]);

  const disabled = nooUser?.person?.data?.role != 'ADMIN';
  const community_link =
    '/penumbra/2/' + memberIds.map(pid => pid.replace('Persons/', '')).join(',');
  const bio_id = node?.name ? bioregionIdFromName(node.name) : null;
  const bioregion_link = bio_id ? '/penumbra/2/' + bio_id : null;
  const showPeopleTab = memberIds.length > 0 || bioregion_link;

  const onSelectUser = node => {
    setCenter(node);
  };
  const onSubmit = e => {
    props.onSubmit({ center, filterUser, textFilter: filterText ? localData : null, filterText });
  };
  const center_name =
    center?.fullname || center?.data?.profile?.fullname || center?.data?.linkedin?.fullname;

  const onCheck = e => {
    const is_on = e.target.checked;
    setFilterUser(is_on);
    if (is_on) setFilterText(false);
  };

  const onCheckText = e => {
    const is_on = e.target.checked;
    setFilterText(is_on);
    if (is_on) setFilterUser(false);
  };
  const available = Object.keys(_SERVICES)
    .map(svc => capitalize(svc))
    .join(', ');

  const onAddPerson = e => {
    console.log('submitted ', e);
    const { svc, username } = e;
    const payload = { query_name: 'aqlMatchUsernameService', data: { svc, username } };
    const params = {
      payload,
      setLoading: () => {},
      setResponse: response => {
        // const data = response.result[0];
        console.log('res', response);
      },
      setResponseError: error => {
        console.log('error ', error);
      }
    };
    nooCommandDirect('aql', params);
  };

  const AccordAddPerson = () => {
    return (
      <Accordion maxWidth={'500px'} backgroundColor={'#eee'} marginTop={'10px'} allowToggle>
        <AccordionItem>
          <Heading as='h2'>
            <AccordionButton>
              <Text fontSize='lg' fontWeight={'bold'}>
                Add a Specific Person from Social link
              </Text>

              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4}>
            <VStack sx={{ backgroundColor: '#eee', padding: '3px', mt: '10px' }}>
              <Text>
                Copy/paste profile URL from LinkedIn, Github, Facebook, Twitter, or Mastodon.{' '}
                <b>Edit the fullname if necessary</b>.
              </Text>
              <InputUser onSubmit={onAddPerson} bioregion={bioregion_name} />
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  };

  return (
    <VStack>
      <Heading as='h4' size='md' textAlign='center' mt='1em'>
        {node.name}
      </Heading>
      <Tabs
        defaultIndex={default_tab}
        variant='soft-rounded'
        colorScheme='green'
        position='relative'
        top='0'
      >
        <TabList>
          <Tab>Find people</Tab>
          <Tab>Rank Nominations</Tab>
        </TabList>

        <TabPanels>
          <TabPanel sx={{ border: '1px solid lightgray', mt: '5px', padding: '5px' }}>
            <VStack>
              <Button onClick={onSubmit}>
                Find People in this {bioregionIdFromName(node.name) ? 'bioregion' : 'realm'}{' '}
              </Button>
              {props.fetching ? <Spinner /> : <Text>Results below map.</Text>}
              {node && (
                <VStack sx={{ backgroundColor: '#eee', padding: '3px' }}>
                  <Heading size={'sm'}>Filter by...</Heading>
                  <VStack sx={{ border: '1px solid lightgray' }}>
                    {' '}
                    <HStack>
                      <Checkbox
                        onChange={onCheck}
                        isChecked={filterUser}
                        sx={{ backgroundColor: 'white', outline: '1px solid black' }}
                      ></Checkbox>
                      <Text>
                        ... connected to{' '}
                        <Text as='b' sx={{ display: 'inline' }}>
                          {center_name}
                        </Text>
                      </Text>
                    </HStack>
                    <PeopleSelector
                      onSelect={onSelectUser}
                      inputPlaceholder={'Name as you know them...'}
                      label={'Change center user'}
                      disabled={!filterUser}
                    />
                  </VStack>
                  <VStack sx={{ border: '1px solid lightgray', width: '100%' }}>
                    {' '}
                    <HStack>
                      <Checkbox
                        onChange={onCheckText}
                        isChecked={filterText}
                        sx={{ backgroundColor: 'white', outline: '1px solid black' }}
                      ></Checkbox>
                      <Text>... matching </Text>
                      <Input
                        type='text'
                        placeholder='e.g. regen, agriculture, ...'
                        name='desc'
                        value={localData?.desc}
                        onChange={onFieldUpdate}
                        // onBlur={onFieldUpdate}
                        size='sm'
                        width='140px'
                        disabled={!filterText}
                        sx={{ border: '1px solid black', backgroundColor: 'white' }}
                      />
                    </HStack>
                  </VStack>
                  <Button onClick={onSubmit} disabled={!filterUser && !filterText}>
                    Find Filtered
                  </Button>
                  {props.fetching ? <Spinner /> : <Text>Results below map.</Text>}
                </VStack>
              )}
            </VStack>
            <AccordAddPerson />
          </TabPanel>
          <TabPanel sx={{ border: '1px solid lightgray', mt: '5px', padding: '5px' }}>
            {!questionExperts ? (
              <DecisionCreateModal
                prompt={'Create Experts Decision'}
                title={node.name + ' Experts'}
                parent_id={node.parent}
                suggestion={'SUGGEST EXPERTS'}
                description={
                  'Who would you ask about regen experts in ' +
                  node.name +
                  '? Search by Name. \n\nIf not found, put their name in the Title section and include links in the Description section.'
                }
                type={'people'}
                disabled={disabled}
              />
            ) : (
              <OptionsList width='100%' />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default RegionData;

import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';

import {
  Box,
  Text,
  Heading,
  HStack,
  useDisclosure,
  VStack,
  Radio,
  RadioGroup,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button
} from '@chakra-ui/react';
import RegionData from './RegionData';

import useNoo from 'hooks/useNoo';
import useGroups from 'hooks/useGroups';
import useDecisions from 'hooks/useDecisions';
import { capitalize } from 'utils';
import SimpleMap from '../Segments/SimpleMap';

const RegionDrawer = props => {
  const { currentOption } = useDecisions();
  const { isOpen, onOpen, onClose } = props;
  const btnRef = React.useRef();
  const label = currentOption?.data?.label || '';
  // <DrawerOverlay bg='tomato' sx={{ '--opacity': 0.5 }} /> // goes above DrawerContent, but it's opaque
  //    <DrawerHeader>{label}</DrawerHeader>
  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      finalFocusRef={btnRef}
      onRightClick={onClose}
      size='md'
    >
      <DrawerContent>
        <DrawerCloseButton />

        <DrawerBody width='100%' sx={{ margin: '0px', padding: '0px' }}>
          <Box width={'100%'}>
            <RegionData node={props.currentNode} network={props.netname} />
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const OneEarthSimpleMap = props => {
  let { width } = props;
  const _WIDTH = width || 600;
  const { nooUser, currentNetworkData } = useNoo('OneEarthSimpleMap');
  const network = currentNetworkData;
  const netname = network.id;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentId, setCurrentId] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [currentNode, setCurrentNode] = useState(null);
  const [level, setLevel] = useState('realm');
  const [label, setLabel] = useState(capitalize(level) + 's');
  const [showLand, setShowLand] = useState(true);
  const [lastGroupId, setLastGroupId] = useState(null);

  const { currentGroupId, setGroupId } = useGroups({
    from: 'OneEarthSimpleMap'
  });

  useEffect(() => {
    const group_ids = {
      climate: 'Groups/1c66a68e-335e-4697-92e1-e650ecfae17b',
      nao: 'Groups/67266ea3-8eb0-46b3-95ca-e9fa53aba7f1', // 'Groups/c0d3dec9-9154-4a07-923a-abf1ed93b70a',
      whoknows: 'Groups/67266ea3-8eb0-46b3-95ca-e9fa53aba7f1'
    };
    const gid = group_ids[netname];
    if (gid && currentGroupId != gid) {
      setGroupId(gid);
    }
  }, [currentGroupId, netname, network, setGroupId]);

  const { getDecisionsByCurrentGroup } = useDecisions();

  useEffect(() => {
    if (currentGroupId) {
      if (!lastGroupId || currentGroupId !== lastGroupId) {
        getDecisionsByCurrentGroup();
        setLastGroupId(currentGroupId);
      }
    }
  }, [currentGroupId, getDecisionsByCurrentGroup, lastGroupId]);

  const Breadcrumbs = () => {
    const items = [];
    items.push(
      <Text as='span' key={'empty'} fontSize={['.5em', '.7em']}>
        {' '}
      </Text>
    );
    if (breadcrumbs.length > 0) {
      breadcrumbs.forEach(idd => {
        const ndata = null; // findNode(taxoData[0], idd, '_id');
        if (ndata) {
          items.push(
            <Text
              as='span'
              key={ndata._id}
              data-id={ndata._id}
              fontSize={['.7em', '.9em']}
              onClick={changeId}
            >
              {'< ' + ndata.name}
            </Text>
          );
        }
      });
    }
    return <Box>{items}</Box>;
  };

  const changeId = e => {
    const tgt = e.target;
    const idd = tgt.dataset['id'];
    setCurrentId(idd);
    // props.rollup(idd);
  };

  useEffect(() => {
    if (currentId) {
      const path = null; // pathTo(currentId, taxoData[0]);
      let new_breads = path ? path.split('.').filter(x => x) : []; // remove empty root
      if (!(currentId in new_breads)) new_breads.push(currentId);
      if (JSON.stringify(new_breads) != JSON.stringify(breadcrumbs)) {
        setBreadcrumbs(new_breads);
      }
    }
  }, [currentId, setBreadcrumbs, breadcrumbs]);

  const onMapClick = geo => {
    if (props.onClick) props.onClick(geo, level); // onOpen();
  };

  const norm_label = strng => {
    const maxx = 40;
    const pattern = /\([A-Z0-9]+\)/g;
    let normed = strng.replace(pattern, '').replace(/\s+/g, ' ');
    return normed.length > maxx ? normed.slice(0, maxx - 2) + '...' : normed;
  };

  const onHover = (geo, on_off) => {
    const { name, meta } = geo;
    const mapper = { bioregion: name, subrealm: meta.subrealm, realm: meta.realm };
    let lbl = '';
    if (on_off) {
      lbl = norm_label(mapper[level]);
      setLabel(lbl);
    } else {
      setLabel(capitalize(level) + 's');
    }
    if (props.onHover) props.onHover(lbl, on_off);
  };

  const onChangeLevel = lvl => {
    if (lvl && lvl != level) {
      setLevel(lvl);
      setLabel(capitalize(lvl) + 's');
    }
  };
  const onCheck = e => {
    setShowLand(e.target.checked);
  };
  return (
    <Box>
      <VStack sx={{ width: _WIDTH }}>
        <SimpleMap
          bioregions={props.bioregions}
          land={props.land}
          onClick={onMapClick}
          onHover={onHover}
          level={level}
          showLand={showLand}
          width={_WIDTH - 4}
        />
        <Heading as='h4' size='md' textAlign='center' mt='1em'>
          {label}
        </Heading>
        <HStack>
          <RadioGroup
            sx={{ border: '1px solid lightgray', padding: '0px 4px' }}
            onChange={onChangeLevel}
            defaultValue={level}
          >
            <HStack direction='row'>
              <Radio value='realm'>Realms</Radio>
              <Radio value='subrealm'>Subrealms</Radio>
              <Radio value='bioregion'>Bioregions</Radio>
            </HStack>
          </RadioGroup>
          <Box sx={{ border: '1px solid lightgray', padding: '0px 4px' }}>
            <Checkbox onChange={onCheck} defaultChecked={showLand}>
              Show land
            </Checkbox>
          </Box>
        </HStack>
      </VStack>
    </Box>
  );
};

export default OneEarthSimpleMap;

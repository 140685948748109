import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import Treemap from './TreeMap'; // Kent's version
import PopoverNode from './TreeMap/PopoverNode';
import AutoComplete from 'components/Segments/AutoComplete';

import {
  Flex,
  Box,
  Text,
  Spinner,
  Heading,
  HStack,
  useDisclosure,
  VStack,
  position
} from '@chakra-ui/react';
import styled from '@emotion/styled';
// import _ from 'lodash';
import DecisionCreateModal from 'components/Segments/DecisionCreateModal';
import RegionData from './RegionData';

import useNoo from 'hooks/useNoo';
import useGroups from 'hooks/useGroups';
import useDecisions from 'hooks/useDecisions';
import oneearth from './TEST_oneearth';
import oneearth_solutions from './oneearth_solutions';
import { pathTo, colorTree, findNode, treeToTaxo } from 'utils/treemapUtils';
import { capitalize } from 'utils';
import { add } from 'lodash';
import TaxoSelector from '../Segments/TaxoSelector';
import { objectSort } from 'utils';

const OneEarthTreeMap = props => {
  const { token, nooUser, currentNetworkData } = useNoo('OneEarthTreeMap');
  const network = currentNetworkData;
  const netname = network.id;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { which } = props; // name of desired taxo

  const [whichTaxo, setWhichTaxo] = useState(which || 'bioregions');
  const [taxoData, setTaxoData] = useState(oneearth);
  const [wholeTree, setWholeTree] = useState(taxoData[0]);
  const [popoverNode, setPopoverNode] = useState(null);
  const [mouseX, setMouseX] = useState(null);
  const [mouseY, setMouseY] = useState(null);
  const [currentId, setCurrentId] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [currentNode, setCurrentNode] = useState(null);
  const [treeNodes, setTreeNodes] = useState(null);
  const [treeTaxo, setTreeTaxo] = useState(null);
  const [lastGroupId, setLastGroupId] = useState(null);

  const { currentGroupId, setGroupId, setCurrentGroupId } = useGroups({
    from: 'OneEarthTreeMap'
  });
  useEffect(() => {
    const group_ids = {
      climate: 'Groups/1c66a68e-335e-4697-92e1-e650ecfae17b',
      nao: 'Groups/67266ea3-8eb0-46b3-95ca-e9fa53aba7f1', // 'Groups/c0d3dec9-9154-4a07-923a-abf1ed93b70a',
      whoknows: 'Groups/67266ea3-8eb0-46b3-95ca-e9fa53aba7f1'
    };
    const gid = group_ids[netname];
    console.log('treem gid', gid, 'current', currentGroupId);
    if (gid && currentGroupId != gid) {
      setGroupId(gid);
    }
  }, [currentGroupId, netname, network, setGroupId]);

  const { getDecisionsByCurrentGroup } = useDecisions();

  useEffect(() => {
    if (currentGroupId) {
      if (!lastGroupId || currentGroupId !== lastGroupId) {
        getDecisionsByCurrentGroup();
        setLastGroupId(currentGroupId);
      }
    }
  }, [currentGroupId, getDecisionsByCurrentGroup, lastGroupId]);

  useEffect(() => {
    if (which && whichTaxo != which) {
      setCurrentId(null);
      setWhichTaxo(which);
    }
  }, [taxoData, which, whichTaxo, setWhichTaxo]);

  useEffect(() => {
    const taxos = { bioregions: oneearth, solutions: oneearth_solutions };
    const data = taxos[whichTaxo];
    setTaxoData(data);
  }, [whichTaxo, setTaxoData, which]);

  useEffect(() => {
    if (taxoData) {
      setCurrentId(taxoData[0]._id);
      colorTree(taxoData);
      const { nodes, taxo } = treeToTaxo(taxoData[0]);
      setTreeNodes(nodes);
      setTreeTaxo(taxo);
    }
  }, [taxoData]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // console.log('isopen changed', isOpen);
  }, [isOpen]);
  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);

  const Breadcrumbs = () => {
    const items = [];
    items.push(
      <Text as='span' key={'empty'} fontSize={['.5em', '.7em']}>
        {' '}
      </Text>
    );
    if (breadcrumbs.length > 0) {
      breadcrumbs.forEach(idd => {
        const ndata = findNode(taxoData[0], idd, '_id');
        if (ndata) {
          items.push(
            <Text
              as='span'
              key={ndata._id}
              data-id={ndata._id}
              fontSize={['.7em', '.9em']}
              onClick={changeId}
            >
              {'< ' + ndata.name}
            </Text>
          );
        }
      });
    }
    return <Box>{items}</Box>;
  };

  const changeId = e => {
    const tgt = e.target;
    const idd = tgt.dataset['id'];
    setCurrentId(idd);
    // props.rollup(idd);
  };

  useEffect(() => {
    const ndata = findNode(taxoData[0], currentId, '_id');
    if (ndata) setCurrentNode(ndata);
  }, [currentId, taxoData]);

  useEffect(() => {
    if (currentId && taxoData) {
      const path = pathTo(currentId, taxoData[0]);
      let new_breads = path ? path.split('.').filter(x => x) : []; // remove empty root
      if (!(currentId in new_breads)) new_breads.push(currentId);
      if (JSON.stringify(new_breads) != JSON.stringify(breadcrumbs)) {
        setBreadcrumbs(new_breads);
      }
    }
  }, [currentId, setBreadcrumbs, taxoData, breadcrumbs]);

  const autocompleteOptions = useMemo(() => {
    if (treeNodes) {
      return Object.entries(treeNodes)
        .map(([idd, node]) => {
          return { value: idd, label: node.name };
        })
        .sort(objectSort('label'));
    }
    return null;
  }, [treeNodes]);

  const doPop = useCallback(
    node => {
      setPopoverNode(node);
    },
    [setPopoverNode]
  );
  const handleMouseover = (node, e) => {
    if (node._id == e.target.dataset.id) {
      setMouseX(e.pageX);
      setMouseY(e.pageY);
      doPop(node);
      // e.target.style.border = '3px solid white ';
    }
  };

  const clearPopover = () => {
    setPopoverNode(null);
  };

  function handleMouseout(node, e) {
    // console.log('mouseout', node.name);
    if (popoverNode?._id == e.target.dataset.id) {
      // console.log('setting null', node._id, popoverNode?._id, e.target.dataset.id);
      clearPopover();
    }
    // if (e) e.target.style.border = '1px solid white ';
  }

  const handleDblClick = (node, e) => {
    if (currentId != node._id) {
      setCurrentId(node._id);
    }
  };

  const selectTaxoNode = (idd, node) => {
    if (currentId != idd) {
      setCurrentId(idd);
    }
  };

  const handleRightClick = (node, e) => {
    e.preventDefault(); // otherwise context menu comes up
    if (breadcrumbs) {
      const newId = breadcrumbs[breadcrumbs.length - 2]; // last one is current
      setCurrentId(newId);
      // props.rollup(newId);
    }
  };
  const handleClick = (node, e) => {
    e.preventDefault(); // otherwise context menu comes up
    let opt_id = node._id.indexOf('Options/') == 0 ? node._id : node.option;
    // console.log('click', node.name, node._id, node.depth, 'opt', opt_id, e.target.dataset.id);

    if (opt_id && e.target.dataset.id == node._id) {
      // console.log('opening', node.name, isOpen, opt_id, e.target.dataset.id, node._id);
      // loadOptionData(opt_id);
      // onOpen();
    }
  };

  const onSelectOption = option => {
    const idd = option.item?.value;
    console.log('id', idd, currentId, option);
    if (idd && currentId != idd) {
      setCurrentId(idd);
    }
  };

  /*   

  const loadOptionData = useCallback(
    optionId => {
      onOpen();
      if (optionId) {
        console.log('getting option', optionId);
        getOptionById(optionId);
      }
    },
    [getOptionById, onOpen]
  );
  useEffect(() => {
    if (currentId != currentDecision?.question?._id) {
      console.log('loading curr ', currentId);
      loadDecisionData(currentId);
    }
  }, [currentDecision?.question?._id, currentId, loadDecisionData]); */

  const noop = () => {
    return null;
  };

  return (
    <Box>
      <div className='App-row listing' bg='white' w='100%'>
        <HStack sx={{ border: '1px solid grey', w: '100%', alignItems: 'top' }}>
          {taxoData && (
            <VStack width={windowWidth * 0.57 + 'px'} height={windowWidth * 0.57 * 0.9 + 'px'}>
              {popoverNode && (
                <Box
                  pos='absolute'
                  top={mouseY}
                  left={mouseX}
                  zIndex={1000}
                  /* onMouseOut={handleMouseout} */
                >
                  <PopoverNode key={popoverNode.id} node={popoverNode} />
                </Box>
              )}
              <VStack sx={{ backgroundColor: '#eeeeee' }}>
                <Text sx={{ width: '100%', fontSize: ['8px', '8px', '16px'] }}>
                  Click box labels to drill down. Use breadcrumbs to go back up.
                </Text>

                <TaxoSelector
                  nodes={treeNodes}
                  taxo={treeTaxo}
                  label={`${capitalize(which)}:`}
                  callback={selectTaxoNode}
                  width={'100%'}
                />
                <HStack sx={{ width: '100%', padding: '0px 3px' }}>
                  <Text>Search:</Text>
                  <AutoComplete
                    options={autocompleteOptions}
                    onSelectOption={onSelectOption}
                    searchOptions={noop}
                  />
                </HStack>
              </VStack>
              <Breadcrumbs />
              {treeNodes && (
                <Treemap
                  data={taxoData}
                  dblclick={handleDblClick}
                  rightclick={handleRightClick}
                  click={handleDblClick}
                  labelclick={handleDblClick}
                  mouseenter={handleMouseover}
                  mouseleave={handleMouseout}
                  /* node_id={currentDecisionId} */
                  rootNodeId={currentId}
                  showLabels={true}
                  maxDepth={3}
                />
              )}
            </VStack>
          )}
          {currentNode && (
            <Box width={windowWidth * 0.4 + 'px'}>
              <RegionData node={currentNode} network={netname} center={nooUser?.person} />
            </Box>
          )}
        </HStack>
        {/* {<TreemapGoogleCharts data={wholeGoogle} tooltip={cellTooltip} cb={clickCallback} />} */}
      </div>
    </Box>
  );
};

export default OneEarthTreeMap;

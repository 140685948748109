export const NOO_SET_LOADING = 'NOO_SET_LOADING';
export const NOO_SET_USER = 'NOO_SET_USER';
// export const NOO_SET_GOOGLE_USER = 'NOO_SET_GOOGLE_USER';
export const NOO_SET_CURRENT_NETWORK = 'NOO_SET_CURRENT_NETWORK';
// export const NOO_SET_ADMIN = 'NOO_SET_ADMIN';

export const NOO_COMMAND_REQUEST = 'NOO_COMMAND_REQUEST';
export const NOO_COMMAND_RESPONSE = 'NOO_COMMAND_RESPONSE';
export const NOO_COMMAND_RESPONSE_ERROR = 'NOO_COMMAND_RESPONSE_ERROR';

export const FBA_SET_LOADING = 'FBA_SET_LOADING';
export const FBA_SET_USER = 'FBA_SET_USER';
export const FBA_SET_ERROR = 'FBA_SET_ERROR';

export const NOOUI_SET_CURRENT_THEME = 'NOOUI_SET_CURRENT_THEME';

export const NOO_SET_INVITATION = 'NOO_SET_INVITATION';
export const NOO_SET_INVITATION_ID = 'NOO_SET_INVITATION_ID';
export const NOO_RESET_INVITATION_DATA = 'NOO_RESET_INVITATION_DATA';

export const NOO_USER_LOGOUT = 'NOO_USER_LOGOUT';

export const SET_BOOKMARKED_URL_DATA = 'SET_BOOKMARKED_URL_DATA';
export const CLEAR_BOOKMARKED_URL_DATA = 'CLEAR_BOOKMARKED_URL_DATA';
export const BOOKMARK_HEADLINE_REQUEST_START = 'BOOKMARK_HEADLINE_REQUEST_START';
export const BOOKMARK_HEADLINE_COMPLETE = 'BOOKMARK_HEADLINE_COMPLETE';
export const BOOKMARK_HEADLINE_ERROR = 'BOOKMARK_HEADLINE_ERROR';
export const BOOKMARK_GET_OPENGRAPH = 'BOOKMARK_GET_OPENGRAPH';

export const SEARCH_PEOPLE_REQUEST_START = 'SEARCH_PEOPLE_REQUEST_START';
export const SET_PEOPLE_DATA = 'SET_PEOPLE_DATA';
export const SET_PEOPLE_ERROR = 'SET_PEOPLE_ERROR';
export const CLEAR_PEOPLE_DATA = 'CLEAR_PEOPLE_DATA';

export const DECISIONS_REQUEST_START = 'DECISIONS_REQUEST_START';
export const DECISION_REQUEST_START = 'DECISION_REQUEST_START';
export const SET_DECISIONS_DATA = 'SET_DECISIONS_DATA';
export const SET_DECISIONS_ERROR = 'SET_DECISIONS_ERROR';
export const SET_DECISION_DATA = 'SET_DECISION_DATA'; // decision details
export const SET_DECISION_ERROR = 'SET_DECISION_ERROR';
export const SET_CURRENT_OPTION_DATA = 'SET_CURRENT_OPTION_DATA';
export const ADD_OPTION_TO_DECISION = 'ADD_OPTION_TO_DECISION';
export const ADD_OPTION_TO_DECISION_ERROR = 'ADD_OPTION_TO_DECISION_ERROR';
export const ADD_ANSWER_TO_DECISION = 'ADD_ANSWER_TO_DECISION';
export const ADD_ANSWER_TO_DECISION_ERROR = 'ADD_ANSWER_TO_DECISION_ERROR';
export const CREATE_DECISION = 'CREATE_DECISION';
export const CREATE_DECISION_ERROR = 'CREATE_DECISION_ERROR';
export const UPDATE_VOTE_IN_CURRENT_DECISION = 'UPDATE_VOTE_IN_CURRENT_DECISION';
export const CLEAR_ALL_DECISIONS_DATA = 'CLEAR_ALL_DECISIONS_DATA';
export const CLEAR_DECISION_DATA = 'CLEAR_DECISION_DATA';

export const SET_CURRENT_GROUP_ID = 'SET_CURRENT_GROUP_ID';

export const PEOPLENETWORK_REQUEST_START = 'PEOPLENETWORK_REQUEST_START';
export const SET_PEOPLENETWORK_DATA = 'SET_PEOPLENETWORK_DATA';
export const SET_PEOPLENETWORK_ERROR = 'SET_PEOPLENETWORK_ERROR';
export const CLEAR_PEOPLENETWORK_DATA = 'CLEAR_PEOPLENETWORK_DATA';

export const ADD_CHAT_DATA = 'ADD_CHAT_DATA';
export const DROP_CHAT_DATA = 'DROP_CHAT_DATA';
export const UPDATE_CHAT_DATA = 'UPDATE_CHAT_DATA';
export const CHAT_IS_REQUESTING = 'CHAT_IS_REQUESTING';
export const CHAT_REQUEST_ERROR = 'CHAT_REQUEST_ERROR';
export const CHAT_SUBSCRIBE = 'CHAT_SUBSCRIBE';
export const CHAT_UNSUBSCRIBE = 'CHAT_UNSUBSCRIBE';

// export const WRITE_DOCUMENT = 'WRITE_DOCUMENT'; // postponed useDocuments hook
// export const READ_DOCUMENT = 'READ_DOCUMENT';
export const FAN_SET_LOADING = 'FAN_SET_LOADING';
export const FAN_SET_USER = 'FAN_SET_USER';
export const FAN_SET_ERROR = 'FAN_SET_ERROR';

export const SET_BIOREGION_DATA = 'SET_BIOREGION_DATA';
export const SET_BIOREGION_IS_FETCHING = 'SET_BIOREGION_IS_FETCHING';
export const BIOREGION_FETCH_ERROR = 'BIOREGION_FETCH_ERROR';

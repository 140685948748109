const markdown = `


### Purpose
The purpose of a Network Cooperative is to aggregate the agency and value of its membership and to provide unique coordination and governance capacities.

### Why?

The work of dealing with the ***Metacrisis*** requires rising above our current broken systems.  Humanity needs dramatically higher levels of global coordination that can outcompete those systems by being better at sensemaking, decision-making, collective action, and superorganism behavior. 

Such capabilities will undoubtedly be built on social graphs, the "neural networks" of the noosphere, the *social substrate* on which innovation can happen. 

And by definition, such a social substrate ***cannot*** be yet another corporate enclosure. Thus, we must grow a ***commons social graph*** owned and governed by its members.


### What is a Network Cooperative?

A Network Cooperative is a ***social capital commons***,  a *new organizational form*, comprised of two things: 

(a) a **legal cooperative** whose membership is a union of overlapping networks, i.e. social graphs of members plus relationships; and 

(b) **governance** specified in the coop’s bylaws that uses its social graph for richer member participation and engagement than traditional cooperatives with flat membership lists.

By including relationships between members, e.g. *delegation, respect, and trust*, advanced tools like _[liquid democracy](https://en.wikipedia.org/wiki/Liquid_democracy)_ become part of the governance possibilities.

Ideally, but not necessarily, membership is propagated by invitation, which creates relationships in the graph, chains of accountability from all members back to the founders (the _kernel_), and social coherence from shared values thereof. For instance, we envision the first instance to be a _regeneration network cooperative_.


### Governance
The Regen Network Cooperative is not yet a legal entity, as of August 2024. Until it is, the code and data are stewarded by NooNet, a fiscally sponsored project of [Planetwork NGO](https://www.planetwork.net/).


Per [Gall's Law](http://principles-wiki.net/principles:gall_s_law) and *minimum viable*, we are exploring the options for legal manifestation in parallel with early propagation of a social commons.

Everyone who shares their Linkedin connections is timestamped and given an according *member number*. The first 1000 members will have the right to participate in ratification of the eventual legal form and its bylaws.


### Assets

The assets of a Network Cooperative are its membership plus all relationships therein – ie a graph, aka _social substrate_; its governance and coordination capacity; and the ecosystem of applications granted access to its membership via the _substrate_, providing utility and revenue.

### What can we do with it?
A shareable social graph means that any application that complies with its principles can share a common user base. This dramatically reduces the cost and risk of user acquisition, and encourages interoperation. 

There are innumerable ways we can leverage that for the common good:

* People Discovery
* Collective action - e.g. Climate Swarm
* Sensemaking
	* Social bookmarking (see [BestOfNow](https://bestofnow.net))
	* Authentic New - networks of domain expertise as emergent editorial boards
	* ...
* Resource allocation
	* [WikiBudgets](https://docs.google.com/document/d/1gr3DGw_yZsyp7vy7fCbnkLwdw77doYZCE2BC6REs3cg/edit#heading=h.k6y0ue5ufxxc)
	* Project prioritization
	* ...
* Deliberation and coordination tools
* Data cooperatives - aggregate the collective value of our data

[BestOfNow](https://bestofnow.net) is the most mature such application at launch. The value of this cooperative should provide resources for identifying and nurturing many others.

### Values and Principles

The Network Cooperative pattern is *value neutral*; it doesn't assume any specific principles.

*However*, propagation by invitation imbues specific instances with the principles of its initiators. For example, a founding _kernel_ of regenerative leaders can span the very large regenerative movement as a network cooperative. 


### It's fractal

Networks and subnetworks are [*fractal*](https://en.wikipedia.org/wiki/Fractal), multi-scale, nested, and overlapping. They are easily created with filters, e.g. just the people currently delegated in the domain of _finance_, and their relationships. Each is a _subgraph_, _[self-similar](https://en.wikipedia.org/wiki/Self-similarity)_ to the whole graph, and to other subgraphs. Any combination can compose via boolean operations like *union* and *intersection*.


### It's a pattern

Subnetwork self-similarity means that tools, especially governance, that work for any subgraph work for all graphs in the network. Thus the combination of a graph plus coordination tools is a pattern that can be harnessed for many purposes, such as _portable communities_ (see below), resource allocation, _liquid coordination_, and even _[coordi-nations](https://jessykate.medium.com/coordi-nations-a-new-institutional-structure-for-global-cooperation-3ef38d6e2cfa)_ (aka _[network nations](https://docs.google.com/presentation/d/1_y3Lsdta38pVxfhnvYuS83W6Ne4FOSkCdSoy-rCVERQ/edit)_). _Network cooperatives_ indeed provide an ideal _citizen topology_ for the latter, because they bring a form of governance currently lacking from the literature. 

### It's self-funding
Cooperatives have member dues. Networks are governed by [Metcalfe’s Law](https://en.wikipedia.org/wiki/Metcalfe%27s_law). Membership by invitation creates a 'velvet rope' strategy (a la Clubhouse). The right level of dues creates working capital that powers innovation and makes it more attractive, creating an upward spiral. 

### Token economics

Lots to explore here. We don't want to require wallets for membership. But there are lots of uses for tokens, e.g. a hyperconnector introduces two people who end up really appreciating knowing each other. So they are in 'debt' to the introducor.

To be explored

### Social Substrate of the Tech for Good Stack

![Tech for Good stack](https://standingwave.net/img/WorldWiseWebStack_5_3.drawio.svg)

### Glossary
We use the term ***liquid coordination*** to mean any decisioning, deliberation, sense-making or other tools that exploit the network topology for greater efficiency and emergence. _[Liquid democracy](https://en.wikipedia.org/wiki/Liquid_democracy)_, *[sociocracy](https://en.wikipedia.org/wiki/Sociocracy)*, and *[holacracy](https://en.wikipedia.org/wiki/Holacracy)* are few specific examples.

A **_cooperative_** in this context is a legal entity incorporated as a cooperative enterprise in some jurisdiction, and thus has bylaws that define membership types and organizational norms including onboarding processes. To be a **_network cooperative_**, it must also have a means of storing, and editing, relationships between members, especially for governance, e.g. delegation by domain of expertise.

The **_governance_** of a _network cooperative_ is defined in its bylaws. As a proof of concept, one reference implementation manifests the traditional board as a _governing network_, itself a _portable community_ (see below), elected by members. That election, and all decisions made by the _governing network_, use _liquid coordination_ enabled by the network connectivity of the membership. The forms and parameters of _liquid coordination_ used, or other decisioning mechanisms, can be changed and evolved with future instances.

The **membership** of a _network cooperative_ is a **_federation._**

A **_federation_** is a union of some combination of **_federations_** and/or **_portable communities_**. This is where the _fractal_ comes in; such composition allows for the arbitrary combination of nesting and overlap among constituent communities/graphs.

A **_portable community_** is a social graph in a minimal but expressive interoperable JSON format. Specifically, it is a collection of ***humanIDs*** and the union of the **_personal networks_** thereof, or the parts thereof that each member agrees to share within that community (see **_masks_**). It is importable and exportable as JSON in the standard _[portable communities protocol](https://docs.google.com/presentation/d/1KHYXbXBxZ8NqIl5nrRhyudLEKGnjMhhEqtB0fkKS8PQ/edit#slide=id.g1bcb072e435_0_15)_. By virtue of that portability, _portable communities_ are composable via boolean operations (union, intersection…) into _federations. _An alternative term for a _community_ is a _neighborhood_.

A ***humanID*** is a _proof-of-humanity_ identifier in the form of a_ decentralized identifier_ (DID) in a **_registry_**. 

A **_registry_** is a service that performs _proof of humanity_ verification. For a given *humanID*, it returns a confidence value, 0. to 1., that said *humanID* is a real human registered exactly once in that registry. See proof of concept at  [GreenCheck](https://greencheck.world/).


A **_personal network_** is the relationships (**_edges_**) between the *humanID* that owns it (the _hub_) and others (_spokes_), representing _trust, respect, delegation,_ etc. The owner has _self-sovereign control_ over their _personal network_, and thus over who has access to what parts of it. Because a _personal network _only contains _edges _from the owner to others, and not among the others, it is referred to as a _hub and spokes._

An **_edge_** is a digital representation of the relationship from a _source *humanID* to a _target *humanID*, i.e. what the _source_ thinks of someone in their personal network (_target<span style="text-decoration:underline;">)</span>_. Specifically, it is a JSON object with _souce_ID, target_ID_, and _data_. The _data_ field is freeform, and can be defined by whatever schema is desired. An initial standard is a _respect vector_, a _multidimensional floating-point vector_, where each dimension is a domain of expertise, and its value is the strength of the source’s respect for the target in that domain, e.g. _data = {respect_vector: {finance: .99, aviation: .01, musical_taste: .5}_

A **_mask_** is a filter that controls what portion of one’s _personal network_ is available within a community. For instance, one might only want to share one’s _edges_ to others in the community, vs. including _edges_ pointing outside it

See spec below.



### Overlapping communities and how they might federate


#### Bioregional
![overlapping communities](https://standingwave.net/img/NN/overlapping_communities.png)

![overlapping communities](https://standingwave.net/img/NN/Overlapping_federations_excalidraw.png)


### Liquid coordination
_[Wikipedia on liquid democracy](https://en.wikipedia.org/wiki/Liquid_democracy)_ is one example. Delegation example from that page.  

![](https://standingwave.net/img/NN/wikipedia_delegation.png)




“Illustration of delegated voting. Voters to the left of the blue line voted by delegation. Voters to the right voted directly. Numbers are the quantity of voters represented by each delegate, with the delegate included in the count.”


### Links

[Network Cooperative deck for Metagov seminar](https://docs.google.com/presentation/d/1M7HAkCQRbi38vM530CYeo-bbUJwSukjKJtZJ9f1Tu30/)




`;

export default markdown;

import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import useNooApi from 'hooks/useNooApi';
import { setInvitation, setInvitationId, resetInvitationData } from 'redux/actions/invitations';

const useInvitations = () => {
  const dispatch = useDispatch();
  const { nooCommand, nooCommandResponse, nooCommandIsProcessing } = useNooApi();

  const invitationId = useSelector(state => state.invitations?.invitationId, shallowEqual);
  const invitation = useSelector(state => state.invitations?.invitation, shallowEqual);

  const [invitationResponse, setInvitationResponse] = useState(null);
  // exploring distinct responses for now in some components,
  // may refactor to one response later
  const [acceptResponse, setAcceptResponse] = useState(null);

  // used mainly for the inbound invitation link handling:
  const updateInvitationId = useCallback(
    id => {
      console.log('updating invitation Id to: ' + id);
      dispatch(setInvitationId(id));
    },
    [dispatch]
  );

  const acceptInvitation = () => {
    dispatch(nooCommand('acceptInvitation', { invitationId }));
    setAcceptResponse(null);
    setInvitationResponse(null);
    dispatch(resetInvitationData());
  };

  const createInvitation = (groupids, invitee) => {
    dispatch(resetInvitationData());
    const config = { groupids, type: 'invite' };
    if (invitee) {
      config.invitee = invitee;
    }
    console.log('createinvite', config);
    dispatch(nooCommand('createInvitation', { config }));
  };

  useEffect(() => {
    if (
      nooCommandResponse?.command &&
      ['acceptInvitation', 'createInvitation'].indexOf(nooCommandResponse.command) > -1
    ) {
      setInvitationResponse(nooCommandResponse.data);
      switch (nooCommandResponse.command) {
        case 'acceptInvitation':
          setAcceptResponse(nooCommandResponse.data);
          break;
        case 'createInvitation':
          // setCreateResponse(nooCommandResponse.data);
          dispatch(setInvitation(nooCommandResponse.data));
          // below is done as part of setInvitation() reducer:
          // if (nooCommandResponse.data?.invitation?._id) {
          //   updateInvitationId(nooCommandResponse.data?.invitation?._id);
          // }
          break;
      }
    }
  }, [dispatch, nooCommandResponse?.command, nooCommandResponse?.data, updateInvitationId]);

  return {
    updateInvitationId,
    acceptInvitation,
    createInvitation,
    invitationId,
    invitationLoading: nooCommandIsProcessing,
    invitationResponse, // not sure we need this any more, need to explore
    invitation,
    acceptResponse
  };
};

export default useInvitations;

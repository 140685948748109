import React, {
  /*useState, useEffect, useCallback, */ useCallback,
  useEffect,
  useMemo
} from 'react';
import _ from 'lodash';
// import { useDispatch } from 'react-redux';
import { Checkbox, Select, Stack } from '@chakra-ui/react';
// import styled from '@emotion/styled';
import useGroups from 'hooks/useGroups';
import useMultiDomain from 'hooks/useMultiDomain';
import useNoo from 'hooks/useNoo';
import { iterate_object, derivePrefixedNodeId } from 'utils';

const GroupSelector = props => {
  const {
    // nooUser,
    callback,
    showAnyOption = false,
    syncOnRender = true,
    useHook = true,
    render = 'checkbox',
    selected,
    filters = ['all'],
    disabled = [],
    width = '300px',
    indentOptions = true
  } = props;

  const { groups, currentGroupId, setGroupId, groupsTaxonomies } = useGroups({
    filters,
    from: 'GroupSelector'
  });

  const is_checkbox = render === 'checkbox';
  const is_selector = render === 'selector';
  const { groupSelector } = useMultiDomain();
  const { currentNetwork, currentNetworkData, nooUser } = useNoo('GroupSelector');
  const my_groups = useMemo(() => {
    return nooUser ? _.union(nooUser?.member, nooUser?.owner) : [];
  }, [nooUser]);

  const root_group = currentNetworkData.root_group;
  // if the GroupSelector is requested with a current group, and !!syncOnRender,
  // update the current group in redux
  useEffect(() => {
    if (is_selector && useHook && syncOnRender && selected && currentGroupId !== selected) {
      setGroupId(selected);
    }
  }, [currentGroupId, is_selector, useHook, syncOnRender, selected, setGroupId]);

  const onSelectGroup = event => {
    const group_id = event.target.value;
    if (group_id != currentGroupId || groups.length == 1) {
      console.log('Changing group_id', group_id);
      setGroupId(group_id);
      if (callback) {
        callback(event, group_id);
      }
    }
  };
  const onBlurGroup = event => {
    if (groups.length == 1) {
      onSelectGroup(event);
    }
  };

  const onCheckGroup = useCallback(
    event => {
      // future thinking: maybe the hook can keep a Set of checked groups,
      // but that might not be very useful outside of this component.
      // UNLESS: the component tracks the Set of CheckedGroups
      // and emits it back out in the callback instead of having the consumer handle the event
      // would enable passing in a pre-checked set to pre-select a subset of the checkboxes

      if (callback) {
        callback(event, currentGroupId);
      }
    },
    [callback, currentGroupId]
  );

  const checkboxes = useMemo(
    () =>
      is_checkbox &&
      groups
        .map(grp => {
          const idd = grp._id;
          const nm = grp.data?.fullname;
          // this is incorrect I think, see above note about local Set management
          const sel = grp._key == currentGroupId;
          const turn_off = disabled.includes(idd);
          return (
            nm && (
              <Checkbox
                onChange={onCheckGroup}
                name={'groups'}
                isDisabled={!!turn_off}
                key={idd}
                value={idd}
              >
                {nm}
              </Checkbox>
            )
          );
        })
        .filter(checkbox => !!checkbox),
    [is_checkbox, groups, currentGroupId, disabled, onCheckGroup]
  );

  const nestedOption = useCallback(
    (gid, depth) => {
      const indent = '\u00A0'.repeat(depth * 4);
      const group = _.find(groups, { _id: gid });
      const gname = group?.data?.fullname || gid;
      const mine = my_groups.includes(gid) ? ' * ' : '';
      const disp = indent + gname + mine;
      return (
        <option value={gid} key={gid}>
          {disp}
        </option>
      );
    },
    [groups, my_groups]
  );

  const options = useMemo(() => {
    const rootId = root_group ? derivePrefixedNodeId(root_group, 'Groups') : null;
    let opts = [];
    if (is_selector && groups?.length && !indentOptions) {
      opts = groups
        .map(group => {
          const fullname = group.data?.fullname;
          const id = group._id;
          const turn_off = disabled.includes(id);
          // const valid = id && (!rootId || id == rootId || path.indexOf(rootId) >= 0); // tbd complete path
          if (fullname && !turn_off) {
            return (
              <option value={id} key={id}>
                {fullname}
              </option>
            );
          }
          return null;
        })
        .filter(group => !!group);
    } else if (indentOptions) {
      let taxo = groupsTaxonomies;
      const cb = (gid, v, depth, path) => {
        const valid =
          gid &&
          groups.find(g => gid == g._id) &&
          (!rootId || gid == rootId || path.indexOf(rootId) >= 0);
        if (valid) {
          opts.push(nestedOption(gid, depth));
        }
      };
      if (taxo) {
        iterate_object(taxo, 0, '', cb);
      }
    }
    if (opts?.length > 0) {
      if (showAnyOption) {
        opts.unshift(
          <option value='' key='group-any-option'>
            Any
          </option>
        );
      }
      return opts;
    }
    return null;
  }, [
    is_selector,
    indentOptions,
    groups,
    showAnyOption,
    disabled,
    groupsTaxonomies,
    nestedOption,
    root_group
  ]);

  if (!groupSelector) {
    return null;
  }

  return (
    <div>
      {is_checkbox && (
        <Stack bg='white' direction={['column', 'row']}>
          {checkboxes}
        </Stack>
      )}
      {is_selector && (
        <Select
          onChange={onSelectGroup}
          onBlur={onBlurGroup}
          value={(useHook && currentGroupId) || ''}
          w={width}
          bg='#fff'
          _focus={{ outline: 0 }}
        >
          {options}
        </Select>
      )}
    </div>
  );
};

export default GroupSelector;

import React, { useEffect, useRef, useState } from 'react';
import { Box, VStack, Heading, HStack, Spinner, Text } from '@chakra-ui/react';
import { useParams, useHistory } from 'react-router-dom';
import useDecisions from 'hooks/useDecisions';
import useGroups from 'hooks/useGroups';
import { dropNodeIdPrefix, derivePrefixedNodeId } from 'utils';
import { ContentSubpanel } from 'components/Segments/Styled';
import GroupSelector from 'components/Segments/GroupSelector';
import UserAuth from 'components/User/UserAuth';
import QuestionsList from './QuestionsList';
import OptionsList from './OptionsList';
// import OptionDetails from './OptionDetails';
import DecisionTreeMap from 'components/Segments/DecisionTreeMap';

const TreemapModule = () => {
  const { groupId: groupIdFromParams, decisionId: decisionIdFromParams } = useParams();
  const history = useHistory();
  const { currentGroupId, setGroupId, groupSelector } = useGroups({ from: 'TreemapModule' });
  const {
    isActive,
    currentDecisionId,
    getDecisionById,
    currentDecision,
    currentDecisionError
  } = useDecisions();
  console.log('error', currentDecisionError);
  const groupIdSetOnLoad = useRef(false);
  const groupId = derivePrefixedNodeId(groupIdFromParams, 'Groups');
  const [decisionId, setDecisionId] = useState(
    derivePrefixedNodeId(decisionIdFromParams, 'Questions')
  );

  useEffect(() => {
    if (currentDecisionError) {
      console.log('Error getting decision:', currentDecisionError);
      setDecisionId(null);
    }
  }, [currentDecisionError]);

  useEffect(() => {
    const addGroupChangeToBrowserHistory = () => {
      const cleanGroupId = currentGroupId && dropNodeIdPrefix(currentGroupId);
      if (cleanGroupId && location.href.indexOf(cleanGroupId) === -1) {
        history.push(`/wikibudget/${cleanGroupId}`);
      }
    };
    if (groupId && groupId !== currentGroupId) {
      if (!groupIdSetOnLoad.current) {
        groupIdSetOnLoad.current = true;
        setGroupId(groupId);
      } else {
        addGroupChangeToBrowserHistory();
      }
    } else if (!groupId && currentGroupId) {
      addGroupChangeToBrowserHistory();
    }
  }, [currentGroupId, groupId, history, setGroupId]);

  useEffect(() => {
    if (decisionId && decisionId !== currentDecisionId && !currentDecision) {
      getDecisionById(decisionId);
    }
  }, [currentDecisionId, decisionId, getDecisionById, currentDecision]);
  return (
    <Box pb='1em'>
      <Box>
        <Text>
          Rough prototype of collaboratively editable taxonomy. Far from integrated / polished so be
          forgiving. If necessary, click another tab, then return to Wikibudget tab.
        </Text>
        <Text>
          Click Climate Ontology on the left to start. Select Climate Swarm group if necessary it is
          not there.
        </Text>
        <Text>Double-click and right-click in the Treemap to explore the full taxonomy.</Text>
        <Text>
          Choose subdomains on the left (e.g. Oceans) to add to it, by voting on existing options
          and adding new ones.
        </Text>
      </Box>
      <UserAuth unauthorized='signin'>
        <Box className='App-row'>
          <ContentSubpanel width={['100%', '85%']}>
            <Box pb='10px' mb='10px' borderBottom='1px solid #eee'>
              <Heading as='h1' size='md'>
                WikiBudget
              </Heading>

              {groupSelector ? (
                <HStack spacing={10}>
                  <Heading as='h3' size='sm' fontWeight='normal'>
                    Choose a Group
                  </Heading>
                  <GroupSelector label='Choose Group' render='selector' selected={currentGroupId} />
                </HStack>
              ) : null}
            </Box>
            <HStack sx={{ gap: '10px' }} alignItems={'flex-start'}>
              <Box sx={{ width: '60%' }}>
                <DecisionTreeMap />
              </Box>
              {currentDecisionId && <OptionsList />}
              {true && (
                <QuestionsList groupId={currentGroupId} sx={{ width: '20%' }} arrowDir={'right'} />
              )}
            </HStack>
          </ContentSubpanel>
        </Box>
      </UserAuth>
    </Box>
  );
};

export default TreemapModule;

const markdown = `

### What if?

This is a critical moment in human history. The metacrisis is real, and our current systems are utterly failing humanity and the planet. One fundamental component of those systems is the devaluing and exploitation of women. 

What if the great women of the world could connect ourselves to act with real collective influence on the global stage. There are [hundreds or thousands of organizations internationally focused on women’s rights and well-being](https://www.google.com/search?q=international+women%27s+organizations&sca_esv=c640f05120339334&sxsrf=ADLYWILD869mg1XssB62l8vEiIvuDC746A%3A1732227555122&source=hp&ei=47E_Z8SPBdHA0PEPq9mpmQU&iflsig=AL9hbdgAAAAAZz-_8wVfYMZLcmzmlkjAdxf3XdL05I3B&oq=international+womens+orga&gs_lp=Egdnd3Mtd2l6IhlpbnRlcm5hdGlvbmFsIHdvbWVucyBvcmdhKgIIADIHEAAYgAQYCjIHEAAYgAQYCjIIEAAYFhgKGB4yBhAAGBYYHjIGEAAYFhgeMggQABgWGAoYHjIGEAAYFhgeMgYQABgWGB4yCBAAGBYYChgeMgYQABgWGB5IrD5Q9gNYgC9wAXgAkAEAmAGHAaABqRGqAQQyMy4yuAEByAEA-AEBmAIaoALzEagCCsICBxAjGCcY6gLCAgoQIxiABBgnGIoFwgILEAAYgAQYkQIYigXCAhEQLhiABBiRAhjRAxjHARiKBcICCxAAGIAEGLEDGIMBwgIREC4YgAQYsQMYgwEY1AIYigXCAg4QLhiABBixAxiDARiKBcICDhAuGIAEGLEDGNEDGMcBwgIEECMYJ8ICCxAuGIAEGJECGIoFwgIOEC4YgAQYsQMYgwEY1ALCAhQQLhiABBixAxiDARjHARiKBRivAcICCxAuGIAEGLEDGIMBwgIOEAAYgAQYsQMYgwEYigXCAhEQLhiABBixAxiDARjHARivAcICERAuGIAEGLEDGNEDGIMBGMcBwgIUEC4YgAQYkQIYsQMY0QMYxwEYigXCAhAQABiABBixAxiDARgUGIcCwgINEAAYgAQYsQMYFBiHAsICCBAAGIAEGLEDwgILEC4YgAQYxwEYrwHCAg4QLhiABBjHARiOBRivAcICBRAAGIAEwgINEAAYgAQYsQMYgwEYCsICDRAuGIAEGNEDGMcBGAqYAwqSBwQyNC4yoAeP7gI&sclient=gws-wiz). All do great work, but suffer from diffusion and are forced to compete for limited resources.

### Concept

We propose here a simple way to allow those large numbers to act collectively with vastly greater agency.  

**We can easily span that sisterhood** with digital networks of trust and respect, starting from a founding kernel of exemplary leaders, creating a planetary women’s *virtual nation,* capable of **fluid coordination, real-time global action, great financial value**, and **serious political influence**.

**A ‘nation’ defined not by geography and borders, but by the hopes, dreams, and principles we share.**

We believe that a global women's virtual nation, and an allied regeneration nation, can have **cultural, political, and financial power** comparable to the Catholic Church (see **Scale** below.)

### Virtual nations

**Virtual nations don’t require new technology**. The book [*The Network State*](https://thenetworkstate.com/book/tns.pdf), though deeply flawed (see [this](https://theblockchainsocialist.com/category/podcast/overthrowing-the-network-state/) and [this](https://linktr.ee/networksovereignties)), succeeded in putting the concept into the popular imagination (albeit two years after the authors of this document began work on virtual nations). 

There will be many variations of such ‘[supranational unions](https://en.wikipedia.org/wiki/Supranational_union)’. This proposal focuses on women because it’s important and its value is obvious. 

It will overlap and federate well with a ‘regeneration nation’. There can of course be numerous others that nest and overlap, e.g. indigenous, ocean experts, stateless people, bioregions. Each can decide what others to interoperate / federate with.

### What's unique

Besides women-only, the approach proposed here stands out from other *network nation* approaches for one major fundamental reason: **citizenries are grown by invitation, starting with founders who *embody the principles and visions desired***. 

That process unites the citizenry through networks of trust and respect, which have the unique capacity for emergent coordination, in effect, acting as *networked adaptive organisms,* 

By manifesting those connections as overlapping digital networks, propagated from proven leaders and sages, the union can quickly span millions of people,  across many aligned movements, e.g. climate, regeneration, bioregional, social justice, decentralized web, etc. 

They create ***mycelial connections*** across the planet for communication and nutrient flow, and form a substrate of community connection that will be invaluable in the challenging times ahead.

### Emergent coordination

Networks of trust, respect, delegation make whole new forms of emergent coordination, such as liquid democracy, at all scales, possible. 

Holocracy, Sociocracy, Pol.is, liquid democracy, and other innovations can take advantage of digital delegation in many ways. 

Network topologies can be 10x or more better at multi-scale coordination than old-school, hierarchical entities like the Catholic Church.

### Proof of concept

The technology for this already exists. [NooNAO](https://nao.is) has been using the invitation and graph database technology for over four years, and have simulated a ‘regeneration network’ of 340,000+ people at most two degrees of separation from a *kernel* of ten people (e.g. [June Holley](https://www.linkedin.com/in/june-holley-5981972/), [Carolina Carvalho](https://www.linkedin.com/in/carolina-carvalho-457523152/), [Tomas Björkman](https://www.linkedin.com/in/tomas-bj%C3%B6rkman-882913a7/),  [Dounia Saeme](https://www.linkedin.com/in/dsaeme/), [Jon Schull](https://www.linkedin.com/in/jonschull/)).

There are 31M users on LinkedIn who match *environment* on the profile. About 46% are women, hence 14M environmental women, all of whom are likely within four degrees of 

[This spreadsheet is a simulation](https://docs.google.com/spreadsheets/d/1g1rJX35DDD38rGY1T-dCp96zwwjcgr1iUDX4eZ8xr-Q/edit?gid=0#gid=0) of the first few cycles of such a network of visionary women. Arbitrarily, we started with [Atossa Soltani](https://www.linkedin.com/in/atossasoltani/); it could be any of our many amazing women, e.g. some founding kernel who agree to kick it off.

Each tab represents a cycle of invitation. The Cycle 0 tab is just Atossa. Cycle 1 is 48 women she might invite, and is an example of what a *founders’ kernel* could be. Those were chosen from the most interconnected women within her LinkedIn connections (we have that information from the NAO.is simulation). Each subsequent cycle is done the same way from the preceding, adding only new women not already invited. The first six cycles result in 23,982 highly interconnected great women across the world. 

Here is a [graph visualization](https://nao.is/search_network/?group=d33250fb-35ac-46e6-9d38-c683e167c654) of the top 200 from that simulation.   


(Caveats: The latter cycles get smaller because we don’t have connection data yet for the later invitees, solved by them providing it when opting in. There are a few males that get through because it’s quite difficult to detect gender from first name only. In an actual propagation, inviters would know their invitees.)

### Why women only?

Do you really have to ask? Humanity is on the brink of collapse under patriarchy’s watch. Women everywhere are furious (see [Of Misogyny, Musk, and Men](https://www.motherjones.com/politics/2024/11/musk-trump-men-election-results-misogyny-sexism/)). Ever hear of [Lysistrata](https://en.wikipedia.org/wiki/Lysistrata)? Or the [Icelandic Women’s Strike](https://en.wikipedia.org/wiki/1975_Icelandic_women%27s_strike)? or  [the 4B movement](https://theonion.com/what-to-know-about-the-4b-movement/)?

Define ‘women’. Planet Women does a [good job of ‘why?’](https://www.planetwomen.org/why-women), and of delineating what they mean by ‘women’. In reality, there are no hard boundaries. The proposed network can have subnetworks that define their boundaries any way they want. And it is expected to overlap with gender-neutral aligned networks.

### Scale

We believe it is very practical to expect serious global cultural, political, and financial influence within a few years. Here's why:

* Comparables
	* The Catholic Church is 1.3B people worldwide
	* France is 68M people
* LinkedIn is 890M of which 46% are women
	* 31M+ who match ‘environment’
	* .5M+ ‘environment’  within 2 degrees of 1 person
* Of women on Linkedin (409M)
	* the 16% most likely to participate = the population of France
* Four degrees of separation
	* Our analytics indicate that most LinkedIn members are actually only four degrees separated

![](http://standingwave.net/img/NN/LI_environment_stats.png)
Of course, LinkedIn is by no means the entire available community of participants. It is a great bootstrapping tool, however.


### Governance

This is uncharted territory, and presents great opportunities for innovation, learning, best practices, etc. 

To bootstrap, we see three phases:

* **Initiators** - A small number of motivated and respected women who believe in the potential and take responsibility for making it happen. While they have no direct governance role, they have great influence on the initial conditions, by nominating and recruiting the Founders. Current initiators include e.g. [Carolina Carvalho](https://www.linkedin.com/in/carolina-carvalho-457523152), [Vicki Saunders](https://www.linkedin.com/in/vsaunders/), others [e.g. [Dounia Saeme](https://www.linkedin.com/in/dsaeme/), [Sheri Herndon](https://www.linkedin.com/in/sheri-herndon/), [Carrie Norton](https://linkedin.com/in/regenerativeeconomybuilder), [Faith Flanigan](https://www.linkedin.com/in/faithflanigan/), [Susan Fassberg](https://www.linkedin.com/in/dearsooz/), ...]. They enlist other close allies who want to be actively involved in initiation. The initiators use the Founders inquiry on the home page to nominate and rank highly respected and influential women they believe would be ideal for launching and initial governance, and would want to.
* **Founders** - The core leadership who embody the principles, intent, and goals; the first [100?, 1000?] of the highest ranking nominations who agree to participate. They are responsible for launching and propagating networks, and for initial legal governance.  It is their influence that will ensure high quality network propagation. 

Once the Founders cohort has been established, they will be responsible for agreeing on an initial legal manifestation, and will be the initial governing body (e.g. board) in the by-laws thereof.

Likely, decisions will be made using existing tools like Holocracy, Sociocracy, Pol.is, liquid democracy etc. Part of its charter would be to develop more long-term governance for ratification by the whole.

### Legal

It will be up to the Founders to decide on the actual legal form. It will likely be a hybrid that allows non-profit status without limiting the ability to generate serious revenues across many jurisdictions.

### Financing

From day one, crowdfunding will allow early participants and supporters to ensure sufficient working capital for all necessary next steps. 

In addition, there should be ample opportunity for grant funding, and the Founders can hopefully attract early grants with minimal overhead to develop.

### Use of funds
Early use of funds will be decided by the Founders, requiring rough consensus ( e.g. and 80% supermajority) for approval.

Most likely early uses of funds will be:

* **Core staff** to handle general coordination to make the most of everyone's time and contributions.
* **Technical infrastructure** to improve public presence, coordination tools, security, etc.
* **Legal work** for dealing with international, organizational, governance, etc.
* **Financial** compacity, e.g. grant development, revenue streams, strategic alliances, etc.

### World-changing potential

Can such an approach make a difference in the trajectory of humanity and the planet? We won’t know without trying. The Catholic Church is effectively an old-school virtual nation, with a lot of influence beyond borders, and without the power of a networked citizenry. 

We believe one or more virtual nations can reach sufficient scale to have similar or greater influence, by being vastly better at collective coordination than any other organizational structure. 

### Political influence

One straightforward way to imagine such influence is the opportunity for virtual nations to act as planetary political parties. Surprisingly, there is currently no such thing. The [Greens are only in four countries](https://en.wikipedia.org/wiki/List_of_largest_political_parties), unaffiliated with each other. The only multi-national party is the [Ba’ath Party, only in Arab states](https://en.wikipedia.org/wiki/Ba%27ath_Party). Imagine what a planetary women’s party of 100s of millions could accomplish.

In particular, the 2024 US election has exposed the unfixability of the two-party system. The Democratic Party could be overtaken from within. Planetary Women and an allied Regen Nation could be a powerful force for fielding and electing progressive candidates and succeeding where the existing Democratic party has failed.

### What can we do with it?

Many things: people discovery and recommendation; collective decision-making; resource allocation; bioregional ‘mycelia’; planetary citizen assemblies;  data cooperatives; collective actions; etc. The landscape of possibilities is vast. We can manifest the networks now, which will drive innovation of apps and utilities.

Social graphs are valuable. By virtue of the social coherence and quality of the membership, a virtual nation can attract funding vastly greater than the sum of its parts.

### Urgency

An aligned network of women can coordinate for action in real time to counter the worst of what is coming in the US. For instance, a womens general strike to stop a national abortion  ban.

### Decentralization

Decentralization is an aspiration but not a gating item. Current tools use simple, centralized infrastructure, with a migration roadmap to fully decentralized and self-sovereign in the first half of 2025.



`;
export default markdown;

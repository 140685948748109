import React, { useEffect, useState, use } from 'react';
import * as d3 from 'd3';
import * as venn from 'venn.js';
// import * as venn from 'utils/venn';

const VennDiagram = props => {
  const { data, onClick, onHover, width = 400, height = 400 } = props;
  const [sets, setSets] = useState([]);
  const [made, setMade] = useState(false);
  const [redraw, setRedraw] = useState(false);

  let zoom = d3.zoom().on('zoom', handleZoom);
  function handleZoom(e) {
    d3.selectAll('g').attr('transform', e.transform);
  }

  useEffect(() => {
    if (data.sets.length != sets.length) {
      setSets(data.sets);
      setRedraw(true);
    } else {
      setRedraw(false);
    }
  }, [data, sets.length]);

  useEffect(() => {
    function initZoom() {
      d3.select('#venn').call(zoom);
    }
    if (sets && (!made || redraw)) {
      let chart = venn.VennDiagram(400, 400);
      chart.width(width);
      chart.height(height);
      const tooltip = d3.select('#venntooltip'); // d3.select('body').append('div').attr('class', 'venntooltip');
      var div = d3.select('#venn');
      div.datum(sets).call(chart);

      div
        .selectAll('path')
        .style('stroke-opacity', 0)
        .style('stroke', '#fff')
        .style('stroke-width', 3);

      div.selectAll('text').each(function (d, i) {
        // truncate label
        if (d.label) {
          const rel = d.size / 1000;
          const nchars = parseInt(rel * 12) + 4;
          d3.select(this).text(d.label.slice(0, nchars));
        }
      });

      div
        .selectAll('g')
        .on('mouseover', function (event, d, i) {
          // sort all the areas relative to the current item
          venn.sortAreas(div, d);

          // Display a tooltip with the current size
          tooltip.transition().duration(400).style('opacity', 0.9);
          const text = (d.label ? d.label + '  |  ' : '') + d.size + ' users';
          tooltip.text(text);

          // highlight the current path
          var selection = d3.select(this).transition('tooltip').duration(400);
          selection
            .select('path')
            .style('fill-opacity', d.sets.length == 1 ? 0.4 : 0.1)
            .style('stroke-opacity', 1);

          if (onHover) onHover(event, d);
        })
        .on('mousemove', function (event) {
          if (tooltip)
            tooltip.style('left', event.pageX + 'px').style('top', event.pageY - 28 + 'px');
        })
        .on('mouseout', function (event, d, i) {
          tooltip.transition().duration(100).style('opacity', 0);
          var selection = d3.select(this).transition('tooltip').duration(100);
          selection
            .select('path')
            .style('fill-opacity', d.sets.length == 1 ? 0.25 : 0.0)
            .style('stroke-opacity', 0);
          if (onHover) onHover(null, null);
        })
        .on('click', function (event, d, i) {
          if (onClick) onClick(event, d);
        });
      setMade(true);
      initZoom();
    }
  }, [made, height, onClick, onHover, sets, width, zoom, redraw]); // ,zoom

  // venn_container for zoom when working
  return <div id='venn' />;
};

export default VennDiagram;

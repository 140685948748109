const formatResponse = (response, returnIfString = true) => {
  try {
    if (typeof response === 'string') {
      return returnIfString ? response : JSON.stringify(JSON.parse(response), null, 2);
    } else {
      return JSON.stringify(response, null, 2);
    }
  } catch (e) {
    return response;
  }
};

export default formatResponse;

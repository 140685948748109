import React, { useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Timestamp from 'components/Chat/Timestamp';
import MessageBody from 'components/Chat/MessageBody';
import { isMessageFromMe } from './utils';
import useNoo from 'hooks/useNoo';
import FontAwesomeIcon from 'utils/FontAwesomeLoader';

// TODO: save this somewhere else, needs to be themeable
const messageContainerColors = {
  mine: '#00d4ff',
  theirs: '#eee'
};

const messageTextColors = {
  mine: '#fff',
  theirs: '#555'
};

export const MESSAGE_CONTAINER_PADDING = '0.35em';
const MESSAGE_CONTAINER_PADDINGx2 = '0.7em';
const MESSAGE_CONTAINER_RADIUS = '0.5em';
const SKIP_TS_OFFSET = 1000 * 60 * 10; // 10 minute offset

const MessageContainer = props => {
  const { nooUser } = useNoo();
  const { message, isFirst, isLast, upVoteMsg, downVoteMsg, ...rest } = props;
  const { body, created: ts, up, down, _id } = message;

  const isMine = useMemo(() => isMessageFromMe(message, nooUser?.person), [
    message,
    nooUser?.person
  ]);

  const backgroundColor = useMemo(
    () => (isMine ? messageContainerColors.mine : messageContainerColors.theirs),
    [isMine]
  );

  const textColor = useMemo(() => (isMine ? messageTextColors.mine : messageTextColors.theirs), [
    isMine
  ]);

  const borderProps = useMemo(() => {
    const props = {
      [`borderTop${isMine ? 'Left' : 'Right'}Radius`]: MESSAGE_CONTAINER_RADIUS,
      [`borderBottom${isMine ? 'Left' : 'Right'}Radius`]: MESSAGE_CONTAINER_RADIUS
    };
    if (isFirst) {
      props[`borderTop${isMine ? 'Right' : 'Left'}Radius`] = MESSAGE_CONTAINER_RADIUS;
    }
    if (isLast) {
      props[`borderBottom${isMine ? 'Right' : 'Left'}Radius`] = MESSAGE_CONTAINER_RADIUS;
    }
    return props;
  }, [isFirst, isLast, isMine]);

  return (
    <Flex
      backgroundColor={backgroundColor}
      display='inline-block'
      float={isMine ? 'right' : 'left'}
      mb='1px'
      py={MESSAGE_CONTAINER_PADDING}
      px={MESSAGE_CONTAINER_PADDINGx2}
      justifyContent={isMine ? 'flex-end' : 'flex-start'}
      {...borderProps}
      textAlign={isMine ? 'right' : 'left'}
    >
      <Flex flexDirection={isMine ? 'row-reverse' : 'row'}>
        <Timestamp as='p' ts={ts} color={textColor} />
        <Box
          color={textColor}
          fontSize='sm'
          mr={isMine ? MESSAGE_CONTAINER_PADDING : 0}
          ml={isMine ? 0 : MESSAGE_CONTAINER_PADDING}
          borderRight={isMine ? '1px solid ' + textColor : ''}
          borderLeft={isMine ? '' : '1px solid ' + textColor}
          pr={isMine ? 2 : 0}
          pl={isMine ? 0 : 2}
        >
          <Flex gap={2}>
            <Flex alignItems='center' gap={1} onClick={() => upVoteMsg(_id)} cursor='pointer'>
              <Box fontWeight='bold'>{up || '0'}</Box>
              {FontAwesomeIcon({
                icon: ['far', 'chevron-square-up'],
                size: 'xs'
              })}
            </Flex>
            <Flex alignItems='center' gap={1} onClick={() => downVoteMsg(_id)} cursor='pointer'>
              <Box fontWeight='bold'>{down || '0'}</Box>
              {FontAwesomeIcon({ icon: ['far', 'chevron-square-down'], size: 'xs' })}
            </Flex>
          </Flex>
        </Box>
      </Flex>
      <MessageBody body={body} color={textColor} />
    </Flex>
  );
};

export default MessageContainer;

import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// FREE:
import {
  faDollarSign,
  faCheckCircle,
  faTimesCircle,
  faBullseye,
  faShoppingCart,
  faRedo,
  faArrowCircleRight,
  faArrowCircleLeft,
  faGear
} from '@fortawesome/free-solid-svg-icons';

// PRO regular:
import {
  faExclamationTriangle,
  faBellExclamation,
  faCalendarStar,
  faChevronSquareLeft,
  faChevronSquareRight,
  faChevronSquareUp,
  faChevronSquareDown
} from '@fortawesome/pro-regular-svg-icons';

// PRO duotone:
import {
  faUsers,
  faMapMarkerAlt,
  faChartNetwork,
  faCommentSmile,
  faSearchLocation,
  faSearch,
  faShareAlt
} from '@fortawesome/pro-duotone-svg-icons';

library.add(faDollarSign);
library.add(faCheckCircle);
library.add(faTimesCircle);
library.add(faBullseye);
library.add(faShoppingCart);
library.add(faRedo);
library.add(faArrowCircleLeft);
library.add(faArrowCircleRight);
library.add(faArrowCircleRight);

library.add(faExclamationTriangle);
library.add(faBellExclamation);
library.add(faCalendarStar);
library.add(faChevronSquareLeft);
library.add(faChevronSquareRight);
library.add(faChevronSquareUp);
library.add(faChevronSquareDown);

library.add(faUsers);
library.add(faMapMarkerAlt);
library.add(faChartNetwork);
library.add(faCommentSmile);
library.add(faSearchLocation);
library.add(faSearch);
library.add(faShareAlt);

const defaultIconConfig = {
  // pull: 'left'
};

const getIcon = iconConfig => {
  if (!iconConfig.icon) {
    console.error('[FA - getIcon] no icon found in iconConfig, nothing to do');
    return null;
  }
  const icon = iconConfig.icon?.name || iconConfig.icon;
  const myConfig = { ...defaultIconConfig, ...iconConfig, icon };
  return <FontAwesomeIcon {...myConfig} />;
};

export default getIcon;

import React from 'react';
import { useMemo, useEffect, useState } from 'react';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
import { Text, Box } from '@chakra-ui/react';
import { bioregion_colors, lecorbu_colors } from 'utils/treemapUtils';
import { hexPlusOpacity } from 'utils';
import styled from '@emotion/styled';

const MapChart = props => {
  const click_cb = props.onClick;
  const level = props.level || 'bioregion';
  const bioregions = props.bioregions;
  const land = props.land;

  const handleClick = geo => () => {
    if (click_cb) click_cb(geo);
  };

  useEffect(() => {
    if (window.matchMedia('(orientation: portrait)').matches) {
      alert('Best viewed in Landscape mode (horizontal)');
    }
  }, []);

  const handleMouseOver = (geo, on_off) => () => {
    const cb = props.onHover;
    if (cb) cb(geo, on_off);
  };
  // const realms = ['AN', 'AT', 'AU', 'IM', 'NA', 'NT', 'OC', 'PA'];
  let realms = [];
  let subrealms = [];
  let bios = [];
  const color_me = (geo, opacity) => {
    const colorset = bioregion_colors; // maybe conditional on realms
    const realm = geo.meta.realm || '';
    const subrealm = geo.meta.subrealm || '';
    if (realms.indexOf(realm) == -1) realms.push(realm);
    if (subrealms.indexOf(subrealm) == -1) subrealms.push(subrealm);
    if (bios.indexOf(geo.id) == -1) bios.push(geo.id);
    let color_index = (bios.indexOf(geo.id) * 1) % colorset.length;
    switch (level) {
      case 'subrealm':
        color_index = (subrealms.indexOf(subrealm) * 1) % colorset.length;
        break;
      case 'realm':
        color_index = (realms.indexOf(realm) * 1) % colorset.length;
        // if (realm == 'Afrotropics') console.log('af', color_index);
        break;
      default:
        break;
    }
    const ind = color_index % colorset.length;
    const hex = colorset[ind];
    return hexPlusOpacity(hex, opacity);
  };
  return (
    <Box>
      <ComposableMap
        projectionConfig={{
          scale: 220
        }}
        style={{ backgroundColor: 'white', width: props.width, height: props.width * 0.5 + 'px' }}
        /* style={{
          backgroundImage:
            'url("https://geospatialmedia.s3.amazonaws.com/wp-content/uploads/2018/06/image2.png")'
        }} */
      >
        <ZoomableGroup>
          {props.showLand && land && (
            <Geographies geography={land}>
              {({ geographies }) => {
                return geographies.map((geo, i) => {
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={'rgba(0, 0, 0, 0)'}
                      stroke='#000000'
                    />
                  );
                });
              }}
            </Geographies>
          )}
          {bioregions?.length > 180 ? (
            <Geographies geography={bioregions}>
              {({ geographies }) => {
                return geographies.map((geo, i) => {
                  let col = color_me(geo);
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={color_me(geo, 0.8)}
                      stroke={color_me(geo, 0.05)}
                      onClick={handleClick(geo)}
                      onMouseOver={handleMouseOver(geo, true)}
                      onMouseLeave={handleMouseOver(geo, false)}
                    />
                  );
                });
              }}
            </Geographies>
          ) : (
            <Text>foo</Text>
          )}
        </ZoomableGroup>
      </ComposableMap>
    </Box>
  );
};

function SimpleMap(props) {
  // position: ${props => (props.position ? 'fixed' : 'initial')};
  const NetworkBox0 = styled(Box)`
    width: 100%;
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5);
    top: 0;
    right: 0;
  `;

  const NetworkBox = styled(Box)`
    width: 100%;
    background-color: white;
    border: 1px solid lightgrey;
  `;
  // ODD BEHAVIOR: Using NetworkBox to achieve the same styling causes rereneder on clicks
  return (
    <Box sx={{ width: '100%', backgroundColor: 'white', border: '1px solid lightgrey' }}>
      <MapChart {...props} />
    </Box>
  );
}

export default SimpleMap;

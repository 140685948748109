import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import useNoo from 'hooks/useNoo';
import { Flex, Box } from '@chakra-ui/react';
// import styled from '@emotion/styled';
// import useNooApi from 'hooks/useNooApi';
// import UserAuth from 'components/User/UserAuth';
import { ContentSubpanel } from 'components/Segments/Styled';
// import { objectSort } from 'utils';
// import { connective } from 'shared/config';
import useMultiDomain from 'hooks/useMultiDomain';

const Chat = props => {
  const { chatId, chatName } = props;
  // console.log('id', chatId, 'name', chatName);
  const { googleUser: user, nooUser } = useNoo('Chat');
  const { domainConfig } = useMultiDomain();
  const [chatToken, setChatToken] = useState(null);
  const [currentChat, setCurrentChat] = useState(null);
  let connective = domainConfig?.chatTenantToken;
  // dynamically attach the chat script when loading the <Chat /> component
  // instead of loading it all the time to all pages,
  // it's ~3.8MB (big thing to wait for just basic app loading)
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://dev.mf.apiconnective.site/live/src/chat/chat.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (chatName != currentChat) {
      setChatToken(null);
      setCurrentChat(chatName);
    }
  }, [currentChat, chatName]);

  useEffect(() => {
    if (nooUser && currentChat && !chatToken) {
      const endpoint = 'https://api.carii.pro/api/v2/chat/token';
      const profile = nooUser?.person?.data?.profile;
      const fn = profile.fullname || profile.displayName;
      const request_data = {
        user_id: nooUser.person._id,
        fullname: fn,
        email: user.email,
        channel_name: chatName,
        tenant_token: connective
      };
      // console.log('requ data', JSON.stringify(request_data));
      try {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(request_data)
        };
        fetch(endpoint, requestOptions)
          .then(response => response.json())
          .then(data => {
            // console.log('RESP data', data);
            const token = data?.data?.chat_token;
            setChatToken(token);
          });
      } catch (e) {
        console.error('ERROR calling chat server', e);
        setChatToken(null);
      }
    }
    return null;
  }, [nooUser, chatToken, chatId, user, chatName, currentChat, connective]);

  useEffect(() => {
    if (chatToken) {
      console.log('starting chat');
      // $(config.container).html('<carii-chat token="'+token+'"></carii-chat>')
    }
  }, [chatToken]);

  return (
    <Box>
      <Box className='App-row'>
        <ContentSubpanel width={['100%', '85%']}>
          {chatToken && chatName ? (
            <Flex>
              <carii-chat token={chatToken} name={chatName}></carii-chat>
            </Flex>
          ) : (
            <p>No token</p>
          )}
        </ContentSubpanel>
      </Box>
    </Box>
  );
};

export default Chat;

import React from 'react';
import { useEffect, useMemo, useState, useCallback, useReducer } from 'react';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Checkbox, Button, HStack, Heading } from '@chakra-ui/react';
import ToolsSelector from 'components/Segments/SelectionTools';
import { nodesAndEdgesNoGenerations } from 'utils/nodesAndEdges';
import LocalStorage from 'utils/LocalStorage';
import useNoo from 'hooks/useNoo';

import SortableTable from 'components/Segments/SortableTable';
import useSearchResults, { ResultLink } from 'hooks/useSearchResults';
import { PopOverUser } from 'components/Segments/UserCard';
import _SERVICES from 'shared/constants/socialServices';

const SearchResultsProfiles = props => {
  const kind = 'profiles';
  const { data, topic, personId, onChecked, tools, onToolChange } = props;
  const [parsedData, setParsedData] = useState(null);
  const { currentNetwork: netname, nooUser } = useNoo('ProfilesTwitter');
  const [myStorage, setMyStorage] = useState(null);
  const [, forceUpdate] = useReducer(x => x + 1, 0); // https://stackoverflow.com/questions/46240647/react-how-to-force-to-re-render-a-functional-component/53837442#53837442
  const [profiles, setProfiles] = useState([]);
  useEffect(() => {
    setMyStorage(new LocalStorage(netname || 'unknown', nooUser?.person._id || 'user'));
  }, [setMyStorage, netname, nooUser]);
  const {
    defaultColumnProperties,
    map_column_title,
    map_column_sx,
    get_columns
  } = useSearchResults(kind);

  useEffect(() => {
    if (data) {
      const it = data?.result?.[0] || data;
      setParsedData(it);
    }
  }, [data, setParsedData]);

  const doCheckUser = useCallback(
    (pid, is_checked) => {
      let now = new Date();
      let myChecked = myStorage.getItem('checked_users') || [];
      if (is_checked && !myChecked.includes(pid)) {
        myChecked.push(pid);
      } else if (myChecked.includes(pid) && !is_checked) {
        const indx = myChecked.indexOf(pid);
        myChecked.splice(indx, 1);
      }
      myStorage.setItem('checked_users', myChecked);
      onChecked(pid, is_checked, myChecked.length);
    },
    [myStorage, onChecked]
  );

  const makeProfiles = useCallback(() => {
    const onCheckUser = e => {
      const pid = e.target.value;
      const is_checked = e.target.checked;
      doCheckUser(pid, is_checked);
    };
    let nodes_out = [];

    if (parsedData?.profiles) {
      const [nodes] = nodesAndEdgesNoGenerations(parsedData);
      nodes_out = nodes.filter(P => P !== null);
    } else if (parsedData?.nodes) {
      const locs = parsedData.nodes.map(one => {
        return one.location?.toLowerCase().trim() || '';
      });
      locs.sort();
      const links = parsedData.links;
      const myChecked = myStorage.getItem('checked_users') || [];
      nodes_out = parsedData.nodes
        .filter(P => P !== null)
        .map(nd => {
          let node = { ...nd };
          const pid = node._id || node.id;
          const targets = links.filter(link => link.source == pid).map(link => link.target);
          const sources = links.filter(link => link.target == pid).map(link => link.source);
          node.in_count = node.followers || node.in_count || node.ins?.length;
          node.in_countSorter = -node.in_count || -node.ins?.length || -node.ins;
          node.insSorter = -node.in_count || -node.ins?.length || -node.ins;
          node.locationSorter = node.location?.toLowerCase().trim() || '';
          node.outs = node.following || targets.length;
          node.degree = _.includes(sources, personId) || _.includes(targets, personId) ? 1 : null;
          const dest = 'https://linkedin.com/in/' + node.handle;
          const imgsrc = node.image;
          node.pic = <img src={imgsrc} title={node.username} width={'50px'} height={'50px'} />;
          node.is_checked = myChecked.includes(pid);
          node.fullname_link = (
            <PopOverUser
              key={pid}
              _id={pid}
              node={node}
              order={['avatar', 'description', 'location', 'bioregion'].concat(
                Object.keys(_SERVICES)
              )}
              onClick={event => {
                props.onclick({
                  _id: event.target.dataset.id,
                  fullname: event.target.dataset.fullname
                });
              }}
            />
          );
          if (!node.fullname) console.log('no fullname', node);
          node.fullname_linkSorter = node.fullname
            ? node.fullname.toLowerCase()
            : node.name
            ? node.name.toLowerCase()
            : '_unknown';
          node.checkbox = (
            <Checkbox
              onChange={onCheckUser}
              colorScheme='green'
              size='md'
              name={'users'}
              defaultChecked={myChecked.includes(pid)}
              key={pid}
              value={pid}
            ></Checkbox>
          );
          node.checkboxSorter = !node.is_checked;
          return node;
        });
    }
    if (nodes_out) {
      return nodes_out.map(node => {
        return node;
      });
    }
    return null;
  }, [doCheckUser, myStorage, parsedData, personId, props]);

  useEffect(() => {
    if (parsedData && profiles.length == 0) {
      setProfiles(makeProfiles());
    }
  }, [makeProfiles, parsedData, profiles.length]);

  const cols = useMemo(() => {
    if (profiles?.length) {
      return get_columns(profiles[0]).map(key => {
        return {
          ...defaultColumnProperties,
          key: key,
          name: map_column_title(_.capitalize(key), kind),
          sx: map_column_sx(key, kind)
        };
      });
    }
    return [];
  }, [defaultColumnProperties, get_columns, profiles, map_column_sx, map_column_title]);

  if (!_.size(profiles)) {
    return null;
  }
  let now = new Date();

  const clearChecked = () => {
    setProfiles([]);
    // setTimeout(forceUpdate, 1000);
    forceUpdate();
    onChecked(null, null, 0);
  };

  const _onToolChange = (tool, selected) => {
    let out = selected;
    if (tool == 'clear selected') {
      clearChecked();
      out = [];
    } else if (tool == 'select all') {
      const pids = profiles.map(one => {
        return one.id || one._id;
      });
      pids.forEach(pid => {
        doCheckUser(pid, true);
      });
      out = pids;
      // setProfiles(makeProfiles());
      // setTimeout(forceUpdate, 1000);
    }
    if (onToolChange) onToolChange(tool, out);
  };

  return (
    <Box mt='20px'>
      {nooUser && tools && (
        <HStack sx={{ width: '500px' }}>
          <Heading size='sm' width='40%'>
            Selection tools:
          </Heading>
          <ToolsSelector tools={tools} current={null} onToolChange={_onToolChange} />
        </HStack>
      )}
      <SortableTable columns={cols} rows={profiles} sortCol={1} />
    </Box>
  );
};

export default SearchResultsProfiles;

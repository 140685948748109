import React, { useMemo, useRef, useEffect, useState } from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import MessageContainer from './MessageContainer';
import UserName from './UserName';
import Avatar from './Avatar';
import { isMessageFromMe } from './utils';
import useNoo from 'hooks/useNoo';

const MESSAGE_LIST_PADDING = '2em';

const MessagesList = props => {
  const { maxHeight, messages, participants, upVoteMsg, downVoteMsg } = props;
  const [msgCount, setMsgCount] = useState(messages?.lenght || 0);
  const { nooUser } = useNoo();
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (messages?.length !== msgCount) {
      scrollToBottom();
      setMsgCount(messages?.length);
    }
  }, [messages, msgCount]);

  const parsedList = useMemo(() => {
    if (messages?.length) {
      const stack = [];
      let lastUser = null;
      let substack = [];
      messages.forEach(m => {
        if (m.user !== lastUser) {
          if (substack.length) {
            stack.push(substack);
          }
          substack = [];
          lastUser = m.user;
        }
        substack.push(m);
      });
      if (substack.length) {
        stack.push(substack);
      }
      return stack;
    }
    return [];
  }, [messages]);

  if (!parsedList?.length) {
    return (
      <Box
        h={maxHeight || 'auto'}
        overflowY='scroll'
        scrollBehavior='smooth'
        p='20px'
        textAlign='center'
      >
        <Heading as='h2' size=''>
          Start this conversation
        </Heading>
        <Text>Be the first person to say something here!</Text>
      </Box>
    );
  }

  return (
    <Box h={maxHeight || 'auto'} overflowY='scroll' scrollBehavior='smooth'>
      {parsedList.map((substack, idx) => {
        const isMine = isMessageFromMe(substack[0], nooUser?.person);
        const u = participants?.[substack[0].user];
        return (
          <Flex
            key={idx}
            gap={2}
            mb={2}
            flexDirection={isMine ? 'row-reverse' : 'row'}
            mr={isMine ? '6px' : MESSAGE_LIST_PADDING}
            ml={isMine ? MESSAGE_LIST_PADDING : '6px'}
          >
            <Avatar
              //   online={!!isMine}
              //   away={!isMine}
              name={u?.name || substack[0].user}
              src={u?.avatar || 'https://i.pravatar.cc/320'} // << temporary avatar
            />
            <Box>
              <UserName name={u?.name || substack[0].user} textAlign={isMine ? 'right' : 'left'} />
              {substack.map((msg, idx2) => {
                return (
                  <Box key={idx2}>
                    <MessageContainer
                      isFirst={idx2 === 0 ? true : false}
                      isLast={idx2 === substack.length - 1 ? true : false}
                      message={msg}
                      upVoteMsg={upVoteMsg}
                      downVoteMsg={downVoteMsg}
                    />
                  </Box>
                );
              })}
            </Box>
          </Flex>
        );
      })}
      <Box ref={messagesEndRef} />
    </Box>
  );
};

export default MessagesList;

import { SET_CURRENT_GROUP_ID } from 'shared/constants/actionTypes';

const initialState = {
  currentGroupId: null,
  groups: []
};

const groups = function (state = initialState, action) {
  // const _state = { ...state };
  switch (action.type) {
    case SET_CURRENT_GROUP_ID:
      return {
        currentGroupId: action.payload
      };

    default:
      return state;
  }
};

export default groups;

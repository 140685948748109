import React, { useMemo, useEffect, useState, useRef } from 'react';
import useDimensions from 'react-cool-dimensions';
import _ from 'lodash';
// import D3Graph from './d3_graph';
import ForceGraph3D from 'react-force-graph-3d';
import SpriteText from 'three-spritetext';
import { gen_color } from 'utils';
import UserCard from 'components/Segments/UserCard';
// import { PopOverUser } from 'components/Segments/UserCard';
import { Link as RouterLink } from 'react-router-dom';
import useNoo from 'hooks/useNoo';

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Button,
  Box,
  Link
} from '@chakra-ui/react';
// import { node_normalizer } from 'utils/graphNormalize';
import { normalize_id } from 'utils';
import _SERVICES from 'shared/constants/socialServices';

function PopOverUser(props) {
  const { node } = props;
  const [isOpen, setIsOpen] = useState(true);
  const open = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);
  const ukey = node?._id?.replace('Persons/', '') || '';
  return (
    <>
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={close}
        placement='top'
        closeOnBlur={false}
      >
        <PopoverAnchor>
          <div sx={{ fontSize: '1px', display: 'hidden', width: '1px' }}></div>
        </PopoverAnchor>
        <PopoverContent>
          <PopoverCloseButton />
          <PopoverHeader fontWeight='semibold'>
            <Link as={RouterLink} to={'/user/' + ukey}>
              {node.fullname || node.displayName}
            </Link>
          </PopoverHeader>
          <PopoverArrow />
          <PopoverBody>
            <UserCard
              data={node}
              order={['avatar', 'description', 'location', 'bioregion'].concat(
                Object.keys(_SERVICES)
              )} // , 'linkedin', 'twitter', 'facebook'
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
}

const ShowForceNetwork = props => {
  const {
    graph,
    config = {},
    node_click_cb,
    mouseover_cb,
    mouseout_cb,
    centerId,
    maxNodes,
    filterProvisional
  } = props;

  const default_max_nodes = 20;
  const _maxNodes = maxNodes || default_max_nodes;
  const default_node_size = 100;
  const [popoverNode, setPopoverNode] = useState(null);
  const { nooUser } = useNoo('ShowForceNetwork');

  const _handleHover = (node, e) => {
    const { idd } = node;
  };

  const onNodeClick = node => {
    if (node_click_cb) {
      node_click_cb(node);
    }
  };

  const onMouseOver = user_id => {
    if (mouseover_cb) {
      mouseover_cb(user_id);
    }
  };
  const onMouseOut = user_id => {
    if (mouseout_cb) {
      mouseout_cb(user_id);
    }
  };

  const { observe, unobserve, width, height, entry } = useDimensions({
    onResize: ({ observe, unobserve, width, height, entry }) => {}
  });

  const vouchers = useMemo(() => {
    let vs = _.filter(graph.links, link => {
      return link.type == 'VOUCHES_FOR';
    });
    return _.uniq(
      _.flatten(
        _.map(vs, edg => {
          return [edg.source];
        })
      )
    );
  }, [graph]);

  const vouched = useMemo(() => {
    let vs = _.filter(graph.links, link => {
      return link.type == 'VOUCHES_FOR';
    });
    return _.uniq(
      _.flatten(
        _.map(vs, edg => {
          return [edg.target];
        })
      )
    );
  }, [graph]);

  const d3_graph = useMemo(() => {
    // customize standard graph for this render; includes pruning because of a bug in react-d3-graph
    // console.log('customize standard graph for react-d3-graph', graph);
    let temp = {};
    try {
      // temp = JSON.parse(JSON.stringify(graph));
      let seen = [];
      temp = JSON.parse(
        JSON.stringify(graph, function (key, val) {
          if (val != null && typeof val == 'object') {
            if (seen.indexOf(val) >= 0) {
              console.log('cyclic object', key, val);
              return;
            }
            seen.push(val);
          }
          return val;
        })
      );
    } catch (e) {
      console.log('error copying graph', e);
      console.log('graph', graph);
    }

    if (temp && temp.nodes?.length > 0) {
      temp.nodes = _.map(temp.nodes, one => {
        // prune unnecessary properties
        return _.pick(one, [
          'id',
          'name',
          'fullname',
          'description',
          'location',
          'size',
          'weight',
          'key',
          'avatar',
          'linkedin',
          'facebook',
          'twitter',
          'priority',
          'mastodon'
        ]);
      });
      // e.log('NODEs', temp.nodes);
      const maxn = 0;
      if (maxNodes > 0 && maxNodes < temp.nodes.length) {
        temp.nodes = temp.nodes.slice(0, maxNodes);
        const all_ids = _.map(temp.nodes, 'id');
        temp.links = _.filter(temp.links, link => {
          return _.includes(all_ids, link.source) && _.includes(all_ids, link.target);
        });
        const all_endpoints = _.uniq(
          _.concat(_.map(temp.links, 'source'), _.map(temp.links, 'target'))
        );
        // console.log('ends', all_endpoints.length);
        temp.nodes = _.filter(temp.nodes, node => {
          return _.includes(all_endpoints, node.id);
        });
        // console.log('ends', all_endpoints.length, 'nodes', temp.nodes.length);
      }

      let lookup = _.keyBy(temp.nodes, 'id');
      temp.links.forEach(link => {
        const a = lookup[link.source];
        const b = lookup[link.target];
        if (a && b) {
          !a.neighbors && (a.neighbors = []);
          !b.neighbors && (b.neighbors = []);
          a.neighbors.push(b);
          b.neighbors.push(a);

          !a.links && (a.links = []);
          !b.links && (b.links = []);
          a.links.push(link);
          b.links.push(link);
        } else {
          console.log('FAIL', link.source, link.target);
        }
      });
      return temp;
    }
    return null;
  }, [graph, maxNodes]);

  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [hoverNode, setHoverNode] = useState(null);

  const updateHighlight = () => {
    setHighlightNodes(highlightNodes);
    setHighlightLinks(highlightLinks);
  };

  const handleNodeHover = node => {
    highlightNodes.clear();
    highlightLinks.clear();
    if (node) {
      highlightNodes.add(node);
      if (node.neighbors) {
        node.neighbors.forEach(neighbor => highlightNodes.add(neighbor));
        node.links.forEach(link => highlightLinks.add(link));
      }
      setPopoverNode(node);
    } else {
      // setPopoverNode(null); // leave it on til replaced
    }

    setHoverNode(node || null);
    updateHighlight();
  };

  const fgRef = useRef();
  const is_graph = d3_graph && d3_graph.nodes && d3_graph.nodes.length > 0; //d3_graph.links && d3_graph.links.length > 0;
  if (!is_graph) {
    console.log('no links so not displaying');
    return null;
  }
  // console.log('D3Graf', d3_graph);

  function genRandomTree(N = 300, reverse = false) {
    return {
      nodes: [...Array(N).keys()].map(i => ({ id: i, weight: 5, fullname: 'foo' })),
      links: [...Array(N).keys()]
        .filter(id => id)
        .map(id => ({
          [reverse ? 'target' : 'source']: id,
          [reverse ? 'source' : 'target']: Math.round(Math.random() * (id - 1))
        }))
    };
  }

  function color_me(node) {
    const min_luminence = 0.6,
      min_saturation = 0.6,
      min_hue = 120, // 180, // 360 is red
      max_hue = 300; // 450;
    const is_highlit = highlightNodes.size == 0 || highlightNodes.has(node);
    const is_priority = node.priority;

    const colr = is_priority
      ? '#ffffff'
      : vouchers.includes(node.id)
      ? gen_color(node.weight, 0.7, 0.7, 1, 1, 355, 365) // reddish
      : nooUser?.social?.includes(node.id) // vouched.includes(node.id)
      ? gen_color(node.weight, 0.7, 0.7, 1, 1, 40, 70) // yellowish
      : is_highlit
      ? gen_color(node.weight, min_luminence, 1, min_saturation, 1, min_hue, max_hue) // other
      : '#000000';
    return colr;
  }

  function closePop() {
    setPopoverNode(null);
  }

  let distance = 1000;
  return (
    <div ref={observe} onBlur={closePop}>
      <Box float='right' className='pop_net'>
        {popoverNode && <PopOverUser key={popoverNode.id} node={popoverNode} />}
      </Box>
      {is_graph && (
        <ForceGraph3D
          cameraPosition={{ z: distance }}
          graphData={d3_graph}
          ref={fgRef}
          width={width - 1}
          height={width * 0.7}
          backgroundColor={'#111'}
          nodeAutoColorBy='id'
          nodeLabel={node => {
            return ''; // '@' + node.name;
          }}
          nodeVal={node => {
            return 200 * node.weight;
          }}
          linkWidth={link => (highlightLinks.has(link) ? 1 : 0)}
          linkOpacity={0.2}
          linkVisibility={link => {
            const is_highlit = highlightLinks.has(link); //highlightLinks.size == 0 || // hide edges unless mouseover
            return is_highlit ? true : false;
          }}
          linkDirectionalParticles={4}
          linkDirectionalParticleWidth={link => (highlightLinks.has(link) ? 1 : 0)}
          nodeCanvasObjectMode={node => (highlightNodes.has(node) ? 'before' : undefined)}
          nodeThreeObject={node => {
            const is_highlit = highlightNodes.size == 0 || highlightNodes.has(node);
            const sprite = new SpriteText(node.fullname);
            sprite.material.depthWrite = false; // make sprite background transparent
            sprite.color = color_me(node);
            sprite.textHeight = is_highlit ? 4 + 8 * node.weight : 0;
            return sprite;
          }}
          onNodeHover={handleNodeHover}
          onNodeClick={onNodeClick}
          cooldownTicks={100}
        />
      )}
    </div>
  );
};

export default ShowForceNetwork;

import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Flex,
  Box,
  Button,
  Input,
  FormLabel,
  Textarea,
  Spinner,
  Select,
  Text,
  Image,
  Heading
} from '@chakra-ui/react';
// import styled from '@emotion/styled';
import useNooApi from 'hooks/useNooApi';
import Results from '../Search/Results/Groups';
import UserAuth from 'components/User/UserAuth';
// import SearchResults from './search_results';
// import ShowNetwork from 'components/Networks/ShowNetwork';
// import formatResponse from 'utils/formatResponse';
import { ContentSubpanel, WhiteBox } from 'components/Segments/Styled';
// import ShowD3Network from 'components/Networks/ShowD3Network';
// import ShowForceNetwork from 'components/Networks/ShowForceNetwork';

import D3Graph from '../Networks/d3_graph';
// import Profile from '../Search/UserProfile';
const now = new Date().getTime() / 1000;
import bfi from './bfi_regen_neighbors.json'; //
import oneearth from './oneearth_projects_neighbors.json'; // './oneearth_neighbors.json'; // './skills_bgt_neighbors.json'; //
// let oe = bfi; // oneearth;
// console.log('current ', oe.root)
// import {
//   queryGroups,
//   queryUsersOfGroup,
//   querySpecificUser,
//   queryUsersMatching,
//   queryGraphOfUsers,
//   queryGraphFromDecisionTree
// } from 'utils/aqlQueryGenerators';

const Ontology = () => {
  // const dispatch = useDispatch();
  // const { node_id } = useParams();

  const {
    // nooCommand,
    // nooCommandResponse,
    nooCommandIsProcessing
  } = useNooApi();

  const [response, setResponse] = useState('{}');

  const [aqlQuery, setAqlQuery] = useState(null);
  const [result_kind, setResultKind] = useState('groups');
  const [graphData, setGraphData] = useState(null);
  const [oe, setOE] = useState(null);
  const [currentNode, setCurrentNode] = useState(null);
  const [collection, setCollection] = useState('bfi');
  const make_option = (text, value) => {
    return (
      <option value={value} key={value}>
        {text}
      </option>
    );
  };

  const update_group_selector = useCallback(data => {
    // Loads group selector after return from Groups call
    if (data) {
      const nodes = data.nodes;
      if (nodes) {
        let pairs = _.map(nodes, nd => {
          return [nd.data.label, nd._id];
        });
        pairs = _.filter(pairs, pair => {
          const both = pair[0] && pair[1] ? true : false;
          if (!both) {
            console.log('BAD pair', pair);
            return false;
          } else {
            return true;
          }
        });
        pairs = _.sortBy(pairs, pair => {
          return pair[0].toLowerCase();
        });
        const new_options = _.map(pairs, pair => {
          return make_option(pair[0], pair[1]);
        });
        setOptions(new_options);
      }
    }
  }, []);

  const [opts, setOpts] = useState([]);
  const [options, setOptions] = useState([]);

  const collections = useMemo(() => {
    return {
      bfi: {
        data: bfi,
        label: 'BFI'
      },
      oneearth: {
        data: oneearth,
        label: 'OneEarth'
      }
    };
  }, []);

  const collection_options = _.map(collections, (val, key) => {
    return make_option(val.label, key);
  });

  useEffect(() => {
    update_group_selector(oe);
  }, [update_group_selector, oe]);

  const root_of = data => {
    let root = _.keys(data.nodes)[0];
    if (data.root && _.includes(_.keys(data.nodes), data.root)) {
      root = data.root;
    } else {
      console.log('no root', _.values(data.nodes)[0]);
    }
    return root;
  };

  useEffect(() => {
    const data = collections[collection].data;
    setCurrentNode(root_of(data));
    setOE(data);
  }, [collection, collections]);

  useEffect(() => {
    if (!currentNode) {
      return {};
    }
    const maxx = 10;
    let current_edges = _.filter(oe.edges, edg => {
      // return edg.source == currentNode;
      return edg.source == currentNode || edg.target == currentNode;
    });
    current_edges = _.sortBy(current_edges, edge => {
      return -edge.weight || 1;
    });
    const top = current_edges.slice(0, maxx);
    let ids = _.map(top, edg => {
      return edg.target == currentNode ? edg.source : edg.target;
    });
    let other_edges = _.filter(oe.edges, edg => {
      return _.includes(ids, edg.source) && _.includes(ids, edg.target);
      // return _.includes(ids, edg.source) || _.includes(ids, edg.target);
    });

    const links = _.concat(top, other_edges);
    ids = _.flatten(
      _.map(links, edg => {
        return edg.target == currentNode ? edg.source : edg.target;
      })
    );
    ids.push(currentNode);
    ids = _.uniq(ids);
    const nodes = _.map(ids, id => {
      let node;
      if (typeof id == 'object') {
        // force3d pass node not id
        node = id;
        id = node.id;
      } else {
        node = oe.nodes[id];
      }
      const types = { location: 'cross', tag: 'square', realm: 'cross', subrealm: 'cross' };
      let it = node.data || node;
      it.type = node.type || node.kind || it.type || it.kind;
      it.id = id;
      it.name = it.label || it.name;
      it.fullname = it.fullname || it.name;
      it.size = id == currentNode ? 500 : 200;
      it.color = id == currentNode ? '#f00' : null;
      it.symbolType = types[it.type] || 'circle';
      it.weight = 1;
      if (!it.symbolType) console.log('no type', it.type);
      return it;
    });
    const vals = _.map(links, link => {
      return link.weight;
    });
    _.forEach(links, link => {
      link.value = normalize(link.weight, vals);
    });
    const gd = { nodes: nodes, links: links, kind: 'ONTOLOGY', focusedNodeId: currentNode };
    setGraphData(gd);
  }, [currentNode, oe]);

  const normalize = (value, allvals) => {
    const minn = _.min(allvals);
    const maxx = _.max(allvals);
    const range = maxx - minn;
    return (20 * (value - minn)) / range;
  };

  const onMouseOver = user_id => {
    // console.log('Ontology callback', user_id);
  };

  const onMouseOut = user_id => {
    // console.log('Ontology callback', user_id);
  };

  const onNodeClick = user_id => {
    setCurrentNode(user_id._id || user_id); // might be an actual node
  };

  const recenter = () => {
    alert('coming soon');
    // setCurrentNode(currentNode);
  };

  const onSelectCollection = event => {
    setCollection(event.target.value);
  };

  const onSelectNode = event => {
    setCurrentNode(event.target.value);
  };

  /*
            <ShowD3Network
              graph={graphData}
              node_click_cb={onNodeClick}
              mouseover_cb={onMouseOver}
              mouseout_cb={onMouseOut}
              centerId={currentNode}
            />
            <ShowForceNetwork
              graph={graphData}
              width={'100%'}
              maxNodes={100}
              node_click_cb={onNodeClick}
              mouseover_cb={onMouseOver}
              mouseout_cb={onMouseOut}
            />
            <D3Graph
              width={800}
              graph={graphData}
              node_click_cb={onNodeClick}
              mouseover_cb={onMouseOver}
              mouseout_cb={onMouseOut}
            />
            </Box>
            */

  return (
    <Box>
      <UserAuth unauthorized='signin'>
        <Box className='App-row'>
          <Heading as='h1'>{`Regeneration Ontologies`}</Heading>
          <ContentSubpanel width={['100%', '85%']}>
            <Flex>
              <FormLabel width='10%'>Collection</FormLabel>
              <Select
                height='30px'
                padding='1px'
                width='200px'
                display='inline'
                onChange={onSelectCollection}
              >
                {collection_options}
              </Select>
            </Flex>
            <Flex>
              <FormLabel width='10%'>Topic</FormLabel>
              <Select
                height='30px'
                padding='1px'
                width='200px'
                display='inline'
                onChange={onSelectNode}
              >
                {options}
              </Select>
            </Flex>
            <Box width='60%'>
              <Button
                borderRadius='full'
                height='30px'
                padding='3px'
                onClick={recenter}
                disabled={nooCommandIsProcessing || aqlQuery === ''}
              >
                {'Recenter'}
              </Button>
            </Box>
            {nooCommandIsProcessing && (
              <Box float='right'>
                <Spinner />
              </Box>
            )}
            <D3Graph
              width={800}
              graph={graphData}
              node_click_cb={onNodeClick}
              mouseover_cb={onMouseOver}
              mouseout_cb={onMouseOut}
            />
            <WhiteBox>
              <Results data={response} />
            </WhiteBox>
          </ContentSubpanel>
        </Box>
      </UserAuth>
    </Box>
  );
};

export default Ontology;

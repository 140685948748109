import React, { useMemo, useState } from 'react';
import RenderChakraMarkdown from 'utils/ChakraMarkdown';
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Text,
  Box,
  HStack
} from '@chakra-ui/react';
import _ from 'lodash';

import { More } from 'components/Segments/AboutPopover';

export function MarksPopover() {
  const text_closed = `
  Latest activity of participants 'marking' each other. Current mark types are  ...
  `;

  const text_open = `
* Claim - Source and Target are the Logged in user, claiming attributes they control, which helps attest to their uniqueness in the GreenCheck namespace
* Validate - Logged in user marks others as being trustworthy in their claim to uniqueness in the GreenCheck namespace
* Praise - Logged in user tags others they know with domains of expertise / respect.
* Follow - Mark others whose marks you want to watch
    `;

  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);
  return (
    <Box d='inline-block' mr={3}>
      <Text className='about_closed'>
        <Text>
          <Text as='em' fontWeight='bold'>
            BETA:
          </Text>
          {text_closed}

          <More
            header_text='People Marks'
            button_text={'more...'}
            text_open={text_open}
            isOpen={isOpen}
            open={open}
            close={close}
          />
        </Text>
      </Text>
    </Box>
  );
}

export default MarksPopover;
export { More };

import {
  NOO_SET_LOADING,
  // NOO_SET_GOOGLE_USER,
  NOO_SET_USER,
  NOO_SET_CURRENT_NETWORK,
  // NOO_SET_ADMIN,
  NOO_USER_LOGOUT
} from 'shared/constants/actionTypes';
import { get_default_network } from 'hooks/useMultiDomain';
import { setNooBundle, nooCommandDirect } from './nooApi';
import { getNetworkBundle, getNetworkData } from 'shared/config';
import { setCurrentGroupId } from 'redux/actions/groups';

import { networks } from 'shared/config';

// export const setIsAdmin = isAdmin => ({
//   type: NOO_SET_ADMIN,
//   payload: isAdmin
// });

export const setNooUser = nooUser => ({
  type: NOO_SET_USER,
  payload: nooUser
});

// export const setGoogleUser = googleUser => ({
//   type: NOO_SET_GOOGLE_USER,
//   payload: googleUser
// });

export const setCurrentNetwork = network => ({
  type: NOO_SET_CURRENT_NETWORK,
  payload: network
});

export const setLoading = loading => ({
  type: NOO_SET_LOADING,
  payload: loading
});

export const userLogout = () => ({
  type: NOO_USER_LOGOUT
});

export const getPersonIdFromState = state => {
  console.warn('getPersonIdFromState', state);
  return state?.noo?.nooUser?.person?._id;
};

// NOTE: not exporting, as this is called only by the readPerson createOnFail
// NOTE 2: only using the passed-in user+token since this is
export const createPerson = (params = {}) => {
  return async (dispatch, getState) => {
    const { user, network } = params;
    // const { googleUser, googleUserToken } = getState().firebaseAuth || {};
    // const currentNetwork = get_default_network(getState());

    if (!user) {
      // TODO: handle the mis configuration, likely will need some sort of retry UI to support this error
      console.error('error, missing user for createPerson request.', [user]);
      return;
    }
    const setResponse = response => {
      // console.log('response from createPerson call', response);
      const person = response.nodes?.[0];
      if (person) {
        dispatch(aqlReadPerson({ user }));
      } else {
        console.error('CreatePerson: could not find the person data in the create response!!!');
      }
    };
    const setResponseError = error => {
      console.log('error from createPerson call', error);
      // TODO: handle the error, likely will need some sort of retry UI to support this error
    };

    nooCommandDirect('createPerson', {
      user,
      network,
      setLoading: () => {},
      setResponse,
      setResponseError
    });
  };
};

export const aqlReadPerson = (params = {}) => {
  return async (dispatch, getState) => {
    const { user, createOnFailure = true } = params;
    const { googleUser } = getState().firebaseAuth || {};
    const currentNetwork = get_default_network(getState());

    const myUser = user || googleUser;
    if (!myUser) {
      // TODO: handle the mis configuration, likely will need some sort of retry UI to support this error
      console.error('error, missing user for readPerson request.', [myUser]);
      return;
    }

    const network = getNetworkData(currentNetwork);
    if (network) {
      setNooBundle(getNetworkBundle(currentNetwork));
    }

    const setResponse = response => {
      // console.log('response from readPerson call', response);
      const person = response.result?.[0];
      if (person) {
        dispatch(setNooUser(person));
      } else {
        console.error('ReadPerson: could not find the person data in the response!!!', response);
        setResponseError(response.error);
      }
      // const default_group = currentNetworkData.default_group;
      const grp = network?.default_group || network?.root_group || null;
      if (grp) dispatch(setCurrentGroupId(grp)); // null messes up passing group in as param
    };
    const setResponseError = error => {
      const re = new RegExp('^.*?({.+})'); // find JSON in error, not used yet
      const bad_connection = new RegExp('connectionreset|remotedisconnected', 'i');
      const new_person = new RegExp('no person found', 'i');
      // TODO: handle the error, likely will need some sort of retry UI to support this error
      console.log('error from readPerson call', error);
      if (new_person.test(error) && createOnFailure) {
        console.log('dispatching createperson after error');
        console.log('create person', user);
        dispatch(createPerson({ user, network }));
      } else if (bad_connection.test(error)) {
        console.log('dispatching readperson after bad connection');
        dispatch(aqlReadPerson({ user }));
      }
    };

    nooCommandDirect('aql', {
      user: myUser,
      payload: { query_name: 'aqlReadPerson', data: { network } }, // added for testing add to default group
      setLoading: () => {},
      setResponse,
      setResponseError
    });
  };
};

import React, { useEffect, useState } from 'react';
import { Box, Spinner } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import Display from './ListingDisplay';
import UserAuth from 'components/User/UserAuth';

const Listing = () => {
  const dispatch = useDispatch();
  const { isAuthorized } = useNoo('Listing');

  const { nooCommand, nooCommandResponse, nooCommandIsProcessing } = useNooApi();

  const { listing_id, person_id } = useParams();
  // const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  // const [listing_data, setListingData] = useState(mock);

  useEffect(() => {
    if (nooCommandResponse?.command === 'aql') {
      setResponse(nooCommandResponse.data);
    }
  }, [nooCommandResponse]);

  useEffect(() => {
    if (!response && isAuthorized && listing_id) {
      const payload = { query_name: 'aqlGetListing', data: { listing_id, person_id } };
      dispatch(nooCommand('aql', { payload }));
    }
  }, [dispatch, listing_id, nooCommand, response, isAuthorized, person_id]);

  /*    <Box className="debug"><textarea ref={responseRef} id="grpcResponse" name="grpcResponse"  readOnly={true} value={formatResponse(response)}/></Box>
   */
  return (
    <Box>
      <UserAuth unauthorized='signin'>
        <Box className='App-row listing'>
          {(!response?.result?.length || nooCommandIsProcessing) && <Spinner />}
          {!nooCommandIsProcessing && response?.result?.length && (
            <Display loading={nooCommandIsProcessing} data={response.result[0]} />
          )}
        </Box>
      </UserAuth>
    </Box>
  );
};

export default Listing;

import React from 'react';
import { HStack, Text, VStack, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import _ from 'lodash';
import styled from '@emotion/styled';

import useNoo from 'hooks/useNoo';
import _SERVICES from 'shared/constants/socialServices';

export const ImageConstrainer = styled.div`
  > img {
    max-width: 100%;
    border: 1px solid #000;
    margin-top: 10px;
  }
`;

const BetaNotice = props => {
  const { nooUser } = useNoo('BetaNotice');
  console.log('beta props', props);
  return (
    <VStack
      margin='1em auto'
      width={['90vw', '82vw', '60vw']}
      borderRadius='lg'
      boxShadow='0px 1px 4px 0px rgba(0, 0, 0, 0.5)'
      padding='1em'
      textAlign='left'
      backgroundColor='cyan.800'
      color='white'
    >
      <Text>
        {' '}
        <strong>BETA:</strong> {props.valid && 'You have been invited to test GreenCheck.'} Our
        initial goal is to grow a decent demonstration network of great people. There are definitely
        known issues. Don&apos;t feel like you need to try to break it. Please report
        issues/reaction at CommonSensemakers Discord.
      </Text>
    </VStack>
  );
};

export default BetaNotice;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Button,
  Link,
  FormLabel,
  Textarea,
  Select,
  Spinner,
  Heading,
  HStack,
  Spacer
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import Results from './Results/Listings';
import UserAuth from 'components/User/UserAuth';
import formatResponse from 'utils/formatResponse';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { objectSort } from 'utils';

const SearchListings = () => {
  const dispatch = useDispatch();
  const { nooUser } = useNoo('SearchListings');

  const { nooCommand, nooCommandResponse, nooCommandIsProcessing } = useNooApi();

  const [response, setResponse] = useState('{}');
  const [zipcode, setZipcode] = useState(null);
  const [depth, setDepth] = useState(2);
  const [personId, setPersonId] = useState(null);
  const [currentState, setCurrentState] = useState(null);
  const [responseHeight, setResponseHeight] = useState('100px');
  const responseRef = useRef(null);
  const selector_setup = {
    '': { group: 'a', name: 'No State' },
    CA: { group: 'a', name: 'CA - California' },
    WA: { group: 'a', name: 'WA - Washington' }
  };
  const make_option = (text, value) => {
    const sel = value == currentState || (value == 'null' && !currentState);
    return (
      <option value={value} selected={sel} key={text.toLowerCase()}>
        {text}
      </option>
    );
  };
  const [options, setOptions] = useState([]);

  const make_selector = useEffect(() => {
    // Loads selector
    let new_options = [];
    _.forOwn(selector_setup, (value, key) => {
      new_options.push(make_option(value.name, key));
    });
    new_options.sort(objectSort('key'));
    setOptions(new_options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchListings = useCallback(() => {
    // console.log('state', currentState, personId)
    if (personId) {
      const payload = {
        query_name: 'aqlSearchListingsByNooUser',
        data: { user_id: personId, depth, zipcode, state: currentState }
      };
      dispatch(nooCommand('aql', { payload }));
    }
  }, [dispatch, nooCommand, personId, zipcode, depth, currentState]);

  const onAllListings = () => {
    setDepth(2);
  };
  const onMyListings = () => {
    setDepth(0);
  };
  const onSelectState = e => {
    const state = e.target.value;
    const val = !state || state == 'null' ? null : state;
    console.log('currentState', e.target.value, val);
    setCurrentState(val);
  };

  useEffect(() => {
    if (nooUser) {
      onSearchListings();
    }
  }, [nooUser, onSearchListings]);

  useEffect(() => {
    if (!personId || personId !== nooUser?.person?._id) {
      setPersonId(nooUser?.person?._id);
    }
  }, [nooUser, personId]);

  useEffect(() => {
    const textAreaRef = responseRef.current;
    if (textAreaRef !== null) {
      const scrollHeight = textAreaRef.scrollHeight;
      if (scrollHeight > 100) {
        setResponseHeight(scrollHeight);
      }
    }
  }, [response, responseRef]);

  useEffect(() => {
    if (nooCommandResponse?.command === 'aql') {
      setResponse(nooCommandResponse.data);
    }
  }, [nooCommandResponse?.command, nooCommandResponse?.data]);

  return (
    <Box>
      <UserAuth unauthorized='signin'>
        <Box className='App-row'>
          <Heading as='h1'>{`Search Listings`}</Heading>
          <HStack>
            <Button
              onClick={onAllListings}
              fontWeight='body'
              size='sm'
              disabled={nooCommandIsProcessing}
            >
              {'All'}
            </Button>
            <Button onClick={onMyListings} size='sm' disabled={nooCommandIsProcessing}>
              {'My Listings'}
            </Button>
            <Box width='200px'>
              <Select
                onChange={onSelectState}
                disabled={nooCommandIsProcessing}
                value={currentState}
              >
                {options}
              </Select>
            </Box>
            <Spacer />
            <Link as={RouterLink} to='/add_listing' key='add_listing' float='right'>
              <Button size='sm' borderRadius='full'>
                Add Listing
              </Button>
            </Link>
            {nooCommandIsProcessing && <Spinner />}
          </HStack>
          <Box className='debug'>
            <FormLabel htmlFor='grpcResponse'>GRPC Response:</FormLabel>
            <Textarea
              ref={responseRef}
              id='grpcResponse'
              name='grpcResponse'
              style={{ height: responseHeight }}
              readOnly={true}
              value={formatResponse(response)}
            />
          </Box>
          <Results data={response} />
        </Box>
      </UserAuth>
    </Box>
  );
};

export default SearchListings;

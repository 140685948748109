import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  FormLabel,
  Input,
  Spinner,
  Flex,
  Text,
  Heading,
  Link,
  Image
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import UserAuth from 'components/User/UserAuth';
import FontAwesomeIcon from 'utils/FontAwesomeLoader';
import UrlCard from './UrlCard';
import useHeadlines from 'hooks/useHeadlines';
import { getGetVar, split_tags } from 'utils';
import GroupSelector from 'components/Segments/GroupSelector';
import { useHistory } from 'react-router-dom';
import BookmarkletDraggable from 'components/Segments/BookmarkletDraggable';
import { ContentSubpanel } from 'components/Segments/Styled';

const Share = props => {
  const { isAuthorized, nooUser, networks, currentNetwork } = useNoo('Invite');

  const {
    isBookmarkingHeadline,
    bookmarkHeadlineResponse,
    bookmarkHeadlineResponseError,
    lastBookmarkedUrlData,
    lastBookmarkedUrlMetadata,
    bookmarkFetchedHeadline,
    checkUrlForBookmarking,
    clearBookmarkUrlData
  } = useHeadlines();

  const dispatch = useDispatch();
  const history = useHistory();
  const { nooCommand, nooCommandResponse, nooCommandIsProcessing } = useNooApi();
  const [tags, setTags] = useState('');
  // const [urlData, setUrlData] = useState(null);
  const [hasCheckedOnce, setHasCheckedOnce] = useState(false);
  const [localData, setLocalData] = useState({});

  const onFieldUpdate = useCallback(
    e => {
      const target = e.target;
      const field = target.name;
      const value = target.value;
      const myLocalData = {
        ...localData
      };
      switch (field) {
        case 'groups':
          let grps = new Set(myLocalData.groups || []);
          if (target.checked) {
            grps.add(value);
          } else {
            grps.delete(value);
          }
          myLocalData.groups = Array.from(grps);
          break;
        default:
          myLocalData[e.target.name] = typeof value == 'string' ? value.trim() : value;
          break;
      }
      setLocalData(myLocalData);
    },
    [localData]
  );

  // setting up for manual input:
  // const url = getGetVar('bookmark');
  const [url, setUrl] = useState('');
  const [input, setInput] = useState('');
  const [incoming, setIncoming] = useState(getGetVar('bookmark') || '');

  const [invalid, setInvalid] = useState(false);

  const autocheck = getGetVar('autocheck', true);

  const invalid_url = text => {
    const re = new RegExp(/localhost|bestofnow.net|noo.network/g, 'i');
    const check_http = new RegExp(/^\s*http/g, 'i');

    if (!check_http.test(text)) {
      alert(`URL must start with "http"`);
      return true;
    }
    if (re.test(text)) {
      alert(`Sorry, it looks like you clicked the Bookmarklet from the installer page, or tried to bookmark a BestOfNow page. 
      If the former, you need to install it in your Bookmarks bar first. 
      Then, from any other webpage you want to share, click Best of Now in your Bookmarks bar.`);
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (url && invalid_url(url)) {
      //; // clicked from bookmarklet in BoN
      setInvalid(true);
      setUrl('');
    }
  }, [url, setInvalid]);

  useEffect(() => {
    console.log('invalid', invalid);
    if (incoming && !invalid) {
      setUrl(incoming);
    }
  }, [incoming, invalid]);

  const urlHasBeenChecked = url && url === lastBookmarkedUrlMetadata?.fetchedUrl;

  // useEffect(() => {
  //   if (urlData) {
  //     console.log('GOT URL DAAT', urlData);
  //   }
  // }, [urlData]);

  // useEffect(() => {
  //   if (url) {
  //     bookmarkFetchedHeadline(url);
  //   }
  // }, [url])

  useEffect(() => {
    if (
      autocheck &&
      !hasCheckedOnce &&
      url &&
      isAuthorized &&
      nooUser &&
      !lastBookmarkedUrlMetadata
    ) {
      checkUrlForBookmarking(url);
      return setHasCheckedOnce(true);
    }
    console.warn(
      'uh, failed to autocheck',
      autocheck,
      hasCheckedOnce,
      url,
      lastBookmarkedUrlMetadata
    );
  }, [
    checkUrlForBookmarking,
    autocheck,
    hasCheckedOnce,
    url,
    lastBookmarkedUrlMetadata,
    nooUser,
    isAuthorized
  ]);

  const onSubmit = e => {
    // console.log('submitting', lastBookmarkedUrlData);
    // console.log('meta', lastBookmarkedUrlMetadata);
    // NOTE: can expand this later to capture tags, groups, etc.::
    const destinationData = { ...localData };
    destinationData.tags = split_tags(destinationData.tags); // convert from string to array
    if (
      (lastBookmarkedUrlData.isbn || lastBookmarkedUrlData.type == 'book') &&
      !destinationData.tags.includes('book')
    ) {
      destinationData.tags.push('book');
    }
    bookmarkFetchedHeadline(destinationData);
  };

  useEffect(() => {
    if (bookmarkHeadlineResponse) {
      setTimeout(history.push, 1000, '/bookmarks');
      clearBookmarkUrlData();
    }
  }, [bookmarkHeadlineResponse, history, clearBookmarkUrlData]);

  const checkUrl = e => {
    console.log('checking', url);
    checkUrlForBookmarking(url);
  };

  const updateUrl = e => {
    const inpt = e.target.value.trim();
    const inval = invalid_url(inpt);
    setUrl(inval ? '' : inpt);
  };

  // guard against error object type while we're developing this,
  // react will choke if we try to just string-display an error
  // (see where this function is called below)
  const getBookmarkError = error => {
    console.log('ERROR ON BOOKMARK SUBMIT', error);
    alert('Sorry, your bookmark did not go through. Please try again.');
    clearBookmarkUrlData();
    setUrl('');
    if (typeof error === 'string') {
      return error;
    }
    return JSON.stringify(error);
  };
  /*
  <Button
    onClick={onGetMyGroups}
    disabled={nooCommandIsProcessing}
    leftIcon={FontAwesomeIcon({
      icon: ['fa', 'redo'],
      pull: 'right',
      style: { marginTop: '4px', marginLeft: '1em' }
    })}
  >
    Share
  </Button>;
                <Flex>
                <Box>
                  <FormLabel htmlFor='tags'>People</FormLabel>
                </Box>
                <Box>
                  <Input
                    backgroundColor='white'
                    type='text'
                    placeholder='people'
                    name={'people'}
                    onBlur={onFieldUpdate}
                  />
                </Box>
              </Flex>
              <Text>(Use bookmarklet upper-right to bookmark from any page you want to save.)</Text>

  */

  return (
    <Box>
      <UserAuth unauthorized='signin'>
        <Box className='App-row'>
          <ContentSubpanel width={['100%', '85%']}>
            <Heading as='h3' color='#555'>
              Bookmark something
            </Heading>
            <Box>
              <BookmarkletDraggable text='bookmarklet (hover for instructions)' />
            </Box>
            <Flex
              border='1px solid lightgrey'
              bgColor='#efefef'
              borderRadius='8px'
              p='0'
              mt='10px'
              mx={['10px', '0']}
              flexDirection='column'
              alignItems={['flex-start']}
            >
              <Flex>
                <Box>
                  <FormLabel htmlFor='tags'>URL to share:</FormLabel>
                </Box>
                <Box>
                  <Input
                    backgroundColor='white'
                    type='text'
                    placeholder='please add a URL to bookmark the headline'
                    name={'urlToBookmark'}
                    defaultValue={incoming}
                    onBlur={updateUrl}
                  />
                </Box>
              </Flex>
              <Flex>
                <Box>
                  <FormLabel htmlFor='tags'>Tags</FormLabel>
                </Box>
                <Box>
                  <Input
                    backgroundColor='white'
                    type='text'
                    placeholder='tags'
                    name={'tags'}
                    onBlur={onFieldUpdate}
                  />
                </Box>
              </Flex>

              <Flex>
                <Box>
                  <FormLabel htmlFor='groups'>Groups</FormLabel>
                </Box>
                <GroupSelector
                  nooUser={nooUser}
                  callback={onFieldUpdate}
                  render={'checkbox'}
                  filters={['member', 'owner']}
                />
              </Flex>
              <Button
                onClick={onSubmit}
                disabled={
                  nooCommandIsProcessing || isBookmarkingHeadline || !url || url.length == 0
                }
                rightIcon={FontAwesomeIcon({
                  icon: ['fa', 'arrow-circle-right'],
                  style: { marginTop: '4px' }
                })}
              >
                Bookmark Headline
              </Button>
              {(!urlHasBeenChecked || url !== lastBookmarkedUrlMetadata.fetchedUrl) && (
                <Button
                  marginLeft='1em'
                  onClick={checkUrl}
                  disabled={
                    nooCommandIsProcessing || isBookmarkingHeadline || !url || url.length == 0
                  }
                  rightIcon={FontAwesomeIcon({
                    icon: ['fa', 'arrow-circle-right'],
                    style: { marginTop: '4px' }
                  })}
                >
                  Check URL
                </Button>
              )}
              {(nooCommandIsProcessing || isBookmarkingHeadline) && (
                <Box float='right'>
                  <Spinner />
                </Box>
              )}
              {bookmarkHeadlineResponseError && (
                <Text>
                  <Text as='strong'>{getBookmarkError(bookmarkHeadlineResponseError)}</Text>
                </Text>
              )}
              {lastBookmarkedUrlData && lastBookmarkedUrlMetadata?.fetchedUrl === url && (
                <UrlCard urlData={lastBookmarkedUrlData} />
              )}
            </Flex>
          </ContentSubpanel>
        </Box>
      </UserAuth>
    </Box>
  );
};

export default Share;

import React, { useMemo } from 'react';
import { Text, Link, Image } from '@chakra-ui/react';
import useNoo from 'hooks/useNoo';

const BookmarkletDraggable = ({
  text,
  styles = {},
  textPlacement = 'bottom',
  controlOnly = false
}) => {
  const { currentNetworkData } = useNoo('Heading');
  const image = currentNetworkData?.favicon || '/public/images/bestofnow_red_favicon_50.png';

  const displayText = text || 'bookmarklet (hover for instructions)';

  const linkComponent = useMemo(() => {
    const bm_text =
      currentNetworkData.display + (currentNetworkData?.id == 'best_of' ? '' : ' (Best of Now)');
    const prefix = location.host.indexOf('localhost') == 0 ? 'http://' : 'https://';
    const href =
      "javascript: it = location.href;if (it) {console.log(it);var url='" +
      prefix +
      location.host +
      "/share/?autocheck=true&bookmark=' + encodeURIComponent(it);  void(open(url,'share','toolbar=no,width=400,height=700'));};";
    return (
      <Link
        href={href}
        color='white'
        display='inline-block'
        p='0 4px'
        borderRadius='full'
        title='Click-Drag this to your browser bookmark bar, and click it from any page you want to save.'
      >
        <Image src={image} alt={bm_text} width={'25px'} />
      </Link>
    );
  }, [image, currentNetworkData]);

  if (controlOnly) {
    return linkComponent;
  }

  return (
    <Text fontSize='sm' textAlign={['center', 'right']} sx={styles}>
      {linkComponent}
      {displayText}
      <Link
        href={'/public/images/BestOfNowHowTo.mp4'}
        display='inline-block'
        isExternal
        p='0 4px'
        borderRadius='full'
        title='Click to see a How To video.'
      >
        | How To Video
      </Link>
    </Text>
  );
};

export default BookmarkletDraggable;

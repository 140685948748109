import React from 'react';
// import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

// import ReactMarkdown from 'react-markdown';
import { Text, Heading, Box, Flex, VStack, Link, Button } from '@chakra-ui/react';
import RenderChakraMarkdown from 'utils/ChakraMarkdown';
import useNoo from 'hooks/useNoo';
import SignInOrRegister from 'components/Segments/SignInOrRegister';
import InvitationsAccept from 'components/Invitations/InvitationsAccept';
import TagCloud from 'components/Segments/TagCloud';
import GreencheckAbout from './AboutPages/GreencheckAbout';
import MarkdownAccordion from 'components/Segments/MarkdownAccordion';
import { ExternalLinkIcon } from '@chakra-ui/icons';

// function sleep(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

function useQuery() {
  var params = new URLSearchParams(useLocation().search);
  var lookup = {};
  for (var pair of params.entries()) {
    lookup[pair[0]] = pair[1];
  }
  return lookup;
}

const About = () => {
  const { googleUser: user, currentNetworkData, nooUser } = useNoo('About');
  const network = currentNetworkData;
  const netname = network.id;
  const markdown = network.about;

  var params = new URLSearchParams(useLocation().search);
  let index = params.get('index');
  index = index ? parseInt(index) : null;

  // TODO: if using invitations here, update to useInvitations() hook
  // const onCallAcceptInvitation = () => {
  // 	nooApi.acceptInvitation(user, invitationId, setAcceptLoading, setAcceptResponse);
  // };
  const paraSx = {
    mb: '12px'
  };

  const display = network?.display;
  const paras = network.welcome;
  const about = null; // network.about;
  let items = [];
  if (netname == 'women') {
    items.push(
      <VStack>
        <Text sx={paraSx}>
          See our simulation of how it might propagate from one founder in the tabs of&nbsp;
          <Link
            href={
              'https://docs.google.com/spreadsheets/d/1g1rJX35DDD38rGY1T-dCp96zwwjcgr1iUDX4eZ8xr-Q/edit?gid=0#gid=0'
            }
            isExternal
            color='#226'
            textDecoration='underline'
            fontWeight='bold'
          >
            this spreadsheet <ExternalLinkIcon mx='2px' />
          </Link>
          and the&nbsp;
          <Link
            href={'/search_network/?group=d33250fb-35ac-46e6-9d38-c683e167c654'}
            color='#226'
            textDecoration='underline'
            fontWeight='bold'
          >
            resulting network
          </Link>
        </Text>
        <Text sx={paraSx}>
          See especially &nbsp;<strong>scaling</strong> and <strong>governance</strong> below. And
          this deck on{' '}
          <a
            href='https://docs.google.com/presentation/d/1C5ToJ55bqhRL7QZydZ6Jp91ILSAC8JBtjTl0pSw9G1Q/edit#slide=id.g1a4567fb90a_0_0'
            target='_blank'
            rel='noreferrer'
          >
            Why a women-only virtual nation
          </a>
          .
        </Text>
        <Text sx={paraSx}>
          Contribute to our{' '}
          <Link
            /* href={crowdfund} */
            isExternal
            color='#226'
            textDecoration='underline'
            fontWeight='bold'
          >
            OpenCollective
          </Link>{' '}
          (coming soon)
        </Text>
        <Text sx={paraSx}>Join the conversation at (coming soon).</Text>
      </VStack>
    );
  }

  if (netname == 'greencheck') {
    items.push(<GreencheckAbout index={index} />);
  } else if (markdown) {
    items.push(<MarkdownAccordion markdown={markdown} index={index} />);
  }

  return <VStack sx={{ alignItems: 'left' }}>{items}</VStack>;
};

export default About;

import {
  NOO_SET_INVITATION,
  NOO_SET_INVITATION_ID,
  NOO_RESET_INVITATION_DATA
} from 'shared/constants/actionTypes';

const initialState = {
  invitation: null,
  invitationId: ''
};

const nooInvitations = function (state = initialState, action) {
  switch (action.type) {
    case NOO_SET_INVITATION:
      return {
        ...state,
        invitation: action.payload?.invitation,
        invitationId: action.payload?.invitation?._id
      };

    case NOO_SET_INVITATION_ID:
      return {
        ...state,
        invitationId: action.payload
      };

    case NOO_RESET_INVITATION_DATA:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

export default nooInvitations;

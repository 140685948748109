import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
// import { ImageConstrainer } from 'components/Listings/ListingDisplay';

const WhiteBox = styled(Box)`
  background-color: #fff;
`;

const UrlCard = props => {
  const { urlData } = props;

  if (!urlData) {
    return null;
  }

  return (
    <WhiteBox>
      <Flex borderTop='4px solid #eee' p='10px' mt='10px'>
        {urlData?.image && (
          <Box mr='1em'>
            <img src={urlData?.image} maxwidth='100px' height='auto' />
          </Box>
        )}
        <Box flex='1'>
          <Flex>
            {urlData.favicon && <img src={urlData.favicon} width={40} />}
            <a href={urlData.url} display='inline-block' ml='8px'>
              {urlData.title}
            </a>
          </Flex>
          <Box>{urlData.description}</Box>
        </Box>
      </Flex>
    </WhiteBox>
  );
};

export default UrlCard;

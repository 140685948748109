import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Input, FormLabel, Button, Flex, Text, HStack, VStack } from '@chakra-ui/react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import useInvitations from 'hooks/useInvitations';
import Select from 'react-select';
import GroupSelector from 'components/Segments/GroupSelector';
import { PopOverUser } from 'components/Segments/UserCard';
import { split_tags } from 'utils';

const InviteEmail = props => {
  const { personId } = props;
  const [localData, setLocalData] = useState({
    email: '',
    tags: [],
    groups: []
  });
  const { createInvitation, invitationLoading } = useInvitations();
  const { isAuthorized, nooUser, googleUser: user } = useNoo('InviteEmail');
  const { nooCommandDirect } = useNooApi();
  const [localErrors, setLocalErrors] = useState(null);
  const [tagOptions, setTagOptions] = useState([]);
  const [existingInvitees, setExistingInvitees] = useState(null);
  const [knownInvitee, setKnownInvitee] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [disabledGroups, setDisabledGroups] = useState([]);

  useEffect(() => {
    const ready = localData?.groups.length > 0 && localData?.email;
    setDisabled(ready ? false : true);
  }, [localData]);

  const onSubmit = useCallback(
    e => {
      const groups = localData.groups;
      if (groups.length > 0) {
        const groupids = groups;
        var myLocal = { ...localData };
        _.forEach(myLocal, (val, key) => {
          if (typeof val == 'string') {
            myLocal[key] = val.trim();
          }
        });
        if (myLocal.groups) {
          delete myLocal.groups;
        }
        let invitee = { profile: myLocal, invite_email: myLocal.email, tags: myLocal.tags };
        if (personId || knownInvitee) {
          invitee._id = personId || knownInvitee._id;
        }
        console.log('submitting', groupids, invitee);
        createInvitation(groupids, invitee);
      }
    },
    [localData, createInvitation, knownInvitee, personId]
  );

  const handleSubmit = evt => {
    evt.preventDefault();
    const ok = validate();
    if (ok) {
      onSubmit(localData);
    } else {
      console.log('Try again');
    }
  };

  const exclude = (allm, excluded) => {
    return _.filter(allm, one => !_.includes(excluded, one));
  };

  useEffect(() => {
    if (!existingInvitees) {
      const fetchInvitees = new Promise((resolve, reject) => {
        const loading = () => {};
        const knownId = nooUser?.person?._id;
        const responseHandler = response => {
          const data = response.result[0];
          resolve(data);
        };
        const responseError = error => {
          console.error('Error getting invitees of', error);
          reject(error);
        };
        if (knownId) {
          const payload = { query_name: 'aqlInviteesOf', data: { user_id: knownId } };
          nooCommandDirect('aql', {
            payload,
            setLoading: loading,
            setResponse: responseHandler,
            setResponseError: responseError
          });
        }
      })
        .then(result => {
          const { profiles, edges } = result;
          const emailById = {};
          const users = {};
          profiles.forEach(profile => {
            const email = profile.data.invite_email || profile.data.profile?.email;
            emailById[profile._id] = email;
            users[email] = { _id: profile._id, profile: profile, groups: [] };
          });
          const emailsByGroup = {};
          edges.forEach(edge => {
            const group = edge.groupId;
            const email = emailById[edge._to];
            if (email) {
              if (!(email in users)) users[email] = { _id: edge._to, groups: [] };
              users[email].groups.push(group);
            }
          });
          setExistingInvitees(users);
        })
        .catch(error => {
          // handle any errors
          console.error('Error on promises', error);
          setExistingInvitees({});
        });
    }
  }, [existingInvitees, nooUser?.person?._id, nooCommandDirect]);

  const onNameChange = useCallback(
    // replace this with aqlInviteesOf
    e => {
      if (localData.email) {
        const email = localData.email;
      }
    },
    [localData]
  );

  const validate = () => {
    const req_fields = ['email'];
    const one_of_fields = [];
    const errors = [];
    const required = [];
    const one_of = [];

    req_fields.forEach(field => {
      if (!localData?.[field]) {
        required.push(field);
      }
    });

    one_of_fields.forEach(field => {
      if (localData?.[field]) {
        one_of.push(field);
      }
    });

    if (required.length) {
      errors.push('All these fields need a value: ' + req_fields.join(' '));
    }
    if (one_of_fields.length > 0 && !one_of.length > 0) {
      errors.push('One these fields need a value: ' + one_of_fields.join(' '));
    }
    if (localData.email == user?.email) {
      errors.push('You cannot invite yourself');
    }
    setLocalErrors(errors);
    if (errors.length) {
      return false;
    }
    return true;
  };

  const onFieldUpdate = e => {
    const tgt = e.target;
    const field = tgt.name;
    const value = tgt.value;
    const trimmed = value.trim();
    setLocalErrors(null);
    const myLocalData = {
      ...localData
    };
    switch (field) {
      case 'phone':
        // console.log('setting', value, value.split('').reverse().join(''));
        break;
      case 'email':
        myLocalData[field] = trimmed;
        if (user.email == trimmed) {
          myLocalData.email = '';
          setLocalErrors(['You cannot invite yourself']);
        }
        const found = existingInvitees ? existingInvitees[trimmed] : null;
        setKnownInvitee(found);
        const skip = found ? found.groups : [];
        setDisabledGroups(skip);
        myLocalData.groups = exclude(myLocalData.groups, skip);
        break;
      case 'groups':
        let groups = exclude(myLocalData.groups, disabledGroups);
        let grps = new Set(groups);
        if (tgt.checked) {
          grps.add(value);
        } else {
          grps.delete(value);
        }
        myLocalData.groups = Array.from(grps);
        break;
      default:
        myLocalData[field] = trimmed;
        break;
    }
    setLocalData(myLocalData);
  };

  const onTags = e => {
    const myLocalData = {
      ...localData
    };
    myLocalData.tags = split_tags(e);
    setLocalData(myLocalData);
  };

  const fieldSx = useMemo(() => ({ mb: '0.5em' }), []);
  /* 
        {< FormLabel htmlFor='phone'>Phone Number</ FormLabel>}
      <Input
        type='text'
        placeholder='Phone Number'
        name='phone'
        value={localData?.phone}
         onChange={onFieldUpdate}
        sx={fieldSx}
      />

      {< FormLabel htmlFor='email'>Email</ FormLabel>}
      <Input
        type='text'
        placeholder='Email'
        name='email'
        value={localData?.email}
         onChange={onFieldUpdate}
        sx={fieldSx}
      />
                  {matchingUsers && <RenderMatchingUsers matchingUsers={matchingUsers} />}
      */

  const showtags = false;
  return (
    <Flex sx={{ w: '100%' }}>
      <Box
        as='form'
        className='__listing-form'
        onSubmit={handleSubmit}
        sx={{ width: '100%', m: '0 auto', backgroundColor: '#eee' }}
      >
        <Flex>
          <VStack>
            <HStack>
              {<FormLabel htmlFor='name'>Email</FormLabel>}
              <Input
                type='text'
                placeholder='Gmail address for now'
                name='email'
                value={localData?.email}
                onChange={onFieldUpdate}
                // onBlur={onNameChange}
                sx={fieldSx}
              />
            </HStack>
          </VStack>
        </Flex>

        {showtags && (
          <Flex>
            {<FormLabel htmlFor='tags'>Tags</FormLabel>}
            {tagOptions && tagOptions.length > 0 && (
              <Select
                sx={{ backgroundColor: '#eee', outline: 'none', float: 'right', width: '80%' }}
                value={localData?.tags}
                name='tags'
                onChange={onTags}
                multi
                options={tagOptions}
              />
            )}
          </Flex>
        )}
        {isAuthorized && (
          <Box>
            {
              <FormLabel fw='bold' htmlFor='groups'>
                Select at least one group
              </FormLabel>
            }
            <GroupSelector
              nooUser={nooUser}
              callback={onFieldUpdate}
              render={'checkbox'}
              filters={['member', 'owner']}
              disabled={disabledGroups}
            />
          </Box>
        )}

        <Button sx={{ cursor: 'pointer' }} type='submit' disabled={disabled}>
          Invite {localData?.fullname}
        </Button>
        {localErrors &&
          localErrors.map((LE, idx) => {
            return (
              <Text key={idx} color='#dd0000' sx={{ m: 0, p: 0, color: '#dd0000' }}>
                {LE}
              </Text>
            );
          })}
      </Box>
    </Flex>
  );
};

export default InviteEmail;

import { useDispatch, useSelector } from 'react-redux';
import { searchPeople as _searchPeople, clearPeople } from 'redux/actions/people';

const usePeopleSearch = () => {
  const dispatch = useDispatch();

  const people = useSelector(state => state.people?.list);
  const isSearching = useSelector(state => state.people?.isRequesting);

  const clearPeopleResults = () => {
    dispatch(clearPeople());
  };

  const searchPeople = name => {
    dispatch(_searchPeople({ name }));
  };

  return {
    people,
    isSearching,
    clearPeopleResults,
    searchPeople
  };
};

export default usePeopleSearch;

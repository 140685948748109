import React from 'react';
import { Select } from '@chakra-ui/react';
import { useParams, useHistory } from 'react-router-dom';
import useNoo from 'hooks/useNoo';
import useInvitations from 'hooks/useInvitations';
import { useEffect, useState } from 'react';

export const INBOUND_INVITE_LOCATION = '/invite/accept';
export const INBOUND_NETWORK_LOCATION = '/';

const InviteUrlProcessor = () => {
  const { bundle, inviteId } = useParams();
  const { setCurrentNetwork, isValidNetwork } = useNoo('InviteUrlProcessor');
  const { updateInvitationId } = useInvitations();
  const history = useHistory();
  const [urlProcessed, setUrlProcessed] = useState(false);

  useEffect(() => {
    if (bundle && isValidNetwork(bundle)) {
      if (inviteId) {
        setUrlProcessed(true);
        setCurrentNetwork(bundle);
        updateInvitationId(inviteId);
        console.log('setting bundle and inviteId', [bundle, inviteId]);
        return history.push(INBOUND_INVITE_LOCATION);
      }
      setUrlProcessed(true);
      setCurrentNetwork(bundle);
      console.log('setting bundle only', [bundle]);
      history.push(INBOUND_NETWORK_LOCATION);
    }
  }, [
    bundle,
    history,
    inviteId,
    isValidNetwork,
    setCurrentNetwork,
    updateInvitationId,
    urlProcessed
  ]);

  return null;
};

export default InviteUrlProcessor;

const markdown = `
## StigMastodon
Find the best people to follow on Mastodon. We built this for ourselves. In this draft form, it's a little rough, but maybe you'll find it useful too. 
### What makes this different?
There are several useful tools for finding people to follow, e.g.: [Fedifinder](https://fedifinder.glitch.me/), [DeBirdify](https://debirdify.pruvisto.org/), [GetStarted](https://getstarted.social/), [OpenCheck](https://opencheck.is/people), [Movetodon](https://www.movetodon.org/), ...

Try them out. Most are focused on finding any of your Twitter connections (follows, followers, lists) who have Mastodon accounts.

Our approach is to find the best Twitter people on any subject and make them easy to find and follow on Mastodon. Building on over a decade of working with the Twitter API, we have a lot of tools for identifying, clustering, and tracking great people. So we've applied those to noticing when any of over 1M domain-respected Twitter users add a Mastodon id to their profile, and making them findable here.

On top of that, we have lots of analytics to layer on, like how people are connected to each other, to enhance discovery.

This is an early proof-of-concept. We'll be adding new tools, and welcome suggestions via our Discord server (soon).

### How to use StigMastodon
Sign in with a Gmail account above if you haven't already (more methods soon, see Privacy below.)

Click the Network tab above and start to explore. 

Search for specific people, and you will get them and whatever others they are connected to meaningfully on Twitter.

Search against the Twitter profile description or location. Best to use short stems of words because they can match more, e.g. *philoso* matches *philosophy, philosopher*, ...

Click the checkboxes for any you want to follow as you meander around. All checks are visible under Show Selected. Download your current selections as a CSV file and [import on you Mastodon instance](https://www.tutonaut.de/en/mastodon-twitter-follower-und-follows-importieren/).

We'll be improving search as quickly as possible.

### Privacy
We don't store any of your activity here. All selections are kept in your browser localStorage and exported for your use. We will soon be offering an opt-in storage in our database and other tools for collective analytics etc.

### Why Stig?
To quote [Wikipedia](https://en.wikipedia.org/wiki/Stigmergy):
> Stigmergy is a mechanism of indirect coordination, through the environment, between agents or actions. The principle is that the trace left in the environment by an individual action stimulates the performance of a succeeding action by the same or different agent. 


Ants and other insects function as superorganisms through *stigmergy*, e.g. individuals deposit pheromones in their environment that cause larger collective behavior in their community. Termites build complex colonies, ants solve the *traveling salesman problem*, ...

We are interested in and playing with *human stigmergy*, people *marking* people as a means of creating interesting superorganism behavior, like better collective sensemaking, emergent coordination, ... 

We figure the once-in-a-decade shift that is happening now from Twitter to elsewhere is a great opportunity to experiment. 

More on that later.

`;

export default markdown;

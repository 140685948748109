import {
  PEOPLENETWORK_REQUEST_START,
  SET_PEOPLENETWORK_DATA,
  SET_PEOPLENETWORK_ERROR,
  CLEAR_PEOPLENETWORK_DATA
} from 'shared/constants/actionTypes';

const initialState = {
  isRequesting: false,
  networkData: null,
  error: null
};

const peopleNetwork = function (state = initialState, action) {
  switch (action.type) {
    case PEOPLENETWORK_REQUEST_START:
      return {
        ...initialState,
        isRequesting: true
      };
    case SET_PEOPLENETWORK_DATA:
      return {
        isRequesting: false,
        networkData: action.payload,
        error: null
      };
    case SET_PEOPLENETWORK_ERROR:
      return {
        isRequesting: false,
        networkData: null,
        error: action.payload
      };
    case CLEAR_PEOPLENETWORK_DATA:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default peopleNetwork;

import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Flex, Heading, Spinner, HStack } from '@chakra-ui/react';
import styled from '@emotion/styled';
// import { Link as RouterLink } from 'react-router-dom';
// import { capitalize } from 'lodash';
import useDecisions from 'hooks/useDecisions';
import useGroups from 'hooks/useGroups';
// import useSearchResults, { ResultLink } from 'hooks/useSearchResults';
// import SortableTable from 'components/Segments/SortableTable';
import FontAwesomeLoader from 'utils/FontAwesomeLoader';
import { objectSort } from 'utils';
import { getOptionDecisionTallyAccum } from 'utils/decisions';
import { dropNodeIdPrefix } from 'utils';
import { TotalBar, CountBar, AllBar } from './Styled';
import DecisionSelector from 'components/Segments/DecisionSelector';

const kind = 'decisions';

const QuestionsList = props => {
  const history = useHistory();
  let { arrowDir } = props;
  arrowDir = arrowDir || 'right';
  const { currentGroupId, currentGroupName } = useGroups({ from: 'QuestionsList' });
  const {
    decisions,
    currentDecisionId,
    getDecisionsByCurrentGroup,
    getDecisionById,
    isActive
  } = useDecisions();
  const [lastGroupId, setLastGroupId] = useState(null);
  const searchOnLoad = useRef(false);
  const hasSearchedOnce = useRef(false);

  useEffect(() => {
    if (!searchOnLoad.current && currentGroupId && !decisions?.length) {
      searchOnLoad.current = true;
      hasSearchedOnce.current = true;
      // console.log('searching on load running....');
      getDecisionsByCurrentGroup();
      setLastGroupId(currentGroupId);
    }
  }, [currentGroupId, decisions?.length, getDecisionsByCurrentGroup]);

  useEffect(() => {
    if (currentGroupId) {
      if (currentGroupId !== lastGroupId || !lastGroupId) {
        hasSearchedOnce.current = true;
        getDecisionsByCurrentGroup();
        setLastGroupId(currentGroupId);
      }
    }
  }, [currentGroupId, getDecisionsByCurrentGroup, lastGroupId]);

  const loadDecisionData = useCallback(
    decisionId => {
      if (decisionId) {
        getDecisionById(decisionId);
      }
    },
    [getDecisionById]
  );

  const chooseDecision = useCallback(
    (event, decisionId) => {
      console.log(`${currentGroupId}/${decisionId}`);
      loadDecisionData(decisionId);
      /* history.push( // doesn't work yet
        `/search_decisions/${dropNodeIdPrefix(currentGroupId)}/${dropNodeIdPrefix(decisionId)}`
      ); */
    },
    [currentGroupId, loadDecisionData]
  );

  const parsedDecisions = useMemo(() => {
    if (decisions?.length) {
      return decisions
        .filter(decision => decision?.data)
        .map(decision => {
          const { data } = decision;
          return {
            _id: decision._id,
            createDate: decision.createDate,
            modDate: decision.modDate,
            title: decision.data.title,
            description: decision.data.description,
            prompt: decision.data.prompt,
            total: getOptionDecisionTallyAccum(decision, 'total'),
            count: getOptionDecisionTallyAccum(decision, 'count'),
            rel: decision.data.rel,
            parent_id: decision.data.parent_id
          };
        })
        .sort(objectSort('createDate'));
    }
  }, [decisions]);

  if (!currentGroupId || !parsedDecisions?.length) {
    if (isActive) {
      return (
        <Box>
          <Spinner size='lg' />
        </Box>
      );
    }
    if (hasSearchedOnce.current) {
      //  || !currentGroupId
      return (
        <Box>
          <Heading size='sm' fontWeight='normal'>
            No topics found for {currentGroupName || 'this one'}. Please try another.
          </Heading>
        </Box>
      );
    }
    return (
      <Box>
        <Heading size='md' fontWeight='normal'>
          Please choose a group to see the topic list.
        </Heading>
      </Box>
    );
  }

  const pd_len = parsedDecisions?.length;

  if (isActive && !pd_len) {
    return <Spinner />;
  }

  return (
    <Box maxWidth='100%'>
      <Box>
        <HStack spacing={10}>
          <Heading as='h3' size='sm' fontWeight='normal'>
            Choose an Inquiry
          </Heading>
          <DecisionSelector
            groupId={currentGroupId}
            callback={chooseDecision}
            selected={currentDecisionId}
          />
        </HStack>
      </Box>
      {/*<SortableTable columns={cols} rows={parsedDecisions} />*/}
    </Box>
  );
};

export default QuestionsList;

import React, { useEffect, useRef } from 'react';
import {
  Box,
  VStack,
  Heading,
  HStack,
  Flex,
  Text,
  Link,
  Radio,
  RadioGroup
} from '@chakra-ui/react';
import { useParams, useHistory } from 'react-router-dom';
import useDecisions from 'hooks/useDecisions';
import useGroups from 'hooks/useGroups';
import { dropNodeIdPrefix, derivePrefixedNodeId } from 'utils';
import { ContentSubpanel } from 'components/Segments/Styled';
import GroupSelector from 'components/Segments/GroupSelector';
import UserAuth from 'components/User/UserAuth';
import QuestionsList from './QuestionsList';
import OptionsList from './OptionsList';
// import OptionDetails from './OptionDetails';
import OneEarthTreeMap from 'components/DataViz/OneEarthTreeMap';

const OneEarthModule = () => {
  const [taxo, setTaxo] = React.useState('bioregions');

  return (
    <Box pb='1em'>
      <UserAuth unauthorized='signin'>
        <Box className='App-row' width='100%'>
          <ContentSubpanel width='100%'>
            <Box pb='10px' mb='10px' borderBottom='1px solid #eee'>
              <Heading as='h1' size='md'>
                OneEarth Taxonomies
              </Heading>
              <RadioGroup onChange={setTaxo} value={taxo}>
                <HStack direction='row'>
                  <Radio value='bioregions'>Bioregions</Radio>
                  <Radio value='solutions'>Climate Solutions</Radio>
                </HStack>
              </RadioGroup>
              {taxo == 'bioregions' ? (
                <Box>
                  <Text>
                    Who knows regeneration in each bioregion of the planet (from{' '}
                    <Link href='https://www.oneearth.org/navigator/' target='_blank'>
                      OneEarth&apos;s Navigator
                    </Link>
                    )? Help identify the people with local expertise, and empower them to identify
                    others.
                  </Text>
                </Box>
              ) : (
                <Box>
                  <Text>
                    Who has domain expertise in each of{' '}
                    <Link href='https://www.oneearth.org/solutions/' target='_blank'>
                      OneEarth&apos;s Climate Solutions
                    </Link>
                    ?
                  </Text>
                  <Text></Text>
                </Box>
              )}
              <VStack>
                <Heading as='h2' fontSize={'20px'}>
                  {taxo[0].toUpperCase() + taxo.slice(1) + ' Taxonomy' || '...'}
                </Heading>
              </VStack>
            </Box>
            <HStack sx={{ gap: '10px', w: '100%' }} alignItems={'flex-start'}>
              <Box width='100%'>
                <OneEarthTreeMap which={taxo} />
              </Box>
            </HStack>
          </ContentSubpanel>
        </Box>
      </UserAuth>
    </Box>
  );
};

export default OneEarthModule;

/*
 * nooApi - class for calls to NN server API
 *
 */
// import { groupsByPersonId } from 'utils/aqlQueryGenerators';

import _ from 'lodash';
// import { sleep } from 'utils';

// privatizing these outside the class
let authToken = null;
let tokenType = '';

class NooApi {
  constructor(route) {
    this.url = route || '/api';
    this.bundle = null;
    this.blocked = {};
  }

  setBundle(bundle) {
    this.bundle = bundle;
  }

  setAuthToken(token, type) {
    switch (type) {
      case 'google':
      case 'firebase':
        authToken = token;
        tokenType = 'firebase';
        break;
      case 'fan':
        authToken = token;
        tokenType = 'fan';
        break;
      default:
        authToken = null;
        tokenType = '';
        break;
    }
  }

  getGoogleToken() {
    if (tokenType === 'firebase') {
      return authToken;
    }

    return null;
  }

  getFanToken() {
    if (tokenType === 'fan') {
      return authToken;
    }

    return null;
  }

  /**
   * _blocking => attempt to stop identical calls from happening,
   * like on init for each useNoo instance at page load, it calls out to readPerson
   * only need one.
   *
   * blockval allows storage of, for example, part of a payload that is truthy
   * then for parallel calls necessary with different payloads
   * (different answer sets for instance) the method can retrieve the blockval
   * and decide how to handle it. could be a stack of payloads to check against...
   * each method can internally decide how to block or pass
   *
   * NOTE: this should be actually fixed by fixing the logic in the client code
   * that is leading to multiple calls in the first place! :-)
   *
   */
  block(what, blockval = true) {
    this.blocked = this.blocked || {};
    this.blocked[what] = blockval;
  }

  unblock(what) {
    this.blocked = this.blocked || {};
    this.blocked[what] = false;
  }

  isBlocked(what) {
    return this.blocked?.[what];
  }

  aql({ bundle, payload, setLoading, setResponse, setResponseError }) {
    const useBundle = bundle || payload.bundle || this.bundle; // allow overriding e.g. to get headlines from Twitter db
    this.genericCall({
      endpoint: '/aql',
      data: payload,
      bundle: useBundle,
      setLoading,
      setResponse,
      setResponseError
    });
  }

  addUsers({ users, person_id, setLoading, setResponse, setResponseError }) {
    console.log('ADD USERS', this.bundle);
    // const params = { question_id: question_id, option: option };
    const data = { users, person_id };
    console.log('users', users);
    this.genericCall({
      endpoint: '/users/create',
      // params,
      data,
      setLoading,
      setResponse,
      setResponseError
    });
  }

  addEdges({
    profiles,
    source_id,
    target_ids,
    person_id,
    site,
    setLoading,
    setResponse,
    setResponseError
  }) {
    // targets and source are Arango Persons nodes, account is LI account name for crediting
    console.log('ADD EDGES', this.bundle);
    console.log('profiles', profiles, source_id, target_ids);
    const data = { profiles, source_id, target_ids, person_id, site };
    this.genericCall({
      endpoint: '/edges/add',
      // params,
      data,
      setLoading,
      setResponse,
      setResponseError
    });
  }
  transmitNetwork({ data, setLoading, setResponse, setResponseError }) {
    // targets and source are Arango Persons nodes, account is LI account name for crediting
    // console.log('tranmit network', this.bundle);
    // console.log('data', data);
    this.genericCall({
      endpoint: '/transmit-network',
      // params,
      data,
      setLoading,
      setResponse,
      setResponseError
    });
  }
  memberCreate({ data, setLoading, setResponse, setResponseError }) {
    // marks the logged in user with a timestamp and member number
    this.genericCall({
      endpoint: '/member/create',
      // params,
      data,
      setLoading,
      setResponse,
      setResponseError
    });
  }

  networkx({ graph, setLoading, setResponse, setResponseError }) {
    // graph has .nodes and .links
    console.log('nooapi networkx', this.bundle);
    // const params = { question_id: question_id, option: option };
    // console.log('graph', graph);
    const data = {
      edges: graph.links.map(one => {
        return { _from: one.source, _to: one.target, type: 13 };
      }),
      nodes: graph.nodes.map(one => {
        const it = { person: { _id: one._id || one.id || one.username, data: {} } };
        return it;
      })
    };
    this.genericCall({
      endpoint: '/networkx',
      // params,
      data,
      setLoading,
      setResponse,
      setResponseError
    });
  }

  createOption({ question_id, option, setLoading, setResponse, setResponseError }) {
    console.log('CREATE OPTION', this.bundle);
    // const params = { question_id: question_id, option: option };
    const data = { question_id: question_id, options: [option] };
    this.genericCall({
      endpoint: '/options/create',
      // params,
      data,
      setLoading,
      setResponse,
      setResponseError
    });
  }

  createAnswer({ question_id, answer, setLoading, setResponse, setResponseError }) {
    console.log('CREATE ANSWER', this.bundle);
    // const params = { question_id: question_id, answer: answer };
    const data = { question_id: question_id, answer: answer };
    this.genericCall({
      endpoint: '/answer/create',
      // params,
      data,
      setLoading,
      setResponse,
      setResponseError
    });
  }

  createEdge({
    from_id,
    to_id,
    type,
    target_data,
    edge_data,
    setLoading,
    setResponse,
    setResponseError
  }) {
    // console.log('CREATE EDGE bundle', this.bundle);
    const data = { from_id, to_id, type, target_data, edge_data };
    this.genericCall({
      endpoint: '/edge/create',
      data,
      setLoading,
      setResponse,
      setResponseError
    });
  }

  updateEdge({ edge_id, update_data, setLoading, setResponse, setResponseError }) {
    // console.log('UPDATE EDGE bundle', this.bundle);
    const data = { edge_id, update_data };
    this.genericCall({
      endpoint: '/edge/update',
      data,
      setLoading,
      setResponse,
      setResponseError
    });
  }

  createDecision({ data, setLoading, setResponse, setResponseError }) {
    this.genericCall({
      endpoint: '/question/create',
      // params: data,
      data,
      setLoading,
      setResponse,
      setResponseError
    });
  }

  createListing({ data, setLoading, setResponse, setResponseError }) {
    this.genericCall({
      endpoint: '/resource/create',
      // params: data,
      data,
      setLoading,
      setResponse,
      setResponseError
    });
  }

  createDocument({ data, setLoading, setResponse, setResponseError }) {
    this.genericCall({
      endpoint: '/resource/create',
      // params: data,
      data,
      setLoading,
      setResponse,
      setResponseError
    });
  }

  createVouch({ data, setLoading, setResponse, setResponseError }) {
    this.genericCall({
      endpoint: '/vouch/create',
      // params: data,
      data,
      setLoading,
      setResponse,
      setResponseError
    });
  }

  genericCall({
    endpoint,
    // params,
    data,
    bundle,
    setLoading,
    setResponse,
    setResponseError,
    logWithWho
  }) {
    /* console.log('PARAMS', params) */
    // console.log('generic DATA', data);

    // _.forOwn(params, (value, key) => {
    //   if (!value) {
    //     return setResponseError('no ' + key + ' found, cannot run task');
    //   }
    // });

    if (!bundle && !this.bundle) {
      return setResponseError('no bundle, cannot run task');
    }

    data.bundle = data.bundle || bundle || this.bundle;

    if (logWithWho) {
      logWithWho('bundle :: ', data.bundle);
    } else {
      console.log('genericCall bundle ', data.bundle);
    }

    setLoading(true);
    // console.log('calling ', `${this.url}${endpoint}`, data);
    const headers = {
      'Content-Type': 'application/json'
    };
    if (authToken && tokenType === 'firebase') {
      headers.Bearer = authToken;
    }
    fetch(`${this.url}${endpoint}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    })
      .then(response => response.text())
      .then(data => {
        const as_json = JSON.parse(data);
        if (as_json.success == false) {
          setResponseError(data);
        } else {
          try {
            // console.log('RESPONSE generic_call ', JSON.parse(data));
            // console.log(`got a response back for ${this.url}${endpoint}`, as_json);
            setResponse(as_json);
          } catch (error) {
            console.log('error trying to set response...?', error);
            setResponseError(error, data);
          }
        }
      })
      .catch(function (error) {
        console.log('error calling API');
        console.log(error);
        setResponseError(error);
        return null;
      });
  }

  createPerson({ user, network, setLoading, setResponse, setResponseError }) {
    if (!user) {
      return setResponseError('no user found, cannot run task');
    }
    if (!this.bundle) {
      return setResponseError('no bundle, cannot run task');
    }
    setLoading(true);
    const data = {
      user,
      bundle: this.bundle,
      network: _.pick(network, ['id', 'bundle', 'default_group'])
    };
    // console.log('create person bundle ', this.bundle);
    const headers = {
      'Content-Type': 'application/json'
    };
    if (authToken && tokenType === 'firebase') {
      headers.Bearer = authToken;
    }

    fetch(`${this.url}/person/create`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    })
      .then(response => response.text())
      .then(data => {
        // console.log('response from createPerson', data);
        try {
          setResponse(JSON.parse(data));
        } catch (error) {
          setResponseError(data);
        }
      })
      .catch(function (error) {
        console.log('error calling API');
        console.log(error);
        setResponseError(error);
        return null;
      });
  }

  deletePerson({ setLoading, setResponse, setResponseError }) {
    if (!this.bundle) {
      return setResponseError('no bundle, cannot run task');
    }
    setLoading(true);
    const headers = {
      'Content-Type': 'application/json'
    };
    if (authToken && tokenType === 'firebase') {
      headers.Bearer = authToken;
    }
    fetch(`${this.url}/person/delete`, { headers })
      .then(response => response.text())
      .then(data => {
        try {
          setResponse(JSON.parse(data));
        } catch (error) {
          setResponseError(data);
        }
      })
      .catch(function (error) {
        console.log('error calling API');
        console.log(error);
        setResponseError(error);
        return null;
      });
  }

  createInvitation({ config, setLoading, setResponse, setResponseError }) {
    if (!config?.groupids || !config?.type) {
      return setResponseError('missing config props, cannot run task');
    }
    if (!this.bundle) {
      return setResponseError('no bundle, cannot run task');
    }
    const headers = {
      'Content-Type': 'application/json'
    };
    if (authToken && tokenType === 'firebase') {
      headers.Bearer = authToken;
    }
    setLoading(true);
    const data = {
      group_ids: config.groupids,
      type: config.type,
      bundle: this.bundle,
      invitee: config.invitee
    };
    console.log('/invitation/create data', data);
    fetch(`${this.url}/invitation/create`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    })
      .then(response => response.text())
      .then(data => {
        // console.log('response from create invite', data);
        try {
          setResponse(JSON.parse(data));
        } catch (e) {
          console.log('Error on create invitation: ', e);
          console.log('Data: ', data);
          setResponseError(data);
        }
      })
      .catch(function (error) {
        console.log('error calling API');
        console.log(error);
        setResponseError(error);
        return null;
      });
  }

  acceptInvitation({ invitationId, setLoading, setResponse, setResponseError }) {
    if (!invitationId) {
      return setResponseError('no invitationId found, cannot run task');
    }
    if (!this.bundle) {
      return setResponseError('no bundle, cannot run task');
    }
    setLoading(true);
    const data = {
      invitation_id: invitationId?.trim(),
      bundle: this.bundle
    };
    const headers = {
      'Content-Type': 'application/json'
    };
    if (authToken && tokenType === 'firebase') {
      headers.Bearer = authToken;
    }

    fetch(`${this.url}/invitation/accept`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    })
      .then(response => response.text())
      .then(data => {
        try {
          setResponse(JSON.parse(data));
        } catch (error) {
          setResponseError(data);
        }
      })
      .catch(function (error) {
        console.log('error calling API');
        console.log(error);
        setResponseError(error);
        return null;
      });
  }

  checkInvitation({ invitationId, setLoading, setResponse, setResponseError }) {
    if (!invitationId) {
      return setResponseError('no invite ID, cannot run task');
    }
    if (!this.bundle) {
      return setResponseError('no bundle, cannot run task');
    }
    setLoading(true);
    let aqlQuery = 'LET invitation = document("Invitations/' + invitationId + '") ';
    aqlQuery += `
    LET edges = (FOR edgeId in invitation.edgeIds return document(document(edgeId)._from))
    return {invitation, edges}  
    `;
    const data = { aqlQueryString: aqlQuery, bundle: this.bundle };
    return this.aql({ payload: data, setLoading, setResponse, setResponseError });

    // looks like this can be replaced with just straight up /aql
    // const headers = {
    //   'Content-Type': 'application/json',
    // }
    // if (authToken && tokenType === 'firebase') {
    //   headers.Bearer = authToken
    // }
    // fetch(`${this.url}/aqlsearch`, {
    //   method: 'POST',
    //   headers,
    //   body: JSON.stringify(data)
    // })
    //   .then(response => response.text())
    //   .then(data => {
    //     try {
    //       setResponse(JSON.parse(data));
    //     } catch (error) {
    //       setResponseError(data);
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log('error calling API');
    //     console.log(error);
    //     setResponseError(error);
    //     return null;
    //   });
  }

  createResource({ resource, setLoading, setResponse, setResponseError }) {
    if (!resource) {
      return setResponseError('no resource found, cannot run task');
    }
    if (!this.bundle) {
      return setResponseError('no bundle, cannot run task');
    }
    setLoading(true);
    const data = { resource, bundle: this.bundle };
    console.log('DATA', typeof data, data);
    const headers = {
      'Content-Type': 'application/json'
    };
    if (authToken && tokenType === 'firebase') {
      headers.Bearer = authToken;
    }
    fetch(`${this.url}/resource/create`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    })
      .then(response => response.text())
      .then(data => {
        // setResponse(data);
        try {
          setResponse(JSON.parse(data));
        } catch (error) {
          setResponseError(data);
        }
      })
      .catch(function (error) {
        console.log('error calling API');
        console.log(error);
        setResponseError(error);
        return null;
      });
  }

  createGroupEdge({ from_id, to_data, setLoading, setResponse, setResponseError }) {
    console.log('bundle', this.bundle);
    if (!from_id || !to_data) {
      return setResponseError('missing one of required params found, cannot run task');
    }
    if (!this.bundle) {
      return setResponseError('no bundle, cannot run task');
    }
    setLoading(true);
    const data = { parent_group_id: from_id, data: to_data, bundle: this.bundle };
    console.log('create group edge bundle ', this.bundle);
    const headers = {
      'Content-Type': 'application/json'
    };
    if (authToken && tokenType === 'firebase') {
      headers.Bearer = authToken;
    }
    fetch(`${this.url}/group/create`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    })
      .then(response => response.text())
      .then(data => {
        try {
          // console.log('createAnswer response data',JSON.parse(data) )
          setResponse(JSON.parse(data));
        } catch (error) {
          setResponseError(data);
        }
      })
      .catch(function (error) {
        console.log('error calling API');
        console.log(error);
        setResponseError(error);
        return null;
      });
  }

  test({ setLoading, setResponse }) {
    setLoading(true);
    fetch(`${this.url}/test`)
      .then(response => response.text())
      .then(data => {
        setResponse(data);
      });
  }
}

const noo = new NooApi();
export default noo;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import 'rc-slider/assets/index.css';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import Display from './DecisionDisplay';
// import DisplayNew from './DecisionDisplayNew';
import UserAuth from 'components/User/UserAuth';

const Decision = () => {
  const { isAuthorized, nooUser } = useNoo('Decision');
  const dispatch = useDispatch();
  const { nooCommand, nooCommandResponse, nooCommandIsProcessing } = useNooApi();

  const { decision_key } = useParams();
  const [decisionKey, setDecisionKey] = useState('');
  const personId = nooUser ? nooUser.person?._id : null;

  const [response, setResponse] = useState(null);
  if (decision_key != decisionKey) {
    setDecisionKey(decision_key);
    setResponse(null);
  }
  useEffect(() => {
    if (!response && isAuthorized && !nooCommandIsProcessing) {
      console.log('fetching', decisionKey, personId);
      const question_id = 'Questions/' + decisionKey;
      const payload = { query_name: 'aqlQuestionData', data: { question_id, person_id: personId } };
      dispatch(nooCommand('aql', { payload }));
    }
  }, [response, nooCommandIsProcessing, decisionKey, nooCommand, personId, dispatch, isAuthorized]);

  useEffect(() => {
    if (nooCommandResponse?.command === 'aql') {
      setResponse(nooCommandResponse.data);
    }
  }, [nooCommandResponse?.command, nooCommandResponse?.data]);
  return (
    <Box>
      <UserAuth unauthorized='signin'>
        <Box className='App-row decision'>
          <Display response={response} nooUser={nooUser} />
        </Box>
      </UserAuth>
    </Box>
  );
};

export default Decision;

import React, { useMemo, useCallback } from 'react';
import { Button, Text, Box, Link } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import useNoo from 'hooks/useNoo';
import useInvitations from 'hooks/useInvitations';
import UserAuth from 'components/User/UserAuth';
import FontAwesomeIcon from 'utils/FontAwesomeLoader';

const InviteLink = props => {
  const { group } = props;

  const { createInvitation, invitationLoading } = useInvitations();

  const onInviteClick = useCallback(
    e => {
      const { _id } = group;
      if (_id) {
        createInvitation(_id);
      }
    },
    [createInvitation, group]
  );

  if (!group) {
    return <Text>error: no group for invite</Text>;
  }

  return (
    <UserAuth>
      <Button
        onClick={onInviteClick}
        disabled={invitationLoading}
        borderRadius='full'
        fontWeight='body'
        fontSize='1'
        leftIcon={FontAwesomeIcon({
          icon: ['fad', 'comment-smile'],
          style: { marginTop: '1px' }
        })}
      >
        Invite
      </Button>
    </UserAuth>
  );
};

export default InviteLink;

export const InviteResultLink = props => {
  const { presenter } = props;
  const { invitationId, invitation } = useInvitations();
  const { currentNetwork } = useNoo('InviteLink:InviteResultLink');
  const linkHref = useMemo(() => {
    if (invitationId) {
      const base = window.location.origin + '/i/' + currentNetwork + '/';
      return base + invitationId.replace('Invitations/', '');
    }
    return null;
  }, [invitationId, currentNetwork]);

  const MailTo = props => {
    const { link } = props;
    console.log('link', link);
    const href =
      "mailto:?subject=Invitation to NooNet&body=I've invited you to .. Click this link to accept " +
      encodeURIComponent(link);
    return (
      <Link href={href} isExternal>
        Email a Friend <ExternalLinkIcon mx='2px' />
      </Link>
    );
  };

  if (linkHref) {
    const link = <a href={linkHref}>{linkHref}</a>;
    if (presenter) {
      return <presenter link={link} />;
    }
    return (
      <div>
        Copy this{' '}
        <strong>
          <a sx={{ color: '#f00' }} href={linkHref}>
            link
          </a>
        </strong>{' '}
        to your invitee:
        <br />
        {link}
        <br />
        <MailTo link={linkHref}></MailTo>
      </div>
    );
  }

  return null;
};

export const CurrentNetworkLink = props => {
  const { presenter } = props;
  const { currentNetwork } = useNoo('InviteLink:CurrentNetworkLink');

  const linkHref = useMemo(() => {
    if (currentNetwork) {
      return window.location.origin + '/n/' + currentNetwork;
    }
    return null;
  }, [currentNetwork]);

  if (linkHref) {
    const link = <a href={linkHref}>{linkHref}</a>;
    if (presenter) {
      return <presenter link={link} />;
    }
    return <Box>Link to the current network / bundle: {link}</Box>;
  }

  return null;
};

import { v4 as uuidv4 } from 'uuid';

// this is meant to tie a response to a request
// response returns the rqKey when needed to pair the async response to a call
// example: optimistic new msg, success will update
// the local list with the actual msg data from server
export const addRqKey = params => {
  if (!params.rqKey) {
    params.rqKey = uuidv4();
  }
  return params;
};

export const addMessage = (chatId, params, socket) => {
  socket.emit('chat:new-message', addRqKey({ chatId, ...params }));
};

export const updateMessage = (chatId, params, socket) => {
  socket.emit('chat:new-message', addRqKey({ chatId, ...params }));
};

export const upVote = (chatId, messageId, socket) => {
  socket.emit('chat:message:upvote', addRqKey({ chatId, messageId }));
};

export const downVote = (chatId, messageId, socket) => {
  socket.emit('chat:message:downvote', addRqKey({ chatId, messageId }));
};

// moved to API call instead:
// const getChatById = (chatId, params, socket) => {
//   console.log('getChatById, params', params);
//   socket.emit('chat:get-by-id', addRqKey({ chatId, ...params }));
// };

// moved to API call instead:
// const getChatByContentId = (contentId, params, socket) => {
//   socket.emit('chat:get-by-content', addRqKey({ contentId, ...params }));
// };

const markdown = `

## Welcome to the (Working Title) Party
### Beyond left / right / center to planetary

Hundreds of millions of us worldwide are watching helplessly as society and the planet spiral down. Meanwhile, our political systems are pitting us against each other, instead of getting us working together to solve our daunting problems.

If we can't even agree that we're in a climate emergency, how can we possibly co-create a positive future?

We propose here a way to:   
* grow a new global political force quickly using advanced networking and decision-making technologies,   
* articulate a compelling vision and platform with wide global appeal, beyond left-right-center,   
* take over the US Democratic party from within,   
* end the duopoly / oligarchy that has brought us to this precipice.

We're all desperate to take action, and this is one way to aggregate that into a global political force.

It's **now or never**. 

##**tl;dr**
An **emergent, self-governing global political party** so good at collective action it leaves other power structures in the dust.

There's no shortage of bad news. **What is the good news you'd like**? Ours is: 
> "A decentralized network of over one billion people, dedicated to a just, thriving, equitable planet, now dominates politics globally and has demonstrated meaningful global governance for addressing existential problems and opportunities." - Global Network News, 2030

Let's make that kind of news.

Alan Kay famously said "The best way to predict the future is to invent it." 

We say "The best way to invent the future is to envision it."


## What we're up against
* **Dystopia** is already here;
	* There will be no 'orderly transition' save what we make for ourselves.
* Current political systems are *not fixable* 
	* **Nation states** are **antithetical** to planetary survival; they are programmed to ***not* act globally**
	*  The US Constitution is **250 year-old software** dominated by an entrenched two-party system with a majority party [so feckless](https://newrepublic.com/article/167100/republican-party-turning-america-into-hungary) that has empowered a **pathologically racist, misogynist, fascist, [anti-democratic minority](https://www.cnn.com/2022/07/26/politics/red-states-blue-states-control-of-national-policy-fault-lines/index.html)**;
* **Capitalism** and Corporations are **rogue AIs** programmed to **eat the world** 
* Oligarchy's prime directive is **more for me** and **to hell with the rest**.

Seems daunting, but we can and must quickly rise above those systems.
## So what do we do?

* Grow a **global** ***trust network***, starting small from highly regarded leaders, and telling a great story;   
* Govern it by using its [embedded capacity for **fast, efficient collective decision-making**](https://markorodriguez.files.wordpress.com/2011/01/smartocracy-hicss2007.pdf);  
* Quickly evolve a **powerful political platform** that speaks to 100s of millions or billions;  
* Allocate our significant **collective resources** optimally for big early wins;  
* Be the first mover in **meaningful global governance**;


### How?

**Two core components** that are ready now and create the substrate for innovation and evolution limited only by our imagination:

* A ***trust network*** that grows by invitation from an initiating kernel of sages who embody the desired ethic. 
	* This forms a decentralized *'graph'* of connections among all participants. 
	* Thus everyone has a chain of accountability back to the initiators.
* **Governance by *[liquid democracy](https://en.wikipedia.org/wiki/Liquid_democracy)***
	* Using the trust edges of the network as delegations/proxies. 
	* Keep it simple at first
		* Focus initially on a compelling political platform
		* Allocation of crowdfunded treasury
	* A roadmap for mature governance systems needed for more complexity.

### Background

Original [Smartocracy grant application](http://standingwave.net/apps/public_html/docs/Smartocracy-wgf_v11.htm) and the resulting [Los Alamos National Labs whitepaper](https://markorodriguez.files.wordpress.com/2011/01/smartocracy-hicss2007.pdf) from 2005. 

Jordan Hall on [disrupting politics](https://medium.com/emergent-culture/we-can-restore-democracy-by-disrupting-politics-here-is-how-c9d514a8c194#.kt0mxtd3g), in 2016.

> So with liquid democracy, you have a fluid “swarm” of governance that forms clusters of expertise around given issues and concerns — expertise that is identified not by credentials or graft, but by real “chains of trust” all the way back down to the individual citizens from whom all sovereignty extends. Rather than a slow moving bureaucracy made up of easy to exploit single points of failure (“representatives”) that change hands only once every few years, you have a platform that can update in realtime and “flow” power and attention where it is needed. In other words, a platform for a resilient and adaptive collective intelligence.

### Tactics in the US


### Decentralization

Decentralization is an aspiration but not a gating item. We can use simple, centralized tools and infrastructue to grow enough to have the resources necessary to design the desired form and migrate.

### Governance
Governance at first is simple liquid democracy with cascading delegation and weighting by network centrality. 

A founding kernel will be elected by liquid democracy to make initial decisions about the specifics of future governance.

In essence, we will bootstrap governance with liquid democracy under the assumption that the initial lack of specificity will not keep it from being effective and arriving at a next generation of governance.






`;

export default markdown;

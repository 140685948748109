import React from 'react';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';
import { Text, Heading, Box, Flex } from '@chakra-ui/react';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import SimpleCloud from './SimpleCloud';
import { readFile } from 'utils/fileReadWrite';

const TagCloud = () => {
  const { googleUser: currentNetworkData } = useNoo('TagCloud');
  const { nooCommandDirect } = useNooApi();
  const network = currentNetworkData;
  const [tagCloud, setTagCloud] = useState(null);
  const [maxTags, setMaxTags] = useState(100);

  const history = useHistory();

  useEffect(() => {
    const onSuccess = response => {
      // console.log('tag cloud', response);
      const data = response.result[0];
      const doc = data.document[0];
      const tcloud = doc.data.cloud;
      let cloud = Object.entries(tcloud).map(pair => {
        const [tag, data] = pair;
        return {
          value: tag,
          count: data.count
        };
      });
      cloud = _.sortBy(cloud, one => {
        // sort for truncation
        return -one.count;
      }).slice(0, maxTags); // truncate
      cloud = _.sortBy(cloud, one => {
        return one.value.toLowerCase();
      });
      setTagCloud(cloud);
    };

    const onError = error => {
      console.log('error ', error);
    };
    readFile({ url: 'file://popular_people_tags' }, nooCommandDirect, onSuccess, onError);
  }, [nooCommandDirect, maxTags]);

  const clickTag = tag => {
    console.log('clicked', tag.value);
    history.push('/respect?tag=' + encodeURIComponent(tag.value));
  };
  return <Flex>{tagCloud && <SimpleCloud data={tagCloud} onClick={clickTag} />}</Flex>;
};

export default TagCloud;

import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Tooltip,
  Button,
  Box,
  Text
} from '@chakra-ui/react';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import DecisionCreateDialog from 'components/Segments/DecisionCreateDialog';

import _ from 'lodash';
import { inputs } from '@theme-ui/preset-tailwind';

const DecisionCreateModal = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [isOpen, setIsOpen] = useState(false);
  const { nooUser } = useNoo('TagPersonModal');
  const { nooCommandDirect } = useNooApi();
  const {
    parent_id,
    suggestion,
    group_id,
    description,
    prompt = 'Create Decision',
    type,
    disabled
  } = props;
  const firstOpen = () => {
    onOpen();
  };

  const otherOpen = () => {
    onOpen();
  };

  const doClose = () => {
    onClose();
  };

  return (
    <Box>
      {!disabled ? (
        <Button onClick={firstOpen} bgColor={'#89df6f'}>
          {prompt}
        </Button>
      ) : (
        <Tooltip label={'Must be ADMIN'}>
          <Button bgColor={'#aaaaaa'}>{prompt}</Button>
        </Tooltip>
      )}
      <Modal isOpen={isOpen} onClose={doClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{prompt}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Tell us what domains they are expert in, their relative expertise in each, what sets
              them apart.
            </Text>
            <DecisionCreateDialog
              label={prompt}
              parent_id={parent_id}
              title={props.title}
              suggestion={suggestion}
              description={description}
              group_id={group_id}
              type={type}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant='ghost' onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DecisionCreateModal;

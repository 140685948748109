/** NB: THIS IS TEMPORARY, SHOULD BE ROLLED INTO A GENERAL PEOPLE SEARCH, e.g. SearchNetwork */
import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { useLocation, Link as RouterLink, useHistory } from 'react-router-dom';
import { CSVLink, CSVDownload } from 'react-csv';
import {
  Flex,
  Box,
  HStack,
  VStack,
  Input,
  Heading,
  Text,
  Spinner,
  Button,
  FormLabel,
  useToast
} from '@chakra-ui/react';
import styled from '@emotion/styled';
// import _ from 'lodash';

import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import useGroups from 'hooks/useGroups';
import usePeopleNetwork from 'hooks/usePeopleNetwork';
import UserAuth from 'components/User/UserAuth';
import PeopleSelector from 'components/Segments/PeopleSelector';
import NetworkTabs from 'components/Networks/NetworkTabs';
import CloudGeneric from 'components/Segments/CloudGeneric';
import TagCloud from 'components/Segments/TagCloud';
import ValidUser from 'components/Segments/ValidUser';
import Videos from 'components/Segments/Videos';

// import TagSelector from 'components/Segments/TagSelector';
import { dropNodeIdPrefix } from 'utils';

const WhiteBox = styled(Box)`
  background-color: #fafafa;
  border: 1px solid #ccc;
`;

const NetworkBox = styled(Box)`
  width: 100%;
  background-color: white;
  border: 1px solid lightgrey;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  position: ${props => (props.position ? 'fixed' : 'initial')};
`;

const ShowRespect = () => {
  const { nooUser, currentNetworkData } = useNoo('ShowRespect');
  const valid = (nooUser?.valids || []).length > 0 && (nooUser?.claimed || []).length > 0;

  const network = currentNetworkData;
  const netname = network.id;

  const show_controls = false;
  const show_social_controls = false;
  const isNoonet = netname == 'noonet';
  const isMastodon = netname == 'ph';
  const isGreencheck = netname == 'greencheck';

  const {
    currentNetwork: graph,
    isRequesting: fetching,
    getRespected,
    getNetwork,
    getEdgesType,
    totalMatches
  } = usePeopleNetwork();

  var params = new URLSearchParams(useLocation().search);
  const [searchData, setSearchData] = useState(null);
  // const [profileDataToShow, setProfileDataToShow] = useState(null);
  const [direction, setDirection] = useState('both');
  const [requiredSocial, setRequiredSocial] = useState('mastodon');
  const [degrees, setDegrees] = useState(1);
  const [localData, setLocalData] = useState({ desc: '', loc: '' }); // form values
  const [userId, setUserId] = useState();
  const [userDisplay, setUserDisplay] = useState(null);
  const [maxNodes, setMaxNodes] = useState(params.get('max_nodes') || 200);
  const [closedCloud, setClosedCloud] = useState(null);

  const [networkGraphFullScreen, setNetworkGraphFullScreen] = useState(false);

  const loadUserOrGroupOnce = useRef(null);
  const inUserId = params.get('user');
  const inTag = params.get('tag');

  const _setUserData = useCallback(
    (userId, displayName) => {
      setUserId(userId);
      if (displayName) {
        setUserDisplay(displayName);
      }
      const logged_in = nooUser?.person?._id;
      getNetwork({ userId, limit: maxNodes, direction, degrees, logged_in });
    },
    [setUserId, setUserDisplay, getNetwork, maxNodes, degrees, direction, nooUser]
  );
  const getTopRespected = useCallback(
    filters => {
      const limit = 100;
      getRespected({ limit, filters });
      setUserDisplay(filters || 'Leaderboard');
    },
    [setUserDisplay, getRespected]
  );

  const onSelectTag = useCallback(
    event => {
      const tag = event.target.value;
      const limit = 100;
      getTopRespected({ tag });
      setUserDisplay(tag);
      setClosedCloud(true);
    },
    [setUserDisplay, getTopRespected, setClosedCloud]
  );

  useEffect(() => {
    if (nooUser && !loadUserOrGroupOnce?.current) {
      if (inUserId) {
        loadUserOrGroupOnce.current = true;
        _setUserData('Persons/' + inUserId, '');
      } else if (inTag && userDisplay != inTag) {
        onSelectTag({ target: { value: inTag } });
      }
    }
  }, [
    inUserId,
    userId,
    _setUserData,
    direction,
    nooUser,
    maxNodes,
    onSelectTag,
    inTag,
    userDisplay
  ]);

  const history = useHistory();

  const display = useMemo(() => {
    return userDisplay; //  || currentGroupName || '';
  }, [userDisplay]);

  /*   <Link as={RouterLink} to='/invite_bulk'>
  .
</Link> */

  const onLeaderSubmit = () => {
    getTopRespected();
  };

  const onLatestSubmit = () => {
    getTopRespected();
  };

  const onSelectUser = user => {
    const user_id = user?.value;
    if (user_id) {
      setUserDisplay(user.label);
      loadUserOrGroupOnce.current = false;
      history.push(`/respect/?user=${dropNodeIdPrefix(user_id)}`);
    }
  };

  const clickFriend = () => {};
  const default_tab = 1; // isGreencheck ? 0 : 0;
  const accordionChange = e => {
    setClosedCloud(e == -1 ? true : 0); // closed the accordion when a tag is selected
  };

  // <Button onClick={onLatestSubmit}>Latest</Button>
  return (
    <Box>
      <VStack alignItems={'left'}>
        <Heading as='h2' fontSize={'20px'}>
          {'Praise those you respect '}
        </Heading>
        <ValidUser valid={valid} user={nooUser} />
        <Text fontSize='14px'>
          Find people you know, tag them with their unique attributes and domains of expertise,
          weight those by your opinion of their importance. Mouseover names for more details. Click
          to recenter on them.
        </Text>
        <Videos key='videos' />
      </VStack>

      <UserAuth unauthorized='signin'>
        <VStack className='search_inputs' align='left' mb='0.5em' width={['100%', '100%', '70%']}>
          <PeopleSelector
            onSelect={onSelectUser}
            inputPlaceholder={'Name as you know them...'}
            label={'Find someone to praise'}
          />
          <HStack className='input_box'>
            <Button onClick={onLeaderSubmit}>Leaderboard</Button>
            <FormLabel htmlFor='tag' mt='8px'>
              Tag
            </FormLabel>
            <Input width='20%' onBlur={onSelectTag} placeholder={'Tag?'} />
          </HStack>
          <CloudGeneric
            buttonText='Popular tags'
            bodyText={<TagCloud />}
            width='50%'
            onChange={accordionChange}
            closeCloud={closedCloud}
          />
        </VStack>
        {fetching && <Spinner />}

        {display && <Heading size='md'>Results for: {display}</Heading>}

        <NetworkTabs
          graph={graph}
          maxNodes={200}
          clickFriend={clickFriend}
          default_tab={default_tab}
          // networkDisabled={nooUser ? false : true}
          userId={userId}
          total={totalMatches}
        />
        <div className='App-row listing' bg='white'></div>
      </UserAuth>
    </Box>
  );
};

export default ShowRespect;

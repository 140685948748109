import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Heading, Text, Link } from '@chakra-ui/react';
import useFirebaseAuth from 'hooks/useFirebaseAuth';
import useFanAuth from 'hooks/useFanAuth';
import useNoo from 'hooks/useNoo';

const SignInOrRegister = props => {
  const { showHeader } = props;
  const { currentNetworkData } = useNoo();
  // just need to call the hook from here (logged-out user handler)
  // to capture the auto-refresh from google
  useFirebaseAuth();

  // same for fanAuth, will try auto-refresh from the server
  useFanAuth();

  const headingText = currentNetworkData?.pageTitle || currentNetworkData?.display;

  return (
    <Box className='App-row' sx={{ margin: '10px' }}>
      {showHeader && headingText && (
        <Heading as='h4' size='sm'>
          Welcome to {headingText}. Most features require signing in.
        </Heading>
      )}
      <Text>
        Please{' '}
        <Link as={RouterLink} to='/register' color='teal.500'>
          sign in or register
        </Link>{' '}
        to continue
      </Text>
    </Box>
  );
};

SignInOrRegister.defaultProps = {
  showHeader: true
};

export default SignInOrRegister;

import domainAppConfig from 'shared/constants/domainAppConfig';
import { networks } from 'shared/config';

export const getDomainConfig = () => {
  let host = location?.host;
  if (new RegExp('^^[0-9]+.[0-9]+.[0-9]+.[0-9]+').test(host)) host = 'localip';
  if (host) {
    return domainAppConfig(host);
  }
  return null;
};

const domainConfig = getDomainConfig();

export const get_default_network = state => {
  // this condition is effectively turned off for now (see notes in redux/reducers/noo):
  if (state?.noo?.currentNetwork in networks) {
    return state.noo.currentNetwork;
  }
  // switch by domain:
  if (domainConfig?.netName in networks) {
    return domainConfig.netName;
  }
  return Object.keys(networks)[0];
};

const useMultiDomain = () => {
  // console.log('useMultiDomain', domainConfig);
  return {
    domainConfig,
    appSwitcher: !!domainConfig?.appSwitcher,
    HomeRouteComponent: domainConfig?.HomeRouteComponent,
    groupSelector: !!domainConfig?.groupSelector,
    domainAppConfig
  };
};

export default useMultiDomain;

import React from 'react';
import { Text } from '@chakra-ui/react';

export const _6hrs = 1000 * 60 * 60 * 6;
export const _23hours = 1000 * 60 * 60 * 23;
export const _6days = 1000 * 60 * 60 * 24 * 6;
export const _29days = 1000 * 60 * 60 * 24 * 29;

// kent: 'Persons/3e55a613-8e14-4a2b-99d8-f10b5f90fabd'
// brad: ??
export const ME_USER = 'Persons/3e55a613-8e14-4a2b-99d8-f10b5f90fabd';

export const MessageAsComponentTest = () => {
  return (
    <>
      <Text mb='2'>
        Burgdoggen doner ham, tail cupim brisket chuck. Drumstick meatloaf short ribs alcatra
        sausage, pork chop corned beef turducken.
      </Text>
      <Text>
        Fatback drumstick ball tip picanha pancetta. Hamburger beef ribs shank strip steak kevin
        bacon.
      </Text>
    </>
  );
};

export const longTextWithNewlines = `
  Beef sirloin short loin pastrami ham shankle pork loin cupim tail spare ribs.
  
  Prosciutto meatloaf buffalo, kevin jerky boudin alcatra picanha ball tip cupim capicola meatball ground round.
  
  Kielbasa chislic brisket pancetta spare ribs?
  `;

export const demoUserList = [
  { name: 'Kent Steiner', avatar: 'https://i.pravatar.cc/302', online: true },
  { name: 'John Woo', avatar: 'https://i.pravatar.cc/300', away: true },
  { name: 'Brad deGraf', avatar: 'https://i.pravatar.cc/304', online: true },
  { name: 'Gary Busey', avatar: 'https://i.pravatar.cc/306' },
  { name: 'The Rock', avatar: 'https://i.pravatar.cc/308', away: true },
  { name: 'Jane Dough', avatar: 'https://i.pravatar.cc/320', online: true }
];

export const fakeMessagesList = [
  {
    body: 'A message body.',
    up: 10,
    down: 5,
    created: new Date().getTime() - _23hours,
    user: 'foo',
    _id: 1
  },
  {
    body: 'A second message body.',
    up: 10,
    down: 5,
    created: new Date().getTime() - _23hours,
    user: 'foo',
    _id: 2
  },
  { body: 'A message body.', created: new Date().getTime() - _23hours, user: ME_USER, _id: 3 },
  {
    body: longTextWithNewlines,
    up: 10,
    down: 5,
    created: new Date().getTime() - _23hours,
    user: ME_USER,
    _id: 4
  },
  {
    body: 'A third message body.',
    down: 5,
    created: new Date().getTime() - _23hours,
    user: 'foo',
    _id: 5
  },
  {
    body: 'A third message body.',
    up: 7,
    created: new Date().getTime() - _6hrs,
    user: ME_USER,
    _id: 6
  }
];

export const fakeParticipantsList = {
  foo: {
    avatar: 'https://i.pravatar.cc/306',
    name: 'Gary Busey'
  },
  bar: {
    avatar: 'https://i.pravatar.cc/320',
    name: 'Jane Dough'
  },
  ME_USER: {
    name: 'Myself',
    avatar: 'https://i.pravatar.cc/302'
  }
};

import React, { useMemo, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  HStack,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  VStack
} from '@chakra-ui/react';

import useNoo from 'hooks/useNoo';

import { DemoBox, ComponentHeader } from './';
import {
  MessageAsComponentTest,
  longTextWithNewlines,
  demoUserList,
  fakeMessagesList,
  fakeParticipantsList,
  _6hrs,
  _6days,
  _23hours,
  _29days,
  ME_USER
} from './chatDemoData';

import Timestamp from 'components/Chat/Timestamp';
import MessageBody from 'components/Chat/MessageBody';
import MessageContainer from 'components/Chat/MessageContainer';
import UserName from 'components/Chat/UserName';
import ChatAvatar from 'components/Chat/Avatar';
import ParticipantList from 'components/Chat/ParticipantList';
import MessagesList from 'components/Chat/MessagesList';
import MessageInputForm from 'components/Chat/MessageInputForm';
import Container from 'components/Chat/Container';

const ChatComponents = () => {
  const { personId } = useNoo();

  const participants = {};
  Object.entries(fakeParticipantsList).forEach(pair => {
    const id = pair[0] === 'ME_USER' ? personId : pair[0];
    participants[id] = pair[1];
  });

  const [localMsgs, setLocalMsgs] = useState([]);

  const fakeMsgList = useMemo(() => {
    return fakeMessagesList.concat(localMsgs);
  }, [localMsgs]);

  const addMsgToList = msg => {
    if (msg) {
      setLocalMsgs([
        ...localMsgs,
        {
          body: msg,
          created: new Date().getTime(),
          user: Math.random() > 0.5 ? ME_USER : 'foo'
        }
      ]);
    }
  };

  return (
    <Box>
      <Heading as='h1'>Chat Components</Heading>
      <Text>Things to build:</Text>
      <UnorderedList>
        <ListItem style={{ textDecoration: 'line-through' }}>Chat Message box</ListItem>
        <ListItem style={{ textDecoration: 'line-through' }}>Chat Messages list</ListItem>
        <ListItem style={{ textDecoration: 'line-through' }}>Chat participant list</ListItem>
        <ListItem style={{ textDecoration: 'line-through' }}>Message Text</ListItem>
        <ListItem style={{ textDecoration: 'line-through' }}>
          Message header (username + timestamp)
        </ListItem>
        <ListItem style={{ textDecoration: 'line-through' }}>Form w/ button</ListItem>
        <ListItem style={{ textDecoration: 'line-through' }}>Avatar</ListItem>
        <ListItem style={{ textDecoration: 'line-through' }}>User name</ListItem>
        <ListItem style={{ textDecoration: 'line-through' }}>Timestamp</ListItem>
      </UnorderedList>
      <Flex flexWrap='wrap' gap='20px' my='4'>
        <DemoBox>
          <ComponentHeader>Timestamps</ComponentHeader>
          <Timestamp ts={new Date().getTime() - _6hrs} /> (6 hours)
          <br />
          <Timestamp ts={new Date().getTime() - _6days} /> (6 days)
          <br />
          <Timestamp ts={new Date().getTime() - _23hours} /> (23 hours)
          <br />
          <Timestamp ts={new Date().getTime() - _29days} /> (29 days)
        </DemoBox>
        <DemoBox>
          <ComponentHeader>User Names</ComponentHeader>
          <UserName name='Brad deGraf' />
          <UserName name='Kent Steiner' />
          <UserName name='Charles Hasareally Long Name Smith' />
          <UserName />
        </DemoBox>
        <DemoBox>
          <ComponentHeader>Avatars</ComponentHeader>
          <Text fontSize='md' mb='2'>
            showing a few sizes:
          </Text>
          <Stack direction='row' spacing={4}>
            <ChatAvatar name='Kent Steiner' src='https://i.pravatar.cc/302' size='2xs' />
            <ChatAvatar name='John Woo' src='https://i.pravatar.cc/300' size='xs' />
            <ChatAvatar name='Brad deGraf' src='https://i.pravatar.cc/304' size='sm' />
            <ChatAvatar name='Gary Busey' src='https://i.pravatar.cc/306' />
            <ChatAvatar name='The Rock' src='https://i.pravatar.cc/308' size='lg' />
          </Stack>
        </DemoBox>
        <DemoBox>
          <ComponentHeader>Avatars w/ status</ComponentHeader>
          <Stack direction='row' spacing={4}>
            <Box>
              <ChatAvatar name='Brad deGraf' src='https://i.pravatar.cc/401' online />
              <br />
              <Text as='em'>Online</Text>
            </Box>
            <Box>
              <ChatAvatar name='Brad deGraf' src='https://i.pravatar.cc/402' />
              <br />
              <Text as='em'>Offline</Text>
            </Box>
            <Box>
              <ChatAvatar name='Brad deGraf' src='https://i.pravatar.cc/403' away />
              <br />
              <Text as='em'>Away</Text>
            </Box>
          </Stack>
        </DemoBox>
        <DemoBox>
          <ComponentHeader>Avatar List</ComponentHeader>
          <VStack spacing={4} align='left'>
            <ParticipantList users={demoUserList} size='sm' />
            <ParticipantList users={demoUserList} max={2} size='lg' />
            <ParticipantList users={demoUserList} max={4} spacing={-2} />
          </VStack>
        </DemoBox>

        <DemoBox>
          <ComponentHeader>Message Header</ComponentHeader>
          <Flex gap={2} mb='20px' pb='20px' borderBottomWidth='1px' borderBottomColor='gray.300'>
            <ChatAvatar name='John Woo' src='https://i.pravatar.cc/300' size='md' online />
            <Flex flexDir='row' gap='6px'>
              <UserName name='John Woo' />
              <Timestamp as='p' ts={new Date().getTime() - _23hours} lineHeight='1.8em' />
            </Flex>
          </Flex>
          <Flex gap={2} mb='20px' pb='20px' borderBottomWidth='1px' borderBottomColor='gray.300'>
            <ChatAvatar name='The Rock' src='https://i.pravatar.cc/308' size='lg' />
            <Flex flexDir='column'>
              <UserName name='Charles Hasareally Long Name Smith' />
              <Timestamp ts={new Date().getTime() - _23hours} />
            </Flex>
          </Flex>
        </DemoBox>

        <DemoBox>
          <ComponentHeader>Message Body</ComponentHeader>
          <MessageBody body='some demo short text' />
          <hr />
          <Text>Body as component:</Text>
          <MessageBody body={<MessageAsComponentTest />} />
          <hr />
          <Text>Body with new lines auto-split:</Text>
          <MessageBody body={longTextWithNewlines} />
        </DemoBox>

        <DemoBox>
          <ComponentHeader>Message Containers</ComponentHeader>
          <MessageContainer
            isFirst
            message={{
              body: 'A message body (isFirst).',
              created: new Date().getTime() - _23hours,
              user: 'foo'
            }}
          />

          <MessageContainer
            isFirst
            message={{
              body: longTextWithNewlines,
              created: new Date().getTime() - _23hours,
              user: ME_USER
            }}
          />
          <MessageContainer
            isLast
            message={{
              body: 'A third message body (isLast).',
              created: new Date().getTime() - _6hrs,
              user: ME_USER
            }}
          />
        </DemoBox>

        <DemoBox>
          <ComponentHeader>Composed Message Exploration</ComponentHeader>
          <VStack align='left'>
            <Flex gap={2} pb='12px' borderBottomWidth='1px' borderBottomColor='gray.300'>
              <ChatAvatar name='John Woo' src='https://i.pravatar.cc/300' size='md' online />
              <Flex flexDir='column'>
                <UserName name='John Woo' />
                <Timestamp ts={new Date().getTime() - _23hours} />
              </Flex>
            </Flex>
            <MessageBody body={<MessageAsComponentTest />} />
          </VStack>

          {/* left off here, make two color-background boxes that simulate a thread view, one for "me" and one not */}
          <HStack align='left'></HStack>
        </DemoBox>

        <DemoBox>
          <ComponentHeader>Messages List</ComponentHeader>
          <MessagesList messages={fakeMessagesList} participants={participants} />
        </DemoBox>

        <DemoBox>
          <ComponentHeader>Chat Input Form</ComponentHeader>
          <MessageInputForm
            onSend={msg => {
              alert(`got the message! ${msg}`);
            }}
          />
        </DemoBox>

        <DemoBox>
          <ComponentHeader>All Together Now</ComponentHeader>
          <Box>
            <MessagesList messages={fakeMsgList} participants={participants} />
            <MessageInputForm
              onSend={msg => {
                // alert(`got the message! ${msg}`);
                addMsgToList(msg);
              }}
            />
          </Box>
        </DemoBox>
        <DemoBox>
          <ComponentHeader>Chat Wrapper</ComponentHeader>
          <Container contentId='Decision/123abc' showParticipants maxHeight='300px' />
        </DemoBox>
      </Flex>
    </Box>
  );
};

export default ChatComponents;

import React, { useMemo, useState } from 'react';
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, Tooltip } from '@chakra-ui/react';
import useNooUi from 'hooks/useNooUi';
import { objectSort } from 'utils';

const _defaultCellPadding = {
  pb: '4px',
  mb: '4px',
  px: '8px'
};

const _defaultResultsCellSx = {
  borderBottom: '1px solid #eee',
  verticleAlign: 'top',
  ..._defaultCellPadding
};

const _defaultHeadRowSx = combinedTheme => ({
  cursor: 'pointer',
  backgroundColor: combinedTheme?.colors?.primaryDark || '#444',
  color: '#fff'
});

const _defaultResultsRowSx = combinedTheme => ({
  cursor: 'pointer',
  backgroundColor: combinedTheme?.colors?.primaryDark || '#444',
  color: '#fff'
});

const SortableTable = props => {
  const { combinedTheme } = useNooUi();
  const { rows, columns, colGroup, kind, sortCol } = props;
  let { headCellSx = {}, headRowSx = {}, resultsCellSx = {}, resultsRowSx = {} } = props;
  headRowSx = { ..._defaultHeadRowSx(combinedTheme), ...headRowSx };
  headCellSx = { ..._defaultCellPadding, ...headCellSx };
  resultsRowSx = { ..._defaultResultsRowSx, ...resultsRowSx };
  resultsCellSx = { ..._defaultResultsCellSx, ...resultsCellSx };
  const [sortDir, setSortDir] = useState('asc');
  const [sortColumn, setSortColumn] = useState(columns?.[sortCol || 0].key);

  const sortedRows = useMemo(() => {
    if (rows?.length) {
      let mySortColumn = sortColumn;
      if (rows[0][mySortColumn + 'Sorter'] != null) {
        mySortColumn += 'Sorter';
      }
      const sorted = sortColumn ? rows.sort(objectSort(mySortColumn)) : rows;
      switch (sortDir) {
        case 'desc':
          return sorted.reverse();
        default:
          return sorted;
      }
    }
    return [];
  }, [rows, sortDir, sortColumn]);

  const sortColumns = (colKey, e) => {
    const myColumn = columns.find(C => C.key === colKey);
    if (!myColumn.sortable) {
      return;
    }
    if (sortColumn && sortColumn === colKey) {
      // toggle direction
      const whichWay = sortDir === 'desc' ? 'asc' : 'desc';
      setSortDir(whichWay);
    } else {
      // new column sort, default asc
      setSortColumn(colKey);
      setSortDir('asc');
    }
  };

  // using sx here because of eslint complaint about borderCollapse vs bordercollapse (lower case "c")
  // <Tooltip label={'row ' + idx} key={'tt_' + idx} placement={'left'}> </Tooltip>

  const ellipsis = value => {
    if (value && typeof value == 'string' && value.length > 50) {
      return <Tooltip label={value}>{value.slice(0, 47) + '...'}</Tooltip>;
    }
    return value;
  };
  const row_colors_by_visibility = { me: '#eadada', group: '#daeada', all: '#eaeada' };
  return (
    <Table bordercollapse='collapse'>
      <Thead data-testid='sortable-table-head'>
        <Tr className={kind} sx={headRowSx}>
          {columns?.map((C, idx) => {
            return (
              <Td key={idx} onClick={sortColumns.bind(null, C.key)} sx={headCellSx}>
                {C.name}
              </Td>
            );
          })}
        </Tr>
      </Thead>
      <Tbody data-testid='sortable-table-body'>
        {sortedRows.map((R, idx) => {
          let color = row_colors_by_visibility[R.vis || ''];
          let visSx = color ? { backgroundColor: color } : {};
          return (
            <Tr key={idx} sx={{ ...resultsRowSx, ...visSx }}>
              {columns.map((C, idx2) => {
                const _sx = { ...resultsCellSx, ...(C.sx || {}) };
                const val = R[C.key] || '';
                return (
                  <Td key={idx2} sx={_sx} className={kind}>
                    {ellipsis(val)}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
      {colGroup}
    </Table>
  );
};

export default SortableTable;

import VennDiagram from 'components/DataViz/Venn/VennDiagram';
import VennMockData from '../DataViz/Venn/venn_mock.json';

import React, { useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, HStack, VStack, Heading } from '@chakra-ui/react';
import styled from '@emotion/styled';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import UserAuth from 'components/User/UserAuth';
import NetworkTabs from 'components/Networks/NetworkTabs';
import ProfilesResults from 'components/Search/Results/Profiles';
import usePeopleNetwork from 'hooks/usePeopleNetwork';
import PeopleSelector from 'components/Segments/PeopleSelector';

const VennCommunities = props => {
  const { nooUser, currentNetworkData, currentNetworkDataRaw } = useNoo('CommunityModule');
  const [groupName, setGroupName] = useState('');
  const [header, setHeader] = useState(null);
  const [description, setDescription] = useState('');
  const [communities, setCommunities] = useState(null);
  const [allSets, setAllSets] = useState(null);
  const [subsets, setSubsets] = useState(null);
  const [redraw, setRedraw] = useState(false);
  const { nooCommandDirect, nooCommandIsProcessingDirect } = useNooApi();
  const {
    currentNetwork: graph,
    isRequesting: fetching,
    getSpecificUsers,
    totalMatches,
    getPenumbra
  } = usePeopleNetwork();

  const boxSx = {
    border: '1px solid #ccc',
    flex: '1 1 auto',
    padding: '10px',
    width: '100%',
    color: '#888888'
  };

  const getCommunities = useEffect(() => {
    if (nooUser && !communities) {
      console.log('getting communities');
      const payload = {
        query_name: 'aqlGetDocumentByUrl',
        data: { url: 'file://community_members.json' }
      };
      const params = {
        payload,
        setLoading: () => {},
        setResponse: response => {
          try {
            const comms = response.result[0].document[0].data.communities;
            setCommunities(comms);
          } catch (e) {
            console.log('error getting communities doc:', e);
          }
        },
        setResponseError: error => {
          console.log('error ', error);
        }
      };
      nooCommandDirect('aql', params);
    }
  }, [communities, nooCommandDirect, nooUser]);

  const handleHover = (e, obj) => {
    if (obj?.label) {
      // setGroupName(obj.label); // NB: CAUSING RE_RENDER OF VENN DIAGRAM, WHY?
      // setDescription(obj.description);
    } else {
      // setGroupName(null);
      // setDescription(null);
    }
  };

  const networkOfChecked = useCallback(
    selected => {
      console.log('getting network', selected, fetching);
      if (!fetching && selected?.length > 0) {
        getPenumbra({ pids: selected, limit: 200, depth: 1 });
      }
    },
    [fetching, getPenumbra]
  );

  const eqSet = (xs, ys) => xs.size === ys.size && [...xs].every(x => ys.has(x));

  const getSet = k => {
    const found = _.find(allSets.sets, it => {
      return it.set == k;
    });
    return found;
  };

  const handleClick = (e, obj) => {
    console.log('click', obj);
    if (obj?.label) {
      setHeader(obj.label);
      const method = 0; // 1 is more correct but 0 looks better
      const my_set = obj.set;
      let containing = _.filter(allSets.sets, one => {
        // any intersection containing me
        return one.sets.length > 1 && one.sets.includes(my_set);
      });
      const setnumbers = _.uniq(
        _.flatten(
          _.map(containing, one => {
            return one.sets;
          })
        )
      );
      let threes = _.filter(allSets.sets, one => {
        // any intersection containing me
        return one.sets.length == 3 && one.sets.includes(my_set);
      });
      const circles = _.filter(allSets.sets, one => {
        return one.set == my_set || setnumbers.includes(one.set);
      });

      containing = _.filter(allSets.sets, one => {
        // include containing all setnumbers
        const all_three = one.sets.length == 3 && one.sets.includes(my_set);
        const my_twos =
          one.sets.length == 2 &&
          _.every(one.sets, num => {
            return setnumbers.includes(num);
          }) &&
          one.sets.includes(my_set);

        return method == 0
          ? one.sets.length > 1 &&
              _.every(one.sets, num => {
                return setnumbers.includes(num);
              })
          : all_three || my_twos;
      });
      console.log('containing', containing);
      const sets = _.concat(circles, containing);
      setSubsets({ sets });
      // setRedraw(true);
    } else {
      const this_one = obj.sets;
      const eq = _.find(allSets.sets, one => {
        return eqSet(new Set(one.sets), new Set(this_one));
      });
      const groups = _.map(eq.sets, k => {
        return getSet(k);
      });
      const gnames = _.map(groups, 'label');
      setHeader(gnames.join(' ∩ '));
      networkOfChecked(_.map(eq.intersection, _key => 'Persons/' + _key));
    }
    // networkOfChecked
  };

  useEffect(() => {
    console.log('after graph', graph);
  }, [graph]);

  useEffect(() => {
    if (communities && !allSets) {
      console.log('computing sets');
      let sets = [];
      let k = 0;
      Object.keys(communities).forEach((gid, i) => {
        const data = communities[gid];
        const membs = data.members;
        const count = membs.length;
        if (count > 10) {
          let one = {
            label: data.name,
            size: count,
            id: gid,
            description: data.description
          };
          const color_scheme = data.colors;
          if (color_scheme) one.colors = color_scheme;
          sets.push(one);
          k += 1;
        }
      });

      sets = _.sortBy(sets, one => {
        return -one.size;
      });
      sets.forEach((one, i) => {
        one.sets = [i];
        one.set = i;
      });

      const end = sets.length;
      const gids = _.map(sets, set => set.id);
      gids.forEach((one, i) => {
        let inter = 10;
        // for j,two in enumerate(sets[:end]):
        gids.forEach((two, j) => {
          if (j > i) {
            const grp1 = communities[one];
            const grp2 = communities[two];
            try {
              const membs1 = grp1.members || [];
              const membs2 = grp2.members || [];
              inter = membs1.filter(value => membs2.includes(value));
              const len = inter.length;
              if (len > 1) {
                sets.push({ sets: [i, j], size: len, intersection: inter });
                gids.forEach((three, k) => {
                  if (k > j) {
                    const grp3 = communities[three];
                    try {
                      const membs3 = grp3.members || [];
                      const inter2 = inter.filter(value => membs3.includes(value));
                      const len2 = inter2.length;
                      if (len2 > 1)
                        sets.push({ sets: [i, j, k], size: len2, intersection: inter2 });
                    } catch (e) {
                      console.log('bad', i, j, k, one, two, three);
                    }
                  }
                });
              }
            } catch (e) {
              console.log('bad', i, j, one.id, two.id);
            }
          }
        });
      });
      console.log('setting all');
      setAllSets({ sets });
      setSubsets({ sets });
    }
  }, [communities, allSets]);

  const onSelectUser = e => {
    console.log('sel u', e);
  };

  console.log('subsets', subsets);
  return (
    <Box>
      <UserAuth unauthorized='signin'>
        <Box className='App-row'>
          <HStack>
            <VStack sx={{ ...boxSx }}>
              <PeopleSelector
                onSelect={onSelectUser}
                inputPlaceholder={'Name as you know them...'}
                label={"Check someone's membership"}
              />
              {subsets && (
                <VennDiagram data={subsets} onClick={handleClick} onHover={handleHover} />
              )}
              {groupName && <Heading size='lg'>{groupName}</Heading>}
              {description && <Heading size='sm'>{description}</Heading>}
              {header && <Heading size='md'>{header}</Heading>}
              {graph && <ProfilesResults data={graph} kind={'profiles'} />}
            </VStack>
          </HStack>
        </Box>
        <div id='venntooltip'></div>
      </UserAuth>
    </Box>
  );
};

export default VennCommunities;

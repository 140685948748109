const markdown = `
#### Collaborative Mapping of Global Regeneration Expertise 
Who knows the many dimensions of regeneration around the world? We do, of course, but that knowledge is locked in our heads and contact lists, inaccessible to open software.

#### What

***Who Knows***  is an *expertise network coooperative*, mapping global regeneration expertise as a shared resource for new levels of collective coordination, agency, sensemaking, resource allocation, etc. Starting with our [Bioregional Challenge](/bioregions).

#### Challenge
The **regeneration movement is millions strong**
with deep, diverse knowledge and expertise
across many dimensions
e.g. OneEarth’s  [Climate Solutions](https://www.oneearth.org/solutions/) and [Bioregions](https://www.oneearth.org/navigator/), [Regeneration/nexus](https://regeneration.org/),...,
and many communities
e.g. [GreenWorld](http://greenworld.org/), [Hypha](https://hypha.earth/), [Gaianet](https://www.gaianet.earth/), [Global Regeneration Colab](https://www.grc.earth/), [Kinship.earth](https://kinshipearth.org/), [Kernel](https://www.kernel.community/en/), [DWeb](https://dwebcamp.org/), many others


Yet little of that expertise is easily accessible and shareable e.g. "*Who knows solar in the AfroTropics?*"

#### Opportunity
That large cohort is highly networked
and richly informed about who knows what, and where.

But that knowledge is primarily **inaccessible to software**
i.e. in people’s heads, rolodexes, personal silos.

What if each of us could contribute our unique knowledge 
about our peers’ expertise and relationships
to a ‘**network cooperative**’ that owns and governs itself

#### Get involved

Help make that **vast collective knowledge** in our heads
machine-readable, as a decentralized, self-sovereign **shared resource**.

And thereby create a highly valuable **substrate for effective and efficient global coordination**.

Start by helping with our [Bioregional Challenge](/bioregions). Point to the people you know who know regeneration all over the world.

#### About
***WhoKnows*** is an initiative of [NooNAO](https://nao.is/), a non-profit, fiscally-sponsored project of [PlanetWork](https://www.planetwork.net/).

           
`;

export default markdown;

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  bioregionFetchError,
  setBioregionData,
  setBioregionIsFetching
} from 'redux/actions/bioregions';

const useBioregionData = () => {
  const dispatch = useDispatch();
  const fetchErrors = useSelector(state => state.bioregions?.fetchErrors);
  const all_93_region = useSelector(state => state.bioregions?.data?.all_93);
  const all_94_185_region = useSelector(state => state.bioregions?.data?.all_94_185);
  const land_50m_region = useSelector(state => state.bioregions?.data?.land_50m);

  // load all_93 region
  useEffect(() => {
    async function run() {
      const segment = 'all_93';
      console.log('fetching ', segment);
      console.log([
        !!all_93_region,
        !!all_93_region?.data,
        !!all_93_region?.isFetching,
        !!fetchErrors.all_93
      ]);
      try {
        dispatch(setBioregionIsFetching(segment));
        const response = await fetch('/data/bioregions?segment=' + segment);
        if (response.ok) {
          const data = await response.json();
          dispatch(setBioregionData({ segment, data }));
        } else {
          dispatch(bioregionFetchError({ segment, error: response.status }));
        }
      } catch (error) {
        dispatch(bioregionFetchError({ segment, error }));
      }
    }
    if (
      !all_93_region ||
      (!all_93_region.data && !all_93_region.isFetching && !fetchErrors.all_93)
    ) {
      run();
    }
  }, [all_93_region, dispatch, fetchErrors.all_93]);

  // load all_94_185 region
  useEffect(() => {
    async function run() {
      const segment = 'all_94_185';
      try {
        dispatch(setBioregionIsFetching(segment));
        const response = await fetch('/data/bioregions?segment=' + segment);
        if (response.ok) {
          const data = await response.json();
          dispatch(setBioregionData({ segment, data }));
        } else {
          dispatch(bioregionFetchError({ segment, error: response.status }));
        }
      } catch (error) {
        dispatch(bioregionFetchError({ segment, error }));
      }
    }
    if (
      !all_94_185_region ||
      (!all_94_185_region.data && !all_94_185_region.isFetching && !fetchErrors.all_94_185)
    ) {
      run();
    }
  }, [all_94_185_region, dispatch, fetchErrors.all_94_185]);

  // load land_50m region
  useEffect(() => {
    async function run() {
      const segment = 'land_50m';
      try {
        dispatch(setBioregionIsFetching(segment));
        const response = await fetch('/data/bioregions?segment=' + segment);
        if (response.ok) {
          const data = await response.json();
          dispatch(setBioregionData({ segment, data }));
        } else {
          dispatch(bioregionFetchError({ segment, error: response.status }));
        }
      } catch (error) {
        dispatch(bioregionFetchError({ segment, error }));
      }
    }
    if (
      !land_50m_region ||
      (!land_50m_region.data && !land_50m_region.isFetching && !fetchErrors.land_50m)
    ) {
      run();
    }
  }, [dispatch, fetchErrors.land_50m, land_50m_region]);

  return {
    all_93: all_93_region?.data,
    all_94_185: all_94_185_region?.data,
    land_50m: land_50m_region?.data,
    all_93_isFetching: !!all_93_region?.isFetching,
    all_94_185_isFetching: !!all_94_185_region?.isFetching,
    land_50m_isFetching: !!land_50m_region?.isFetching,
    fetchErrors
  };
};

export default useBioregionData;

import {
  BOOKMARK_HEADLINE_REQUEST_START,
  BOOKMARK_HEADLINE_COMPLETE,
  BOOKMARK_HEADLINE_ERROR,
  SET_BOOKMARKED_URL_DATA,
  CLEAR_BOOKMARKED_URL_DATA
} from 'shared/constants/actionTypes';

const initialState = {
  isBookmarkingHeadline: false,
  lastBookmarkedUrlData: null,
  lastBookmarkedUrlMetadata: null,
  bookmarkHeadlineResponse: null,
  bookmarkHeadlineResponseError: null
};

const headlines = function (state = initialState, action) {
  // const _state = { ...state };
  switch (action.type) {
    case BOOKMARK_HEADLINE_REQUEST_START:
      return {
        ...state,
        isBookmarkingHeadline: true,
        lastBookmarkedUrlData: null,
        lastBookmarkedUrlMetadata: null,
        bookmarkHeadlineResponse: null,
        bookmarkHeadlineResponseError: null
      };

    case SET_BOOKMARKED_URL_DATA:
      // console.log('set bk', action.payload?.data);
      return {
        ...state,
        isBookmarkingHeadline: false,
        lastBookmarkedUrlData: action.payload?.data,
        lastBookmarkedUrlMetadata: action.payload?.meta
      };

    case BOOKMARK_HEADLINE_COMPLETE:
      return {
        ...state,
        isBookmarkingHeadline: false,
        bookmarkHeadlineResponse: action.payload,
        bookmarkHeadlineResponseError: null
        //   lastBookmarkedUrlData: action.payload?.data,
        //   lastBookmarkedUrlMetadata: action.payload?.meta
      };

    case BOOKMARK_HEADLINE_ERROR:
      return {
        ...state,
        isBookmarkingHeadline: false,
        bookmarkHeadlineResponse: null,
        bookmarkHeadlineResponseError: action.payload
        //   lastBookmarkedUrlData: action.payload?.data,
        //   lastBookmarkedUrlMetadata: action.payload?.meta
      };

    case CLEAR_BOOKMARKED_URL_DATA:
      return {
        ...state,
        isBookmarkingHeadline: false,
        lastBookmarkedUrlData: null,
        lastBookmarkedUrlMetadata: null,
        bookmarkHeadlineResponse: null,
        bookmarkHeadlineResponseError: null
      };

    default:
      return state;
  }
};

export default headlines;

import React from 'react';
import { useEffect, useMemo, useState, useRef } from 'react';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Link,
  Checkbox,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  HStack,
  Text
} from '@chakra-ui/react';
import { nodesAndEdgesNoGenerations } from 'utils/nodesAndEdges';
import SortableTable from 'components/Segments/SortableTable';
import useSearchResults, { ResultLink } from 'hooks/useSearchResults';
import { node_normalizer, get_profile } from 'utils/graphNormalize';
import LocalStorage from 'utils/LocalStorage';
import useNoo from 'hooks/useNoo';
import _SERVICES from 'shared/constants/socialServices';

import { norm_mastodon } from 'utils';
import styled from '@emotion/styled';
export const ImageConstrainer = styled.div`
  > img {
    max-width: 100%;
    border: 1px solid #000;
    margin-top: 10px;
  }
`;

const UserCard = props => {
  const clickFollow = e => {
    console.log(e);
  };
  const default_order = [
    'avatar',
    'fullname',
    'description',
    'location',
    'mastodon',
    'linkedin',
    'facebook',
    'twitter'
  ];
  const alts = { fullname: 'displayName' };

  let { data, fields, order, node, default_server } = props;
  if (!data) {
    return null;
  }
  default_server = default_server || 'mathstodon.xyz';
  order = order || default_order;
  let imageUrl;
  const profile = node || get_profile(data);
  const normed = node_normalizer(profile);
  const displayData = [];
  order.forEach(field => {
    if (normed[field]) {
      if (field == 'avatar' && normed[field]) {
        imageUrl = normed[field];
      } else if (!['linkedin', 'facebook', 'twitter', 'mastodon'].includes(field)) {
        const it = normed[field];
        let uname = typeof it == 'string' ? it : it.username;
        if (uname) {
          displayData.push({ type: field, value: uname });
        }
      } else {
        let alt = normed[field]?.username || normed[field]?.handle;
        if (alt) {
          alt = alt.replace(/@$/, ''); // some erroneous ending @s
          displayData.push({ type: field, value: alt });
        } else {
          console.log('no uname', field, normed);
        }
      }
    }
  });

  const mastodon_link = uname => {
    let address = norm_mastodon(uname, 'name');
    const ind = address.indexOf('@' + default_server);
    if (ind >= 0) {
      // searches don't work if address is within the server
      address = address.replace('@' + default_server, '');
    }
    const link = 'https://' + default_server + '/' + address;
    return link;
  };

  return (
    <ImageConstrainer sx={{ borderTop: '4px solid #eee', pt: '10px', mt: '10px' }}>
      {imageUrl && <img src={imageUrl} title={normed.name} />}
      {displayData.map((node, idx) => {
        const isName = node.type === 'displayName';
        const weight = isName ? 'bold' : 'normal';
        if (['linkedin', 'facebook', 'twitter', 'mastodon'].includes(node.type)) {
          const svc = node.type;
          let url =
            svc == 'mastodon'
              ? mastodon_link(node.value)
              : 'https://' + _SERVICES[svc] + '/' + (svc == 'linkedin' ? 'in/' : '') + node.value;
          const disp = {
            linkedin: 'LinkedIn',
            facebook: 'Facebook',
            twitter: 'Twitter',
            mastodon: 'Mastodon'
          }[svc];
          return (
            <div key={idx}>
              <Button
                size='xs'
                alt={node.value}
                title={node.value}
                mr='8px'
                mb='8px'
                borderRadius='4px'
                backgroundColor='cyan.200'
                color='cyan.900'
              >
                <Link target='_noo' href={url} fontSize='14px'>
                  {disp + ' ' + (svc == 'twitter' ? '@' : '') + node.value}
                </Link>
              </Button>
            </div>
          );
        } else {
          return <div key={idx}>{node.value}</div>;
        }
      })}
    </ImageConstrainer>
  );
};

function PopOverMast(props) {
  const clickUser = e => {
    // setKeepOpen(!keepOpen);
    const personId = e.target.dataset.id.replace('Persons/', '');
    history.push('/user/' + personId);
  };

  const {
    node,
    _id,
    shrink,
    order = ['avatar', 'location', 'mastodon', 'twitter'],
    default_server
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);
  // const ukey = node?._id ? node._id.replace('Persons/', '') : '';
  const idd = node?._id || node?.id;
  const ukey = idd ? idd.replace('Persons/', '') : '';
  const onClick = props.onClick || clickUser;
  const fullname = node.fullname || node.displayName;
  const fn = fullname.length > 20 ? fullname.slice(0, 17) + '...' : fullname;
  node.fullname = fullname;
  return (
    <>
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={close}
        placement='right'
        closeOnBlur={true}
      >
        <PopoverTrigger>
          <Button
            data-id={_id}
            data-fullname={fullname}
            mr={5}
            onMouseEnter={open}
            onClick={props.onClick || clickUser}
            size='sm'
            m='4px 6px'
            backgroundColor='gray.200'
            color='blue.700'
            _hover={{
              backgroundColor: 'cyan.600',
              color: 'cyan.100'
            }}
          >
            {fn}
          </Button>
        </PopoverTrigger>
        <PopoverContent width={shrink ? 'auto' : undefined}>
          <PopoverCloseButton />
          <PopoverHeader fontWeight='semibold' pr={shrink ? '10' : undefined}>
            <Text>{fullname}</Text>
          </PopoverHeader>
          <PopoverArrow />
          <PopoverBody>
            <UserCard data={node} order={order} node={node} default_server={default_server} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
}

const SearchResultsProfiles = props => {
  const kind = 'profiles_twitter';
  const { data, topic, personId, onChecked, server } = props;
  const [parsedData, setParsedData] = useState(null);
  const { currentNetwork: netname, nooUser } = useNoo('ProfilesTwitter');
  const [myStorage, setMyStorage] = useState(new LocalStorage(netname, nooUser?.person?._id));

  const {
    defaultColumnProperties,
    map_column_title,
    map_column_sx,
    get_columns
  } = useSearchResults(kind);

  useEffect(() => {
    if (data) {
      const it = data?.result?.[0] || data;
      setParsedData(it);
    }
  }, [data, setParsedData]);

  const profiles = useMemo(() => {
    const onCheckUser = e => {
      const idd = e.target.value;
      const is_checked = e.target.checked;
      let myFollows = myStorage.get('checked') || [];
      if (is_checked && !myFollows.includes(idd)) {
        myFollows.push(idd);
      } else if (myFollows.includes(idd) && !is_checked) {
        const indx = myFollows.indexOf(idd);
        myFollows.splice(indx, 1);
      }
      myStorage.set('checked', myFollows);
      console.log('myfol', myFollows);
      onChecked();
    };
    let nodes_out = [];
    if (parsedData?.profiles) {
      const [nodes] = nodesAndEdgesNoGenerations(parsedData);
      nodes_out = nodes.filter(P => P !== null);
    } else if (parsedData?.nodes) {
      const links = parsedData.links;
      const myFollows = myStorage.get('checked') || [];
      nodes_out = parsedData.nodes
        .filter(P => P !== null && P.mastodon != null)
        .map(nd => {
          let node = { ...nd };
          const pid = node._id || node.id;
          const targets = links.filter(link => link.source == pid).map(link => link.target);
          const sources = links.filter(link => link.target == pid).map(link => link.source);
          node.in_count = node.followers || node.in_count || node.ins?.length;
          node.in_countSorter = -node.in_count || -node.ins?.length || -node.ins;
          node.insSorter = -node.ins;
          node.outs = node.following || targets.length;
          node.degree = _.includes(sources, personId) || _.includes(targets, personId) ? 1 : null;
          const dest = 'https://linkedin.com/in/' + node.handle;
          const imgsrc = node.image;
          node.pic = <img src={imgsrc} title={node.username} width={'50px'} height={'50px'} />;
          node.is_checked = myFollows.includes(pid);
          node.fullname_link = (
            <PopOverMast
              key={pid}
              _id={pid}
              node={node}
              order={['location', 'mastodon', 'twitter']}
              onClick={event => {
                props.onclick({
                  _id: event.target.dataset.id,
                  fullname: event.target.dataset.fullname
                });
              }}
              default_server={server}
            />
          );
          node.checkbox = (
            <Checkbox
              onChange={onCheckUser}
              colorScheme='green'
              size='md'
              name={'users'}
              defaultChecked={myFollows.includes(pid)}
              key={pid}
              value={pid}
            ></Checkbox>
          );
          node.checkboxSorter = !node.is_checked;
          node.fullname_linkSorter = node.fullname.toLowerCase();
          return node;
        });
    }
    if (nodes_out) {
      return nodes_out.map(node => {
        return node;
      });
    }
    return null;
  }, [parsedData, personId, props, myStorage, onChecked, server]);

  const cols = useMemo(() => {
    if (profiles?.length) {
      return get_columns(profiles[0]).map(key => {
        return {
          ...defaultColumnProperties,
          key: key,
          name: map_column_title(_.capitalize(key), kind),
          sx: map_column_sx(key, kind)
        };
      });
    }
    return [];
  }, [defaultColumnProperties, get_columns, profiles, map_column_sx, map_column_title]);

  if (!_.size(profiles)) {
    return null;
  }
  return (
    <Box mt='20px'>
      <SortableTable columns={cols} rows={profiles} sortCol={1} />
    </Box>
  );
};

export default SearchResultsProfiles;

import React, { useState, useHistory } from 'react';
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Link,
  Image,
  Box,
  Text
} from '@chakra-ui/react';

export default function PopoverNode(props) {
  const { node } = props;
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);
  // const ukey = node?._id ? node._id.replace('Persons/', '') : '';
  const idd = node?._id || node?.id;
  const title = node.name;
  const images = node.images || [];
  const img = node.map; // images?.length > 0 ? 'https://images.takeshape.io/' + images[0].path : null;
  return (
    <>
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={close}
        placement='bottom'
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <div>
            <Button
              data-fullname={title}
              mr={5}
              onClick={open}
              onMouseEnter={props.onHover}
              // onClick={props.onClick || clickUser}
              size='sm'
              m='4px 6px'
              backgroundColor='gray.200'
              color='blue.700'
              _hover={{
                backgroundColor: 'cyan.600',
                color: 'cyan.100'
              }}
            >
              {title}
            </Button>
            {img && (
              <Image
                src={img}
                alt=''
                objectFit='cover'
                height={'200px'}
                borderRadius='10px'
                mt={[undefined, '4px']}
                mb={[undefined, '4px']}
              />
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent bg='#eee' borderColor='#333' borderWidth='1px' width={'auto'}>
          <PopoverCloseButton />
          <PopoverHeader fontWeight='semibold' pr={'10'}></PopoverHeader>
          <PopoverArrow />
          <PopoverBody>
            <Link>{title}</Link>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
}

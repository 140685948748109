const markdown = `
***Blue Swarm*** is an immune response to assaults on democracy. 

[Let's get to it](/search_decisions-old/fba25f40-eb6a-4379-9c98-817397b8f3e0)

Face it. The far-right is a [small percentage of the U.S. population](https://fortune.com/2018/10/22/far-right-americans-just-six-person-study-says/), yet it has taken over half of the two-party system. Not good. They have three massive advantages that thwart policies desired by signficant majorities:

* The [U.S. Senate is unfixable](https://www.vox.com/mischiefs-of-faction/2019/4/9/18300749/senate-problem-electoral-college); “the Democratic half of the Senate represents [41,549,808 more people](https://www.minnpost.com/eric-black-ink/2021/02/u-s-senate-representation-is-deeply-undemocratic-and-cannot-be-changed/) than the Republican half” and  "we have no viable way to address most of these problems within our constitutional framework."
* The [1% are right-wing](https://fivethirtyeight.com/features/the-politics-of-the-top-1-percent/), and [they get what they want](https://www.bbc.com/news/blogs-echochambers-27074746).
* [Rupert Murdoch](https://www.nytimes.com/interactive/2019/04/03/magazine/murdoch-family-investigation.html)

This causes a [doom loop](https://www.theatlantic.com/ideas/archive/2021/04/how-stop-minority-rule-doom-loop/618536/) of voter suppression, gerrymandering, corruption of the education system, a compromised judiciary, and more.

But there are way more of us, and we're smarter. So we have to become a *smart swarm* and overwhelm them. The feckless Democratic Party isn't going to do it for us.

### Our purpose

BlueSwarm's purpose is to empower progressive majorities with the impact and influence we deserve, using the latest in *decentralized decision-making and governance*.

Our near-term goal is to help grow the Democratic majorities in both houses and progressive successes at state and local levels. 

We do that by maximizing the energy and money of millions of progressive Americans who are horrified by right-wing assaults on democracy itself, but baffled about what to do.

It's time to claim our collective agency by using our numbers strategically. BlueSwarm wants to help.

### Our means

We do it by tapping into the collective intelligence of a fractal network of progressive smartypants. It grows  by invitation, with subnetworks at all scales from local to national to global. 

Those overlapping networks use the latest in *liquid democracy* to dramatically optimize resource allocation, channeling energy and money where it is most needed.

[ActBlue](https://secure.actblue.com/), [Run for Something](https://runforsomething.net/), [Justice Democrats](https://justicedemocrats.com/), [Brand New Congress](https://www.brandnewcongress.org/), [SwingLeft](https://swingleft.org/), and others are doing great work, and have made it much easier for us all to contribute to helping great candidates win. BlueSwarm facilitates your support of them.

But there are still way too many options, making it daunting to decide where to put our money and energy with the confidence it's going where it's most needed.

BlueSwarm augments those great efforts by collectivizing the knowledge of experts to sort the many options by impact.

### Specifics
We start with progressive thought leaders who are the *national kernel* of the BlueSwarm network. They invite their peers, forming the *national network*. 

Subnetworks are created for each state, and their *kernels* are started by invitation from the *national network*. 

Eventually, the state networks create local level networks and populate them.

At all levels, our *Decision Service* makes it easy to collectively propose and sort the best races deserving of financial and organizational support.

### Execution
Powered by NooNet

#### Multi-level networks

We will auto-create NooNet *groups* for national and state focus. Local focus will come organically later.

##### National
Create a NooNet group *National*. Invite a few dozen progressive thought leaders and get them to invite others.

Create NooNet *Decisions* for *Senate 2022* and *House 2022* for prioritizing races by impact.

##### State
Create NooNet groups for all states, initiated with local thought leaders known by the national network.

Start with Pennsylvania, because it is a bellwether and we have connections there, e.g. [Chris Rabb](https://www.pahouse.com/Rabb/), [Tom Laskawy](https://muckrack.com/tlaskawy), and [Julie Lynch](https://web.sas.upenn.edu/jflynch/).

Create *Decisions* for statewide races, e.g. governor, primaries, etc.
`;

export default markdown;

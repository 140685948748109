class LocalStorage {
  constructor(netName, userId) {
    this.netName = netName;
    this.userId = userId || 'user';
    this.data = {};
    // this.sync();
  }
  getItem(key) {
    // verbatim but with JSON in and out
    try {
      const val = localStorage.getItem(key);
      return val ? JSON.parse(val) : null;
    } catch (e) {
      console.error('error getting', key, 'from localstorage', e);
      return null;
    }
  }
  setItem(key, value) {
    // verbatim but with JSON in and out
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error('error setting', key, 'to localstorage', e);
    }
  }
  pathOf(key) {
    return [this.netName, this.userId, key].join('|');
  }

  show() {
    if (!this.netName) {
      console.log('Need to provide the name of the current app/network.');
    } else {
      console.log('Showing storage for network ', this.netName);
      Object.entries(localStorage).forEach(pair => {
        const key = pair[0];
        const val = pair[1];
        if (key.indexOf(this.netName) == 0) {
          console.log(key, ':', val);
        }
      });
    }
  }
  sync(verbose) {
    console.log('SYNCING storage');
    if (!this.netName || !this.userId) {
      console.log('Need to provide the name of the current app/network and userId.');
    } else {
      Object.entries(localStorage).forEach(pair => {
        const key = pair[0];
        const val = pair[1];
        const parts = key.split('|');
        if (parts[0] == this.netName && parts[1] == this.userId) {
          this.ensure();
          if (parts[2]) {
            this.data[this.netName][this.userId][parts[2]] = JSON.parse(val);
          }
        }
      });
      if (verbose) this.show(this.netName);
    }
  }

  ensure() {
    // make sure this.data has the right fields
    if (this.netName) {
      if (!(this.netName in this.data)) this.data[this.netName] = {};
      if (this.userId && !(this.userId in this.data[this.netName]))
        this.data[this.netName][this.userId] = {};
    }
  }

  set(key, value) {
    this.ensure();
    this.data[this.netName][this.userId][key] = value;
    // console.log('writing ', JSON.stringify(value), this.pathOf(netName, key));
    localStorage.setItem(this.pathOf(key), JSON.stringify(value));
  }
  get(key, force) {
    // gets from this.data if there, else gets from localStorage
    const path = this.pathOf(key);
    this.ensure();
    let data = this.data[this.netName][this.userId][key];
    if (!data || force) {
      const saved = localStorage.getItem(path);
      data = saved ? JSON.parse(saved) : null;
      this.data[this.netName][this.userId][key] = data;
    }
    return data;
  }
  _get(key, force) {
    // assumes this.data is up to date with localStorage, which it should be. Set force=true to sync
    const path = this.pathOf(key);
    this.ensure();
    let data = this.data[this.netName][this.userId][key];
    if (force) {
      const saved = localStorage.getItem(path);
      data = saved ? JSON.parse(saved) : null;
      this.data[this.netName][this.userId][key] = data;
    }
    return data;
  }
  clear_key(key) {
    // sets value stored at key to null
    this.set(key, null);
  }
  clear_all() {
    Object.entries(localStorage).forEach(pair => {
      const key = pair[0];
      const val = pair[1];
      if (key.indexOf(this.netName) == 0) {
        console.log('removing', key, val);
        localStorage.removeItem(key);
      }
    });
    if (this.netName in this.data) {
      console.log('clearing this.data[', this.netName, ']');
      delete this.data[this.netName];
    }
  }
}
export default LocalStorage;

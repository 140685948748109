import {
  SEARCH_PEOPLE_REQUEST_START,
  SET_PEOPLE_DATA,
  SET_PEOPLE_ERROR,
  CLEAR_PEOPLE_DATA
} from 'shared/constants/actionTypes';

const initialState = {
  isRequesting: false,
  list: null,
  error: null
};

const people = function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_PEOPLE_REQUEST_START:
      console.log('SEARCH_PEOPLE_REQUEST_START', action);
      return {
        ...state,
        isRequesting: true,
        error: null
      };

    case SET_PEOPLE_DATA:
      console.log('SET_PEOPLE_DATA', action);
      return {
        ...state,
        isRequesting: false,
        list: action.payload
      };

    case SET_PEOPLE_ERROR:
      console.log('SET_PEOPLE_ERROR', action);
      return {
        ...state,
        isRequesting: false,
        list: null,
        error: action.payload
      };

    case CLEAR_PEOPLE_DATA:
      return {
        ...state,
        list: null,
        error: null
      };

    default:
      return state;
  }
};

export default people;

import React from 'react';
import { useLocation } from 'react-router-dom';
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => {
    const parsed = new URLSearchParams(search);
    const lookup = {};
    for (var pair of parsed.entries()) {
      lookup[pair[0]] = pair[1];
    }
    return lookup;
  }, [search]);
};
export default useQuery;

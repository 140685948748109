import _ from 'lodash';
import { hexToHSL, gen_color, RGBToHex, shuffle } from 'utils';
export function createTree(paths) {
  const node = (idd, parent = null) => ({
    _id: idd,
    parent,
    children: []
  });

  const addNode = (parent, child) => {
    if (parent) parent.children.push(child);
    return child;
  };

  const top = addNode(null, node('root')); // node(topItem);
  let current;

  for (const node_ids of paths) {
    current = top;
    for (const idd of node_ids) {
      const found = findNode(current, idd, '_id');
      current = found ? found : addNode(current, node(idd, current._id));
    }
  }
  return top; // top starts out with an extra parent
}

export const objectsFromPaths = paths => {
  /* creates an array of hierarchical objects from an array of paths, where each path is a chain of parents and children, esp. for Treemaps
  e.g. [[1,2], [1,2,3], [1,2,4], [3,5], [6,7]] yields two objects, one containing 1,2,3,4,5 and the other containing 6,7
  [
  {
    "_id": 1,
    "parent": "foo",
    "children": [
      {
        "_id": 2,
        "parent": 1,
        "children": [
          {
            "_id": 3,
            "parent": 2,
            "children": [
              {
                "_id": 5,
                "parent": 3,
                "children": []
              }
            ]
          },
          {
            "_id": 4,
            "parent": 2,
            "children": []
          }
        ]
      }
    ]
  },
  {
    "_id": 6,
    "parent": "foo",
    "children": [
      {
        "_id": 7,
        "parent": 6,
        "children": []
      }
    ]
  }
]
 from https://codereview.stackexchange.com/questions/219418/convert-nested-array-of-values-to-a-tree-structure
*/

  // const result = nestedArrayToJson(paths);
  const result = createTree(paths);
  return result.children;
};

export const nodesFromPaths = (
  paths,
  questions,
  options,
  options_other,
  option_scores,
  options_by_question
) => {
  // creates a dictionary of objects pointed to by the input paths, keyed by the 'name' of the object.
  // objects have ids Questions/.... and Options/....
  // Options of one Question often point to child Questions, and both have the same 'name'
  // from https://developers.google.com/chart/interactive/docs/gallery/treemap#data-format
  let nodes = {};
  let min_total = 1000000.0;
  let max_total = 0.0;
  let name_by_id = {};
  if (paths) {
    let parents = {};
    let uniqs = Array.from(new Set(_.flatten(paths)));
    uniqs.forEach(qid => {
      const q =
        questions[qid] ||
        options.find(one => one._id == qid) ||
        options_other.find(one => one._id == qid);
      const qdata = q.data;
      const name = qdata.title || qdata.label; // question or option
      name_by_id[qid] = name;
      let existing = nodes[name] || {}; // matched questions and options have same name
      const score_data = option_scores[qid]; // only on options, not questions
      const total = Math.max(existing.total || score_data?.total);
      if (total) existing.total = total; // don't set for root
      const children_ids = options_by_question[qid];
      if (children_ids) {
        existing.children_ids = children_ids;
      }
      existing.data = qdata;
      existing._id = qid;
      existing.name = name;
      existing.color = existing.color || qdata.color; // question or option
      if (qid == 'Questions/47aaf0d7-42af-4b4f-aaf5-7d2be7b97850')
        if (qid.indexOf('Questions/') == 0) {
          existing.question_id = qid;
        }
      if (qid.indexOf('Options/') == 0) {
        existing.option_id = qid;
      }
      nodes[name] = existing;
    });
  }
  return { nodes, lookup: name_by_id };
};

export function traverse_tree(node, depth, path, cb) {
  // traverses a dict and executes a callback at each node
  cb(node, depth, path);
  const chirren = node.children || [];
  chirren.forEach(child => {
    traverse_tree(child, depth + 1, [path, node._id].join('.'), cb);
  });
}

export const pathTo = (idd, tree) => {
  // get path from root to given id
  let result = null;
  traverse_tree(tree, 0, '', (node, depth, path) => {
    if (node._id == idd) result = path;
  });
  return result;
};

export const attributeTest = (node, val, attribute = '_id') => {
  const matched = node && node[attribute] == val;
  return matched;
};

export const findNode = (tree, value, tester = '_id') => {
  // get path from root to given id.
  // tester can be string, in which case it is treated as an attribute, and node with that attribute == value is matched
  // if not null and not string, it's a function
  tester = tester || '_id';
  let result = null;
  traverse_tree(tree, 0, '', (node, depth, path) => {
    let match = false;
    const typ = typeof tester;
    if (typeof tester == 'string') {
      match = attributeTest(node, value, tester);
    } else if (typeof tester == 'function') {
      match = tester(node, value);
    }
    if (match) result = node;
  });
  return result;
};

export const testFindNode = tree => {
  const my_test = (node, val) => {
    const matched = node.name == val;
    return matched;
  };
  /*  let testdata;
   ['name', my_test].forEach(tester => {
    testdata = findNode(tree, 'Runoff', tester);
    console.log('test match Runoff', testdata?._id, testdata?.name, tester);
  });
  let tdata2;
  [null, '_id'].forEach(tester => {
    tdata2 = findNode(tree, testdata._id, tester);
    console.log('test match _id', tdata2?._id, tdata2?.name, tester);
  }); */
};
export const lecorbu_colors = [
  // from https://observablehq.com/@taekie/le-corbusier-color-pallete
  // '#eadbc0',
  // '#5e6061',
  //'#929494',
  // '#a7a8a5',
  // '#bcbbb6',
  '#4d6aa8',
  '#8fabc9',
  '#abbdc8',
  '#b6c6ce',
  //'#d9e1dd',
  '#3e6e90',
  '#679dae',
  '#8ab5ba',
  '#a8c4c1',
  // '#c6d5cc',
  '#406e58',
  '#91afa1',
  //'#becbb7',
  '#3e6f42',
  '#7fa25a',
  '#abc17a',
  '#c4d39b',
  // '#eacfa6',
  '#d46c40',
  '#dc8d67',
  // '#eacfb9',
  '#9b3738',
  // '#e6cdbf',
  '#8f3a43',
  '#943a4d',
  // '#d6afa6',
  '#8b4d3e',
  '#cd9886',
  // '#dbbeaa',
  '#68443c',
  '#b67b66',
  //'#d8b29a',
  //'#e2cbb5',
  // '#4c423d',
  // '#b7a392',
  // '#5a5550',
  '#928a7e',
  '#b7ac9d',
  '#ac443a',
  // '#eae4d7',
  '#dba3af',
  '#744438',
  // '#3a3b3b',
  '#b8a136',
  '#428f70',
  '#81868b',
  // '#403c3a',
  '#3957a5',
  '#dbb07f',
  '#74393b',
  '#7aa7cb',
  '#92969a',
  '#ddbf99',
  // '#45423e',
  '#c45e3a',
  // '#313d6b',
  '#60646a',
  '#f2bb1d'
];

export const bioregion_colors = [
  // from https://observablehq.com/@taekie/le-corbusier-color-pallete
  '#eadbc0',
  // '#5e6061',
  '#929494',
  '#a7a8a5',
  '#bcbbb6',
  // '#4d6aa8',
  '#8fabc9',
  '#abbdc8',
  '#b6c6ce',
  '#d9e1dd',
  // '#3e6e90',
  '#679dae',
  '#8ab5ba',
  '#a8c4c1',
  '#c6d5cc',
  // '#406e58',
  '#91afa1',
  '#becbb7',
  // '#3e6f42',
  '#7fa25a',
  '#abc17a',
  '#c4d39b',
  '#eacfa6',
  '#d46c40',
  '#dc8d67',
  '#eacfb9',
  '#9b3738',
  '#e6cdbf',
  '#8f3a43',
  '#943a4d',
  '#d6afa6',
  '#8b4d3e',
  '#cd9886',
  '#dbbeaa',
  '#68443c',
  '#b67b66',
  '#d8b29a',
  '#e2cbb5',
  // '#4c423d',
  '#b7a392',
  '#5a5550',
  '#928a7e',
  '#b7ac9d',
  '#ac443a',
  '#eae4d7',
  '#dba3af',
  '#744438',
  // '#3a3b3b',
  '#b8a136',
  // '#428f70',
  '#81868b',
  '#403c3a',
  // '#3957a5',
  '#dbb07f',
  '#74393b',
  '#7aa7cb',
  '#92969a',
  '#ddbf99',
  '#45423e',
  '#c45e3a',
  // '#313d6b',
  // '#60646a',
  '#f2bb1d'
];

export const colorTree = tree => {
  // get path from root to given id
  let colors1 = [
    '#E8DCBE',
    '#D9DFDB',
    '#3C7244',
    '#E4CCBD',
    '#D8B29C',
    '#DCA1AF',
    '#606264',
    '#416D93',
    '#7DA459',
    '#903D45',
    '#75473E',
    '#7BA4CA',
    '#919392',
    '#6A99AD',
    '#ABC27B',
    '#A6A6A3',
    '#8AB4BC',
    '#C7D297',
    '#D4B2A6',
    '#B6A392',
    '#B4A134',
    '#DDC299',
    '#BCBAB6',
    '#A9C3C1',
    '#8D4E40',
    '#428E70',
    '#5169AE',
    '#C6D6CE',
    '#D36B3E',
    '#8F8B7F',
    '#91AAC9',
    '#D98C64',
    '#ABBDC9',
    '#92AEA1',
    '#EBD3B8',
    '#B0473B',
    '#BDC8B8',
    '#B67B62',
    '#E9E4D8',
    '#DAB07B',
    '#F1BA1B'
  ];
  const colors2 = '#c3dc77,#55928b,#e6c750,#90c98b,#b5e68a,#e1cb55'.split(',');
  const colors3 = '#B8B68F,#EBE6D2,#DBD3C6,#DECCA6,#D0C0A7'.split(',');
  /* Color Theme Swatches in Hex */
  const colors4 = '#049DBF,#4F8C4D,#F2D027,#F2C063,#D93829'.split(',');

  // const colors = '#658C11,#B9BF04,#D93829'.split(','); // ,#48CAD9 // oceans // ,#3DA62D // forests // ,#D96B2B // equity
  const colors = shuffle(lecorbu_colors); // slice(10);
  /*     .filter(color => {
      return hexToHSL(color).l > 0.4;
    })
    .slice(15); */
  let branches = [];
  let color_lookup = {};
  const to_do = Array.isArray(tree) ? tree : [tree];
  to_do.forEach(root => {
    traverse_tree(root, 0, '', (node, depth, path) => {
      const atoms = path ? path.split('.').filter(x => x) : [];
      const idd = node._id;
      if (depth == 1 && !(idd in branches)) {
        branches.push(idd);
        const indx = branches.indexOf(idd) % colors.length;
        node.color = colors[indx]; // might be set in db //
        color_lookup[idd] = node.color;
        // console.log('branch', indx, node.name, node._id, node.color);
      }
      if (atoms.length >= 2) {
        const branch_id = atoms[1];
        const indx = branches.indexOf(branch_id); //  % branches.length;
        const branch_color = color_lookup[branch_id] || colors[indx];
        const hsl = hexToHSL(branch_color);
        const rand = Math.random() * 0.5 + 0.5;
        // generate a random color near the branch color
        node.color = gen_color(
          rand,
          Math.max(0.3, hsl.l * 0.7), // darker but not too dark
          Math.min(1, hsl.l * 1.2), // brighter but not too bright
          hsl.s * 0.9, // less saturated, but not to much
          Math.min(1, hsl.s * 1.2), // more saturated but not too much
          hsl.h * 360 - 50,
          hsl.h * 360 + 50,
          false // branch_id == 'Afrotropics' // hsl.h * 360 + 50 > 360 //  verbose //
        );
        if (hexToHSL(node.color).l < 0.4) {
          // console.log(node.name, node.color, branch_id);
        }
        if (branch_id == 'Afrotropics') {
          // console.log('hsl', hsl);
          // console.log(branch_id, node.name, indx, branch_color, node.color);
          // console.log('col', node.name, rand, node.color, hsl);
        }
      }
    });
  });
  return;
};

export const peopleQuestionTest = new RegExp(' People$');

export const formatDataForTreemap = (objs, nodesData, normalizeTo = 1000, minValue = 100) => {
  // formats for Kent's treemap
  // assign normalized values; remove redundant options from children if corresponding questions exist;

  objs.forEach(obj => {
    traverse_tree(obj, 0, '', (node, depth, path) => {
      const idd = node._id;
      const nm = nodesData.lookup[idd];

      const ndata = nodesData.nodes[nm];
      node.size = depth == 0 && !ndata.total ? 1 : ndata.total || 0; // normed to 1., root get 1.
      node.value = Math.max(minValue, Math.floor(node.size * normalizeTo));
      node.name = ndata.name || ndata.label;
      node.color = ndata.color;
      let to_delete = [];
      node.children.forEach(child => {
        const cid = child._id;
        const cnm = nodesData.lookup[cid];
        const cdata = nodesData.nodes[cnm];
        const is_people_q = peopleQuestionTest.test(cnm);
        if (is_people_q) {
          // People question, not for tree map
          node.people_question = cid;
          to_delete.push(cid);
        }
        const optid = cdata.option_id;
        child.option = optid;
        if (cid.indexOf('Questions') == 0 && optid) {
          to_delete.push(optid); // corresponding option is also a child and is redundant
        }
      });
      const filtered = node.children.filter(function (node) {
        return !_.includes(to_delete, node._id);
      });
      if (filtered.length < node.children.length) node.children = filtered;
    });
  });
};
export const sortChildren = tree => {
  // large to small
  const to_sort = Array.isArray(tree) ? tree : [tree];
  to_sort.forEach(root => {
    traverse_tree(root, 0, '', (node, depth, path) => {
      if (node.children?.length > 0) {
        node.children.sort((a, b) => (a.value > b.value ? -1 : b.value > a.value ? 1 : 0));
      }
    });
  });
};

export const graphFromTree = tree => {
  let nodes = [];
  let edges = [];
  traverse_tree(tree, 0, '', (node, depth, path) => {
    nodes.push(node);
    if (path.length > 1) {
      const verts = path.split('.');
      const len = verts.length;
      edges.push({ target: verts[len - 1], source: node._id || node.id });
    }
  });
  return { nodes, edges };
};

export const treeToTaxo = tree => {
  // returns dictionary of all nodes keyed by _id, and JSON taxo of id nesting, for use in nested selector
  let nodes = {};
  traverse_tree(tree, 0, '', (node, depth, path) => {
    const { children, ...rest } = node;
    nodes[node._id] = rest;
  });
  let parent = null;
  let taxo = {};
  traverse_tree(tree, 0, '', (node, depth, path) => {
    let current = taxo;
    let ids = path.split('.').filter(x => x);
    ids.push(node._id);
    ids.forEach(idd => {
      if (idd) {
        current[idd] = current[idd] || {};
        current = current[idd];
      }
    });
  });
  return { nodes, taxo };
};

export const convert2googlecharts = (
  paths,
  questions,
  options,
  options_other,
  option_scores,
  options_by_question
) => {
  // from https://developers.google.com/chart/interactive/docs/gallery/treemap#data-format
  let rows = [['domain', 'Parent', '# subdomains', 'support']];
  let nodes = {};
  let min_total = 1000000.0;
  let max_total = 0.0;
  let name_by_id = {};
  if (paths) {
    let parents = {};
    let uniqs = new Set();
    paths.forEach(path => {
      const kedges = path.length - 1; // number of pairs in path
      const them = [...Array(kedges).keys()];
      them.forEach(index => {
        const parent = path[index];
        const child = path[index + 1];
        uniqs.add(parent);
        uniqs.add(child);
        parents[child] = parent;
      });
    });
    uniqs.forEach(qid => {
      const q =
        questions[qid] ||
        options.find(one => one._id == qid) ||
        options_other.find(one => one._id == qid);
      const qdata = q.data;
      const name = qdata.title || qdata.label;
      name_by_id[qid] = name;
      const pdata = parents[qid] ? questions[parents[qid]].data : {};
      const pname = pdata.title;
      let existing = nodes[name] || {};
      const score_data = option_scores[qid]; // only on options, not questions
      existing.total = Math.max(existing.total || score_data?.total || 0);
      existing.parent = pname;
      const children = options_by_question[qid];
      if (children) {
        existing.children = children;
      }
      min_total = Math.min(min_total, existing.total);
      max_total = Math.max(max_total, existing.total);
      existing.data = qdata;
      existing._id = qid;
      existing.name = name;
      if (qid.indexOf('Questions/') == 0) {
        existing.question_id = qid;
      }
      if (qid.indexOf('Options/') == 0) {
        existing.option_id = qid;
      }
      nodes[name] = existing;
    });
    const range = max_total - min_total;
    const minn = range / 10;
    Object.entries(nodes).forEach(([name, data, ind]) => {
      const size = Math.max(minn, data.total);
      const next = [name, data.parent, size, Math.floor(Math.random() * 50)];
      rows.push(next);
    });
  }
  return { rows, nodes, name_by_id };
};

// import { useEffect } from 'react';
// import useMultiDomain from './useMultiDomain';
// import useNoo from './useNoo';

let networkWasSet = false;
// let isBootstrapped = false;

const useBootstrap = ({ domainConfig, setCurrentNetwork }) => {
  //   useEffect(() => {
  if (!networkWasSet && domainConfig?.netName) {
    setCurrentNetwork(domainConfig.netName);
    networkWasSet = true;
  }
  //   }, [domainConfig?.netName, setCurrentNetwork]);

  // only do this once...
  // if (!isBootstrapped) {
  // 	isBootstrapped = true;
  // }
};

export default useBootstrap;

import React, { useEffect, useMemo, useState, useRef } from 'react';
import { LuCheckSquare, LuFolder, LuUser } from 'react-icons/lu';
import { ExternalLinkIcon } from '@chakra-ui/icons';

// import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Box,
  Flex,
  Heading,
  Link,
  Text,
  VStack,
  HStack,
  UnorderedList,
  ListItem,
  Radio,
  RadioGroup,
  Button,
  useToast
} from '@chakra-ui/react';

import useNoo from 'hooks/useNoo';
import InvitationsAccept from 'components/Invitations/InvitationsAccept';
import useGroups from 'hooks/useGroups';
import UserAuth from 'components/User/UserAuth';
import OneDecision from 'components/Decisions/OneDecision';
import GenericSelector from 'components/Segments/GenericSelector';
import CopyButton from 'components/Segments/CopyButton';

// function sleep(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

function useQuery() {
  var params = new URLSearchParams(useLocation().search);
  var lookup = {};
  for (var pair of params.entries()) {
    lookup[pair[0]] = pair[1];
  }
  return lookup;
}
const tabSx = {
  border: '1px solid grey'
};

const Splash = () => {
  const { googleUser: user, currentNetworkData, nooUser } = useNoo('Splash');
  const network = currentNetworkData;
  const options = [
    'united by networks of respect',
    'a virtual nation',
    'purchasing collectives',
    'capable of global collective action',
    'a large global audience',
    'a network cooperative',
    'co-owners of a cooperative global bank',
    'mad as hell and not going to take it any more'
  ];
  const options_qualities = [
    'brilliant',
    'exemplary',
    'exceptional',
    'ingenious',
    'compassionate',
    'wise',
    'progressive',
    'admirable',
    'insightful',
    'benevolent'
  ];
  const [selected, setSelected] = useState(options[0]);
  const [focusRef, setFocusRef] = useState(null);
  const [warned, setWarned] = useState(false);
  const [inviter, setInviter] = useState(null);
  const [inviteGroup, setInviteGroup] = useState(null);
  const inputRef = useRef();
  const history = useHistory();
  const toast = useToast();

  // TODO: if using invitations here, update to useInvitations() hook
  // const onCallAcceptInvitation = () => {
  // 	nooApi.acceptInvitation(user, invitationId, setAcceptLoading, setAcceptResponse);
  // };

  let params = useQuery();

  useEffect(() => {
    if (params.token) {
      const [group, inviter] = params.token.split('|');
      setInviter(inviter);
      setInviteGroup(group);
      console.log('in', group, inviter);
      // history.replace('/');
    }
  }, [history, inviter, params]);

  useEffect(() => {
    if (inviter && inviteGroup) {
      const key = 'inviters';
      let inviters_by_group = JSON.parse(localStorage.getItem(key) || '{}');
      let current = inviters_by_group[inviteGroup] || [];
      if (!current.includes(inviter)) {
        current.push(inviter);
      }
      inviters_by_group[inviteGroup] = current;
      localStorage.setItem(key, JSON.stringify(inviters_by_group));
    }
  }, [inviteGroup, inviter]);

  const makeLink = () => {
    if (nooUser?.person) {
      const gkey = defaultGroup.replace('Groups/', '');
      const ikey = nooUser.person._id.replace('Persons/', '');
      const token = [gkey, ikey].join('|');
      const link = location.href + '?token=' + token;
      toast({
        title: (
          <HStack>
            <Text>Share this link with others you want to invite</Text>{' '}
            <CopyButton text={link} color='#fff' bgColor='#f00' />
          </HStack>
        ),
        description: link,
        status: 'success',
        duration: 3000,
        isClosable: true
      });
    }
  };
  var invitationId = params.invite;
  const { currentGroupId, setGroupId, groupSelector, currentGroupName } = useGroups({
    from: 'Women splash'
  });
  const defaultDecision = 'Questions/cdb5467e-c118-43a3-ac1d-3d26c0d63e3d';
  const defaultGroup = 'Groups/0c73e7c3-47d5-4fcf-9fa4-6048a3d99ef7';
  const [decision, setDecision] = useState(defaultDecision);

  // TBD: InputRef for each decision so we can focus there.

  const display = network?.display;
  const paras = network.welcome;

  const onChangeDecision = dId => {
    setFocusRef(inputRef);
    setDecision(dId);
    if (window.matchMedia('(orientation: portrait)').matches && !warned) {
      alert('Inquries are best used in Landscape mode (horizontal)');
      setWarned(true);
    }
  };
  const boxSx = {
    border: '2px solid #aaa',
    padding: '4px',
    margin: '4px',
    backgroundColor: '#eee',
    width: '75%'
  };
  const whiteBoxSx = {
    border: '1px solid #ccc',
    padding: '4px',
    margin: '4px',
    width: ['95%', '85%', '75%'],
    fontSize: ['12px', '14px', '16px']
  };

  const inquirySx = {
    border: '2px solid #aaa',
    padding: '4px',
    margin: '4px',
    backgroundColor: '#eee'
  };
  const paraSx = {
    mb: '12px'
  };
  const onSelect = e => {
    console.log('sel', e.target.value);
    setSelected(e.target.value);
  };
  const crowdfund = 'https://opencollective.com/noonao/projects/planetary-women';

  return (
    <Box>
      <Heading as='h3' size='md' fontWeight='bold' textAlign='center' mb='1em'>
        A women-only virtual nation.
      </Heading>
      <VStack alignItems={'center'}>
        <Box sx={whiteBoxSx}>
          <Box sx={{ margin: '10px' }}>
            <Text sx={paraSx}>
              Suppose we wanted to propagate a large network of all the{' '}
              <GenericSelector
                pairs={options_qualities}
                callback={onSelect}
                selected={selected}
                width='150px'
                bgColor='#eee'
              />
              women of the planet, a <b>global sisterhood </b>...
              <UnorderedList sx={{ ml: '30px' }}>
                <ListItem>
                  <b>grown by invitation</b>...
                </ListItem>
                <ListItem>
                  getting good at the <strong> extreme coordination</strong> unique to such
                  networks...
                </ListItem>
                <ListItem>
                  <strong>acting like a superorganism</strong>...
                </ListItem>
                <ListItem>
                  and claiming the <strong>planetary-scale agency</strong> we deserve...
                </ListItem>
                <ListItem>toward a &apos;world that works for all&apos;</ListItem>
              </UnorderedList>
            </Text>
            <Text sx={paraSx}>
              How to ensure the <strong>highest quality results</strong>? By starting the invitation
              propagation from <strong>women who embody the qualities we want</strong>.
            </Text>
            <Text sx={paraSx}>
              That&apos;s what you&apos;re here for, to <strong>nominate Founders</strong> below,
              amplify the nominations of others, and <strong>discover great women</strong> in the
              process. And{' '}
              {nooUser && nooUser.person ? (
                <Button onClick={makeLink} bgColor={'#89df6f'}>
                  Share
                </Button>
              ) : (
                'Share '
              )}
              this page with a very few women you think will help shape it well.
            </Text>
            <Text>
              Sample network:
              {/*               <Link
                sx={{ bgColor: '#eee', m: '3px', p: '5px' }}
                href='/search_network/?group=d33250fb-35ac-46e6-9d38-c683e167c654&hide'
              >
                Simulation
              </Link> */}
              <Link
                sx={{ bgColor: '#eee', m: '3px', p: '5px' }}
                href='search_network/?group=9ecda2eb-a16c-48c9-9f2f-6b0ad2d184b3&hide'
              >
                women of Global Regen Colab
              </Link>
            </Text>
          </Box>
        </Box>
      </VStack>

      <InvitationsAccept align='center' />
      <HStack backgroundColor='cyan.200'>
        <Text sx={{ fontWeight: 'bold' }}>Choose an Inquiry:</Text>
        <RadioGroup
          sx={{ border: '1px solid lightgray', padding: '0px 4px' }}
          onChange={onChangeDecision}
          defaultValue={defaultDecision}
        >
          <HStack direction='row'>
            <Radio bg='#fff' value={defaultDecision}>
              Founders
            </Radio>
            <Radio bg='#fff' value='Questions/f86ccea0-5ffa-42ec-a2ee-6d18bbcc39da'>
              Principles
            </Radio>
            <Radio bg='#fff' value='Questions/3495f3bd-4c05-4dce-80c7-26dd9c91f9d2'>
              Name It
            </Radio>
            <Radio bg='#fff' value='Questions/4dd18f17-2d1f-48ba-b3e9-408d44c535e3'>
              TagLine
            </Radio>
          </HStack>
        </RadioGroup>
      </HStack>
      <UserAuth unauthorized='signin'>
        <Text>
          <strong>Add</strong> your own proposed answers in <em>Suggest</em> at the bottom.{' '}
          <strong>Upvote</strong> those and others you want to amplify. <strong>Click</strong>{' '}
          proposed options for more detail.{' '}
          <Text>
            <strong>First-timers probably need to reload the page</strong> before voting.
          </Text>
        </Text>
        <Text>
          <strong>
            Use the <em>Sort by</em> options
          </strong>{' '}
          to see latest, find alphabetically, ...
        </Text>
        {decision && (
          <Box sx={inquirySx}>
            <OneDecision group_in={defaultGroup} decision_in={decision} focus={focusRef} />
          </Box>
        )}
      </UserAuth>
    </Box>
  );
};

export default Splash;

import { useReducer, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { nooCommand, nooCommandDirect as _nooCommandDirect } from 'redux/actions/nooApi';

const ncdDefault = {
  loading: false,
  response: null,
  error: null
};

const DIRECT_LOADING = 'DIRECT_LOADING';
const DIRECT_RESPONSE = 'DIRECT_RESPONSE';
const DIRECT_ERROR = 'DIRECT_ERROR';

const ncdReducer = (state, action) => {
  switch (action.type) {
    case DIRECT_LOADING:
      return {
        ...ncdDefault,
        loading: true
      };
    case DIRECT_RESPONSE:
      return {
        loading: false,
        response: action.data,
        error: null
      };
    case DIRECT_ERROR:
      return {
        loading: false,
        response: null,
        error: action.data
      };
  }
};

const useNooApi = () => {
  const nooCommandIsProcessing = useSelector(state => state.nooApi?.nooCommandIsProcessing);
  const _nooCommandResponse = useSelector(state => state.nooApi?.nooCommandResponse, shallowEqual);
  const _nooCommandResponseCommand = useSelector(state => state.nooApi?.nooCommandResponseCommand);
  const _nooCommandResponseError = useSelector(
    state => state.nooApi?.nooCommandResponseError,
    shallowEqual
  );

  const [ncdState, ncdDispatch] = useReducer(ncdReducer, { ...ncdDefault });

  const nooCommandDirect = useCallback((call, params) => {
    if (!params.setLoading) {
      params.setLoading = () => {
        ncdDispatch({ type: DIRECT_LOADING });
      };
    }
    if (!params.setResponse) {
      params.setResponse = response => {
        ncdDispatch({ type: DIRECT_RESPONSE, data: { data: response, command: call } });
      };
    }
    if (!params.setResponseError) {
      params.setResponseError = error => {
        ncdDispatch({ type: DIRECT_ERROR, data: { data: error, command: call } });
      };
    }
    _nooCommandDirect(call, params);
  }, []);

  return {
    nooCommand,
    nooCommandIsProcessing,
    nooCommandResponse: {
      data: _nooCommandResponse,
      command: _nooCommandResponseCommand
    },
    nooCommandResponseError: {
      data: _nooCommandResponseError,
      command: _nooCommandResponseCommand
    },
    nooCommandDirect,
    nooCommandIsProcessingDirect: ncdState.loading,
    nooCommandResponseDirect: ncdState.response,
    nooCommandResponseErrorDirect: ncdState.error
  };
};

export default useNooApi;

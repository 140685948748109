import React, { useState, useEffect, useMemo } from 'react';
import { Text, HStack, VStack, Radio, RadioGroup } from '@chakra-ui/react';
import usePeopleSearch from 'hooks/usePeopleSearch';
import AutoComplete from 'components/Segments/AutoComplete';
import oneearth from 'components/DataViz/TEST_oneearth';
import oneearth_solutions from 'components/DataViz/oneearth_solutions';
import TaxoSelector from '../Segments/TaxoSelector';
import { treeToTaxo } from 'utils/treemapUtils';
import { objectSort } from 'utils';

const TaxoInputs = props => {
  const [treeNodes, setTreeNodes] = useState(null);
  const [treeTaxo, setTreeTaxo] = useState(null);
  const [whichTaxo, setWhichTaxo] = useState('bioregions');
  const [taxoData, setTaxoData] = useState(oneearth);
  const [selected, setSelected] = useState(null);
  const { onSelect } = props;

  useEffect(() => {
    const taxos = { bioregions: oneearth, solutions: oneearth_solutions };
    const data = taxos[whichTaxo];
    setTaxoData(data);
  }, [whichTaxo, setTaxoData]);

  useEffect(() => {
    if (taxoData) {
      const { nodes, taxo } = treeToTaxo(taxoData[0]);
      const todo = 'Central America,Southern America,Afrotropics,Indomalaya'.split(',');
      let names = {};
      Object.entries(taxo.OneEarth).forEach(([key, value]) => {
        // taxo of realm/subrealm names
        const all = true; // todo.includes(key)
        if (all) {
          names[key] = [];
          Object.entries(value).forEach(([k, v]) => {
            const name = nodes[k].name;
            names[key].push(name);
          });
        }
      });
      // console.log('names', names);
      setTreeNodes(nodes);
      setTreeTaxo(taxo);
    }
  }, [taxoData]);

  const selectTaxoNode = e => {
    const obj = treeNodes[e];
    setSelected(e);
    props.onSelect(obj);
  };
  const onSelectOption = e => {
    const idd = e.item.value;
    setSelected(idd);
    const obj = treeNodes[idd];
    props.onSelect(obj);
  };
  const autocompleteOptions = useMemo(() => {
    if (treeNodes) {
      return Object.entries(treeNodes)
        .map(([idd, node]) => {
          return { value: idd, label: node.name };
        })
        .sort(objectSort('label'));
    }
    return null;
  }, [treeNodes]);

  const noop = () => {
    return null;
  };

  return (
    <VStack sx={{ backgroundColor: '#eeeeee', padding: '5px', border: '1px solid lightgray' }}>
      <RadioGroup onChange={setWhichTaxo} defaultValue={whichTaxo}>
        <HStack direction='row'>
          <Radio value='bioregions'>Bioregions</Radio>
          <Radio value='solutions'>Climate Solutions</Radio>
        </HStack>
      </RadioGroup>
      <HStack sx={{ padding: '0px 3px', border: '1px solid #aaa' }}>
        <TaxoSelector
          nodes={treeNodes}
          taxo={treeTaxo}
          label={`Select:`}
          callback={selectTaxoNode}
          defaultValue={selected}
          width={'100%'}
        />
      </HStack>
      <HStack sx={{ width: '100%', padding: '0px 3px', border: '1px solid #aaa' }}>
        <Text>Search:</Text>
        <AutoComplete
          options={autocompleteOptions}
          onSelectOption={onSelectOption}
          searchOptions={noop}
          defaultValue={selected}
        />
      </HStack>
    </VStack>
  );
};

export default TaxoInputs;

import { useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import { firebaseAppAuth, firebaseApp, providers } from 'utils/firebaseSetup';
import { setUser, setLoading, setError } from 'redux/actions/firebaseAuth';
import { aqlReadPerson } from 'redux/actions/noo';

const useFirebaseAuth = () => {
  const dispatch = useDispatch();
  // const history = useHistory();

  const fuser = useSelector(state => state.firebaseAuth?.user, shallowEqual);
  const googleUsertoken = useSelector(state => state.firebaseAuth?.token, shallowEqual);
  const loading = useSelector(state => state.firebaseAuth?.loading);
  const error = useSelector(state => state.firebaseAuth?.error);

  // 60 seconds * 50 minutes * 1000ms to get timeout period
  const REFRESH_TIMEOUT_MS = 60 * 50 * 1000;
  const refreshTimeout = useRef();

  useEffect(() => {
    return firebaseAppAuth.onAuthStateChanged(user => {
      async function handleUser() {
        let token;
        if (user) {
          token = googleUsertoken || (await user.getIdToken(true));
        }
        // guard against unnecessary redux dispatches, if the token is the same then the user is the same....
        if (token !== googleUsertoken) {
          dispatch(setUser({ user, token }));

          // TODO: need to conditionally call out to readPerson here,
          // not exactly sure what property we can track to compare if something changed.
          // console.log('reading user', user?.displayName, user?.uid); // ); //
          dispatch(aqlReadPerson({ user }));

          // setting up the timeout-refresh if/when the token gets updated by setUser()
          // which designates that there was a token change
          if (refreshTimeout.current) {
            clearTimeout(refreshTimeout.current);
          }
          // only start refresh timer if the user exists (i.e., "is logged in")
          if (user) {
            refreshTimeout.current = setTimeout(handleUser, REFRESH_TIMEOUT_MS);
          }
        } else {
          console.log('no token');
        }
      }
      handleUser();
    });
  });

  const tryToAuth = async operation => {
    try {
      dispatch(setLoading(!loading));
      const result = await operation();
      return result;
    } catch (error) {
      dispatch(setError(error));
      return error;
    } finally {
      dispatch(setLoading(!loading));
    }
  };

  const tryToSignInWithProvider = provider => {
    tryToAuth(() => {
      const providerInstance = providers[provider];
      if (!providerInstance) {
        throw new Error('no provider instance found! cannot auth');
      }
      return firebaseAppAuth.signInWithPopup(providerInstance);
    });
  };

  const signInAnonymously = () => {
    return tryToAuth(() => firebaseAppAuth.signInAnonymously());
  };

  const signInWithGithub = () => tryToSignInWithProvider('githubProvider');

  const signInWithTwitter = () => tryToSignInWithProvider('twitterProvider');

  const signInWithGoogle = () => tryToSignInWithProvider('googleProvider');

  const signInWithFacebook = () => tryToSignInWithProvider('facebookProvider');

  const signInWithEmailAndPassword = (email, password) => {
    const x = tryToAuth(() => firebaseAppAuth.signInWithEmailAndPassword(email, password));
    return x;
  };

  const signInWithPhoneNumber = (phoneNumber, applicationVerifier) => {
    return tryToAuth(() => firebaseAppAuth.signInWithPhoneNumber(phoneNumber, applicationVerifier));
  };

  const createUserWithEmailAndPassword = (email, password) => {
    return tryToAuth(() => firebaseAppAuth.createUserWithEmailAndPassword(email, password));
  };

  const updateUserEmail = (email, cb, cbe) => {
    if (!fuser) {
      throw new Error('cannot update user data without a user');
    }
    if (!email) {
      console.log('not updating email to empty string');
      return;
    }
    fuser
      .updateEmail(email)
      .then(() => {
        firebaseApp
          .auth()
          .currentUser.reload()
          .then(() => {
            dispatch(setUser({ user: firebaseApp.auth().currentUser }));
          });
        // dispatch(setUser({ user: { ...fuser, email, emailVerified: false } }));
      })
      .catch(error => {
        // An error occurred
        console.error('error updating user email');
        // dispatch(setError(error));
        if (cbe) cbe(error);
      });
  };

  const updateUserPassword = (password, cb, cbe) => {
    if (!fuser) {
      throw new Error('cannot update user data without a user');
    }
    if (!password) {
      console.log('not updating password to empty string');
      return;
    }
    fuser
      .updatePassword(password)
      .then(() => {
        // firebaseAppAuth.onAuthStateChanged(user => {
        //   dispatch(setUser({ user }));
        // });
        if (cb) cb('user password updated');
      })
      .catch(error => {
        // An error occurred
        console.error('error updating user password');
        // dispatch(setError(error));
        if (cbe) cbe(error);
      });
  };

  const updateUserProfile = (name, cb, cbe) => {
    if (!fuser) {
      throw new Error('cannot update user data without a user');
    }
    if (!name) {
      console.log('not updating name to empty string');
      return;
    }
    fuser
      .updateProfile({
        displayName: name
        // firebase supports only avatar URL, not direct upload:
        // photoURL: "https://example.com/jane-q-user/profile.jpg"
      })
      .then(() => {
        // dispatch(setUser({ user: { ...fuser, displayName: name } }));
        firebaseApp
          .auth()
          .currentUser.reload()
          .then(() => {
            console.log('UPDATED THE USER + RELOAD', firebaseApp.auth().currentUser);
            dispatch(setUser({ user: firebaseApp.auth().currentUser }));
          });
        if (cb) cb('user profile updated');
      })
      .catch(error => {
        // An error occurred
        console.error('error updating user profile data');
        // dispatch(setError(error));
        if (cbe) cbe(error);
      });
  };

  const sendVerificationEmail = (cb, cbe) => {
    console.log('sending verification to');
    if (!fuser) {
      throw new Error('cannot update user data without a user');
    }
    fuser
      .sendEmailVerification()
      .then(() => {
        console.log('verification email sent, need a toast');
        if (cb) cb('verification email sent');
      })
      .catch(error => {
        // testable by sending too many
        console.error('error sending verification email', error);
        if (cbe) cbe(error);
      });
  };

  const sendPasswordResetEmail = (email, cb, cbe) => {
    if (!email) {
      console.log('not updating email to empty string');
      return;
    }
    firebaseAppAuth
      .sendPasswordResetEmail(email)
      .then(() => {
        console.log('password reset email sent, need a toast');
        if (cb) cb('password reset email sent');
      })
      .catch(error => {
        // An error occurred
        console.error('error sending password reset email');
        // dispatch(setError(error));
        if (cbe) cbe(error);
      });
  };

  return {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithGithub,
    signInWithTwitter,
    signInWithGoogle,
    signInWithFacebook,
    signInWithPhoneNumber,
    signInAnonymously,
    updateUserEmail,
    updateUserPassword,
    updateUserProfile,
    sendVerificationEmail,
    sendPasswordResetEmail,

    loading,
    user: fuser,
    userIsEmailLogin: fuser?.providerData?.find(pd => pd.providerId === 'password'),
    error
  };
};

export default useFirebaseAuth;

import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import SortableTable from 'components/Segments/SortableTable';
import InviteLink from 'components/Invitations/InviteLink';
import PostLink from 'components/Invitations/PostLink';
import useSearchResults, { ResultLink } from 'hooks/useSearchResults';

const SearchResultsGroups = props => {
  const kind = 'groups';
  const { data, topic } = props;
  const [parsedData, setParsedData] = useState(null);

  const {
    defaultColumnProperties,
    map_column_title,
    map_column_sx,
    get_columns
  } = useSearchResults(kind);

  useEffect(() => {
    if (data) {
      const it = data?.result?.[0] || data;
      setParsedData(it);
    }
  }, [data, setParsedData]);

  const groups = useMemo(() => {
    if (parsedData?.groups) {
      const groups = parsedData.groups.filter(G => G?._id.indexOf('Groups') == 0);
      return groups.map((group, index) => {
        const { data } = group;
        const title = data.title || data.fullname || data.prompt;
        const qs = group.questions || [];
        let has_questions = _.filter(qs, (val, key) => {
          return val?.render == 'curation';
        });
        return {
          titleSorter: title,
          title: (
            <ResultLink
              as={RouterLink}
              className='group'
              to={`/group/${group._key}`}
              title={data.description}
            >
              {title}
            </ResultLink>
          ),
          description: data.description || ' ',
          invite: <InviteLink group={group} />,
          post: has_questions.length > 0 ? <PostLink group={group} /> : ' '
        };
      });
    }
    return null;
  }, [parsedData]);

  const cols = useMemo(() => {
    if (groups?.length) {
      return get_columns(groups[0]).map(key => {
        return {
          ...defaultColumnProperties,
          key: key,
          name: map_column_title(_.capitalize(key), kind),
          sx: map_column_sx(key, kind)
        };
      });
    }
    return [];
  }, [defaultColumnProperties, get_columns, groups, map_column_sx, map_column_title]);

  if (!_.size(groups)) {
    return null;
  }

  return (
    <Box mt='20px'>
      <SortableTable columns={cols} rows={groups} />
    </Box>
  );
};

export default SearchResultsGroups;

import React from 'react';
import { Text } from '@chakra-ui/react';
import { getDayJs } from 'utils/dayJs';

const dayJs = getDayJs();

const formatTime = (ts, formatter) => {
  //   if (!ts) throw new Error('no timestamp to format! check data');
  const dj = dayJs(ts);
  switch (formatter) {
    case 'from':
    case 'ago':
      return dj.from();
    case 'to':
      return dj.to();
  }
  return dj;
};

const Timestamp = props => {
  const {
    ts,
    as = 'span',
    formatter = 'from',
    color = 'gray.500',
    size = 'xs',
    fontStyle = 'italic',
    ...rest
  } = props;

  const formattedTime = formatTime(ts, formatter);

  return (
    <Text as={as || 'span'} fontSize={size} color={color} fontStyle={fontStyle} {...rest}>
      {formattedTime}
    </Text>
  );
};

export default Timestamp;

/* eslint-disable react/no-unescaped-entities */
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Heading,
  HStack,
  Input,
  Spinner,
  Text,
  VStack,
  Tooltip
} from '@chakra-ui/react';
import useNoo from 'hooks/useNoo';

import { objectSort, truncate_precision } from 'utils';
import { normalize_votes } from 'redux/actions/decisions';

import FontAwesomeLoader from 'utils/FontAwesomeLoader';
import OptionSlider from 'components/Decisions/OptionSlider';
import { VoteTotalBox, Divider, Panel } from 'components/Decisions/Styled';
import _ from 'lodash';
import TaxoInputs from 'components/Segments/TaxoInputs';

const EditableVector = props => {
  const { sourceId, endorsements, onTagChange, prompt, allowFreeform, allowTaxos } = props;
  const [inputEdges, setInputEdges] = useState(null);
  const [currentOptionId, setCurrentOptionId] = useState(null);
  const [preset, setPreset] = useState(props.preset);
  // inputEdges is a dict of personId: {tag: strength, tag: strength...}
  const [newOptionData, setNewOptionData] = useState(preset); // needs to be {label: 'foo'}
  const [maxAnswer, setMaxAnswer] = useState(1);
  const [maxTotal, setMaxTotal] = useState(0);
  const [maxValue, setMaxValue] = useState(100);
  const [options, setOptions] = useState([]);
  const qprompt = prompt || 'Add a Tag';
  const disabled = false;

  const clean = useCallback(edges => {
    // remove any invalid scores
    let out = {};
    Object.entries(edges).forEach(pair => {
      const [pid, cloud] = pair;
      out[pid] = Object.fromEntries(
        Object.entries(cloud).filter(pair2 => {
          const [word, val] = pair2;
          return isFinite(val);
        })
      );
    });
    return out;
  }, []);

  useEffect(() => {
    // set inputEdges first time. They're altered locally until submit
    if (inputEdges == null && endorsements != null) {
      setInputEdges(clean(endorsements));
    }
  }, [endorsements, setInputEdges, inputEdges, clean]);

  const updateNewOptionData = e => {
    const key = e.target.name;
    const val = e.target.value;
    const _id = e.target._id;
    const myOptionData = { ...newOptionData };
    myOptionData[key] = val;
    if (_id) myOptionData._id = _id;
    setNewOptionData(myOptionData);
    setPreset(null);
  };

  useEffect(() => {
    let opts_data = {};
    let maxx = 0;
    let myMax = 0;
    if (inputEdges != null) {
      Object.entries(inputEdges).forEach(pair => {
        const [pid, votes] = pair; // who voted for what words
        Object.entries(votes).forEach(pair2 => {
          const [word, val] = pair2;
          if (isFinite(val)) {
            const value = parseInt(val);
            opts_data[word] = opts_data[word] || { title: word, total: 0, votes: {} };
            opts_data[word].total += val;
            opts_data[word].votes[pid] = val;
            if (sourceId == pid) opts_data[word].my_vote = val;
            opts_data[word]._id = word;
            maxx = Math.max(maxx, opts_data[word].total);
            myMax = Math.max(myMax, opts_data[word].my_vote);
          } else {
            console.log('ERROR in respect edge, value not a number, ' + word + ' = ', val);
          }
        });
      });
    }

    let opts = Object.values(opts_data).map(one => {
      one.is_top = one.total >= maxx ? true : false;
      return one;
    });
    setMaxValue(myMax * 100);
    setOptions(opts);
    // opts = _.sortBy(opts, 'total').reverse();
  }, [inputEdges, sourceId]);

  const handleCheckboxVote = (event, option) => {
    const isChecked = event.target.checked;
    const tag = option._id;
    const vote = event.target.value > 0 ? 0 : maxAnswer; // binary on or off for checkbox click
    let them = { ...inputEdges };
    let mine = them[sourceId] || {};
    const currentMax = Math.max.apply(null, Object.values(mine));
    const maxx = currentMax > 0 ? currentMax : 1; // set newly checked to current max
    const before = mine[tag];
    mine[tag] = isChecked ? maxx : 0;
    if (mine[tag] != before) {
      mine = normalize_votes(mine);
      them[sourceId] = mine;
      setInputEdges(them);
    }
  };

  const handleSliderChange = (option, voteValue) => {
    const tag = option._id;
    let them = { ...inputEdges };
    let mine = them[sourceId] || {};
    mine[tag] = voteValue;
    mine = normalize_votes(mine);
    them[sourceId] = mine;
    setInputEdges(them);
  };

  const normalize_tag = txt => {
    return txt.toLowerCase().trim();
  };

  const handleAddOption = () => {
    const { label, description, _id } = newOptionData;
    if (label) {
      const normed = label; // normalize_tag(label); // can't normalize taxo names
      let them = { ...inputEdges };
      let mine = them[sourceId] || {};
      const maxx = _.isEmpty(mine) ? 1 : Math.max.apply(null, Object.values(mine));
      mine[normed] = maxx;
      mine = normalize_votes(mine);
      them[sourceId] = mine;
      setInputEdges(them);
      setNewOptionData({});
    }
  };

  const onSort = () => {
    const sorted = _.sortBy(options, 'total').reverse();
    setOptions(sorted);
  };

  const onTaxoSelect = node => {
    const label = node.name;
    const _id = node._id;
    updateNewOptionData({ target: { name: 'label', value: label, _id } }); // simulate event
    // updateNewOptionData({ target: { name: '_id', value: _id } });
  };

  const loadOptionData = () => {
    console.log('show right sidebar');
  };

  useEffect(() => {
    if (inputEdges) {
      onTagChange(inputEdges);
    }
  }, [inputEdges, onTagChange]);
  return (
    <Panel max='100%' min='100%'>
      {props.allowTaxos && <TaxoInputs onSelect={onTaxoSelect} />}
      <Button onClick={onSort} name={'sort'}>
        Sort
      </Button>
      {options?.length ? (
        <>
          {options.map((option, idx) => {
            const title = option.title;
            const optionVote = option.my_vote;
            const hasPositiveVote = optionVote > 0;
            return (
              <HStack
                key={idx}
                alignItems='top'
                pointerEvents={true}
                cursor='pointer'
                _hover={{
                  backgroundColor: currentOptionId === option._id ? 'inherit' : '#f6f6f6',
                  borderTopRightRadius: currentOptionId === option._id ? '' : '20px',
                  borderBottomRightRadius: currentOptionId === option._id ? '' : '20px'
                }}
              >
                <Tooltip label={disabled ? 'Sorry, not allowed' : ''}>
                  <HStack width='100%' borderBottom='1px solid #eee' pt='6px' pb='6px' flex='1'>
                    <VoteTotalBox
                      maxChars={3}
                      isHighlighted={hasPositiveVote}
                      isTop={option.is_top}
                    >
                      {truncate_precision(option.total)}
                    </VoteTotalBox>
                    <Box flex='1' onClick={() => loadOptionData(option._id)}>
                      <Text as='span' fontSize='1.2em'>
                        {title || 'no title'}
                      </Text>
                    </Box>
                    <Box width={['30%', '20%']} height='24px' pr='8px'>
                      {hasPositiveVote && (
                        <OptionSlider
                          option={option}
                          voteValue={optionVote}
                          onUpdate={handleSliderChange}
                          maxValue={maxValue}
                        />
                      )}
                    </Box>

                    <Checkbox
                      onChange={event => handleCheckboxVote(event, option)}
                      name={option._id}
                      isDisabled={disabled}
                      isChecked={hasPositiveVote}
                      // defaultChecked={hasPositiveVote}
                      value={hasPositiveVote ? 1 : 0}
                      borderColor='#666'
                    />

                    <Box width='22px' height='22px'>
                      {currentOptionId === option._id ? (
                        <FontAwesomeLoader
                          size='lg'
                          color='#6be300'
                          icon={['fas', 'arrow-circle-right']}
                        />
                      ) : null}
                    </Box>
                  </HStack>
                </Tooltip>
              </HStack>
            );
          })}
        </>
      ) : null}
      <Tooltip label={disabled ? 'not allowed' : ''}>
        <Box backgroundColor='cyan.700' borderRadius='12px' px='1em' py='0.5em' mt='1em'>
          <VStack mt='8px' align='start' maxWidth='300px'>
            <Input
              type='text'
              name='label'
              size='xs'
              onChange={updateNewOptionData}
              value={newOptionData?.label || ''}
              placeholder='Title of suggestion'
              color='cyan.900'
              backgroundColor='white'
              borderColor='cyan.900'
              isDisabled={disabled}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  handleAddOption();
                }
              }}
            />
            <Button
              onClick={handleAddOption}
              disabled={disabled}
              size='xs'
              color='white'
              backgroundColor='cyan.400'
              _hover={{
                color: 'cyan.400',
                backgroundColor: 'cyan.200'
              }}
            >
              {qprompt}
            </Button>
          </VStack>
        </Box>
      </Tooltip>
    </Panel>
  );
};

export default EditableVector;

import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import { PopOverUser } from 'components/Segments/UserCard';

const onePop = user => {
  const idd = user._id || user.id;
  const profile = user.data?.profile || user;
  return (
    <PopOverUser
      key={idd}
      _id={idd}
      node={profile}
      order={[
        'avatar',
        'description',
        'location',
        'bioregion',
        'mastodon',
        'linkedin',
        'twitter',
        'facebook'
      ]}
    />
  );
};

const ShowNetwork = props => {
  const { data, graph, onMouseOver, onMouseLeave, filterProvisional, maxNodes } = props;
  const { nodes } = graph; // nodesAndEdgesNoGenerations(data, filterProvisional);
  const [keepOpen, setKeepOpen] = useState(false);

  const items = useMemo(() => {
    if (nodes?.length) {
      return nodes.slice(0, maxNodes || 100).map((node, idx) => {
        node.idx = idx;
        return onePop(node);
      });
    }
  }, [maxNodes, nodes]);

  return <div className='network'>{items}</div>;
};

export default ShowNetwork;

const markdown = `
## Climate Swarm 
#### A modest proposal

Politely asking the ruling class is not going to work. It's clear from COP26 that there is not going to be any "grand strategy" or "orderly transition" to address the climate catastrophe.

We need to stop hoping for that and start acting with the scope, scale, speed, and impact necessary for planetary survival and regeneration. 

### Vision
What if the climate movement could quickly transform from its current state (essentially a diffuse collection of listservs like XR, Sunrise Movement, KlimaStreik, 350...) into a "networked swarm" capable of highly efficient, rapid, emergent self-governance and coordinated action at all scales and across all domains?

### Sounds good, but how?
Fortunately, we have the tools and tech we need to do it *now*. This vision requires only two pieces of existing technology: *[trust networks](#trust-networks)* and *[liquid democracy](liquid-democracy)*. That combination is a critical requirement for the *societal-scale collective decision-making* we need if we are to realize the thriving planet we know is possible. 

Let's call that combination a *Smart Swarm*.

Here's the [2005 Smartocracy whitepaper](https://markorodriguez.files.wordpress.com/2011/01/smartocracy-hicss2007.pdf) on the science behind it.

The network formation has already begun, and can propagate quickly. It can start small, and use existing liquid democracy tools to agree on how to expand, overall strategy, etc. It can rapidly innovate its *[decision server](#decision-server)*, enabling it to run rings around old-school governance globally, locally, fractally.

And it can easily migrate to Web3 technologies such as DAOs and token economies as they mature. In fact, such a *smart swarm* is ideal for rapid iteration on the very issues that are serious problems for DAOs, particularly governance.


### WikiBudget for Climate
A WikiBudget is a "what if?" budget that engages communities of expertise in collectively aligning on what could be accomplished for a given domain, presuming sufficient resources. It leverages individual expertise and creates something of real value out of small individual contributions of such expertise.

The basic concept is to crowdsource a global budget for everything 'climate' that could be executed in the next year. If the US can create a zero-based line item $5T budget every year through bureaucracy, why can't the climate movement create a $10B budget for next year using new decision making approaches. 

This [document](https://docs.google.com/document/d/1gr3DGw_yZsyp7vy7fCbnkLwdw77doYZCE2BC6REs3cg/edit#heading=h.k6y0ue5ufxxc) details how it would work. It starts with collaboratively evolving an ontology of all the domains relevant to climate, and associating domain experts with each subdomain. They then further refine their subdomain, eventually drilling down to specific actions/initiatives the experts think are necessary and doable.

### Technical Details

#### Trust Networks

A ***Trust Network*** is a computer-readable representation of what we all have in our heads about who we respect and trust, and in what domains. Forming such a data structure out of an amorphous collection of individuals creates huge value and power.

*Trust networks* form *by invitation*, which creates accountability, [security](https://en.wikipedia.org/wiki/Web_of_trust), coherence, resilience, flexibility, ...

They're *fractal*, because subnetworks can form, overlap, and federate at any scale, around domains of expertise, geography, goals, strategies, ...

*Trust networks* start with a few respected sages who act as the initiating *seed crystal*, embodying the principles, goals, and ethic desired. For example, the Climate Smart Swarm might start with 
[@gretathunberg](https://twitter.com/gretathunberg), [@drmichaelemann](https://twitter.com/drmichaelemann), [@varshprakash](https://twitter.com/varshprakash), [@littlemissflint](https://twitter.com/littlemissflint), [@israhirsi](https://twitter.com/israhirsi), [@jamie_margolin](https://twitter.com/jamie_margolin), [@xiuhtezcatl](https://twitter.com/xiuhtezcatl), [@gailbradbrook](https://twitter.com/gailbradbrook), [@naomiklein](https://twitter.com/naomiklein), [@drjamesehansen](https://twitter.com/drjamesehansen), [@drkatemarvel](https://twitter.com/drkatemarvel), [@khayhoe](https://twitter.com/khayhoe), [@jacquelyngill](https://twitter.com/jacquelyngill), [@billmckibben](https://twitter.com/billmckibben), [@drvandanashiva](https://twitter.com/drvandanashiva), [@hmmcgray](https://twitter.com/hmmcgray), [@yebsano](https://twitter.com/yebsano), [@alexsteffen](https://twitter.com/alexsteffen), [@greendig](https://twitter.com/greendig)...

Each new participant is allowed to invite N (10?) unique new participants. 

At that rate, it could span the entire superset of climate organizations and a lot more in a few rounds. Every participant would have a *chain of accountability* back to one or more of the initiators.

Invitations create *edges* in the network representing *trust* or *respect* from the *inviter node* to the *invitee node*. Participants can also *vouch for* others they didn't invite. All such edges can be qualified by domain of expertise and strength of respect.

#### Liquid Democracy

***Trust networks*** are embedded with unique attributes that make new kinds of collective decision-making possible. That is because:

1. the edges in the network (relationships between people) can function as *delegations / proxies*; and
* the connectivity of any *participant node* (its *network centrality* aka *mojo* is a good measure of its authoritativeness relative to the whole.

Number 1 means that all participants are represented by their proxies if they abstain or haven't yet voted. Number 2 means that domain experts can be efficiently empowered with greater influence on outcomes.

The field of *liquid democracy* is mature and there are numerous available tools. In this case, the Decision Server will initially be a simple implementation, and evolve to accommodate the requirements unique to swarming behavior. 

#### Decision Server

The Decision Server is a means of asking the important questions about what the Climate Movement can and should be doing, and exploiting trust networks' embedded capacity for rapid, global-scale decision-making to answer them.

`;

export default markdown;

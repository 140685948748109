import _ from 'lodash';
import { node_normalizer, get_profile } from 'utils/graphNormalize';

export const nodesAndEdges = (data, filterProvisional) => {
  let nodes = [];
  const ins = {};
  const outs = {};
  const { profiles, edges, groups, group_edges } = data || {};
  let nodesToDo = profiles || groups;
  let edgesToDo = edges || group_edges;
  let dupes = [];
  if (nodesToDo && edgesToDo) {
    nodesToDo.forEach(one => {
      if (one) {
        ins[one._id] = [];
        outs[one._id] = [];
        if (one.data.rel) {
          dupes.push(one.data.rel);
        }
      }
    });
    nodesToDo = nodesToDo.filter(one => {
      return !dupes.includes(one._id);
    });
    edgesToDo.forEach(edge => {
      ins[edge._to].push(edge._from);
      outs[edge._from].push(edge._to);
    });

    nodesToDo
      .filter(
        one =>
          !(filterProvisional && one?.provisional) &&
          (ins[one._id].length > 0 || outs[one._id].length > 0)
      )
      .forEach((user, index) => {
        const profile = get_profile(user);
        if (profile) {
          let node = node_normalizer(profile);
          node.id = user._id;
          node.ins = ins[user._id];
          node.outs = outs[user._id];
          nodes.push(node);
        } else if (
          user.type == 'GROUP' ||
          user._id.indexOf('Groups/') == 0 ||
          user._id.indexOf('Questions/') == 0 ||
          user._id.indexOf('Options/') == 0
        ) {
          const fullname = user.data.fullname || user.data.title || user.data.label; // group or question or option
          let node = {
            id: user._id,
            fullname,
            ins: ins[user._id],
            outs: outs[user._id]
          };
          if (user._id.indexOf('Options/') == 0) {
            node.symbolType = 'square';
            node.size = 20;
          }
          nodes.push(node);
        }
      });
    nodes = _.sortBy(nodes, node => {
      return -(node.ins ? node.ins.length : 0);
    });
  } else {
    // console.log('Error getting profiles, missing either profiles or edges in data', data);
  }
  return [nodes];
};

export const nodesAndEdgesNoGenerations = (data, filterProvisional) => {
  /* Attaches Ins and Outs to each profile, pulled from edges, e.g. so hovering over a node can show its edges easily */
  return nodesAndEdges(data, filterProvisional);
};

import { Box } from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import {
  getSquarifiedTree,
  findRootNode,
  replaceParent,
  truncateToMaxDepth,
  setParentIds,
  generateParentContainers
} from './utils';
// @ts-ignore
import TreeMapLabel from './TreeMapLabel';
// @ts-ignore
import TreeMapLeaf from './TreeMapLeaf';
import { sortChildren } from 'utils/treemapUtils';

const TreeMap = ({
  data,
  rootNodeId,
  showLabels,
  maxDepth = 3,
  clickdelay = 250,
  click,
  dblclick,
  rightclick,
  mouseover,
  mouseout,
  mouseenter,
  mouseleave,
  labelclick
}) => {
  const localData = useMemo(() => {
    // ensure we are not mutating by making a copy....
    let tree = JSON.parse(JSON.stringify(data));
    sortChildren(tree);
    if (rootNodeId) {
      tree = findRootNode(tree, rootNodeId);
    }
    if (!tree) console.log('no tree. wrong root node?');
    if (!(tree instanceof Array)) {
      tree = [tree];
    }
    if (maxDepth && maxDepth > 0) {
      // truncate the children depth
      tree = truncateToMaxDepth(tree, maxDepth);
    }
    tree = setParentIds(tree);
    return tree;
  }, [data, rootNodeId, maxDepth]);

  // const topLevelNode = useMemo(() => {

  // })

  // calculate the output
  let nodes = getSquarifiedTree(localData);
  const rootNode = localData[0];

  const showTopLabel = showLabels && rootNode.id !== nodes[0].id;

  const parentContainers = generateParentContainers(nodes, data, rootNode);
  const mergedNodes = useMemo(() => {
    let mergedNodes = [...nodes];
    for (const parent of Object.values(parentContainers)) {
      mergedNodes = replaceParent(mergedNodes, parent);
    }
    return mergedNodes;
  }, [nodes, parentContainers]);

  return (
    <Box width='100%' height='100%'>
      {showTopLabel && (
        <TreeMapLabel
          click={labelclick}
          node={rootNode}
          mouseover={mouseenter}
          mouseout={mouseleave}
        />
      )}
      <Box width='100%' height={showLabels ? `calc(100% - 14px)` : '100%'} position='relative'>
        {mergedNodes.map((leaf, idx) => {
          return (
            <TreeMapLeaf
              labelclick={labelclick}
              key={idx}
              leaf={leaf}
              clickdelay={clickdelay}
              click={click}
              dblclick={dblclick}
              rightclick={rightclick}
              mouseover={mouseenter}
              mouseout={mouseleave}
              depth={0}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default TreeMap;

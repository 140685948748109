import {
  ADD_CHAT_DATA,
  DROP_CHAT_DATA,
  UPDATE_CHAT_DATA,
  CHAT_IS_REQUESTING,
  CHAT_REQUEST_ERROR,
  CHAT_SUBSCRIBE,
  CHAT_UNSUBSCRIBE
} from 'shared/constants/actionTypes';

const initialState = {
  chatData: [],
  isRequesting: false,
  subscriptions: new Set([]),
  error: ''
};

const chatService = function (state = initialState, action) {
  switch (action.type) {
    case ADD_CHAT_DATA:
      if (!state.chatData.find(data => data._id === action.payload._id)) {
        return {
          ...state,
          isRequesting: false,
          error: '',
          chatData: [...state.chatData, action.payload]
        };
      }
      return state;

    case UPDATE_CHAT_DATA:
      return {
        ...state,
        isRequesting: false,
        error: '',
        chatData: state.chatData.map(data => {
          if (data._id === action.payload._id) {
            return action.payload;
          }
          return data;
        })
      };

    case DROP_CHAT_DATA: {
      const { chatId, contentId } = action.payload;
      return {
        ...state,
        chatData: state.chatData
          .map(data => chatId && data._id !== chatId && contentId && data.contentId !== contentId)
          .filter(data => !!data)
      };
    }

    case CHAT_IS_REQUESTING:
      return {
        ...state,
        isRequesting: true
      };

    case CHAT_REQUEST_ERROR:
      return {
        ...state,
        isRequesting: false,
        error: action.payload
      };

    case CHAT_SUBSCRIBE: {
      const subs = state.subscriptions;
      subs.add(action.payload);
      return {
        ...state,
        subscriptions: subs
      };
    }

    case CHAT_UNSUBSCRIBE: {
      const subs = state.subscriptions;
      subs.delete(action.payload);
      return {
        ...state,
        subscriptions: subs
      };
    }

    default:
      return state;
  }
};

export default chatService;

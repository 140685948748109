import React, { useEffect, useState } from 'react';

import { Select } from '@chakra-ui/react';
import usePeopleNetwork from 'hooks/usePeopleNetwork';
import LocalStorage from 'utils/LocalStorage';
import useNoo from 'hooks/useNoo';

const ToolsSelector = props => {
  const { tools, onToolChange, current } = props;
  const { currentNetwork: graph, isRequesting: fetching, getSpecificUsers } = usePeopleNetwork();
  const { nooUser, currentNetworkData } = useNoo('ToolsSelector');
  const netname = currentNetworkData?.id;
  const [myStorage, setMyStorage] = useState(null);
  useEffect(() => {
    setMyStorage(new LocalStorage(netname || 'unknown', nooUser?.person._id || 'user'));
  }, [setMyStorage, netname, nooUser]);

  const noop = () => {};
  const hasChecks = true;

  const getChecked = force => {
    // const them = myStorage.get('checked_users', force) || [];
    const them = myStorage.getItem('checked_users') || [];
    // setChecked(them);
    return them;
  };

  const onToolSelect = e => {
    let current = getChecked();
    const sel = e.target.value;
    const func = tools_combined[sel];
    if (func) func(current);
    current = getChecked();
    onToolChange(sel, current);
  };

  const showChecked = () => {
    const them = getChecked();
    if (hasChecks && them != null) {
      /*       getSpecificUsers(them);
      // TODO: verify it's correct to unset the groupId here, this does it globally
      setGroupId(null);
      setUserDisplay('My Selections');
      setShowingChecked(true); */
    }
  };

  const clearChecked2 = () => {
    let current = getChecked();
    if (confirm('About to clear ' + current.length + ' checked users. Are you sure?')) {
      myStorage.setItem('checked_users', []);
      // setChecked([]);
      // forceUpdate();
      // history.go(0);
    }
  };

  /*   const saveChecked = () => {
    const owner_id = nooUser.person._id;
    const url = 'file://checked_users_' + owner_id;
    const onSuccess = response => {
      console.log('read file current', response);
      const doc = response.result[0].document[0];
      const current = doc?.data?.checked || [];
      const union = Array.from(new Set([...checked, ...current]));

      const data = { url, checked };
      writeFile({
        type: 'document',
        data,
        owner_id,
        nooCommandDirect,
        onSuccess: resp => {
          console.log('saved', resp);
        },
        onError: error => {
          console.log('error saving', error);
        }
      });
    };
    const onError = error => {
      console.log('error ', error);
    };

    readFile({ url }, nooCommandDirect, onSuccess, onError);
  };
  const restoreChecked = () => {
    const pid = nooUser.person._id;
    const url = 'file://checked_users_' + pid;
    const onSuccess = response => {
      try {
        const doc = response.result[0].document[0];
        const current = doc?.data?.checked || [];
        const union = Array.from(new Set([...checked, ...current]));
        console.log(
          'current checked',
          checked.length,
          'from saved',
          current.length,
          'union',
          union.length
        );
        setChecked(union);
        myStorage.set('checked_users', union);
      } catch (error) {
        alert('Could not restore checked users: ', error);
      }
    };
    const onError = error => {
      console.log('error ', error);
    };
    readFile({ url }, nooCommandDirect, onSuccess, onError);
  }; */

  const tools_options = {
    'choose one': noop,
    'show selected': showChecked,
    'clear selected': clearChecked2
    /*     'save selected': saveChecked,
    'restore selected': restoreChecked */
  };

  const tools_combined = { ...tools, ...tools_options };
  return (
    <Select
      onChange={onToolSelect}
      padding={'1px '}
      sx={{ backgroundColor: '#fff', height: '30px', paddingTop: '1px' }}
    >
      {Object.entries(tools_combined).map((pair, i) => {
        return (
          <option value={pair[0]} placeholder={'choose one'} key={i}>
            {pair[0]}
          </option>
        );
      })}
    </Select>
  );
};

export default ToolsSelector;

// moved it out of the component since it's a constant at this time.
// also, able to tell prettier to leave formatting alone if it's separate...
export const selector_setup ={'105092773,105099717,1432916024358232064,1442496192940691463,1442678657948979201,1444526773446877185': {'group': 'nature',
'name': 'Nature'},
'105092773': {'group': 'oceans',
'name': 'Ocean'},
'105110097,105112361,1417555661727145988,189289958': {'group': 'mind',
'name': 'Mind'},
'108241263,1443701976861184000,1491184931493625856': {'group': 'society',
'name': 'Indigenous'},

'1417536326598111238,1419673886824796161,1441963276171194369,1442117489874309123,1445014744423927809,1448060344862117889': {'group': 'science',
                    'name': 'Science'},
'1421495010227261440,1435326532633997317,1440732283363749896,1441109253293424643': {'group': 'environment',
'name': 'Environment'},

'1430158629295034368,1447552141246222338': {'group': 'tech', 'name': 'Tech'},
'1441105340079083524,1454124308276596743,1491190790978260992': {'group': 'climate',
'name': 'Climate'},
 '1493617402030047239': {'group': 'catholic', 'name': 'Catholic Climate'},
 '1494445753758064644': {'group': 'privacy', 'name': 'Data Privacy'},
 '1498646952749187077': {'group': 'canada_climate', 'name': 'Canada Climate'}
  }
;
const alts = {'108254154': {'group': 'general', 'name': 'General'},'1440052026654687232': {'group': 'finance', 'name': 'Finance'}, '1422194707803824128': {'group': 'food', 'name': 'Food'}}

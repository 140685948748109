export const readFile = (filters, nooCommandDirect, onSuccess, onError, loading) => {
  const payload = {
    query_name: 'aqlGetDocument', // 'aqlGetDocumentByUrl',
    data: filters
  };
  const params = {
    payload,
    setLoading: loading || (() => {}),
    setResponse: response => {
      onSuccess(response);
    },
    setResponseError: error => {
      console.log('error ', error);
      onError(error);
    }
  };
  nooCommandDirect('aql', params);
};

export const writeFile = ({
  type,
  data,
  owner_id,
  bundle,
  nooCommandDirect,
  onSuccess,
  onError,
  loading
}) => {
  if (data.url) {
    let payload = { resource: { type, data } };
    if (bundle) payload.bundle = bundle;
    if (owner_id) payload.person_id = owner_id; // causes OWNS edge to doc
    const params = {
      data: payload,
      setLoading: loading || (() => {}),
      setResponse: response => {
        // console.log('resp', response);
        onSuccess(response);
      },
      setResponseError: error => {
        console.log('error ', error);
        onError(error);
      }
    };
    // console.log('submitting document', params);
    nooCommandDirect('createDocument', params);
  }
};

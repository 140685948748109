import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const ContentSubpanel = styled.div`
  margin: 0 auto;
`;

export const WhiteBox = styled(Box)`
  background-color: #fff;
`;

import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Button,
  Input,
  Link,
  FormLabel,
  Spinner,
  Box,
  Heading,
  Textarea,
  Text
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { ADMIN_ROLE } from 'shared/constants';
import useNoo from 'hooks/useNoo';
import useInvitations from 'hooks/useInvitations';
import UserAuth from './UserAuth';
import useNooApi from 'hooks/useNooApi';
import { createPerson } from 'redux/actions/noo';
import formatResponse from 'utils/formatResponse';

import { useDispatch } from 'react-redux';

const AccountFAN = () => {
  const { googleUser: user, nooUser, loading, currentNetworkData, siteSettings } = useNoo(
    'AccountFAN'
  );
  var params = new URLSearchParams(useLocation().search);
  console.log('params', params);
  const ott = params.get('ott');
  const { nooCommandDirect } = useNooApi();
  const dispatch = useDispatch();
  const responseRef = useRef(null);
  const [resp, setResp] = useState();

  const readUserByOTT = useCallback(ott => {
    console.log('fetching', ott);
    fetch(`/api/fan/ott/${ott}`)
      .then(async response => {
        console.log('ott resp', response);
        if (response.ok) {
          const json = await response.json();
          setResp('resp2 = ' + JSON.stringify(json));
          console.log('json', json);
        } else {
          console.log('bad response ');
        }
      })
      .catch(error => {
        console.log('there was an error getting by ott');
      });
  }, []);

  const network = currentNetworkData;

  const onCallRead = () => {
    readUserByOTT(ott);
  };
  console.log('OTT', ott, 'resp', resp);
  // readUserByOTT(ott);
  useEffect(() => {
    async function handleUser(ott) {
      // see handleUser in useFirebaseAuth
      readUserByOTT(ott);
    }
    if (ott) handleUser(ott);
  }, [dispatch, ott, readUserByOTT, user]);
  const domain = siteSettings?.fanLoginDomain || 'http://192.168.1.7:3001';
  const href = `${domain.replace(':3001', ':3000')}/fan-claim/` + ott;

  return (
    <Box>this should no longer be called. If you see this, then we need to fix something.</Box>
  );

  // return (
  //   <Box>
  //     <Box className='App-row'>
  //       <Heading as='h1'>{`Welcome ${user?.displayName} ${ott}`}</Heading>
  //       <Box className='App-card'>
  //         <Box>
  //           <Button onClick={onCallRead}>{'Read FAN person'}</Button>
  //         </Box>
  //         <a href={href}>fake link to simulate redirect</a>
  //       </Box>
  //       <Text>{JSON.stringify(siteSettings, null, 2)}</Text>
  //       <Textarea
  //         ref={responseRef}
  //         id='grpcResponse'
  //         name='grpcResponse'
  //         // style={{ height: responseHeight }}
  //         readOnly={true}
  //         value={resp}
  //       />
  //     </Box>
  //   </Box>
  // );
};

export default AccountFAN;

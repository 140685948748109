import { ADMIN_ROLE } from 'shared/constants';
import { getNetworkData } from 'shared/config';
import {
  NOO_SET_LOADING,
  NOO_SET_USER,
  // NOO_SET_GOOGLE_USER,
  NOO_SET_CURRENT_NETWORK,
  // NOO_SET_ADMIN,
  NOO_USER_LOGOUT
} from 'shared/constants/actionTypes';
// import { get_default_network } from 'hooks/useMultiDomain';

import nooApi from 'api';

const initialState = {
  loading: false,
  nooUser: null,
  // DEVNOTE: setting this to null for now, domainAppConfig takes care of setting the default currentNetwork by domain
  // if you do set this statically, then it will override the domain check
  // get_default_network can also be used here, which will try to find a net name by domain
  currentNetwork: null, // 'best_of', //  'greencheck', // 'commonsgraph', // 'climate', // 'psyche', // 'ph', // default value
  currentNetworkData: {},
  isAdmin: false
};

const noo = function (state = initialState, action) {
  // const _state = { ...state };
  switch (action.type) {
    case NOO_SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case NOO_SET_USER:
      const u = action.payload?.person?._id ? action.payload : action.payload.nodes[0];
      return {
        ...state,
        nooUser: u,
        isAdmin:
          u?.person?.data?.role === ADMIN_ROLE ||
          u?.person?.role === ADMIN_ROLE ||
          u?.role == ADMIN_ROLE
      };
    // case NOO_SET_GOOGLE_USER:
    //   return {
    //     ...state,
    //     googleUser: action.payload
    //   };
    case NOO_USER_LOGOUT:
      nooApi.setAuthToken();
      return {
        ...state,
        nooUser: null
      };
    case NOO_SET_CURRENT_NETWORK:
      return {
        ...state,
        currentNetwork: action.payload,
        currentNetworkData: getNetworkData(action.payload)
      };
    // case NOO_SET_ADMIN:
    //   return {
    //     ...state,
    //     isAdmin: action.payload
    //   };
    default:
      return state;
  }
};

export default noo;

/* eslint-disable no-unused-vars */
/*
 * Account component
 *
 */
import React, { useState, useEffect } from 'react';
import { Button, Box, Heading, FormLabel, Spinner, Textarea } from '@chakra-ui/react';
import AboutPopover from 'components/Segments/AboutPopover';
// import { Link as RouterLink } from 'react-router-dom';
// import { ADMIN_ROLE } from 'shared/constants';
// import NetworkUser from './Networks/NetworkUser';
import SimpleCloud from './Segments/SimpleCloud';
import SimpleMap from './Segments/SimpleMap';
// import WordCloud from './wordcloud';
// import D3Cloud from './d3_cloud';
import { useParams } from 'react-router-dom';

/*
let time = 1626277183 
let lists = (for l in lists filter l._key in  ["1865999", "1157025833065897988", "1266429536423383041", "1116455078750228481", "1324870197598064642", "1300512581535150080", "1280578311974817792", "1185940890231230464", "1076570061719126017", "105114252", "1271477408214876161"] return l._id)

let post_ids = ( for lid in lists
FOR v, e, p  IN 1..1  OUTBOUND lid GRAPH "tweets_graph" 
FILTER e.ts >= time RETURN e._to ) 
let urls = (
    for e in edges
      filter(e.type == "url_of")
      filter(e._from IN post_ids)
      COLLECT id = e._to INTO g
      return { id, count: count(g), doc: document(id)}
)

let uids = (for i in urls
  filter i.count > 1
  sort i.count desc
  return i)

return uids
*/
import _ from 'lodash';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import formatResponse from 'utils/formatResponse';
import { useDispatch } from 'react-redux';

const D3Cloud = () => <Heading as='h2'>D3 Cloud will not load with React 17</Heading>;

function genRandomTree(N = 300, reverse = false) {
  return {
    nodes: [...Array(N).keys()].map(i => ({ id: i })),
    links: [...Array(N).keys()]
      .filter(id => id)
      .map(id => ({
        [reverse ? 'target' : 'source']: id,
        [reverse ? 'source' : 'target']: Math.round(Math.random() * (id - 1))
      }))
  };
}

const Test = () => {
  const { currentNetworkData, isAuthorized } = useNoo('Test');
  const dispatch = useDispatch();

  const { nooCommand, nooCommandResponse } = useNooApi();

  const network = currentNetworkData;
  const [invitationId, setInvitationId] = useState('');
  const [aqlQuery, setAqlQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState('{}');
  const { origin } = useParams();

  useEffect(() => {
    console.log('resp ', nooCommandResponse);
    if (nooCommandResponse?.command === 'aql') {
      setResponse(nooCommandResponse.data);
    }
  }, [nooCommandResponse]);

  const onUpdateInvitationId = e => {
    setInvitationId(e.target.value);
  };
  const onCallAcceptInvitation = () => {
    if (isAuthorized && invitationId) {
      dispatch(nooCommand('acceptInvitation', { invitationId }));
    }
  };
  const onCallCreateInvitation = subnet => {
    if (isAuthorized && subnet) {
      const config = { subnet: subnet, type: 'invite' };
      dispatch(nooCommand('createInvitation', { config }));
    }
  };

  const _OPENER = window.opener;
  if (_OPENER) {
    // const origin = 'https://www.linkedin.com/feed/'; // 'http://standingwave.net/apps/message_windows/'; // _OPENER.origin
    const orig = decodeURIComponent(origin);
    console.log('opener', _OPENER, orig);
    _OPENER.postMessage('success', orig);
  }
  const is_sharebnb = network.id === 'sharebnb';

  const handleHover = e => {
    console.log('test handlehover', e);
  };

  const updateCoords = e => {
    console.log('test update coords', e);
  };

  const idx = 13;
  const display_weight = 11;
  const node = {
    idx: idx,
    weight: 0.5,
    status: 'highlit',
    nm: 'foobar',
    mj: 111,
    fn: 'Foo Bar',
    desc: 'This is a description'
  };

  const words_tag = [
    { value: 'jQuery', count: 25 },
    { value: 'MongoDB', count: 18 },
    { value: 'JavaScript', count: 38 },
    { value: 'React', count: 30 },
    { value: 'Nodejs', count: 28 }
  ];
  const words_d3 = _.map(words_tag, one => {
    return { text: one.value, value: one.count };
  });
  const fontSizeMapper = word => word.value * 30; // Math.log2(word.value) * 5;

  const onWordClick = word => {
    console.log('on word click ', word);
    alert(word);
  };
  const onUpdateAqlQuery = e => {
    setAqlQuery(e.target.value);
  };
  const onCallAqlSearch = () => {
    const payload = { query_name: 'aqlFreeform', data: { query: aqlQuery } };
    dispatch(nooCommand('aql', { payload }));
  };
  // <UserComponent node={node} key={idx} weight={display_weight} handleHover={handleHover} status={node.status || 'neutral'}  updateCoords={updateCoords} />
  // <WordCloud />
  // <SimpleCloud />
  // <D3Cloud words={words_d3} onWordClick= />
  /* 
        <D3Cloud
        words={words_d3}
        width={350}
        height={350}
        onWordClick={onWordClick}
        fontSizeMapper={fontSizeMapper}
      />

      <SimpleCloud />
  */
  const font = 'helvetica';
  return (
    <Box>
      {' '}
      {/* className='wordcloud' */}
      {/* <AboutPopover /> */}
      {/* <SimpleCloud /> */}
      <SimpleMap />
      {/*       <FormLabel htmlFor='aql'>AQL query:</FormLabel>
      <textarea type='text' id='aql' name='aql' value={aqlQuery} onChange={onUpdateAqlQuery} />
      <Button onClick={onCallAqlSearch} disabled={loading || aqlQuery === ''}>
        {'AQL Search'}
      </Button>

      <FormLabel htmlFor='grpcResponse'>GRPC Response:</FormLabel>
      <Textarea
        id='grpcResponse'
        name='grpcResponse'
        readOnly={true}
        value={formatResponse(response)}
      /> */}
    </Box>
  );
};

export default Test;

import React from 'react';
import {
  Box,
  Button,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Text,
  VStack
} from '@chakra-ui/react';

const ForgotPasswordForm = ({ localData, onFieldUpdate, handleForgot, setUiType }) => {
  return (
    <Box>
      <Heading as='h3' fontWeight='normal' fontStyle='italic' size='md'>
        Enter your email address, if we find your account
        <br />
        we&#39;ll send password reset instructions.
      </Heading>

      <HStack my='10px' alignItems='flex-start' width='440px' mx='auto'>
        <FormLabel htmlFor='f_email' width='80px' mt='8px'>
          Email
        </FormLabel>
        <VStack alignItems='flex-start' flex='1'>
          <Input
            type='text'
            placeholder='Email'
            name='f_email'
            value={localData.f_email || ''}
            onChange={onFieldUpdate}
          />
          <Button onClick={() => handleForgot()}>Send Password Reset</Button>
        </VStack>
      </HStack>

      <Text fontSize='11px' textAlign='center' mt='1.5em'>
        <Link href='#' onClick={() => setUiType('login')} color='teal.400'>
          Back to login
        </Link>
      </Text>
    </Box>
  );
};

export default ForgotPasswordForm;

const markdown = `
How can making your tiny house available to friends of friends help the housing crisis?

Stayforward provides a way to help our communities as well as a fun new way to travel, or just stay at home, and meet great, like-minded folks.

##### We can unlock all those dwellings without having to offer them to the world.

We encourage guests to pay what they think the stay is worth, and hosts to donate what they earn to housing relief.

StayForward is an invitation-only social network of hosts and guests. Because membership is by invitation, every member is a friend of a friend. Each member/user can set the number of “degrees of separation” they feel comfortable either staying with, or hosting.

You might only want to host immediate friends, your daughter may be comfortable staying with friends of friends, your cousin is fine with hosting or staying with a wider circle yet, and so on.
`;

export default markdown;

import React from 'react';
import useNoo from 'hooks/useNoo';
import SignInOrRegister from 'components/Segments/SignInOrRegister';
import useAuthRedirect from 'hooks/useAuthRedirect';
import useFirebaseAuth from 'hooks/useFirebaseAuth';

import { Box, Text, Button, useToast } from '@chakra-ui/react';

// simple wrapper to provide protection for components / anything that needs user auth
// future: can expand this to accept an auth level, in addition to logged in / not logged in

export const EmailNotice = () => {
  const { sendVerificationEmail } = useFirebaseAuth();
  const toast = useToast();
  return (
    <Box
      margin='1em auto'
      width={'260px'}
      borderRadius='lg'
      boxShadow='0px 1px 4px 0px rgba(0, 0, 0, 0.5)'
      padding='1em'
      textAlign='left'
      backgroundColor='cyan.800'
      color='white'
    >
      <Text>You need to verify your email.</Text>
      <Button
        onClick={() =>
          sendVerificationEmail(
            msg => {
              console.log('email sent', msg);
              toast({
                title: 'Verification email sent',
                description: 'To your logged in user email',
                status: 'success',
                duration: 3000,
                isClosable: true
              });
            },
            error => {
              console.log('email error', error);
              toast({
                title: error.message,
                description: error.code,
                status: 'error',
                duration: 10000,
                isClosable: true
              });
            }
          )
        }
        color='black'
      >
        Send verification email
      </Button>
    </Box>
  );
};
const UserAuth = props => {
  const { children, unauthorized, redirectTo, inHeader } = props;
  const { setRedirect } = useAuthRedirect();

  if (redirectTo) {
    setRedirect(redirectTo);
  }

  const { googleUser, fanUser, isAuthorized } = useNoo('UserAuth');

  // console.log('fanU', fanUser, 'authed?', isAuthorized);
  if (!isAuthorized) {
    // !googleUser && !fanUser) {
    switch (unauthorized) {
      case 'signin':
        return (
          <Box
            textAlign='center'
            backgroundColor='cyan.400'
            color='white'
            width={['85%', '68%', '47%', '35%']}
            m='1em auto'
            padding='0.5em 0'
            borderRadius='3px'
            sx={{
              a: { color: 'cyan.900', fontWeight: 'bold' }
            }}
          >
            <SignInOrRegister showHeader={false} redirectTo={redirectTo} />
          </Box>
        );
      default:
        return null;
    }
  } else if (googleUser && googleUser.emailVerified == false) {
    if (inHeader) {
      return <React.Fragment>{children}</React.Fragment>;
    } else {
      return <EmailNotice />;
    }
  }

  return <React.Fragment>{children}</React.Fragment>;
};

// UserAuth.defaultProps = {};

export default UserAuth;

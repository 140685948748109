import {
  SET_BIOREGION_DATA,
  SET_BIOREGION_IS_FETCHING,
  BIOREGION_FETCH_ERROR
} from 'shared/constants/actionTypes';

const initialState = {
  data: {},
  fetchErrors: {}
};

const bioregions = function (state = initialState, action) {
  switch (action.type) {
    case SET_BIOREGION_DATA: {
      const { segment, data } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [segment]: { data }
        },
        fetchErrors: {
          ...state.fetchErrors,
          [segment]: undefined
        }
      };
    }

    case SET_BIOREGION_IS_FETCHING: {
      const { segment } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [segment]: { isFetching: true }
        },
        fetchErrors: {
          ...state.fetchErrors,
          [segment]: undefined
        }
      };
    }

    case BIOREGION_FETCH_ERROR: {
      const { segment, error } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [segment]: undefined
        },
        fetchErrors: {
          ...state.fetchErrors,
          [segment]: { error }
        }
      };
    }

    default:
      return state;
  }
};

export default bioregions;

import React, { /*useState, useEffect, useCallback, */ useCallback, useMemo } from 'react';
import _ from 'lodash';
// import { useDispatch } from 'react-redux';
import { HStack, Select, Text } from '@chakra-ui/react';
// import styled from '@emotion/styled';
import useGroups from 'hooks/useGroups';
import useNoo from 'hooks/useNoo';
import { iterate_object, derivePrefixedNodeId } from 'utils';

const TaxoSelector = props => {
  const { callback, taxo, nodes, label, showAnyOption = true, width = '300px' } = props;

  const onSelectOption = event => {
    const oid = event.target.value;
    if (callback) {
      callback(oid, nodes[oid]);
    }
  };
  const onBlurGroup = event => {
    if (nodes.length == 1) {
      onSelectOption(event);
    }
  };

  const nestedOption = useCallback(
    (oid, depth) => {
      const indent = '\u00A0'.repeat(depth * 4);
      const node = nodes[oid]; // _.find(nodes, { _id: oid });
      const name = node.name || oid;
      // const mine = my_groups.includes(gid) ? ' * ' : '';
      const disp = indent + name; // + mine;
      return (
        <option value={oid} key={oid}>
          {disp}
        </option>
      );
    },
    [nodes]
  );

  const options = useMemo(() => {
    let opts = [];
    const cb = (oid, v, depth, path) => {
      const valid = oid;
      if (valid) {
        opts.push(nestedOption(oid, depth));
      }
    };
    if (taxo) {
      iterate_object(taxo, 0, '', cb);
    }

    if (opts?.length > 0) {
      if (showAnyOption) {
        opts.unshift(
          <option value='' key='group-any-option'>
            Any
          </option>
        );
      }
      return opts;
    }
    return null;
  }, [taxo, nestedOption, showAnyOption]);

  return (
    <HStack sx={{ width }}>
      <Text>{label}</Text>
      <Select
        onChange={onSelectOption}
        onBlur={onBlurGroup}
        value={''}
        w={width}
        bg='#fff'
        height='30px'
        _focus={{ outline: 0 }}
      >
        {options}
      </Select>
    </HStack>
  );
};

export default TaxoSelector;

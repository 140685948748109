import React, { useMemo } from 'react';
import _ from 'lodash';
import styled from '@emotion/styled';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Button, Flex, Box, Stack, Text, Heading, Link, Image } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import useNoo from 'hooks/useNoo';

export const ImageConstrainer = styled.div`
  > img {
    max-width: 100%;
    border: 1px solid #000;
    margin-top: 10px;
  }
`;

const EditButton = styled(Button)`
  cursor: pointer;
`;

const OwnerSidebar = props => {
  const { data } = props;
  if (!data) {
    return null;
  }
  let imageUrl;
  const displayData = [];
  for (let k in data) {
    switch (k) {
      case 'picture':
      case 'photoURL':
        if (!imageUrl) {
          imageUrl = data[k];
        }
        break;
      default:
        displayData.push({ type: k, value: data[k] });
    }
  }
  return (
    <ImageConstrainer borderTop='4px solid #eee' pt='10px' mt='10px'>
      <Text mb='0' fontStyle='italic'>
        Owner:
      </Text>
      {imageUrl && <Image src={imageUrl} />}
      {displayData.map((node, idx) => {
        const isName = node.type === 'displayName';
        return (
          <Box key={idx} fontWeight={isName ? 'bold' : 'normal'}>
            {node.value}
          </Box>
        );
      })}
    </ImageConstrainer>
  );
};

const CarouselNode = styled(Box)`
  height: 400px;
  position: relative;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  > p {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 4px auto;
    margin: 0;
  }
`;

const getLocationLabel = type => {
  switch (type) {
    case 'zipCode':
      return 'Zip Code';
    case 'state':
      return 'State';
    case 'Country':
      return 'Country';
    default:
      return type;
  }
};

const Display = props => {
  const { data } = props;
  const { nooUser } = useNoo('ListingDisplay');

  const onEditListing = () => {
    alert('coming soon');
  };

  console.warn('data for display!!!');
  console.log(data);

  const listing = useMemo(() => {
    if (data?.listing?.data) {
      return data.listing.data;
    }
    return null;
  }, [data]);

  const owner = useMemo(() => {
    if (data?.owner?.data?.profile) {
      return data.owner.data.profile;
    }
    return null;
  }, [data]);

  const isOwner = useMemo(() => {
    const ownerId = data?.owner?._id;
    return ownerId && ownerId === nooUser?.person?._id;
  }, [nooUser, data]);

  const locationInfo = useMemo(() => {
    if (listing) {
      const locProps = ['zipCode', 'state', 'country'];
      const locData = [];
      locProps.map(P => {
        if (listing[P]) {
          locData.push({ type: P, value: listing[P] });
        }
      });
      if (locData.length) {
        return locData;
      }
    }
    return null;
  }, [listing]);

  if (!listing || !owner) {
    return <Heading as='h3'>Sorry, there was an error loading the listing, no data found.</Heading>;
  }

  let images = listing.imageIds;
  if (typeof images === 'string') {
    images = [images];
  }
  let imageMetadata = listing.imageMetadata || {};

  /*
    TODO:

    chakra-ui has done something to break the carousel thumbnail display,
    it no longer wants to be constrained in its container and blows out the page width.

    Need to figure out why this is happening.

    So far, found that setting a static width on:
      div.carousel-root > div.carousel > div.thumbs-wrapper
    
      ... will constrain it properly.

    there MUST be something in the Chakra reset that is causing the problem.

    backstop solution:

      use the react-cool-measure hook to measure the Flex > Stack width
      WITHOUT the carousel rendered, then somehow set up some dynamic css
      to apply it on the fly via styled-components, like
      StyledStack = styled(Stack)`
      & .carousel-root {
        .carousel {
          .thumbs-wrapper: {
            width: {CALCULATED_WIDTH}px
          }
        }
      }
      `
    
    INTERIM:
      thumbnails are turned off (by renaming the renderThumbs function below)
  */

  return (
    <Flex className='listing'>
      <Stack flex='1' pr='1em'>
        <Heading as='h1' className='listing-title'>
          {listing?.title}
        </Heading>
        <Text my='1em'>{listing.description}</Text>

        {images?.length && (
          <Box>
            <Carousel
              autoPlay={false}
              infiniteLoop={true}
              stopOnHover={false}
              renderThumbs={() => {
                return images.map((im, idx) => {
                  return <Image key={idx} src={im} alt='' />;
                });
              }}
            >
              {images.map((im, idx) => {
                // future: image object will likely not be a string, temp deconstruct:
                const imageUrl = im;
                const meta = imageMetadata[im] || {};
                const caption = meta.caption || 'eventual caption';
                // return <Box key={idx}>wtf</Box>;
                // eslint-disable-next-line no-unreachable
                return (
                  <CarouselNode key={idx} backgroundImage={`url('${imageUrl}')`}>
                    <Text>{caption}</Text>
                  </CarouselNode>
                );
              })}
            </Carousel>
          </Box>
        )}
      </Stack>
      <Box width='200px'>
        {locationInfo && (
          <>
            <Heading as='h3' mb='0' fontSize='2xl'>
              Location
            </Heading>
            {locationInfo.map((li, idx) => {
              return (
                <Box key={idx}>
                  {getLocationLabel(li.type)}: {li.value}
                </Box>
              );
            })}
          </>
        )}
        {listing.webpage && (
          <Box>
            <Link href={listing.webpage} isExternal>
              Click for More Info <ExternalLinkIcon mx='2px' />
            </Link>
          </Box>
        )}

        <OwnerSidebar data={owner} />
        {isOwner && <EditButton onClick={onEditListing}>Edit Listing</EditButton>}
      </Box>
    </Flex>
  );
};

export default Display;

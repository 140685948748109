import React, { useMemo } from 'react';
import { Radio, HStack, Text, Select } from '@chakra-ui/react';

const times = [1, 2, 5, 10, 20, 30, 60, 1000];

const RadioTime = ({ times, value, name, handleChoice }) => {
  const radioName = (name || '') + 'TimeChooserRadio';
  const radios = times.map((t, idx) => (
    <Radio key={idx} value={t} name={radioName} isChecked={value === t} onChange={handleChoice}>
      <Text as='span' fontSize={['xs', 'sm', 'md']}>
        {t}d
      </Text>
    </Radio>
  ));
  return <>{radios}</>;
};

const SelectorTime = ({ times, value, name, handleChoice, width, size }) => {
  const options = times.map((t, idx) => (
    <option value={t} key={idx}>
      {t}d
    </option>
  ));

  return (
    <Select
      name={name}
      onChange={handleChoice}
      value={value}
      bg='#fff'
      _focus={{ outline: '0' }}
      width={width}
      size={size}
    >
      {options}
    </Select>
  );
};

const TimeChooser = props => {
  const {
    name,
    value,
    handleChoice,
    type = 'radio',
    controlOnly = false,
    width = '120px',
    size = 'md'
  } = props;

  const TimeSelector = useMemo(() => {
    switch (type) {
      case 'radio':
        return RadioTime;
      case 'select':
        return SelectorTime;
    }
  }, [type]);

  const timeProps = useMemo(() => {
    let alltimes = [...times];
    if (!alltimes.includes(value)) {
      alltimes.push(value);
      alltimes.sort(function (a, b) {
        return a - b;
      });
    }
    return {
      times: alltimes,
      value,
      name,
      handleChoice,
      width,
      size
    };
  }, [handleChoice, name, size, value, width]);

  if (controlOnly) {
    return <TimeSelector {...timeProps} />;
  }

  return (
    <HStack wrap='wrap' space='2' mb='8px'>
      <Text>Time Range</Text>
      <TimeSelector {...timeProps} />
    </HStack>
  );
};

export default TimeChooser;

const markdown = `




#### Know your human

  GreenCheck is a simple, cooperative approach to trust, identity, and community. It is a ***by-invitation mutual validation network***, to be formalized as a ***[network cooperative](/about?index=6)***.
  
  GreenCheck makes it easy for each of us to assert *proof of personhood* (uniqueness in the GreenCheck registry), and to validate each other's assertions (aka ***three-friend authentication***).
  
 That uniqueness solves a [lot of problems](/about?index=3) and makes important things like [People Discovery](/about?index=7), [Networks of Trust](/about?index=7), and [Portable Communities](/about?index=5) possible. 
 
GreenCheck is also a tool for ***[human stigmergy](http://pespmc1.vub.ac.be/Papers/Stigmergy-Springer.pdf)***. 
  
* Use [Claim](/claim) to start the process by claiming your social accounts.
* [Praise](/respect) others to amplify them and to facilitate  *people discovery*.
* [Validate](/respect) others who have claimed attributes that assert their uniqueness.  
* See [Timeline](/timeline) for the latest marking activity.
* See [About](/about) for lots more detail.
  
  


`;

export default markdown;

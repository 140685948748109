import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Box, Link, Text, Image, VStack, Tooltip, IconButton, Button } from '@chakra-ui/react';
import { ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons';

import SortableTable from 'components/Segments/SortableTable';
import PostLink from 'components/Invitations/PostLink';
import useSearchResults, { ResultLink } from 'hooks/useSearchResults';
import { truncateTimestamp } from 'utils';

const SearchResultsProfiles = props => {
  const kind = 'documents';
  // group_id was pushed from SearchHeadlines, but doesn't appear to be used in SearchResults anywhere?
  const { data, topic, group_id } = props;
  const [parsedData, setParsedData] = useState(null);

  const {
    defaultColumnProperties,
    map_column_title,
    map_column_sx,
    get_columns
  } = useSearchResults(kind);

  const onUp = _id => {
    console.log('up', _id);
  };
  const onDown = _id => {
    console.log('down', _id);
  };

  useEffect(() => {
    if (data) {
      const it = data?.result?.[0] || data;
      setParsedData(it);
    }
  }, [data, setParsedData]);

  const ellipsis = value => {
    if (value && typeof value == 'string' && value.length > 50) {
      return <Tooltip label={value}>{value.slice(0, 47) + '...'}</Tooltip>;
    }
    return value;
  };

  const documents = useMemo(() => {
    let profiles = {}; // make lookup for profiles
    const people = parsedData?.people || [];
    _.forEach(people, one => {
      if (one) {
        profiles[one._id] = one.data.profile;
      }
    });
    const edges = parsedData?.edges || [];
    const docIds = _.map(edges, '_to');
    let peopleByDoc = {}; // get people of each doc
    _.forEach(docIds, did => {
      peopleByDoc[did] = [];
    });
    _.forEach(edges, one => {
      peopleByDoc[one._to].push(one._from);
    });
    let tagsByDoc = {}; // get tags of each doc
    _.forEach(docIds, did => {
      tagsByDoc[did] = [];
    });
    _.forEach(edges, one => {
      const tgs = one.data?.tags || [];
      _.forEach(tgs, tg => {
        tagsByDoc[one._to].push(tg);
      });
    });
    if (parsedData?.documents) {
      // console.log('DOCS', parsedData?.documents)
      const find_url = new RegExp('//(.*?)/');
      return (
        parsedData.documents
          // .filter(document => document.doc)
          .map((document, index) => {
            const { _id, count, data, url, createDate } = document;
            const ts = Date.parse(createDate) / 1000;
            let pointers = peopleByDoc[_id] || [];
            let names = _.map(pointers, pid => {
              return profiles[pid]?.displayName || profiles[pid]?.fullname;
            });
            names = _.filter(names, x => {
              return x ? true : false;
            });
            const names_trunc =
              names.length > 1 ? names[0] + ' +' + (names.length - 1) : names.join(',');
            const timestamp = truncateTimestamp(ts);
            const tgs = tagsByDoc[_id] || [];
            const tags_trunc = tgs.length > 1 ? tgs[0] + ' +' + (tgs.length - 1) : tgs.join(',');
            if (data) {
              const { title, url, description, site_name, image, imageSecureUrl } = data;
              const source = site_name;
              const imgsrc = image || imageSecureUrl;
              // const imgsrc = image ? image.identifier || image.secure_url || image : null;
              return {
                count: count,
                countSorter: -count,
                titleSorter: title,
                title: (
                  <Box>
                    <Link
                      key={index}
                      className='document'
                      href={url}
                      target='_noo'
                      title={description}
                      fontFamily='helvetica, arial' // "noto serif", // `"Roboto Slab", Georgia, Times, "Times New Roman", serif`, //
                      textDecoration='none'
                      color='#333'
                      fontSize={['0.9em', '1.1em', '1.2em']}
                      lineHeight='1.1em'
                      padding={['8px 0 0', '10px 0 0']}
                    >
                      {title}
                    </Link>
                    {description && typeof description === 'string' ? (
                      <Text
                        fontSize={['.8em', '.9em', '1em']}
                        fontStyle='italic'
                        paddingTop={[0, '5px']}
                      >
                        {ellipsis(description)}
                      </Text>
                    ) : null}
                    {/*                     <Tooltip label='Coming soon'>
                      <Button
                        size='xs'
                        alt={'Coming Soon'}
                        onClick={e => e.preventDefault()}
                        mr='8px'
                        mb='8px'
                        borderRadius='4px'
                        backgroundColor='cyan.200'
                        color='cyan.900'
                      >
                        Discuss
                      </Button>
                    </Tooltip> */}
                  </Box>
                ),
                description: description,
                source,
                names: (
                  <Tooltip label={names.length > 1 ? names.join(', ') : ''} placement='right'>
                    <Text
                      textAlign='center'
                      fontSize={['11px', '13px', '14px']}
                      px={[0, '4px', '10px']}
                    >
                      {names_trunc}
                    </Text>
                  </Tooltip>
                ),
                image: (
                  <Box p='0px' m='0px' height='100%' maxHeight='200px' overflow='hidden'>
                    {imgsrc && (
                      <Image
                        src={imgsrc}
                        alt=''
                        objectFit='cover'
                        height={['70px', '100%', '100%']}
                        borderRadius='10px'
                        mt={[undefined, '4px']}
                        mb={[undefined, '4px']}
                      />
                    )}
                  </Box>
                ),
                ts: (
                  <Text
                    as='span'
                    width={['20%', '20%', '20%']}
                    fontSize={['11px', '13px', '14px']}
                    backgroundColor='cyan.700'
                    color='cyan.200'
                    fontWeight='bold'
                    p='2px 4px'
                    mx={[0, '4px', '10px']}
                    borderRadius='5px'
                  >
                    {timestamp}
                  </Text>
                ),
                tsSorter: ts,
                post: <PostLink group={topic} />,
                rate: (
                  <Tooltip label='Coming soon' placement='right'>
                    <VStack>
                      <IconButton
                        onClick={() => onUp(_id)}
                        sx={{ height: '15px', width: '15px' }}
                        icon={<ArrowUpIcon />}
                      />
                      <span>1.2k</span>
                      <IconButton
                        onClick={() => onDown(_id)}
                        sx={{ height: '15px', width: '15px' }}
                        icon={<ArrowDownIcon />}
                      />
                    </VStack>
                  </Tooltip>
                ),
                tags: (
                  <Tooltip label={tgs.length > 1 ? tgs.join(', ') : ''} placement='right'>
                    <Text
                      textAlign='left'
                      fontSize={['9px', '10px', '11px']}
                      px={[0, '4px', '10px']}
                      width={['70px']}
                    >
                      {tags_trunc}
                    </Text>
                  </Tooltip>
                )
              };
            }
            return null;
          })
          // lastly, filter out any falsy's from map()
          .filter(result => !!result)
      );
    }
    return null;
  }, [parsedData, topic]);

  const cols = useMemo(() => {
    if (documents?.length) {
      return get_columns(documents[0]).map(key => {
        return {
          ...defaultColumnProperties,
          key: key,
          name: map_column_title(_.capitalize(key), kind),
          sx: map_column_sx(key, kind)
        };
      });
    }
    return [];
  }, [defaultColumnProperties, get_columns, documents, map_column_sx, map_column_title]);

  if (!_.size(documents)) {
    return null;
  }

  return (
    <Box mt='20px'>
      <SortableTable
        columns={cols}
        rows={documents}
        headRowSx={{ backgroundColor: 'cyan.900' }}
        resultsCellSx={{ padding: '4px 4px 0 0', a: { pt: 0 } }}
      />
    </Box>
  );
};

export default SearchResultsProfiles;

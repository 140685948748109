import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { firebaseAppAuth } from 'utils/firebaseSetup';

import { setLoading, setError } from 'redux/actions/firebaseAuth';

import { userLogout } from 'redux/actions/noo';

const useFirebaseAuthLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const tryToAuth = async operation => {
    try {
      dispatch(setLoading(true));
      const result = await operation();
      return result;
    } catch (error) {
      dispatch(setError(error));
      return error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const signOut = () => {
    return tryToAuth(() => {
      dispatch(userLogout());
      firebaseAppAuth.signOut();
      history.push('/');
    });
  };

  return {
    signOut
  };
};

export default useFirebaseAuthLogout;

import React, { useMemo, useState } from 'react';
import RenderChakraMarkdown from 'utils/ChakraMarkdown';
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Text,
  Box,
  HStack
} from '@chakra-ui/react';
import { More } from 'components/Segments/AboutPopover';
import _ from 'lodash';

export function NewsPopover() {
  const text_closed = `
  BETA: We have a state of the art crawler monitoring social media, focused on the Regeneration Movement (e.g. currently covering mostly climate, nature, science, mind, justice, and tech.)
  `;

  const text_open = `
BETA: This is a *minimum viable product* and as such is far from comprehensive or seamless. We're crawling a limited range of topics, and our search interface is very basic. Both of those will improve a lot over the next few months. Please be forgiving of UX clunkiness for now.

We are currently monitoring 10s of thousands of subject matter experts on climate, nature, science, mind, justice, tech, and related topics.

When you find interesting articles, you can *bookmark* them using our [Best of Now bookmarklet](http://bestofnow.net/share), or by pasting their URL on the [Share page](http://bestofnow.net/share). Before long, we'll add a button to directly share from the Search results.  
`;

  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);
  return (
    <Box d='inline-block' mr={3}>
      <Text className='about_closed' fontSize='12px'>
        <Text>
          <Text as='em' fontWeight='bold' display='inline'>
            BETA Proof of Concept:
          </Text>
          <Text display='inline' fontSize='12px'></Text>
          &nbsp;We have a state of the art crawler monitoring social media, focused generally on the
          Regeneration Movement. Be forgiving of the search interface and results thereof. They will
          improve...
          <More
            header_text='Our Social Media Crawler'
            button_text={'more...'}
            size={'12px'}
            text_open={text_open}
            isOpen={isOpen}
            open={open}
            close={close}
          />
        </Text>
      </Text>
    </Box>
  );
}

export default NewsPopover;

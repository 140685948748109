import React from 'react';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';
// import ReactMarkdown from 'react-markdown';
import {
  Text,
  Heading,
  Box,
  Flex,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Link
} from '@chakra-ui/react';
import RenderChakraMarkdown from 'utils/ChakraMarkdown';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import SignInOrRegister from 'components/Segments/SignInOrRegister';
import InvitationsAccept from 'components/Invitations/InvitationsAccept';
import About from 'components/Segments/AboutPages/GreencheckAbout';

// function sleep(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

function useQuery() {
  var params = new URLSearchParams(useLocation().search);
  var lookup = {};
  for (var pair of params.entries()) {
    lookup[pair[0]] = pair[1];
  }
  return lookup;
}

const Splash = () => {
  const { googleUser: user, nooUser, networks, currentNetworkData } = useNoo('Splash');
  const network = currentNetworkData;

  const history = useHistory();

  // TODO: if using invitations here, update to useInvitations() hook
  // const onCallAcceptInvitation = () => {
  // 	nooApi.acceptInvitation(user, invitationId, setAcceptLoading, setAcceptResponse);
  // };

  let params = useQuery();
  var invitationId = params.invite;

  if (invitationId) {
    localStorage.setItem('invitationId', invitationId);
  } else {
    // TBD: check local storage and if there, check it's still valid by reading it
    const stored = localStorage.getItem('invitationId');
    if (stored) {
      /* CHECK INVITE IS LEGIT HERE, AND SET OR REMOVE ON RESPONSE, BUT I DON'T KNOW HOW TO HANDLE THE RESPONSE
      // TODO: if using noo Api here, update to useNooApi() hook
			const aqlQuery = 'return document("' + invitationId + '")';
			localStorage.setItem('invitation', invite);
			nooApi.aqlSearch({user, aqlQuery, setLoading, setResponse});
			*/
      invitationId = stored;
    }
  }

  const tagline = network?.tagline; // || 'Welcome to ' + display;
  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      margin='1em auto'
      width='90vw'
      borderRadius='lg'
      boxShadow='0px 1px 4px 0px rgba(0, 0, 0, 0.5)'
      padding='1em'
      bgColor='splashBg'
    >
      {tagline && (
        <Heading as='h2' size='md'>
          {tagline}
        </Heading>
      )}

      <Flex sx={{ w: '100%' }}>
        <InvitationsAccept />
      </Flex>
      {!user && <SignInOrRegister showHeader={false} />}
      <VStack>
        {' '}
        <Heading as='h2' fontSize={'24px'}>
          A simple, cooperative approach to trust, identity, and community
        </Heading>
      </VStack>
    </Flex>
  );
};

export default Splash;

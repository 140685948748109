import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, Box, VStack, Link, Button, Input, Text, Spinner, Heading } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import styled from '@emotion/styled';
import _ from 'lodash';
// import Slider from 'rc-slider';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
// import { TagCloud } from 'react-tagcloud';
// import D3Cloud from './d3_cloud';
import SimpleCloud from '../Segments/SimpleCloud';
// import Chat from '../Segments/Chat';
import ChatPopover from '../Segments/ChatPopover';
import { find_link, objectSort } from 'utils';
import FontAwesomeIcon from 'utils/FontAwesomeLoader';
// import { question_query } from 'utils/aqlQueryGenerators';

// function getRandomInt(max) {
//   return Math.floor(Math.random() * Math.floor(max));
// }

const VoteCount = styled.span`
  font-weight: bold;
  display: inline-block;
  margin-right: 6px;
  padding: 0 6px;
  border-radius: 4px;
  background-color: #000;
  color: #fff;
`;

const Tools = props => {
  /*
  {
  questionId: "Questions/943f67bf-f149-4c59-b887-7a70fd129d8a",
  _id: "Options/06c8026e-8fa5-4019-a04f-45e49225cdad",
  data {
    title: , 
    description: ,
    image: ,
    url: 
  }
}
*/
  const { isAuthorized, nooUser } = useNoo('DecisionDisplay:Tools');
  const { nooCommand } = useNooApi();
  const dispatch = useDispatch();

  const recordVote = val => {
    if (!nooUser) {
      console.error('no nooUser, cannot complete the vote action');
      console.log(nooUser);
      return;
    }
    // setLoading(true);
    const personId = nooUser ? nooUser.person._id : null;
    const question_id = props.question._id;

    const answer = /*props.answer || */ { data: { vote: {} } };
    answer.data = answer.data || { vote: {} };
    answer.data.vote = props.answer?.data?.vote || {};
    const optid = props.option._id;
    answer.data.vote[optid] = val;
    answer.questionId = question_id;
    answer.personId = personId;
    // console.log('create answer', answer);
    dispatch(nooCommand('createAnswer', { question_id, answer }));
  };

  const voteFor = () => {
    // console.log('vote for', props.option, props.question);
    recordVote(1);
  };

  const voteAgainst = () => {
    // console.log('vote against', props.option, props.question);
    recordVote(-1);
  };
  return (
    <Text as='span'>
      <Button onClick={voteFor} p='0 8px' m='2px' bg='teal.500' size='sm' color='white'>
        {'+'}
      </Button>
      <Button onClick={voteAgainst} p='0 8px' m='2px' bg='teal.500' size='sm' color='white'>
        {'-'}
      </Button>
    </Text>
  );
};

const Option = props => {
  const { option, votes, question, answer, isProcessing } = props;
  // console.log('OPTION', { option, votes, question, answer, isProcessing });
  // const [value, setValue] = useState(0.0);

  // const sliderProps = {
  //     min: -1.0,
  //     max: 1.0,
  //     step: 0.1 //,
  //     // marks: { 0.0: 0, 0.1: 10, 0.2: 20, 0.3: 30, 0.4: 40, 0.5: 50, 0.6: 60, 0.7: 70, 0.8: 80, 0.9: 90, 1.0: 100 }
  // }

  const [isExpanded, setIsExpanded] = useState(false);
  const data = option.data;
  let description = data.description || data.label || '';

  const weblink = data.url;
  // const image = description ? find_link(description, 'image') : null;
  description = description.split('[image')[0];
  const title = data.title || data.label;
  const expandIcon = isExpanded ? 'chevron-square-down' : 'chevron-square-right';

  const toggleExpandedView = e => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };
  const myClass = option.mine ? 'my_vote' : 'normal_vote';
  const optid = option._id;
  // {image ? <img src={image.url} width={40}/> : "" }
  // https://titanembeds.com/embed/295085744249110529
  const ifr =
    'https://titanembeds.com/embed/882993586692567060?css=293&defaultchannel=882993586851954800';
  // const ifr = "https://discord.com/widget?id=882993586692567060&theme=light"; //  width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>';
  //{true && <iframe src={ifr} height='600' width='100%' frameBorder='0'></iframe>}
  // <Chat chatId={option._id} chatName={option.data.label} />
  return (
    <>
      <Flex className='__option' key={optid}>
        <Text
          as='span'
          onClick={toggleExpandedView}
          cursor='pointer'
          opacity={isExpanded ? 0.7 : 0.35}
        >
          {FontAwesomeIcon({ icon: ['far', expandIcon], size: '2x' })}
        </Text>
        {!isExpanded && (
          <Box mt='4px' pl='4px' width='2em'>
            <VoteCount className={myClass}>{option.tally}</VoteCount>
          </Box>
        )}
        <Box flex='1'>
          {weblink ? (
            <Link className='option_link' href={weblink} title={description} isExternal>
              {title} <ExternalLinkIcon mx='2px' />
            </Link>
          ) : (
            <Text as='span' title={description}>
              {title}
            </Text>
          )}
        </Box>
        {!isExpanded &&
          (isProcessing ? (
            <Spinner size='sm' />
          ) : (
            <Tools option={option} question={question} answer={answer} />
          ))}
      </Flex>
      {isExpanded && (
        <Box
          // borderBottom: '1px solid #ddd',
          border='1px solid #333'
          p='6px 6px 12px 6px'
          mb='10px'
          backgroundColor='white'
        >
          <Heading as='h4' mb='0'>
            {title}
          </Heading>
          <Text fontSize='20px'>{description}</Text>
          <Text my='0'>
            Vote Count: <VoteCount>{option.tally}</VoteCount>
            <br />
            Vote this up or down!:
          </Text>
          {isProcessing ? (
            <Spinner size='sm' />
          ) : (
            <Tools option={option} question={question} answer={answer} />
          )}
          <Heading as='h4' mb='0' fontWeight='normal'>
            Discuss
          </Heading>
          <span>Chat goes here</span>
        </Box>
      )}
    </>
  );
};

const nooCommandsUsed = ['createAnswer', 'createOption', 'aql'];

const Display = props => {
  const { response } = props;
  const { nooUser } = useNoo('DecisionDisplay:Display');
  const dispatch = useDispatch();
  const {
    nooCommand,
    nooCommandIsProcessing, // "loading" boolean
    nooCommandResponse // response, in format of { data, command } where command is what was passed to nooCommand
  } = useNooApi();

  const [parsedData, setParsedData] = useState(null);
  const [parsedDataError, setParsedDataError] = useState(null);

  const [inputs, setInputs] = useState({});

  // const [nooCommandName, setNooCommandName] = useState(null);
  // const [nooData, setNooData] = useState(null);
  // const [newFormLabel, setNewFormLabel] = useState('');
  const [cloud, setCloud] = useState([]);
  const [questionName, setQuestionName] = useState(null);
  const [questionId, setQuestionId] = useState(null);
  const [responseAql, setResponseAql] = useState(null);
  const history = useHistory();
  const decisionRef = useRef(null);

  // useEffect(() => {
  //   setNooCommandName(nooCommandResponse?.command);
  //   setNooData(nooCommandResponse?.data);
  // }, [nooCommandResponse]);

  useEffect(() => {
    if (response) {
      try {
        setParsedData(response?.result?.[0]);
      } catch (e) {
        console.log('ERROR', e);
        setParsedDataError(e);
      }
    }
  }, [response, responseAql, setParsedData]);

  useEffect(() => {
    // This is the same as from Decision.jsx, and is called after the return from creating a new option
    if (nooCommandResponse?.command === 'aql') {
      setResponseAql(nooCommandResponse.data);
    }
  }, [nooCommandResponse]);

  useEffect(() => {
    const votesArea = decisionRef.current;
    if (votesArea !== null) {
      const { innerWidth: width, innerHeight: height } = window;
      // const height = votesArea.scrollHeight;
      // const width = votesArea.scrollWidth;
      console.log('w', width, 'h', height);
    }
  }, [decisionRef]);

  useEffect(() => {
    const cmd = nooCommandResponse?.command;
    if (nooCommandsUsed.indexOf(cmd) > -1) {
      let data = nooCommandResponse.data;
      data = data?.result ? data.result[0] : data;
      if (data && (cmd === 'createAnswer' || cmd === 'aql')) {
        let answers = null;
        let opts = null;
        let question = null;
        let changed = false;
        if (cmd === 'aql') {
          // console.log('aql', data);
          answers = data.answers;
          opts = data.options;
          question = data.question;
        } else if (cmd === 'createAnswer') {
          const nodes = data.nodes;
          answers = _.filter(nodes, one => {
            return one.answer;
          });
          opts = _.filter(nodes, one => {
            return one.option;
          });
          const qs = _.filter(nodes, one => {
            return one.question;
          });
          question = qs?.[0]?.question || null;
        }

        let pd = { ...parsedData };
        if (question) {
          const funct = question.tallyFunction || 'bucket';
          const tally = question.tally[funct] || {};
          let myVotes = pd.answers || [];
          myVotes = myVotes.length > 0 ? myVotes[0].data?.vote : [];
          // console.log('tally', tally)
          _.forEach(pd.options, function (option) {
            // console.log('TALLY OPTION', option)
            const tal = tally[option._id];
            if (_.keys(myVotes).includes(option._id)) {
              option.mine = true;
            }
            if (tal) {
              if (option.tally != tal.total) {
                option.tally = tal.total;
                changed = true;
              }
            }
          });
        }

        if (changed) {
          setParsedData(pd);
        }
      } else if (cmd === 'createOption') {
        setInputs({});
        const q = parsedData?.question;
        if (q && nooUser) {
          const question_id = q._id;
          const person_id = nooUser.person._id;
          const payload = { query_name: 'aqlQuestionData', data: { question_id, person_id } };
          dispatch(nooCommand('aql', { payload }));
        }
      }
    } else {
      // clean up if necessary
    }
  }, [
    dispatch,
    nooCommand,
    nooCommandResponse?.command,
    nooCommandResponse.data,
    nooUser,
    parsedData
  ]);

  const question = useMemo(() => {
    return parsedData?.question || null;
  }, [parsedData]);

  const answer = useMemo(() => {
    const it = parsedData?.my_answer ? parsedData.my_answer[0] : null;
    return it || { data: { vote: {} } };
  }, [parsedData]);

  const votes = useMemo(() => {
    if (parsedData) {
      const answers = parsedData.my_answer;
      const options = parsedData.options;
      const stack = {};
      _.forEach(answers, one => {
        _.forEach(options, ind => {
          stack[ind] = stack[ind] || [];
          stack[ind].push(one.personId);
        });
      });
      _.forEach(stack, (value, key) => {
        stack[key] = _.uniq(value);
      });
      // console.log('VOTES', stack)
      return stack;
    }
    return {};
  }, [parsedData]);

  const items = useMemo(() => {
    if (question) {
      let options = parsedData.options || [];
      const lookup = parsedData.option_question_lookup;
      options.sort(objectSort('tally'));
      options.reverse();
      let new_cloud = [];
      if (question.data?.render == 'cloud') {
        const tallies = _.filter(options, opt => {
          return opt.tally ? true : false;
        });
        if (tallies.length > 0) {
          // odd behavior where tally is in object but not being found
          _.forEach(options, function (option) {
            /* console.log('opt', option.data.label, option.tally)
            console.log('op', option)
            console.log('keys', _.keys(option))
            console.log('has', _.has(option, 'tally'))
            console.log('get', _.get(option, 'tally'))
            */
            let word = {
              value: option.data.label,
              count: option.tally || 0,
              _id: option._id,
              parent: question._id
            };
            const my_question = lookup[option._id]; // option has a rel pointing to a sub question
            if (my_question) {
              word.question = my_question;
            }
            new_cloud.push(word);
          });
          if (new_cloud.length > 0) {
            setCloud(new_cloud);
          }
        }
      }
      return options.map(option => {
        const optid = option._id;
        const vts = votes[optid] || [];
        return (
          <Option
            option={option}
            key={optid}
            votes={vts}
            question={question}
            answer={answer}
            isProcessing={nooCommandIsProcessing}
          />
        );
      });
    }
    return null;
  }, [question, parsedData, votes, answer, nooCommandIsProcessing]);

  // const words = useMemo(() => {
  //   if (question) {
  //     const options = parsedData.options || [];

  //     return options.map(option => {
  //       const optid = option._id;
  //       const vts = votes[optid] || [];
  //       return { text: option.label, value: getRandomInt(10) + 10 };
  //     });
  //   }
  //   return [{ text: '', value: 63 }];
  // }, [question, parsedData, votes]);

  const header = useMemo(() => {
    if (parsedData?.question) {
      // console.log('parsed', parsedData)
      const { question, paths } = parsedData;
      setQuestionName(question?.data?.title);
      setQuestionId(question._id);
      let parents = []; // add breadcrumbs to parent questions
      if (paths && paths.length > 0) {
        paths.sort(one => {
          return -one.length;
        });
        const path = paths[0].reverse().filter(one => {
          return one[0] != question._id;
        });
        parents = path.map(it => {
          const qid = it[0].replace('Questions/', '');
          return (
            <Link
              as={RouterLink}
              key={qid}
              className='decision'
              to={`/decision-old/${qid}`}
              title={it[1].description}
            >
              {it[1].title}
            </Link>
          );
        });
      }
      const stack = ['title', 'description'].map(key => {
        switch (key) {
          case 'title':
            return (
              <Heading as='h2' key={key}>
                {question.data.title}
              </Heading>
            );

          case 'description':
            return (
              <Text marginTop='2px' marginBottom='6px' key={key}>
                {question.data.description}
              </Text>
            );
        }
      });
      // const question = data.result[0].question;
      // stack.push(<Heading as="h5" key={'prompt'}>{question.prompt}</Heading>);
      return parents.concat(stack);
    }
    return null;
  }, [parsedData]);

  const qprompt = useMemo(() => question?.data?.prompt || 'suggest', [question]);

  const onCallAddOption = e => {
    if (!nooUser) {
      console.error('no nooUser, cannot complete the addOption action');
      console.log(nooUser);
      return;
    }
    const question_id = parsedData?.question?._id;
    const newlabel = inputs?.add_option_label;
    const newdescription = inputs?.add_option_desc;
    if (newlabel && newdescription) {
      const option = {
        data: { label: newlabel, description: newdescription },
        published: true,
        creator: nooUser.person._id
      };
      dispatch(nooCommand('createOption', { question_id, option }));
    } else {
      alert('must provide a label and a description');
    }
    //
  };

  const updateInputs = e => {
    const myInputs = { ...inputs };
    myInputs[e.target.name] = e.target.value;
    setInputs(myInputs);
  };

  const onWordClick = word => {
    // console.log('on word click ', word.value, word._id, word.parent)
    // unused stray query:
    // const query = 'for q in Questions FILTER  q.data.rel == ' + word._id + ' return  q';
    const new_question = word.question;
    if (new_question) {
      const dest = '/decision-old/' + new_question.replace('Questions/', '');
      return history.push(dest);
    }
    // alert('Vote here for ' + word.value)
  };
  // const fontSizeMapper = word => word.value * 100; // Math.log2(word.value) * 5;

  //<label htmlFor="add_option">{qprompt}</label>
  //<Box className="wordcloud"><D3Cloud words={words} indata={appendResponse} width={350} height={350} onWordClick={onWordClick} fontSizeMapper={fontSizeMapper} /></Box>
  return (
    <Box className='decision'>
      <Box className='decision-header'>{header}</Box>
      <ChatPopover chatId={questionId} chatName={questionName} />
      {cloud.length > 0 && <SimpleCloud data={cloud} onClick={onWordClick} />}
      <Box className='decision-vote' ref={decisionRef}>
        {items}
      </Box>

      <Box className='addoption'>
        <VStack
          mb='1em'
          border='1px solid #333'
          p='6px 6px 12px 6px'
          width='500px'
          backgroundColor='white'
        >
          <Input
            width='400px'
            type='text'
            id='add_option_label'
            name='add_option_label'
            onChange={updateInputs}
            value={inputs?.add_option_label}
            placeholder='Name'
          />
          <Input
            width='400px'
            type='text'
            id='add_option_desc'
            name='add_option_desc'
            onChange={updateInputs}
            value={inputs?.add_option_desc}
            placeholder='Description'
          />

          <Button onClick={onCallAddOption} disabled={nooCommandIsProcessing} width='400px'>
            {qprompt}
          </Button>
        </VStack>
      </Box>

      {parsedDataError && <Box className='App-body'>There was an error, sorry.</Box>}
    </Box>
  );
};

export default Display;

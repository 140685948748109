const markdown = `
**CommonsGraph** is an invitation-only, cooperatively owned and governed [social graph](https://en.wikipedia.org/wiki/Social_graph), a decentralized autonomous organism. It is intended as an interoperable resource for any aligned application.

We grow the CommonsGraph by exploiting the California Consumer Privacy Act ([CCPA](https://oag.ca.gov/privacy/ccpa)), which requires businesses to allow users to download their data.

Participation in CommonsGraph requires invitation, followed by importing one's user data from LinkedIn / Facebook / Twitter (FBLITW). (We have tools to make that easy.) The collective result is a resource that is better in numerous ways to those of FBLITW (see below).

You are probably already in the graph by being connected to one or more participants. If you've been invited, you can claim your node in the network and invite others.

Because it's invitation only, every participant is accountable to a chain of invitations leading back to that kernel; bad behavers are unlikely and easily detected and muted.

### Shhhh 

The first rule of CommonsGraph is **DO NOT** talk about CommonsGraph. We can get to critical mass most easily by not attracting attention until we are too big to ignore or resist. 

### Purpose
Facebook's hegemony is founded entirely on their social graphs and the lock-in it provides. 

CommonsGraph's purpose is to break that hegemony, but more importantly, to create something much better than we get from FBLITW,  a rich ecosystem of applications that align with CG's principles and create financial value for the commons. 

We have no intention of trying to match the functionality of Facebook, LinkedIn, Twitter. We leave that to a global community of open source developers who can focus on design and utility because they don't have to worry about where their users are going to come from.

### Principles
* Commons ownership
* Governed by Liquid Democracy
* No wankers
* Decentralized identity
* Interoperability

### Penumbras
Your penumbra is you and your connections. In FBLITW, that's your *friends/connections*. Once you add those to CG, you own and control the resulting *subnetwork* of edges from you to others. You can export it and remove it at any time.

We give you tools to see your data and decide which people in your penumbra you want to carry over from your friends lists on FBLITW.

### Networks (aka Flocks), not Groups
The standard model for collections of people in FBLITW and elsewhere is groups, flat collections of people. Our model is networks, where each member has relationship vectors with others in the group (e.g. respects, delegates...). Those vectors are multidimensional, and rich in information. 

Groups are a degenerate case of networks and have nowhere near the utility of a network, especially around collective decision-making.

CommonsGraph gives you tools for creating and managing Flocks, which go way beyond Twitter Lists, Facebook Groups, etc. because they have relationships. 

Because interoperability is a core element of CG, networks can be exported to any other application that accepts them, with or without the edge data.

### Privacy
CommonsGraph has been bootstrapped with publicly available data that the CCPA mandates access to. By default, one can only see one's own data, specifically who they are linked to on FBLITW. Once you add your penumbra to CG, it is visible to everyone else, unless/until you decide to remove it.

Full participation requires that you share your penumbra with the commons.

As you explore the CommonsGraph, you can see:   

* Any people you are connected to on FBLITW
* Their network, but only your *mutual friends/connections*
* Any users who have made their data available to the commons.

Any new *userIDs* from external services are added to the global *inventory of external IDs*, visible only to:   

* Any user whose Flock includes it. This is available to them already through external networks.
* The owners who claim those IDs.


Full members (invited and accepted) can publish their Flock to the *CommonsGraph*, and can un-publish at any time. They can also export their Flock(s).
`;

export default markdown;

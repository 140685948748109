import _ from 'lodash';
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Lorem,
  Button,
  Box,
  Text,
  Tooltip
} from '@chakra-ui/react';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import { Claims } from 'components/Segments/UserCard';
import { inputs } from '@theme-ui/preset-tailwind';

const ValidatePersonModal = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [claimed, setClaimed] = useState(null);
  const [validatable, setValidatable] = useState(false);
  const [respectEdges, setRespectEdges] = useState(null);
  const [message, setMessage] = useState('');
  // const dispatch = useDispatch();
  const { nooUser } = useNoo('ValidatePersonModal');
  const { nooCommandDirect } = useNooApi();
  const valid_marker = (nooUser?.valids || []).length > 0 && (nooUser?.claimed || []).length > 0;

  const { target, prefix } = props;
  const fullname = target.fullname;
  const targetId = target._id || target.id;
  const sourceId = nooUser?.person?._id;
  const min_claims = 2;

  const firstOpen = () => {
    onOpen();
    setClaimed(null);
    const person_id = target?.id || target?._id;
    if (person_id) {
      const payload = {
        query_name: 'aqlEdgesType',
        data: { types: ['CLAIM'], filters: { source: targetId } }
      };
      const params = {
        payload,
        setLoading: () => {},
        setResponse: response => {
          const data = response.result[0];
          const targets = data.edges.map(edge => {
            return edge._to;
          });
          const profiles = _.filter(data.profiles, profile => {
            return targets.includes(profile._id);
          });
          setClaimed(profiles);
        },
        setResponseError: error => {
          console.log('error ', error);
        }
      };
      nooCommandDirect('aql', params);
    }
  };

  const onSubmit = e => {
    const params = {
      from_id: sourceId,
      to_id: targetId,
      type: 'VALIDATE',
      target_data: {},
      edge_data: null,
      setLoading: () => {},
      setResponse: response => {
        console.log('updated edge response ', response);
      },
      setResponseError: error => {
        console.log('error ', error);
      }
    };
    nooCommandDirect('createEdge', params); // This only overwrites my edge on submit, because noone else can change that edge
    // update the tag cloud

    onClose();
  };

  const check_claimed = useCallback(
    profiles => {
      setValidatable(profiles?.length >= min_claims);
    },
    [setValidatable]
  );

  useEffect(() => {
    let msg = '';
    if (!validatable)
      msg +=
        'This user does not have enough claimed accounts to validate' +
        (valid_marker ? '.' : ', and ');
    if (!valid_marker)
      msg += 'You are not yet authorized because you have not been praised or validated by others';
    if (!(validatable && valid_marker)) {
      msg = 'Sorry. ' + msg;
    }
    setMessage(msg);
  }, [validatable, valid_marker]);

  return (
    <Box>
      <Button onClick={firstOpen} bgColor={'#b9bf04'} title={'Validate ' + fullname}>
        {typeof prefix == 'string' ? prefix : 'Validate'}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Is this the {fullname} you know?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Please review {fullname}&apos;s claims, and if they match what you know about them,
              click Validated.
            </Text>
            {validatable && (
              <Text>
                They must have claimed at least {min_claims} accounts for the Validate button to be
                active .
              </Text>
            )}
            <Claims personId={targetId} data_cb={check_claimed} />
          </ModalBody>

          <ModalFooter>
            {!validatable || !valid_marker ? (
              <Tooltip label={message}>
                <span>
                  <Button colorScheme='blue' mr={3} disabled>
                    Validate
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button colorScheme='blue' mr={3} onClick={onSubmit}>
                Validate
              </Button>
            )}

            <Tooltip label={''}>
              <Button variant='ghost' onClick={onClose}>
                Cancel
              </Button>
            </Tooltip>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ValidatePersonModal;

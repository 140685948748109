import React, { useMemo, useState } from 'react';
import { Claims } from 'components/Segments/UserCard';

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Box,
  Heading,
  Link
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const UserAttributes = props => {
  const { buttonText, width, onChange, personId } = props;
  const ukey = personId ? personId.replace('Persons/', '') : '';
  const index = [0];
  return (
    <Accordion
      maxWidth={'500px'}
      backgroundColor={'#eee'}
      allowToggle
      width={width}
      onChange={onChange}
    >
      <AccordionItem>
        <Heading as='h2'>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left'>
              <Text fontSize='lg' fontWeight={'bold'}>
                Claimed accounts
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </Heading>
        <AccordionPanel pb={4}>
          <Claims personId={personId} />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <Heading as='h2'>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left'>
              <Text fontSize='lg' fontWeight={'bold'}>
                Network of connections
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </Heading>
        <AccordionPanel pb={4}>
          <Link as={RouterLink} to={'/search_network/?user=' + ukey} fontWeight='normal'>
            {'Click here'}
          </Link>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default UserAttributes;

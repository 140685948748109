import React, { useState, useEffect, useRef } from 'react';
import { Box, Textarea, Spinner } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import ListingForm from 'components/Listings/ListingForm';
import UserAuth from 'components/User/UserAuth';
import formatResponse from 'utils/formatResponse';
import { useHistory, useParams } from 'react-router-dom';
import { Heading } from '@chakra-ui/react';
import { norm_id } from 'utils';
import _ from 'lodash';

const AddListing = props => {
  const dispatch = useDispatch();
  const { isAuthorized, loading, nooUser } = useNoo('AddListing');
  const history = useHistory();
  const { listing_id } = useParams();
  const { nooCommand, nooCommandResponse } = useNooApi();

  const [response, setResponse] = useState('{}');
  const [listingData, setListingData] = useState('{}');
  const [pageLabel, setPageLabel] = useState('Add a Listing');

  useEffect(() => {
    let person_id = nooUser?.person?._id;
    person_id = norm_id(person_id, 'person', 'without');
    if (listing_id && person_id) {
      const payload = { query_name: 'aqlGetListing', data: { listing_id, person_id } };
      dispatch(nooCommand('aql', { payload }));
    }
  }, [listing_id, dispatch, nooCommand, response, nooUser]);

  const normalizeImgs = ldata => {
    const dta = ldata?.listing?.data;
    if (dta) {
      const ids = dta.imageIds;
      if (ids && !dta.images && typeof ids == 'object') {
        // original form, array of ids/urls
        ldata.listing.data.images = _.map(dta.imageIds, (one, i) => {
          return { id: one, url: one, order: i, caption: '' };
        });
        console.log('made images attribute', ldata.listing.data.images);
      }
    }
  };

  useEffect(() => {
    const cmd = nooCommandResponse?.command;
    switch (cmd) {
      case 'createListing':
        try {
          const listing = nooCommandResponse.data?.nodes[0];
          if (listing) {
            const lkey = norm_id(listing.listing._id, 'listing', 'without');
            const pkey = norm_id(nooUser?.person?._id, 'person', 'without');
            history.push('/listing/' + lkey + '/' + pkey);
          }
        } catch (e) {
          console.log('ERROR', e);
        }
        setResponse(nooCommandResponse.data);
        break;

      case 'aql':
        const ldata = nooCommandResponse.data?.result;
        if (ldata) {
          const listing = ldata[0];
          normalizeImgs(listing);
          setListingData(listing);
          setPageLabel('Edit Listing');
        }

        break;

      default:
        console.log('No command', nooCommandResponse);
    }
  }, [nooCommandResponse, nooUser, history]);

  // const mock = {
  // 	title: "Cloud Tiny House",
  // 	description: "Cloud Hidden, Whereabouts Unknown",
  // 	zipCode: '95472',
  // 	imageIds: [],
  // 	features: {},
  // 	geoPoint: {
  // 		lat: 38.3828838,
  // 		long: -122.8406882
  // 	},
  // 	state: 'ca',
  // 	country: 'united states',
  // 	webpage: "https://www.cloudtinyhouse.com/",
  // 	imageUrl: "https://images.squarespace-cdn.com/content/v1/5a469152f43b5576b28e6fbd/1514588512708-2XLH1G4IVXOLSG7TOQIA/ke17ZwdGBToddI8pDm48kNiEM88mrzHRsd1mQ3bxVct7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0s0XaMNjCqAzRibjnE_wBlkZ2axuMlPfqFLWy-3Tjp4nKScCHg1XF4aLsQJlo6oYbA/IMG_20171229_113408-02-02.jpeg?format=1000w"
  // }
  // const [listing_data, setListingData] = useState(mock);
  // const [loading, setLoading] = useState(false);

  var listing_data = {};

  const responseRef = useRef(null);
  useEffect(() => {
    const textAreaRef = responseRef.current;
    if (textAreaRef !== null) {
      // console.log(textAreaRef);
    }
  }, [response, responseRef]);

  const normalizeListing = data => {};
  const onFormSubmit = data => {
    let listing = { resource: { type: 'listing', data: data } };
    listing.person_id = nooUser?.person?._id;
    if (listing_id) {
      listing.resource._id = norm_id(listing_id, 'listing', 'with');
    }
    console.log('submitting listing', listing);
    dispatch(nooCommand('createListing', { data: listing }));
  };

  /*          <Label htmlFor="addlisting">Add Listing:</Label>
		  <Textarea type="text" id="addlisting" name="addlisting" value={JSON.stringify(listing_data)} onChange={onUpdateListingData}/>
		  <Button onClick={onCallCreateResource} disabled={loading}>
			{'Submit'}
		  </Button>
	*/
  return (
    <Box>
      <UserAuth unauthorized='signin'>
        <Box className='App-row'>
          <Heading as='h1'>{pageLabel}</Heading>
          <Box className='App-card'>
            {loading && <Spinner />}

            <ListingForm onSubmit={onFormSubmit} listingData={listingData} />

            <Box className='debuf'>
              <Textarea
                ref={responseRef}
                id='grpcResponse'
                name='grpcResponse'
                readOnly={true}
                value={formatResponse(listingData, false)}
              />
            </Box>
          </Box>
        </Box>
      </UserAuth>
    </Box>
  );
};

export default AddListing;

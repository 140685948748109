// import { useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  bookmarkFetchedHeadlineForUser,
  checkUrlForBookmarking as _checkUrlForBookmarking,
  clearBookmarkUrlData,
  getOpenGraph
} from 'redux/actions/headlines';
import useNoo from './useNoo';

const useHeadlines = () => {
  const { isAuthorized } = useNoo();
  const dispatch = useDispatch();
  const lastBookmarkedUrlData = useSelector(
    state => state.headlines?.lastBookmarkedUrlData,
    shallowEqual
  );

  const lastBookmarkedUrlMetadata = useSelector(
    state => state.headlines?.lastBookmarkedUrlMetadata,
    shallowEqual
  );

  const isBookmarkingHeadline = useSelector(state => state.headlines?.isBookmarkingHeadline);
  const bookmarkHeadlineResponse = useSelector(state => state.headlines?.bookmarkHeadlineResponse);
  const bookmarkHeadlineResponseError = useSelector(
    state => state.headlines?.bookmarkHeadlineResponseError
  );

  const bookmarkFetchedHeadline = destinationData => {
    if (isAuthorized) {
      dispatch(bookmarkFetchedHeadlineForUser(destinationData));
    }
  };

  const checkUrlForBookmarking = (url, skipcheck) => {
    if (url && isAuthorized) {
      const decodedUrl = decodeURIComponent(url);
      if (decodedUrl) {
        dispatch(_checkUrlForBookmarking(decodedUrl, skipcheck));
      }
    }
  };

  return {
    isBookmarkingHeadline,
    bookmarkHeadlineResponse,
    bookmarkHeadlineResponseError,
    bookmarkFetchedHeadline,
    checkUrlForBookmarking,
    clearBookmarkUrlData: () => dispatch(clearBookmarkUrlData()),
    lastBookmarkedUrlData,
    lastBookmarkedUrlMetadata,
    getOpenGraph
  };
};

export default useHeadlines;

import { useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  getNetwork as _getNetwork,
  getNetworkEdges as _getNetworkEdges,
  getCommunity as _getCommunity,
  getPenumbra as _getPenumbra,
  getBioregion as _getBioregion,
  getShortestPath as _getShortestPath,
  stringSearchNetwork,
  getSpecificUsers as _getSpecificUsers,
  getRespected as _getRespected,
  getEdgesType as _getEdgesType,
  getNominationData as _getNominationData
} from 'redux/actions/peopleNetwork';
import useNoo from 'hooks/useNoo';
// import useMultiDomain from 'hooks/useMultiDomain';
// import { derivePrefixedNodeId } from 'utils';
import { graph_normalize } from 'utils/graphNormalize';

const usePeopleNetwork = () => {
  const { nooUser } = useNoo('usePeopleNetwork');
  const dispatch = useDispatch();
  const { isAuthorized, networks, currentNetwork: appnetwork /*, personId*/ } = useNoo();
  const app = networks[appnetwork]; // for display flags like obfuscate

  const currentNetworkRaw = useSelector(state => state.peopleNetwork?.networkData);

  const isRequesting = useSelector(state => state.peopleNetwork?.isRequesting);

  const currentNetwork = useMemo(() => {
    if (currentNetworkRaw) {
      return graph_normalize({
        data: currentNetworkRaw,
        allowlist: nooUser?.social,
        obfuscate: app.obfuscate
      });
    }
    console.log('no curr net');
    return null;
  }, [currentNetworkRaw, nooUser, app]);

  const currentProfile = useMemo(() => {
    // need the profile of the current center
    const pId = currentNetworkRaw?.person_id;
    if (pId) {
      const profile = pId
        ? _.find(currentNetworkRaw.profiles, one => {
            return one?._id == pId || one?.id == pId;
          })
        : {};
      return profile;
    }
    return null;
  }, [currentNetworkRaw?.person_id, currentNetworkRaw?.profiles]);

  const totalMatches = useMemo(() => {
    // in aqlSearch, return a value for 'total' if you want that info, can be anything as long as context knows how to interpret
    return currentNetworkRaw?.total;
  }, [currentNetworkRaw]);

  const getNetwork = ({ userId, limit, direction, degrees, logged_in, types }) => {
    if (!isAuthorized) {
      console.warn('cannot hit api without isAuthorized');
      return;
    }
    if (userId) {
      dispatch(_getNetwork({ userId, limit, direction, degrees, logged_in, types }));
    }
  };

  const getNetworkEdges = ({ groupId, direction, limit, personId }) => {
    if (!isAuthorized) {
      console.warn('cannot hit api without isAuthorized');
      return;
    }
    if (groupId) {
      dispatch(_getNetworkEdges({ groupId, direction, limit, personId }));
    }
  };

  const getCommunity = ({ groupId, personId, direction, limit }) => {
    if (!isAuthorized) {
      console.warn('cannot hit api without isAuthorized');
      return;
    }
    if (groupId) {
      dispatch(_getCommunity({ groupId, personId, direction, limit }));
    }
  };

  const getPenumbra = ({ pids, limit, depth }) => {
    if (pids?.length > 0) {
      dispatch(_getPenumbra({ pids, limit, depth }));
    }
  };

  const getBioregion = ({ bio_ids, person_id, depth, filters, limit, max_people }) => {
    if (bio_ids) {
      dispatch(_getBioregion({ bio_ids, person_id, depth, filters, limit, max_people }));
    }
  };

  const getShortestPath = ({ source, target }) => {
    if (source && target) {
      dispatch(_getShortestPath({ source, target }));
    }
  };

  const searchNetwork = (query, filters) => {
    if (!isAuthorized) {
      console.warn('cannot hit api without isAuthorized');
      return;
    }
    if (query) {
      dispatch(stringSearchNetwork({ query, filters }));
    }
  };

  const getSpecificUsers = personIds => {
    if (!isAuthorized) {
      console.warn('cannot hit api without isAuthorized');
      return;
    }
    if (personIds?.length > 0) {
      dispatch(_getSpecificUsers({ personIds }));
    }
  };

  const getRespected = ({ limit, filters }) => {
    if (!isAuthorized) {
      console.warn('cannot hit api without isAuthorized');
      return;
    }
    dispatch(_getRespected({ limit, filters }));
  };

  const getEdgesType = ({ types, limit, filters }) => {
    if (!isAuthorized) {
      console.warn('cannot hit api without isAuthorized');
      return;
    }
    dispatch(_getEdgesType({ types, limit, filters }));
  };

  const getNominationData = ({ limit, filters }) => {
    if (!isAuthorized) {
      console.warn('cannot hit api without isAuthorized');
      return;
    }
    dispatch(_getNominationData({ limit, filters }));
  };

  return {
    currentNetwork,
    currentNetworkRaw,
    isRequesting,
    currentProfile,
    totalMatches,
    getNetwork,
    getNetworkEdges,
    getCommunity,
    searchNetwork,
    getSpecificUsers,
    getRespected,
    getEdgesType,
    getPenumbra,
    getBioregion,
    getShortestPath,
    getNominationData
  };
};

export default usePeopleNetwork;

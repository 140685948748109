import { FBA_SET_LOADING, FBA_SET_USER, FBA_SET_ERROR } from 'shared/constants/actionTypes';

export const setUser = payload => ({
  type: FBA_SET_USER,
  payload
});

export const setError = error => ({
  type: FBA_SET_ERROR,
  payload: error
});

export const setLoading = loading => ({
  type: FBA_SET_LOADING,
  payload: loading
});

import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import Container from 'components/Chat/Container';

const ChatService = () => {
  return (
    <Box>
      <Heading as='h1'>Chat Service</Heading>
      <Container
        contentId='Demo/demo-chat-service-id'
        contentType='Demo'
        label='Demo Chat Service'
      />
    </Box>
  );
};

export default ChatService;

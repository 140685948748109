import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Button, Box, HStack, Text, Heading } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import useInvitations from 'hooks/useInvitations';
import UserAuth from 'components/User/UserAuth';
import FontAwesomeIcon from 'utils/FontAwesomeLoader';
import _ from 'lodash';
import { PopOverUser } from 'components/Segments/UserCard';

const InviteLink = props => {
  const { inviter, inviteId, group, onClick, align } = props;
  return (
    <HStack
      justifyContent={align}
      border='1px solid #ddd'
      p='8px 10px'
      borderRadius='12px'
      width={['95%', '88%', '77%', '55%']}
      m='8px auto'
    >
      <Button onClick={onClick} value={inviteId}>
        Accept your invitation
      </Button>
      <Text>
        to group&nbsp;
        <Text as='em' fontWeight='bold'>
          {group}
        </Text>
        &nbsp;from
      </Text>
      <PopOverUser
        shrink
        _id={inviter._id}
        node={inviter.data?.profile || inviter}
        order={['avatar', 'description', 'linkedin', 'twitter', 'facebook', 'id']}
      />
    </HStack>
  );
};

const InvitationsAccept = props => {
  // Looks for invitations matching the logged in user, presents any found
  const { onChange, align } = props;
  const dispatch = useDispatch();
  const { googleUser: user, isAuthorized, nooUser, currentNetworkData } = useNoo(
    'InvitationsAccept'
  );
  const [invites, setInvites] = useState([]);
  const { nooCommandDirect, nooCommand, nooCommandResponse } = useNooApi();
  const _GET_INVITATIONS = 'aqlUserInvitations';

  useEffect(() => {
    // check for my invitations
    setInvites(nooUser?.invitations || []);
  }, [nooUser, setInvites]);

  const acceptInviteHandler = useCallback(
    inviteId => {
      if (isAuthorized && inviteId) {
        const sendAcceptance = new Promise((resolve, reject) => {
          const loading = () => {};
          const responseHandler = response => {
            console.log('RESOLVING', response);
            resolve(response);
          };
          const responseError = error => {
            console.error('Error accepting invite', error);
            reject(error);
          };
          nooCommandDirect('acceptInvitation', {
            invitationId: inviteId,
            setLoading: loading,
            setResponse: responseHandler,
            setResponseError: responseError
          });
        })
          .then(result => {
            console.log('resolved', result);
            const filtered = invites.filter(invite => invite.invitation._id != inviteId);
            setInvites(filtered);
            if (onChange) {
              onChange('accepted ' + inviteId);
            }
          })
          .catch(error => {
            // handle any errors
            console.error('Error on accept promise', error);
          });
      }
    },
    [nooCommandDirect, isAuthorized, onChange, invites]
  );

  //                   <Text> {inviter.email} invited {invitee.email} </Text>
  const myInvites = useMemo(() => {
    const onAcceptClick = e => {
      acceptInviteHandler(e.target.value);
    };
    if (invites && invites?.length > 0) {
      let them = [];
      _.forEach(invites, invite => {
        const invitation = invite.invitation;
        // const invitee = invite.invitee.data.profile;
        const inviter = invite.inviter; // .data.profile;
        const group = invite.group.data.fullname;
        if (inviter && invitation && group) {
          them.push(
            <InviteLink
              key={invitation._id}
              inviteId={invitation._id}
              inviter={inviter}
              group={group}
              onClick={onAcceptClick}
              align={align}
            ></InviteLink>
          );
        }
      });
      return them;
    } else {
      return null;
    }
  }, [invites, acceptInviteHandler, align]);

  return (
    <Box>
      {myInvites?.length > 0 && (
        <Box align={align}>
          <Heading as='h3'>My Invitations</Heading>
          {myInvites}
        </Box>
      )}
    </Box>
  );
};

export default InvitationsAccept;

const markdown = `
***Newday Impact***  is the financial services company which provides authentic portfolios for socially responsible investors. Backed by insightful research and recognized community leaders, we offer portfolios addressing the world’s most pressing environmental and social issues, including climate action, ocean health, clean water, diversity equity and inclusion, wildlife conservation and animal welfare, and stakeholder capitalism.
### Impact Investing

What if the problem isn’t the financial system, but the way people use it? What if the desire to make more money could make the world a better place? In a system that values profit above all else, every dollar you invest is a vote for (or against) your values. Do you want to vote for pollution or for the solution? How about voting for glass ceilings or for gender equality? You can advocate for change and empowering companies to adapt. Those which adapt can thrive, the others may not. Your actions and your money can change the world consistent with your values.

Newday Impact is much more than an impact investing company. We are a platform that empowers individuals and institutions to invest consistent with values. Newday Impact supports its partners by donating 5% of net revenue to nonprofits working on the frontline of these issues that are addressing society’s greatest environmental, societal, and governance challenges. Our clients join a community which can achieve far more progress than can be achieved when we don’t act together.

### Contact

 



    
              
`;

export default markdown;

/* eslint-disable no-unused-vars */
/*
 * Account component
 *
 */
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import {
  Box,
  Input,
  Spinner,
  Heading,
  VStack,
  Text,
  UnorderedList,
  OrderedList,
  ListItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Image,
  Link,
  Button,
  HStack,
  Tooltip,
  Flex,
  Progress,
  useToast
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useCSVReader, readString } from 'react-papaparse';
import useNooApi from 'hooks/useNooApi';
import useNoo from 'hooks/useNoo';
import { readFile, writeFile } from 'utils/fileReadWrite';
import ProfilesResults from 'components/Search/Results/Profiles';
import NetworkTabs from 'components/Networks/NetworkTabs';
// import AlertGeneric from 'components/Segments/AlertGeneric';
import { graph_normalize } from 'utils/graphNormalize';
import UserAuth from 'components/User/UserAuth';
// import { transmitProfilesMock, charles_blass, harry_uvegi } from 'shared/constants/mockData';
import LocalStorage from 'utils/LocalStorage';

import _ from 'lodash';

const Import = () => {
  const [inputFile, setInputFile] = useState(JSON.parse(localStorage.getItem('input_file')));
  const [connectionsDoc, setConnectionsDoc] = useState({}); // document record holding connections
  const [fileData, setFileData] = useState(null);
  const [jsonData, setJsonData] = useState(null);
  const [connectionData, setConnectionData] = useState(null); // localStorage.getItem('connection-data')
  const [sent, setSent] = useState(null);
  const [writing, setWriting] = useState(false);
  const [rows, setRows] = useState(null);
  const [isProcessing, setIsProcessing] = useState(null);
  const [status, setStatus] = useState(null);
  const [existingImport, setExistingImport] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const { CSVReader } = useCSVReader();
  const { nooCommandDirect, nooCommandIsProcessingDirect } = useNooApi();
  const { token, isAuthorized, nooUser, currentNetworkData } = useNoo('Import');
  const [lIPerson, setLIPerson] = useState(null);
  const [toImport, setToImport] = useState([]);
  const [checked, setChecked] = useState(null);
  const [buttonsDisabled, setButtonsDisabled] = useState(null);
  const [totalMatches, setTotalMatches] = useState(graphData?.total || 0);
  const [idLookup, setIdLookup] = useState(null);
  const [analytics, setAnalytics] = useState({ connected: {} });
  const fileRef = useRef(null);
  const netname = currentNetworkData?.id;
  // const mystor = nooUser ? new LocalStorage(netname, nooUser?.person?._id) : null;
  const [myStorage, setMyStorage] = useState(null); // mystor
  const [lytics, setLytics] = useState([]);
  const [isTransmitting, setIsTransmitting] = useState(false);
  const [progressBar, setProgressBar] = useState(0);
  const [graph, setGraph] = useState(null);
  const [timestamp, setTimestamp] = useState(null);
  const [memberInfo, setMemberInfo] = useState(null);
  const toast = useToast();

  useEffect(() => {
    if (!jsonData && localStorage['connections-json']) {
      console.log('getting jsonData');
      setJsonData(JSON.parse(localStorage.getItem('connections-json')));
    }
  }, [jsonData]);
  useEffect(() => {
    if (!graphData && localStorage['connections-graph']) {
      console.log('getting graphdata');
      setIsProcessing(true);
      setGraphData(JSON.parse(localStorage.getItem('connections-graph')));
    }
  }, [graphData]);

  const getChecked = useCallback(() => {
    const force = false; // true;
    const them = JSON.parse(localStorage?.getItem('checked_users')) || [];
    setChecked(them);
    return them;
  }, []);

  useEffect(() => {
    if (!checked && localStorage['checked_users']) {
      getChecked();
    }
  }, [checked, getChecked]);

  useEffect(() => {
    // find their claimed LI user for _from of connections
    if (nooUser) {
      const li = nooUser?.claimed
        ? _.find(nooUser?.claimed, one => {
            return one.data.linkedin?.username;
          })
        : null;
      setLIPerson(li);
      if (nooUser.memberNumber >= 0) {
        const prof = nooUser.person.data.profile;
        const fn = prof.fullname || prof.displayName;
        setMemberInfo(`${fn}, member #${nooUser.memberNumber + 1}`);
      }
    }
  }, [nooUser]);

  useEffect(() => {
    // make a lookup mapping linkedin usernames to Person record ids and vice versa
    if (graphData && jsonData) {
      if (!idLookup) {
        let lookup = graphData.lookup;
        Object.entries(lookup).forEach(([k, v]) => {
          lookup[v] = k;
        });
        setIdLookup(lookup);
        // sort jsonData based on network centrality
        let current = jsonData;
        let order = {};
        if (graphData.connected) {
          graphData.connected.forEach(([k, v]) => {
            order[lookup[k]] = v;
          });
        }
        current.sort((a, b) => {
          return (order[a.uid] || 1) < (order[b.uid] || 1);
        });
      }

      // do analytics on results
      if (graphData.connected) {
        if (Object.keys(analytics.connected).length == 0) {
          let items = [];
          [2, 3, 4, 5].forEach(i => {
            // API only returns those with > 1
            const kount = graphData.connected.filter(pair => pair[1] >= i).length;
            analytics.connected[i] = kount;
            items.push(
              <ListItem key={[i, kount].join('_')}>
                {kount} are connected to {i} others
              </ListItem>
            );
          });
          setLytics(items);
        }
      }
    }
  }, [analytics.connected, graphData, idLookup, jsonData]);

  const [savePermission, setSavePermission] = useState(false);

  let session_id = localStorage.getItem('noo-single-use-token');
  if (!session_id) {
    session_id = btoa(Math.round((Date.now() * Math.random() * 1000000) / 1000) + '').replace(
      /=/g,
      ''
    );
    localStorage.setItem('noo-single-use-token', session_id);
  }
  useEffect(() => {
    if (nooUser) {
      setMyStorage(new LocalStorage(netname, nooUser.person._id));
    }
  }, [setMyStorage, netname, nooUser]);

  useEffect(() => {
    const doc = connectionsDoc;
    if (session_id && fileData) {
      doc.owner_id = nooUser?.person?._id || 'foobar';
      doc.url = 'file://connections_' + session_id + '_' + fileData.name;
      doc.source_name = fileData.name;
      setConnectionsDoc(doc);
    }
  }, [session_id, fileData, nooUser, connectionsDoc]);

  useEffect(() => {
    // only in setState. if initialized to localStorage, it uses it
    if (connectionData) {
      const parsed = JSON.parse(connectionData);
      setJsonData(parsed.connections);
      setFileData({ name: parsed.filename });
      setStatus(`got ${connectionData.length}...`);
    }
  }, [connectionData]);

  /*   useEffect(() => {
    const onSuccess = resp => {
      console.log('resp', resp);
    };
    const onError = e => {
      console.log('error', e);
    };
    if (session_id && nooUser) {
      // readFile({ session_id }, nooCommandDirect, token, onSuccess, onError); // file whose data.session_id = session_id
    }
  }, [nooCommandDirect, nooUser, session_id, token]); */

  const norm_field = str => {
    const parts = str.split('/in/'); // strip username from URL
    if (parts.length == 2) {
      return parts[1].trim().replace('/', '');
    } else {
      return str.trim();
    }
  };

  useEffect(() => {
    const lookup = {
      'first name': 'first',
      'last name': 'last',
      url: 'uid',
      'connected on': 'ts',
      position: 'title',
      'email address': 'email'
    };
    if (fileData?.connections && (jsonData || []).length <= 0) {
      setIsProcessing(true);
      setStatus('Parsing input file...');
      const rows = fileData.connections.split('\n'); // .map(row => row.split(','));
      let connections = [];
      let keys = null;
      rows.forEach((row, idx) => {
        readString(row, {
          worker: false,
          complete: results => {
            let it = results.data;
            it = it && it.length > 0 ? it[0] : [];
            var values = ['First Name', 'Last Name', 'URL'];
            const test = _.every(values, el => it.indexOf(el) >= 0); // find keys row
            if (keys) {
              let dict = {};
              Object.entries(keys).forEach(pair => {
                let key = pair[0].toLowerCase();
                key = lookup[key] || key;
                const index = pair[1];
                // if (!it[index]) console.log('bad', idx, key, index, it);
                dict[key] = norm_field(it[index]);
              });
              if (['uid', 'first', 'last'].every(f => dict[f])) {
                // not empty
                dict.username = dict.uid;
                dict.fullname = [dict.first, dict.last].join(' ');
                connections.push(dict);
              }
            }
            if (test) {
              // header row
              keys = {};
              it.forEach((txt, k) => {
                keys[txt] = k;
              });
            }
          }
        });
      });
      if (keys) {
        setJsonData(connections);
        localStorage.setItem('connections-json', JSON.stringify(connections));
      } else {
        alert(
          'Sorry. That file is not formatted correctly. It should have a header row in the first few rows containing "First Name", "Last Name", and "URL". See instructions.'
        );
        setStatus('Invalid input file');
      }
      setIsProcessing(false);
      console.log('done parsing');
    }
  }, [fileData, jsonData]);

  const writeConnectionsFile = useCallback(
    // writes connections from file to a doc in arango, then does a network from those. Too big to do othwerise
    permission_to_save => {
      if (fileData && jsonData.length > 0 && !writing) {
        const loading = () => {
          setWriting(true);
        };
        const onSuccess = response => {
          // after doc is written, build the network using it.
          console.log('success writing doc. Now get the network');
          setStatus('Done parsing');
          const doc = response.nodes[0].document;
          const doc_id = doc._id;
          setConnectionsDoc({ ...connectionsDoc, _id: doc_id, source_name: fileData.name });
          const limit = 300;
          if (doc_id) {
            const payload = {
              query_name: 'aqlUsersNetwork',
              data: { doc_id, limit },
              bundle: 'nodal_sandbox' // the analytics data is there
            };
            const params = {
              payload,
              token,
              setLoading: () => {},
              setResponse: response => {
                const data = response.result[0];
                // add any from original file data that weren't matched in nodal_sandbox
                const matched_unms = _.map(data.profiles, one => {
                  return one.data.linkedin?.username || one.data.profile?.username;
                });

                jsonData.forEach(one => {
                  if (data.profiles.length < limit && matched_unms.indexOf(one.username) == -1) {
                    data.profiles.push({ data: { linkedin: one.username, profile: one } });
                  }
                });
                setTotalMatches(Math.max(data.total, data.profiles.length));
                setStatus('Rendering network data');
                setGraphData(data);
                setIsProcessing(false);
                setIsTransmitting(false);
                setStatus('Your connections below ↓, sorted by network centrality ');
                setWriting(false);
                localStorage.setItem('connections-graph', JSON.stringify(data));
                toast({
                  title: 'Your enhanced connections down here ↓',
                  description: 'sorted by network centrality ',
                  status: 'success',
                  duration: 5000,
                  isClosable: true
                });
              },
              setResponseError: error => {
                console.log('error ', error);
                setIsTransmitting(false);
                toast({
                  title: 'Sorry, there was an error',
                  description: error,
                  status: 'error',
                  duration: 5000,
                  isClosable: true
                });
              }
            };
            setStatus('Getting related network data...');
            nooCommandDirect('aql', params);
          }
          setSent(false);
          setFileData(null);
        };
        const onError = error => {
          console.log('error ', error);
          setIsTransmitting(false);
          setSent(false);
          setFileData(null);
          toast({
            title: 'Sorry, there was an error',
            description: error,
            status: 'error',
            duration: 5000,
            isClosable: true
          });
          setStatus('Please try again');
        };

        const data = {
          url: connectionsDoc.url,
          connections: jsonData,
          type: 'connections',
          session_id,
          filename: fileData.name,
          savePermission: permission_to_save
        };
        console.log('writing connections to document');
        setIsTransmitting(true);
        writeFile({
          type: 'document',
          data,
          bundle: 'nodal_sandbox', // the analytics data is there
          owner_id: nooUser?.person?._id || null,
          nooCommandDirect,
          onSuccess,
          onError,
          loading
        });
        setSent(true);
      }
    },
    [
      connectionsDoc,
      fileData,
      jsonData,
      nooCommandDirect,
      nooUser?.person?._id,
      session_id,
      toast,
      token,
      writing
    ]
  );

  useEffect(() => {
    writeConnectionsFile(savePermission);
  }, [
    connectionsDoc.source_name,
    connectionsDoc.url,
    nooCommandDirect,
    nooUser?.person?._id,
    savePermission,
    session_id,
    writeConnectionsFile
  ]);

  const fileInput = e => {
    setStatus('Reading input file...');
    localStorage.removeItem('connections-data');
    localStorage.removeItem('connections-graph');
    localStorage.removeItem('connections-json');
    setSent(false);
    setGraphData(null);
    setJsonData([]);
    setIdLookup(null);
    setWriting(false);
    setLytics(null);
    setGraph(null);
    const files = e.target.files;
    const file = files ? files[0] : null;
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      setInputFile(file);
      localStorage.setItem('input_file', JSON.stringify({ name: file.name }));
      if (fileRef.current) fileRef.current.textContent = file.name;
      setFileData({ name: file.name, connections: reader.result });
      setLytics([]);
      setAnalytics({ connected: {} });
    };
  };

  useEffect(() => {
    if (graphData) {
      const it = graph_normalize({
        data: graphData,
        //allowlist: nooUser?.social,
        obfuscate: false
      });
      setGraph(it);
      setIsProcessing(false);
    }
    // setGraph(null);
  }, [graphData]);

  useEffect(() => {
    setButtonsDisabled(!checked || checked.length == 0 ? true : false);
  }, [checked]);

  const onChecked = useCallback((a, b, c) => {
    setButtonsDisabled(c == 0 ? true : false);
  }, []);

  const onToolChange = useCallback((a, b, c) => {
    setButtonsDisabled(c == 0 ? true : false);
  }, []);

  const clickFriend = user => {
    console.log('clicked, noop', user);
  };

  const addChecked = async () => {
    const them = getChecked();
    if (them.length > 0) {
      let items = [];
      them.forEach(pid => {
        const unm = idLookup[pid];
        let item = _.find(jsonData, one => one.uid == unm);
        item = { ...item, pid };
        items.push(item);
      });
      const toTransmit = formatForTransmission(items, 'linkedin', 'respect');
      const fullnames = _.map(toTransmit.friends, 'fullname').sort();

      const sure = confirm(
        `About to add ${them.length} connections. \n\n${fullnames.join(', ')} \n\nContinue? `
      );
      if (sure) {
        try {
          setIsTransmitting(true);
          const resp = await transmitNetwork(toTransmit);
          if (resp.success) {
            toast({
              title: items.length + ' Respect edges created',
              description: 'To your checked friends',
              status: 'success',
              duration: 3000,
              isClosable: true
            });
          } else {
            toast({
              title: 'Sorry, no success',
              description: JSON.stringify(resp, null, 2),
              status: 'error',
              duration: 3000,
              isClosable: true
            });
          }
          setIsTransmitting(false);
        } catch (e) {
          toast({
            title: 'Sorry, there was an error',
            description: e,
            status: 'error',
            duration: 3000,
            isClosable: true
          });
          setIsTransmitting(false);
        }
      }
    } else {
      alert('Noone checked');
    }
  };

  const onAddConnections = async () => {
    // const nodes = graph.nodes.filter(one => toImport.indexOf(one._id) > 1);
    // NB: ~2000 connections can be handled by POST, < 1G. Limit seems to be 2G, so should be able to handle 4000

    const sure = confirm('Adding ' + jsonData?.length + ' connections. Are you sure?');
    if (sure) {
      try {
        const chunkSize = 100;
        const allChunks = [];
        setProgressBar(1);
        const todo = jsonData; // ; // .slice(0, 45); // testing
        const test = true;
        let sent = 0;
        for (let i = 0; i < todo.length; i += chunkSize) {
          const chunk = todo.slice(i, i + chunkSize);
          const toTransmit = formatForTransmission(chunk, 'linkedin', 'linkedin');

          console.log(
            i,
            'totran',
            chunk.length,
            JSON.stringify(toTransmit).length,
            toTransmit.friends[0].fullname
          );
          try {
            const resp = await transmitNetwork(toTransmit);
            if (resp.success) {
              const percentDone = ((i + chunkSize) / todo.length) * 100;
              setProgressBar(percentDone >= 100 ? 0 : percentDone);
              sent += toTransmit.friends.length;
            }
          } catch (e) {
            toast({
              title: 'Sorry, there was an error on ' + i + ':' + (i + chunkSize),
              description: e,
              status: 'error',
              duration: 3000,
              isClosable: true
            });
          }
        }
        toast({
          title: 'Successfully sent ' + sent + ' connections',
          description: '',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
        markMember(); // add timestamp to logged in user
      } catch (error) {
        console.log('error on addconnections', error);
        alert('Error: Please try again.\n' + JSON.stringify(error, null, 2));
      }
    }
  };

  const formatForTransmission = (connections, site, type) => {
    // needs to match transmitProfilesMock, same as connection scanner
    // site is linkedin until further notice
    // type allows edge to be other than LI edge
    const keep = [
      'about',
      'bioregion',
      'company',
      'contact',
      'desc',
      // 'description',
      'email',
      'first',
      'fullname',
      'image',
      'last',
      'loc',
      // 'location',
      // 'mutuals',
      // 'pid',
      'skills',
      // 'test',
      'title',
      'url',
      'username'
    ];
    const normalize = blob => {
      if (!(blob.uid && blob.first && blob.last)) return null;
      blob.username = blob.uid;
      blob.fullname = [blob.first, blob.last].join(' ');
      let before =
        _.find(graphData?.profiles || [], one => {
          return (
            one.data.linkedin?.username == blob.username ||
            one.data.profile?.username == blob.username
          );
        }) || {};
      if (before?.data?.linkedin) {
        const data = before.data;
        before = { ...data.linkedin, ...data.profile };
      }
      let out = { ...before, ...blob };
      ['li', 'ts', 'uid', '_id'].forEach(field => {
        if (out[field]) delete out[field];
      });
      Object.entries(out).forEach(([k, v]) => {
        if (!(v && keep.includes(k))) delete out[k];
      });
      return out;
    };

    let profile = lIPerson.data; // harry_uvegi.data; // charles_blass.data; //
    profile = profile.linkedin.fullname ? profile.linkedin : profile.profile;

    let out = [];
    connections.forEach(blob => {
      const normed = normalize(blob);
      if (normed) out.push(normed);
    });
    return { friends: out, profile, account: profile, site, type }; // account == profile avoids vouch edge
  };

  const transmitNetwork = async data => {
    return new Promise((resolve, reject) => {
      // uses the same API and data format as connection scanner
      if (isAuthorized) {
        const loading = () => {};
        const responseHandler = response => {
          setIsTransmitting(false);
          resolve(response);
        };
        const responseError = error => {
          console.error('Error adding edges', error);
          reject(error);
        };
        nooCommandDirect('transmitNetwork', {
          data,
          // bundle: 'data_sandbox',
          site: data.site,
          setLoading: loading,
          setResponse: responseHandler,
          setResponseError: responseError
        });
      }
    });
  };

  const markMember = async () => {
    return new Promise((resolve, reject) => {
      // uses the same API and data format as connection scanner
      if (isAuthorized) {
        const loading = () => {};
        const responseHandler = response => {
          const person = response.markMembers.nodes[0].person;
          const members = response.getMembers.members;
          const epoch = person.data.netcoop?.imported[0];
          const date = new Date(epoch).toDateString();
          const pid = person._id;
          const which = _.find(members, one => {
            return (one[0] = pid);
          });
          const memberNumber = _.findIndex(members, one => {
            return (one[0] = pid);
          });
          setTimestamp(date);
          setMemberInfo(`${which[1]}, member #${memberNumber + 1}`);

          resolve(response);
        };
        const responseError = error => {
          console.error('Error adding edges', error);
          //reject(error);
        };
        nooCommandDirect('memberCreate', {
          data: {},
          setLoading: loading,
          setResponse: responseHandler,
          setResponseError: responseError
        });
      }
    });
  };
  const boxSx = {
    border: '1px solid #ccc',
    flex: '1 1 auto',
    paddingBottom: '4px'
  };

  const showVideo = () => {
    window.open(
      'https://www.loom.com/share/29ca668c114a4d188f330e488fcb1585?sid=2d787d63-6517-4caa-aa31-b4c376f35cce',
      'loom',
      'popup,width=800,height=600'
    );
  };

  return (
    <Box>
      <UserAuth unauthorized='signin'></UserAuth>

      <Box className='import'>
        <Heading as='h2' size='lg' textAlign='center' mt='1em'>
          Share your connections
        </Heading>
        <Text>LinkedIn and others are required by law to allow you to export your data.</Text>
        <Text>We will enhance it for you, and you can download that as JSON.</Text>
        <Text> You can decide if you want to contribute it to the cooperative.</Text>
        <HStack verticalAlign='top' alignItems={'top'}>
          <Box width={'60%'}>
            <Heading fontSize={'xl'}>Four easy steps</Heading>
            <Accordion className='accordion' sx={boxSx} allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as='span' flex='1' textAlign='left'>
                      <Text as='span' fontSize='lg' fontWeight={'bold'}>
                        Tutorial video
                      </Text>
                      <Text as='span' fontWeight={'normal'}>
                        (4 min)
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Box>
                    <Text>Watch the whole process in the brief video.</Text>
                    <Button onClick={showVideo}>View video</Button>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as='span' flex='1' textAlign='left'>
                      <Text as='span' fontSize='lg' fontWeight={'bold'}>
                        Step 1: Export your LinkedIn connections{' '}
                      </Text>
                      <Text as='span' fontWeight={'normal'}>
                        (2 min)
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  See{' '}
                  <Link
                    href={
                      'https://www.linkedin.com/help/linkedin/answer/a566336/export-connections-from-linkedin'
                    }
                    isExternal
                  >
                    instructions here <ExternalLinkIcon mx='2px' />
                  </Link>
                  .
                  <OrderedList>
                    <ListItem>
                      i.e., click your <em>Me avatar</em> on LinkedIn at the top, then{' '}
                      <em>Settings and Privacy</em>, <em>Data Privacy</em>, then{' '}
                      <em>Get a copy of your data</em>
                    </ListItem>{' '}
                    <ListItem>
                      For fast turnaround, choose <em>Want something in particular?</em>, and{' '}
                      <em>Connections</em> and <em>Profile</em> under that. Otherwise, choose{' '}
                      <em>Download larger data archive</em>
                      <Image
                        src={'http://standingwave.net/img/NN/LinkedIn_download.png'}
                        height={'300px'}
                        border='1px solid lightgray'
                      />
                    </ListItem>{' '}
                    <ListItem>
                      When you get the email from LinkedIn that your archive is ready, return to{' '}
                      <em>
                        <Link
                          href='https://www.linkedin.com/mypreferences/d/download-my-data'
                          isExternal
                        >
                          Get a copy of your data <ExternalLinkIcon mx='2px' />
                        </Link>
                      </em>{' '}
                      and click to download it. If no email, check that link and it might be ready.
                    </ListItem>{' '}
                    <ListItem>
                      Double-click the downloaded archive (.zip file) to uncompress it. If you asked
                      only for Connections, it will create a Connections.csv file in the same
                      directory. If you asked for more, e.g. all your data, it will create a folder
                      with the requested data, mostly as .csv files. The Connections.csv file is
                      what you will use.
                    </ListItem>{' '}
                  </OrderedList>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as='span' flex='1' textAlign='left'>
                      <Text as='span' fontSize='lg' fontWeight={'bold'}>
                        Step 2: Upload your connections anonymously{' '}
                      </Text>
                      <Text as='span' fontWeight={'normal'}>
                        (1 min)
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  Choose the Connections.csv file you dowloaded. It should contain rows of First
                  Name, Last Name, URL, etc.
                  <Input type='file' name='file' onChange={fileInput} />
                  {(isProcessing || isTransmitting) && <Spinner />}
                  {status && (
                    <Heading size='md' color={'#aaa'}>
                      {status}
                    </Heading>
                  )}
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as='span' flex='1' textAlign='left'>
                      <Text as='span' fontSize='lg' fontWeight={'bold'}>
                        Step 3: Claim your Linkedin account{' '}
                      </Text>
                      <Text as='span' fontWeight={'normal'}>
                        (2 min)
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  In order to import your Linkedin connections, you need to demonstrate ownership of
                  the account the connections come from. Click <strong>Claim</strong> in the nav bar
                  and follow the instructions.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as='span' flex='1' textAlign='left'>
                      <Text as='span' fontSize='lg' fontWeight={'bold'}>
                        Step 4: Share your links with the cooperative.{' '}
                      </Text>
                      <Text as='span' fontWeight={'normal'}>
                        (1-5 min)
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  {!lIPerson ? (
                    <Text>
                      <strong>NB: You need to {!nooUser ? 'log in and ' : ''}</strong>
                      <Link href={'https://greencheck.world/claim'}>
                        <strong>claim your LinkedIn account</strong> before you can save your
                        connections. <ExternalLinkIcon mx='2px' />
                      </Link>
                    </Text>
                  ) : !jsonData?.length ? (
                    <Text>You first need to load your Connections.csv file</Text>
                  ) : (
                    <Box>
                      <Text>
                        By clicking <em>Add all connections</em>, you are contributing your current
                        Linkedin connections to the shared database.
                      </Text>
                      <Text>
                        <em>
                          <strong>IMPORTANT</strong>
                        </em>
                        : This is not yet reversible; we will be adding a DELETE option that removes
                        your connections and your membership.
                      </Text>
                      <Text>
                        To explore the current state of the database, click Network in the nav
                        above.
                      </Text>
                    </Box>
                  )}
                  {graphData && (
                    <VStack>
                      <Button
                        colorScheme='teal'
                        onClick={onAddConnections}
                        disabled={!lIPerson || !jsonData?.length}
                      >
                        Add all connections
                      </Button>
                    </VStack>
                  )}
                  {progressBar > 0 && <Progress hasStripe value={progressBar} />}
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as='span' flex='1' textAlign='left'>
                      <Text as='span' fontSize='lg' fontWeight={'bold'}>
                        Step 5 (Optional): Amplify your favorite people.{' '}
                      </Text>
                      <Text as='span' fontWeight={'normal'}>
                        (1-10 min)
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  {!lIPerson ? (
                    <Text>
                      <strong>NB: You need to {!nooUser ? 'log in and ' : ''}</strong>
                      <Link href={'https://greencheck.world/claim'}>
                        <strong>claim your LinkedIn account</strong> before you can amplify others.{' '}
                        <ExternalLinkIcon mx='2px' />
                      </Link>
                    </Text>
                  ) : !jsonData?.length ? (
                    <Text>You first need to load your Connections.csv file</Text>
                  ) : (
                    <Box>
                      <Text>
                        In the List View below, click the checkbox next to people you particularly
                        want to amplify. Not all Linkedin connections are equal, and this is a quick
                        way to curate yours. You can do this more than once.
                      </Text>
                      <Text>When done,</Text>
                    </Box>
                  )}
                  {graphData && (
                    <HStack>
                      <Button
                        title={
                          buttonsDisabled
                            ? 'Check individuals in List View'
                            : 'Highlight your  checked'
                        }
                        colorScheme='teal'
                        onClick={addChecked}
                        disabled={buttonsDisabled}
                      >
                        Add checked connections
                      </Button>
                      {(isProcessing || isTransmitting) && <Spinner />}
                    </HStack>
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
          <Box sx={boxSx} width={'40%'}>
            <Heading fontSize={'xl'}>Status and Analytics</Heading>
            <VStack>
              <Box>
                {memberInfo && <Text sx={{ fontWeight: 'bold' }}>{memberInfo}</Text>}
                {timestamp && <Text>joined {timestamp}</Text>}
              </Box>

              {jsonData && <Text>You have {jsonData.length} connections to add.</Text>}
              {nooUser && (
                <Box sx={{ padding: '10px' }}>
                  <Text fontSize='14px'>
                    <strong>Total in database:</strong>
                  </Text>
                  <Text fontSize='14px'>
                    <strong>
                      {nooUser?.k_nodes} profiles, and {nooUser?.k_edges} connections from{' '}
                      {nooUser?.netcoop?.length} members, so far.
                    </strong>
                  </Text>
                </Box>
              )}
              {/*               <Box>
                <Text as='span'>Input file name:&nbsp;</Text>
                <Text as='span' ref={fileRef}>
                  {inputFile?.name}
                </Text>
              </Box> */}
              <Box sx={boxSx}>
                {graphData && (
                  <Tooltip label={'Coming soon'}>
                    <Button colorScheme='teal' disabled={false}>
                      Download your enhanced connections.
                    </Button>
                  </Tooltip>
                )}
              </Box>
              {/*               {lytics && (
                <Box sx={boxSx}>
                  <Text>Of your {jsonData?.length} connections:</Text>{' '}
                  <UnorderedList>{lytics}</UnorderedList>
                  <Text>See column 2 of List View below for more</Text>
                </Box>
              )} */}
            </VStack>
          </Box>
        </HStack>

        {graph && (
          <NetworkTabs
            graph={graph}
            maxNodes={200}
            onChecked={onChecked}
            clickFriend={clickFriend}
            default_tab={0}
            networkDisabled={true} /* nooUser ? false : true */
            total={'Total: ' + jsonData.length + ', showing top ' + graphData.profiles.length}
            onToolChange={onToolChange}
          />
        )}
      </Box>
    </Box>
  );
};

export default Import;

import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Tooltip,
  Button,
  Box,
  Text,
  HStack
} from '@chakra-ui/react';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import useDecisions from 'hooks/useDecisions';
import useGroups from 'hooks/useGroups';
import EditableVector from 'components/Segments/EditableVector';

/* import oneearth from 'components/DataViz/TEST_oneearth';
import { treeToTaxo } from 'utils/treemapUtils';
import AutoComplete from 'components/Segments/AutoComplete'; */

import _ from 'lodash';
import { inputs } from '@theme-ui/preset-tailwind';

const NominateModal = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inEdges, setInEdges] = useState(null);
  const [respectEdges, setRespectEdges] = useState(null);
  const [message, setMessage] = useState('');
  const [lastGroupId, setLastGroupId] = useState(null);
  const dispatch = useDispatch();
  const { token, nooUser } = useNoo('NominateModal');
  const { nooCommandDirect } = useNooApi();
  const valid_marker = (nooUser?.claimed || []).length > 1; // (nooUser?.valids || []).length > 0 &&
  /*   
  const [taxoData, setTaxoData] = useState(oneearth);
  const [treeNodes, setTreeNodes] = useState(null);
  const [treeTaxo, setTreeTaxo] = useState(null);
 */
  const disabled = !valid_marker;

  const { target, prefix } = props;
  const fullname = target.fullname;
  const targetId = target._id || target.id;
  const sourceId = nooUser?.person?._id;

  const { currentGroupId, setGroupId, setCurrentGroupId } = useGroups({
    from: 'NominateModal'
  });
  useEffect(() => {
    const gid = 'Groups/67266ea3-8eb0-46b3-95ca-e9fa53aba7f1';
    if (gid && currentGroupId != gid) {
      setGroupId(gid);
    }
  }, [currentGroupId, setGroupId]);

  const { decisions, addOption, getDecisionsByCurrentGroup } = useDecisions();

  /* NOT NECESSARY AND CAUSES UNNECESSARY LOADS
  useEffect(() => {
    if (currentGroupId) {
      if (!lastGroupId || currentGroupId !== lastGroupId) {
        getDecisionsByCurrentGroup();
        setLastGroupId(currentGroupId);
      }
    }
  }, [currentGroupId, getDecisionsByCurrentGroup, lastGroupId]); */

  const firstOpen = () => {
    onOpen();
    setInEdges(null);
    const person_id = target?.id || target?._id;
    if (person_id) {
      const payload = { query_name: 'aqlRespectEdges', data: { person_id } };
      const params = {
        payload,
        token,
        setLoading: () => {},
        setResponse: response => {
          const data = response.result[0];
          setInEdges(data.edges);
        },
        setResponseError: error => {
          console.log('error ', error);
        }
      };
      nooCommandDirect('aql', params);
    }
  };

  const endorsements = useMemo(() => {
    if (inEdges) {
      let out = {};
      inEdges.forEach(one => {
        if (one.data) {
          out[one._from] = one.data;
        }
      });
      return out;
    }
    return null;
  }, [inEdges]);

  const updateTags = data => {
    console.log('update respect edges', data);
    setRespectEdges(data);
  };

  useEffect(() => {
    let msg = '';
    if (!valid_marker)
      msg += 'You are not yet authorized because you have not yet claimed at least two accounts';
    if (!valid_marker) {
      msg = 'Sorry. ' + msg;
    }
    setMessage(msg);
  }, [valid_marker]);

  const onSubmit = e => {
    // push respect edge, and add to any related decision
    const mine = respectEdges[sourceId];
    console.log('submitting', JSON.stringify(mine));
    if (mine) {
      const params = {
        from_id: sourceId,
        to_id: targetId,
        type: 'RESPECT',
        target_data: null,
        edge_data: mine,
        token,
        setLoading: () => {},
        setResponse: response => {
          console.log('updated edge response ', response);
        },
        setResponseError: error => {
          console.log('error ', error);
        }
      };
      nooCommandDirect('createEdge', params); // This only overwrites my edge on submit, because noone else can change that edge
      // update the tag cloud happens in scheduled task.
    }
    // Is there a decision related to this?
    // console.log('decisions', decisions ? decisions.length : 'none');
    if (mine && decisions) {
      Object.entries(mine).forEach(([name, value]) => {
        const match = decisions.filter(one => one.data.title == name + ' Experts');
        const it = match ? match[0] : null;
        if (it) {
          console.log('matched', it);
          const data = { _id: targetId, avatar: target.avatar };
          console.log('target', target);
          const description = (target.description || '') + ' \n' + props.description;
          addOption({ questionId: it._id, label: fullname, description, data });
          // Does not add vote from current user to new option bc don't know option id. Deal with in scheduled tasks?
        } else {
          console.log('Decision not found matching ', name + ' Experts');
        }
      });
    }
    onClose();
  };

  return (
    <Box>
      <Button onClick={firstOpen} bgColor={'#89df6f'} title={'Nominate ' + fullname}>
        {typeof prefix == 'string' ? prefix : 'Nominate'}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Amplify {fullname} </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>What locations or domains is {fullname} knowledgeable about?</Text>

            <EditableVector
              sourceId={sourceId}
              endorsements={endorsements}
              onTagChange={updateTags}
              prompt={'Add bioregion or domain of expertise'}
              allowTaxos={true}
              preset={props.preset}
            />
          </ModalBody>

          <ModalFooter>
            <Tooltip label={message}>
              <span>
                <Button colorScheme='blue' mr={3} onClick={onSubmit} disabled={disabled}>
                  Submit
                </Button>
              </span>
            </Tooltip>
            <Button variant='ghost' onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default NominateModal;

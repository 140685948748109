import {
  NOO_SET_INVITATION,
  NOO_SET_INVITATION_ID,
  NOO_RESET_INVITATION_DATA
} from 'shared/constants/actionTypes';

export const setInvitation = invitation => ({
  type: NOO_SET_INVITATION,
  payload: invitation
});

export const setInvitationId = id => ({
  type: NOO_SET_INVITATION_ID,
  payload: id
});

export const resetInvitationData = () => ({
  type: NOO_RESET_INVITATION_DATA
});

import React from 'react';
import {
  Box,
  Button,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Text,
  VStack
} from '@chakra-ui/react';

const LoginForm = ({ localData, onFieldUpdate, handleLogin, setUiType }) => {
  return (
    <Box>
      <Heading as='h3' fontWeight='normal' fontStyle='italic' size='md'>
        Login with your email address
      </Heading>

      <HStack my='10px' width='440px' mx='auto'>
        <FormLabel htmlFor='l_email' width='80px' mt='8px'>
          Email
        </FormLabel>
        <Input
          type='text'
          placeholder='Email'
          name='l_email'
          value={localData.l_email || ''}
          onChange={onFieldUpdate}
          flex='1'
        />
      </HStack>

      <HStack my='10px' alignItems='flex-start' width='440px' mx='auto'>
        <FormLabel htmlFor='l_pass' width='80px' mt='8px'>
          Password
        </FormLabel>
        <VStack alignItems='flex-start' flex='1'>
          <Input
            type='password'
            placeholder='Password'
            name='l_pass'
            value={localData.l_pass || ''}
            onChange={onFieldUpdate}
          />
          <Button onClick={() => handleLogin()}>Login</Button>
        </VStack>
      </HStack>

      <Text fontSize='11px' textAlign='center' mt='1.5em'>
        <Link href='#' onClick={() => setUiType('forgot')} color='teal.400'>
          Forgot Password
        </Link>
      </Text>

      <Text fontSize='11px' textAlign='center' mt='1.5em'>
        Don&#39;t have an account?{' '}
        <Link href='#' onClick={() => setUiType('signup')} color='teal.400'>
          Sign up
        </Link>
      </Text>
    </Box>
  );
};

export default LoginForm;

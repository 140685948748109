const oneearth_solutions = [
  {
    name: 'Solutions',
    _id: 'solutions',
    value: 1000,
    children: [
      {
        name: 'Energy Transition',
        _id: 'energy transition',
        children: [
          {
            name: 'Renewable Power',
            _id: 'renewable power',
            children: [
              {
                description:
                  'Solar photovoltaic (PV) power utilizes sheets or panels of semiconducting materials capable of capturing photons from the sun and turning them into an electrical current. ',
                people: [
                  'Solar PV has become the lowest-cost option for new electricity generation in most regions of the world, with an average of $0.0x cents per kWh, leading to a record increase of 179\u202fTWh in new capacity 2021 alone (a 22% increase from 2020).',
                  'According to the One Earth climate model, at least ____ TWh will be needed to meet X% total energy demand by mid-century. In addition to meeting primary electricty demand, solar power will help meet global heating demand and energy required to produce green hydrogen fuel. ',
                  'Marta Victoria'
                ],
                name: 'Solar Photovoltaic',
                _id: 'solar photovoltaic',
                value: 300
              },
              {
                description:
                  'Power plants that generate electricity by using mirrors to concentrate sunlight on a central receiver, which converts the light into heat to create electricity with a heat engine. ',
                people: [
                  'Concentrated Solar can generate electricity even when the sun is not shining by storing solar heat that can be used later in the day. ',
                  'Alfredo Gonzalo'
                ],
                name: 'Concentrated Solar',
                _id: 'concentrated solar',
                value: 200
              },
              {
                description:
                  'Using underground thermal energy, harnessed most often near volcanoes, geysers, and hot springs, to create steam power.',
                people: [
                  'Iceland generates nearly 100% of its electricity from geothermal power due to its abundant volcanic resources along the Mid-Atlantic Ridge. ',
                  'Wilson Ricks'
                ],
                name: 'Geothermal Power',
                _id: 'geothermal power',
                value: 200
              },
              {
                description:
                  "Wind turbines use long blades to collect the wind's kinetic energy using a drive shaft that turns an electric generator, producing electricity. ",
                people: [
                  'The average generating capacity of onshore wind turbines has steadily increased from less than 1 MW c. 2000 to 3 MW capacity today. ',
                  'Russell McKenna'
                ],
                name: 'Onshore Wind',
                _id: 'onshore wind',
                value: 200
              },
              {
                description:
                  'Wind turbines anchored to the sea floor and are generally more efficient than their onshore relatives because they utilize the higher, more consistent wind speeds over the ocean and can be larger in size.',
                people: ['Hugo Diaz'],
                name: 'Offshore Wind',
                _id: 'offshore wind',
                value: 100
              },
              {
                description:
                  'Most commonly wave power extracts energy from the rise and fall of the waves using weighted buoy, converting motion to electrical energy with a linear or rotary generator.',
                people: [
                  'There are five main types of wave power technology -- absorber, attenuator, oscillation water column, overtopping, and inverted pendulum.',
                  'Susan Gourvenec'
                ],
                name: 'Wave Energy',
                _id: 'wave energy',
                value: 200
              },
              {
                description:
                  'Smaller-scale hydropower projects generate electricity through a controlled flow of water, using less concrete and allowing fish to maintain their migration pathways.',
                people: [
                  'Sustainable hydropower produces significantly less methane emissions than large-scale hydropower dams...',
                  'Himanshu Nautiyal'
                ],
                name: 'Sustainable Hydropower',
                _id: 'sustainable hydropower',
                value: 200
              },
              {
                description:
                  'Combusting cellulosic waste products such as wood scraps, agricultural residues, and organic landfill materials to produce electricity from a thermoelectric generator. Trees harvested for the purposes of combustion are not considered sustainable. ',
                people: ['Mohammed Antar', 'Richardvan den Broek'],
                name: 'Sustainable Biomass Power',
                _id: 'sustainable biomass power',
                value: 200
              },
              {
                description:
                  'Hydrogen produced by using renewable energy to electrolyze water (splitting water into hydrogen and oxygen). The resulting hydrogen can be stored and used on demand in a fuel cell to create renewable power. ',
                people: ['Alexandra M Oliveira', 'Albert H.Schrotenboer'],
                name: 'Green Hydrogen Power',
                _id: 'green hydrogen power',
                value: 200
              }
            ],
            value: 900
          },
          {
            name: 'Renewable Heat',
            _id: 'renewable heat',
            children: [
              {
                description:
                  'Collecting the thermal energy from the sun, most often in a sealed flat plate with copper pipes, used for residential, commercial or industrial space heating or hot water. ',
                people: ['Luca Evangelisti', 'Tabish Alam'],
                name: 'Solar Heat',
                _id: 'solar heat',
                value: 200
              },
              {
                description:
                  'Combusting cellulosic waste products in the form of wood scraps, agricultural residues, and other organic materials, to generate renewable heat without reducing the total extent of forests or croplands. ',
                people: ['G\u00f6ran Berndes', 'Richardvan den Broek'],
                name: 'Sustainable Biomass Heat',
                _id: 'sustainable biomass heat',
                value: 200
              },
              {
                description:
                  'Harnessing and distributing latent heat within the Earth, which can be accomplished at district level with larger heat distribution plants, or at an individual residential level, using the steady temperature below the surface as a heat reservoir. ',
                people: ['Wilson Ricks'],
                name: 'Geothermal Heat',
                _id: 'geothermal heat',
                value: 100
              },
              {
                description:
                  'Turning hydrogen produced using renewable energy into heat, by running it through a fuel cell; useful for meeting fluctuating demands in heat due to its on-demand nature. ',
                people: ['Alexandra M Oliveira', 'Albert H.Schrotenboer'],
                name: 'Green Hydrogen Heat',
                _id: 'green hydrogen heat',
                value: 200
              },
              {
                description:
                  'Utility-scale system for distributing renewable heat generated in a centralized location for residential and commercial heating requirements, such as space heating and water heating, through a system of insulated pipes. ',
                people: ['ZhiyongTian'],
                name: 'District Heat',
                _id: 'district heat',
                value: 100
              },
              {
                description:
                  'Heat pumps, induction ovens, and other devices that convert renewable electricity into heat through resistance, radiation, or separating hot from cool air. ',
                people: [],
                name: 'Electric Heat',
                _id: 'electric heat',
                value: 100
              }
            ],
            value: 600
          },
          {
            name: 'Renewable Transport',
            _id: 'renewable transport',
            children: [
              {
                description:
                  'Hydrogen produced using renewable energy to electrolyze water (splitting water into Hydrogen and Oxygen). The resulting hydrogen can be stored and used on demand in a fuel cell to create renewable power. ',
                people: ['YushanYan', 'Albert H.Schrotenboer'],
                name: 'Green Hydrogen Fuel',
                _id: 'green hydrogen fuel',
                value: 200
              },
              {
                description:
                  'Combustable fuels that are similar to petroleum but created using renewable power and abundant inputs (e.g. hydrogen and carbon monoxide). They can be used in existing jets and ships without significantly modifying their engines.',
                people: ['Sascha Stegen', 'YasamanBalali'],
                name: 'Sustainable Synfuel',
                _id: 'sustainable synfuel',
                value: 200
              },
              {
                description:
                  'Fuels made from engineered algae that are grown in a sustainable way. Fuels made from crops (e.g. soy, corn) are not considered sustainable as they compete for critical agricultural land used in food production.',
                people: ['Sascha Stegen', 'Stefano Puricelli'],
                name: 'Sustainable Biofuel',
                _id: 'sustainable biofuel',
                value: 200
              },
              {
                description:
                  'Trains, trams, cars, buses, and bikes that run on renewable electricity by either a direct connection to the grid or through battery storage. ',
                people: ['Sascha Stegen'],
                name: 'Electric Transport',
                _id: 'electric transport',
                value: 100
              }
            ],
            value: 400
          },
          {
            name: 'Energy Efficiency',
            _id: 'energy efficiency',
            children: [
              {
                description:
                  'Any built structure -- homes, commercial buildings, government facilities, roads & bridges, factories -- where engineering is used to reduce the materials needed for construction (and emissions associated with those materials) and designed to require less energy for HVAC and lighting. ',
                people: ['Luisa F. Cabeza (Spain)', 'Quan Bai (China)'],
                name: 'Built Environment',
                _id: 'built environment',
                value: 200
              },
              {
                description:
                  'Measures that reduce dependence on low-efficiency travel like automobiles and airplanes. This includes infrastructure investments in public rail, busing, bicycles, and PEVs as well as policy measures encouraging remote working, which reduces commuting, or vehicle weight reduction (lighter cars need less energy to move). ',
                people: [
                  'Paulina Jaramillo (US)',
                  'Aritra Ghosh',
                  'Suzana Kahn Ribeiro (Brazil)',
                  'Peter Newman (Australia)'
                ],
                name: 'Transportation',
                _id: 'transportation',
                value: 400
              },
              {
                description:
                  'Measures that reduce "line loss" -- i.e. the loss of power between the point of production and the point of consumption. These measures can include smart grids, smart meters, demand response, integrated grid storage, utility-scale batteries, and load shedding.',
                people: ['Leon Clarke (IPCC)', 'S.Koohi-Fayegh'],
                name: 'Transmission & Storage',
                _id: 'transmission & storage',
                value: 200
              },
              {
                description:
                  'Innovations and upgrades that reduce the energy intensity and direct emissions from manufacturing chemicals, metals, electrical goods, physical materials, and cement.  ',
                people: [
                  'The One Earth climate model charts transition pathways for 8 major industry sub-categories -- ...',
                  'Nick Campbell (UK)'
                ],
                name: 'Industrial Processes',
                _id: 'industrial processes',
                value: 200
              }
            ],
            value: 400
          }
        ],
        value: 400
      },
      {
        name: 'Nature Conservation',
        _id: 'nature conservation',
        children: [
          {
            name: 'Land Conservation',
            _id: 'land conservation',
            children: [
              {
                description:
                  'Land areas already protected or recognized by governments. This includes all IUCN protected area classes as well as Other Effective Conservation Measures (OECMs) as defined by WCMC and makes up Layer 1 of the Global Safety Net (GSN).',
                people: [
                  'Land Protected Areas total 15% of the planet\u2019s land and make up Layer 1 of the Global Safety Net. ',
                  'Neil Burgess'
                ],
                name: 'Land Protected Areas',
                _id: 'land protected areas',
                value: 200
              },
              {
                description:
                  'Unprotected areas that need to be protected immediately due to the presence of rare or range-restricted plant and animal species. This includes species rarity sites and makes up Layer 2 of the Global Safety Net (GSN). ',
                people: [
                  'Species Rarity Sites total 2.3% of the planet\u2019s land and make up Layer 2 of the Global Safety Net. ',
                  'Eric Dinerstein'
                ],
                name: 'Rarity Sites',
                _id: 'rarity sites',
                value: 200
              },
              {
                description:
                  'Unprotected land areas with groupings of plants and animals that are vital to maintaining our ecosystems. This makes up Layer 3 of the Global Safety Net (GSN).',
                people: [
                  'High biodiversity areas total 6.0% of the planet\u2019s land and make up Layer 3 of the Global Safety Net. ',
                  'Norman Myers'
                ],
                name: 'Land Habitats',
                _id: 'land habitats',
                value: 200
              },
              {
                description:
                  'Unprotected large mammal landscapes where seasonal groupings of animals occur, in particular megafauna. This includes areas like the Pantanal wetlands in Western Brazil and makes up Layer 4 of the Global Safety Net (GSN). ',
                people: [
                  'Large mammal landscapes, like the Pantanal wetlands in Western Brazil, home to the world\u2019s largest jaguars, total 6.3% of the planet\u2019s land and make up Layer 4 of the Global Safety Net. ',
                  'Carly Vynne'
                ],
                name: 'Mammal Assemblages',
                _id: 'mammal assemblages',
                value: 200
              },
              {
                description:
                  "Unprotected areas with a large extent of intact wilderness. This includes continuous forests, shrublands, and grasslands that aren't identified in previous layers and makes up Layer 5 of the Global Safety Net (GSN).",
                people: [
                  "Intact Wilderness totals 16% of the planet's land and makes up Layer 5 of the Global Safety Net.",
                  'Peter Potapov'
                ],
                name: 'Intact Wilderness',
                _id: 'intact wilderness',
                value: 200
              },
              {
                description:
                  'Other unprotected areas that store > 50 tC per hectare and could potentially provide habitat. This makes up Layer 6 of the Global Safety Net (GSN).',
                people: [
                  "Climate Stabilization Areas totals 4.7% of the planet's lands and makes up Layer 6 of the Global Safety Net.",
                  'Carolina Soto Navarro'
                ],
                name: 'Other Refugia',
                _id: 'other refugia',
                value: 200
              },
              {
                description:
                  'Land currently occupied or managed by Indigenous People or Local Communities (IPLCs) that are legally recognized by governments as belonging to those communities.',
                people: [
                  "Although Indigenous Peoples comprise less than 5% of the world population, they protect 80% of the Earth's biodiversity.",
                  'Malumbo Chipofya'
                ],
                name: 'Indigenous Tenure',
                _id: 'indigenous tenure',
                value: 200
              },
              {
                description:
                  'Supporting the reintroduction of wildlife back into urban or suburban areas. This includes tree planting, micro forests, pollinator meadows, river restoration, etc.  ',
                people: [
                  'Urban forests can play an increasingly important role in resting and food hunting by migratory birds, and even small patches of forest make a difference',
                  'Travis Gallo'
                ],
                name: 'Urban Biodiversity',
                _id: 'urban biodiversity',
                value: 200
              }
            ],
            value: 800
          },
          {
            name: 'Ocean Conservation',
            _id: 'ocean conservation',
            children: [
              {
                description:
                  'Marine areas already protected or recognized by governments. This includes all IUCN protected area classes as well as Other Effective Conservation Measures (OECMs). ',
                people: [
                  'More than 5,000 Marine Protected Areas (MPAs) have been established throughout the world, covering 0.8% of the ocean.',
                  'Graham Edgar'
                ],
                name: 'Marine Protected Areas',
                _id: 'marine protected areas',
                value: 200
              },
              {
                description:
                  'Unprotected marine areas with groupings of plants and animals that are vital to maintaining marine ecosystems.',
                people: [
                  'About three-fifths of all the fish species that we know about live in marine habitats.\n',
                  'Graham Edgar',
                  'Jennifer Smith'
                ],
                name: 'Marine Habitats',
                _id: 'marine habitats',
                value: 300
              },
              {
                description:
                  'Marine areas with no-take seasons or other measures that maintain fish populations so they can properly reproduce. This includes measures to reduce fishing of wildlife, like sustainable fish farming. ',
                people: [
                  'Sustainable fish farms create jobs, support resilient working waterfronts and coastal communities, and provide international trade opportunities.',
                  'Anthony T. Charles'
                ],
                name: 'Sustainable Fisheries',
                _id: 'sustainable fisheries',
                value: 200
              },
              {
                description:
                  "Adding alkalizing substances to seawater to enhance the ocean's natural carbon sink without harming any plant or animal populations. This helps to maintain the conditions needed to keep corals alive by reducing pollution-caused acidity of the oceans using tools like olivine sand, which is naturally occurring. ",
                people: [
                  'Some methods of alkalinization use electrochemical weathering, or using fuel cells to enhance alkalinity. This produces hydrogen, which could be used as an alternative energy source.',
                  'Tatiana Ilyina'
                ],
                name: 'Alkalinization',
                _id: 'alkalinization',
                value: 200
              }
            ],
            value: 400
          },
          {
            name: 'Ecosystem Restoration',
            _id: 'ecosystem restoration',
            children: [
              {
                description:
                  'Restoring a forest that has been previously logged or degraded through natural regeneration. This can be unassisted or assisted, the latter of which involves periodic clearing of invasive species if present. ',
                people: [
                  'Employing natural regeneration can create a 5-fold cost reduction can be achieved when compared to restoration planting.',
                  'Robin Chazdon'
                ],
                name: 'Forest Recovery',
                _id: 'forest recovery',
                value: 200
              },
              {
                description:
                  'Restoring a forest through the process of planting native trees in areas that have been affected man made disturbances (e.g. logging, mining, agricultural clearing, and development) or by natural disturbances (e.g. wildfires, drought, and insect and disease infestations). ',
                people: [
                  'As of 2019, science shows there are 0.9 billion hectares of forests that could be restored - storing 205 gigatonnes of carbon',
                  'Susan Cook-Patton'
                ],
                name: 'Reforestation',
                _id: 'reforestation',
                value: 200
              },
              {
                description:
                  'Managing forests that includes selective logging as opposed to clear cutting. It is more expensive to do but results in high-quality timber products over the longer term, reducing carbon emissions from logging and benefiting wildlife. ',
                people: [
                  'Reduced-impact logging for climate (RIL-C) is a way to maintain timber production while minimizing forest damage, reducing logging emissions by 44%',
                  'Peter Ellis'
                ],
                name: 'Sustainable Forestry',
                _id: 'sustainable forestry',
                value: 200
              },
              {
                description:
                  'Restoring grassland ecosystems using a combination of management and planting of native species.',
                people: [
                  "Grasslands are among the largest terrestrial biomes, covering >25% of the terrestrial earth's surface",
                  'P\u00e9ter T\u00f6r\u00f6k'
                ],
                name: 'Grassland Restoration',
                _id: 'grassland restoration',
                value: 200
              },
              {
                description:
                  'Restoring wetland ecosystems using a combination of management and planting of native species, covering all types of wetlands -- marshes, swamps, bogs, fens, sea grass, and kelp forests. ',
                people: [
                  "Wetlands are critical to the Earth's biodiversity - 40% of all the world's species live and breed in these environments",
                  'Joy Zedler'
                ],
                name: 'Wetlands Restoration',
                _id: 'wetlands restoration',
                value: 200
              },
              {
                description: 'Restoring native mangrove tree species along coastal areas...',
                people: ['Megan Saunders'],
                name: 'Mangrove Restoration',
                _id: 'mangrove restoration',
                value: 100
              },
              {
                description:
                  'Hybridizing or reestablishing corals in areas that have experienced bleaching or other disturbances. ',
                people: [
                  'Coral reefs occur in less than 1 percent of the ocean, yet are home to nearly one-quarter of all ocean species.',
                  'Elisa Bayraktarov'
                ],
                name: 'Coral Restoration',
                _id: 'coral restoration',
                value: 200
              },
              {
                description:
                  'Reintroducing species of wild terrestrial and marine animals that were previously driven out or exterminated from their native habitats.',
                people: [
                  'Rewilding just 20 large mammal species back to their historic habitats could restore ecosystems across almost one-quarter of the Earth\u2019s land.',
                  'Steve Carver',
                  'Ian Convery'
                ],
                name: 'Species Rewilding',
                _id: 'species rewilding',
                value: 300
              }
            ],
            value: 800
          },
          {
            name: 'Wildlife Connectivity',
            _id: 'wildlife connectivity',
            children: [
              {
                description:
                  'Areas of land that serve to connect two or more wildlife areas. These are often narrow strips of land and can include small pockets of habitat or microrefugia.',
                people: [
                  'Land corridors can increase movement between isolated populations, help to increase genetic diversity, and increase food availability for a variety of species.',
                  'Annika Keely'
                ],
                name: 'Land Corridors',
                _id: 'land corridors',
                value: 200
              },
              {
                description:
                  'Areas of land that act as buffers between cultivated or developed land and a protected area, Indigenous conservation area, or other wildlife habitat. Often these buffers are productive (e.g. agroforestry projects, etc).',
                people: [
                  'Buffer areas can help meet a number of natural resource, economic, and social objectives, including providing wildlife habitat, protecting cropland and downstream communities from flood damage, and filtering nutrients, pesticides, and animal waste from agricultural land runoff.',
                  'Ignacio Palomo'
                ],
                name: 'Land Buffers',
                _id: 'land buffers',
                value: 200
              },
              {
                description:
                  'Areas of land running alongside a river that enable river-dependent species to feed, mate, and migrate. Conserving these can stabilize river banks and reduces the velocity of water to support wildlife.  ',
                people: [
                  "Rivers hold less than 1% of the world's water, with the rest existing in the salty ocean and polar ice caps - making rivers incredibly important for freshwater conservation.",
                  'Ellen Wohl'
                ],
                name: 'Rivers & Streams',
                _id: 'rivers & streams',
                value: 200
              },
              {
                description:
                  'Marine areas that serve as migration routes for sea birds, fish, and marine mammals across the ocean.',
                people: [
                  'The Eastern Tropical Pacific Marine Corridor (CMAR) was established in 2004 to provide for the long-term conservation of nature, restore ecosystem resilience and mitigate the damage to marine biodiversity caused by human activities.',
                  'Gail Schofield'
                ],
                name: 'Marine Corridors',
                _id: 'marine corridors',
                value: 200
              }
            ],
            value: 400
          }
        ],
        value: 400
      },
      {
        name: 'Regenerative Agriculture',
        _id: 'regenerative agriculture',
        children: [
          {
            name: 'Regenerative Croplands',
            _id: 'regenerative croplands',
            children: [
              {
                description:
                  'Afforestation on croplands can take many forms -- from windbreaks and pocket forests to orchards and alley cropping (with trees dividing strips of cropland.)',
                people: [],
                name: 'Farm Afforestation',
                _id: 'farm afforestation',
                value: 100
              },
              {
                description:
                  'Farming practices that increases the health, resilience, carbon content and prodcutivity of soils -- including cover crops, erosion control, microbial innoculants, and other non-fertilizer soil improvers. ',
                people: [
                  'Soil carbon sequestration and the conservation of existing soil carbon stocks, given its multiple benefits including improved food production, is an important mitigation pathway to achieve the less than 2\u2009\u00b0C global target of the Paris Climate Agreement.\n\n'
                ],
                name: 'Soil Management',
                _id: 'soil management',
                value: 100
              },
              {
                description:
                  'Returning degraded cropland back to functioning cropland or grazing lad using regenerative agriculture practices. ',
                people: [
                  'Globally, cropland stores almost 10% of the total global soil organic carbon in the top 30\u2009cm of soil.'
                ],
                name: 'Cropland Restoration',
                _id: 'cropland restoration',
                value: 100
              },
              {
                description:
                  'Carbonised biomass obtained from sustainable sources and sequestered in soils to sustainably enhance their agricultural and environmental value under present and future management.',
                people: [
                  'Sustainable biochar can both sequester carbon to help mitigate climate change while also providing energy and increasing crop yields'
                ],
                name: 'Sustainable Biochar',
                _id: 'sustainable biochar',
                value: 100
              },
              {
                description:
                  'Fertilizer that is derived from organic sources, including organic compost, herbivore manures, vermiculture, microbial soil amendments, and domestic sewage. ',
                people: [
                  'Organic fertilizers can improve the structure of soil over time by increasing aeration and water-holding capacity of the soil. '
                ],
                name: 'Sustainable Fertilizers',
                _id: 'sustainable fertilizers',
                value: 100
              },
              {
                description:
                  'Planting rice with no or minimal tillage into previous crop residue to protect the soil from erosion and loss of nutrients, reducing methane emissions. ',
                people: [
                  'Sustainable rice farming can decrease water use by 2%, reduce greenhouse gas emissions by 50%, and increase income by 10%.'
                ],
                name: 'Sustainable Rice Farming',
                _id: 'sustainable rice farming',
                value: 100
              },
              {
                description:
                  'Built infrastructure designed to grow crops. This can include vertical farming, advanced greenhouses, green roofs, etc.',
                people: [
                  'Growing crops vertically allows for conservation in space, resulting in a higher crop yield per square foot of land used.'
                ],
                name: 'Agritecture',
                _id: 'agritecture',
                value: 100
              },
              {
                description:
                  'Using AI and data modeling to determine the optimal location for various crops, increasing overall yields and crop resilience while reducing water consumption.',
                people: [
                  'Crop production will need to increase by about 60% to satisfy the demand of food for the fast-growing population globally - demonstrating the need for crop optimization.'
                ],
                name: 'Crop Optimization',
                _id: 'crop optimization',
                value: 100
              },
              {
                description:
                  'Using technologies like drip irrigation to turn desert areas with insufficient precipitation into viable croplands. ',
                people: [
                  'Dryland irrigation helps employ sustainable and regenerative agriculture practices, like keeping the soil rooted year round.'
                ],
                name: 'Dryland Irrigation',
                _id: 'dryland irrigation',
                value: 100
              },
              {
                description:
                  'Farming underneath the forest canopy or multi-story cropping on farmland that combines trees, shrubs, and herbaceous plants. ',
                people: [
                  'Forests containing multiple tree species could store at least twice as much carbon as the average monoculture plantation. A recent study also concluded that each native tree species introduced into a plantation could increase carbon stocks by 6% (per additional species).'
                ],
                name: 'Agroforestry',
                _id: 'agroforestry',
                value: 100
              },
              {
                description:
                  'The practice of cultivating diverse crops and integrating multiple species, which helps sequester carbon, enhance biodiversity, and improve ecosystem resilience',
                people: [
                  'Like natural systems, polycultures offer benefits, including reduced pest damage and soil conservation, even increasing crop yields per area compared to monocultures.'
                ],
                name: 'Polyculture',
                _id: 'polyculture',
                value: 100
              },
              {
                description:
                  'Growing specialized produce that is exceptionally high in nutrients and therefore considered beneficial for human health and wellbeing, for example acai, goji, moringa, etc. ',
                people: [
                  'Studies have demonstrated that superfoods high in antioxidants and flavonoids help prevent coronary heart disease and cancer, as well as improving immunity and decreasing inflammation.'
                ],
                name: 'Perennial/Superfoods',
                _id: 'perennial/superfoods',
                value: 100
              },
              {
                description:
                  'Seed saving and hybridization to restore heirloom crops and increase the diversity of plant types available to farmers. This prioritizes flavor and nutritional content over visual appearance, a shift that occurred with industrialization.',
                people: [
                  'Without seed diversity, it\u2019s difficult for plants to adapt to pests, diseases, and changing climate conditions \u2014 a particular concern as the world warms.'
                ],
                name: 'Seed Diversity',
                _id: 'seed diversity',
                value: 100
              },
              {
                description:
                  'Family or community farming on less than five acres that is an essential solution to both food security and climate change, allowing for micro-management of a diversity of crops adapted to a specific region and has a much lower carbon footprint than industrial agriculture.',
                people: [
                  'While 75 percent of the world\u2019s food is generated from only 12 plants and 5 animal species, making the global food system highly vulnerable to shocks, biodiversity is key to smallholder systems who keep many rustic and climate-resilient varieties and breeds alive.'
                ],
                name: 'Smallholder Farming',
                _id: 'smallholder farming',
                value: 100
              }
            ],
            value: 1400
          },
          {
            name: 'Sustainable Rangelands',
            _id: 'sustainable rangelands',
            children: [
              {
                description:
                  'Integration of trees in landscapes dedicated primarily to grazing livestock.  ',
                people: [
                  'One of the main advantages of silvopasture systems is reducing heat stress in livestock, which improves animal performance and well-being. '
                ],
                name: 'Silvopasture',
                _id: 'silvopasture',
                value: 100
              },
              {
                description:
                  'Subsistence agricultural practice that involves raising domestic animals in natural grassland environments using herd mobility, often in dryland areas.',
                people: [
                  'What distinguishes pastoral systems is traditional knowledge, centuries-long tested experiences based on inference and ground truthing, with a propensity to adapt to new circumstances.'
                ],
                name: 'Pastoralism',
                _id: 'pastoralism',
                value: 100
              },
              {
                description:
                  'Raising herbivores like cattle, bison, and sheep on agricultural areas that are rotated periodically, allowing the remainder of the pasture to \u201crest" so that plants can regrow and mimicking the natural conditions that allowed both grasslands and large herbivores to thrive historically.',
                people: [
                  'Facilitating soil carbon sequestration through improved grazing regimes is an important measure for offsetting greenhouse-gas emissions to mitigate current climate change.'
                ],
                name: 'Grazing Optimization',
                _id: 'grazing optimization',
                value: 100
              },
              {
                description:
                  'Feeding livestock a healthy diet that provides essential macronutrients such as protein, vitamins and minerals, and adequate fiber, increasing the health of animals, reducing dependence on antibiotics, and also reducing methane emissions from enteric fermentation.',
                people: [
                  'Improving cattle feed can reduce carbon dioxide equivalent emissions by 4.42\u201315.05 gigatons by 2050. '
                ],
                name: 'Healthy Feed',
                _id: 'healthy feed',
                value: 100
              },
              {
                description:
                  'Diet based on the EAT Lancet commission which (if everyone followed it) would allow us to feed 10 billion people on our current agricultural footprint by significantly reducing the intake of red meat to 1 serving per week and increasing the intake of vegetables, while cutting food waste in half. ',
                people: [
                  'The commission states that it\u2019s important to reduce your overall consumption of meat because the production contributes to the growing social and economic costs of poor public health, climate disaster relief and environmental degradation. '
                ],
                name: 'Planetarian Diet',
                _id: 'planetarian diet',
                value: 100
              },
              {
                description:
                  'New sector of the food industry that seeks to replace red meat with plant-based protein from pulses (legume crops), seaweed, moringa or other high-protein sources. Note: not all plant proteins are good and research is necessary into pesticides, novel GMOs, or other chemicals that might be required.',
                people: [
                  'When demand shifts from animal-based to alternative proteins, there is less pressure for farmers to convert native vegetation to farmland and some existing farmland could even be restored to native vegetation.'
                ],
                name: 'Meat-free Proteins',
                _id: 'meat-free proteins',
                value: 100
              }
            ],
            value: 600
          },
          {
            name: 'Food Waste Reduction',
            _id: 'food waste reduction',
            children: [
              {
                description:
                  'Recycling organic matter, such as leaves and food scraps, into a valuable fertilizer and soil amendment to enrich soil, water retention, and carbon sequestration.',
                people: [
                  'When compost is applied, the soil\u2019s health increases as microbes grow and become more plentiful. These microbes sequester carbon in the soil from photosynthesis. '
                ],
                name: 'Composting',
                _id: 'composting',
                value: 100
              },
              {
                description:
                  'Cold storage solutions including technology like solar-powered refrigerators that can greatly reduce crop loss due to lack of accessible and adequate cold storage that keeps produce fresh before it is sold or taken to market.',
                people: [
                  'On-farm grain storage can give a farmer financial benefits, greater control over where crops are sold, a hearty supply of animal feed and protection against weather damage'
                ],
                name: 'On-farm Storage',
                _id: 'on-farm storage',
                value: 100
              },
              {
                description:
                  "Local farmers can grow crops that are better adapted to specific regions vs. industrial crops that are shipped from a long distance, which can amplify a local economy, nutrition and plant diversity, and can potentially lower the crop's carbon footprint (although there is not complete agreement on the amount).",
                people: [
                  'Sourcing locally not only contributes to green manufacturing, but ultimately helps you build consumer confidence. When consumers buy with confidence, the business benefits from increasing positive brand awareness and customer loyalty.'
                ],
                name: 'Bioregional Sourcing',
                _id: 'bioregional sourcing',
                value: 100
              },
              {
                description:
                  'Produce that is cosmetically imperfect but fresh and full of nutrition is often thrown out by commercial retailers. So this perfectly good food ends up in landfills. A movement to capture this wasted produce is growing, including companies like Misfit Markets.',
                people: [
                  'Globally, we lose around $1 trillion per year on food that is wasted or lost. Upcycled food captures that value, and leverages it to create a sustainable and resilient food system.'
                ],
                name: 'Food Upcycling',
                _id: 'food upcycling',
                value: 100
              },
              {
                description:
                  'At least 50 million square miles in the U.S. are covered in grass turf, requiring an enormous amount of water, chemicals, and petroleum fuel and causing 35,000 injuries per year. Converting just a portion of this land to gardens would increase food security and reduce carbon emissions, and studies have shown it also boosts public health.',
                people: [
                  'Growing your own herbs, fruits and vegetables is a good way to make your garden more climate friendly. Home-grown food increases soil carbon and decreases carbon emissions from food packaging, refrigeration and transportation.'
                ],
                name: 'Urban Gardening',
                _id: 'urban gardening',
                value: 100
              },
              {
                description:
                  'Taking the time to plan a balanced diet saves consumers money, boosts their health, and is a major strategy to reduce food waste.',
                people: [
                  'Wasted food accounts for 2.6% of the annual greenhouse gas emissions in the U.S., which is equivalent to 1 in 7 cars on the road. Reducing food waste is a top strategy for addressing climate change.'
                ],
                name: 'Meal Planning',
                _id: 'meal planning',
                value: 100
              }
            ],
            value: 600
          },
          {
            name: 'Circular Fibersheds',
            _id: 'circular fibersheds',
            children: [
              {
                description:
                  'Sourcing fiber stock used to make textiles from regenerative and organic agriculture practices that build soil health, increase biodiversity, protect water resources, and sequester carbon. ',
                people: [
                  'The global market for "eco-fibers" is projected to grow from $40.38 billion in 2020 to $58.29 billion by 2027, with a compound annual growth rate (CAGR) of 4.6% over the forecast period.',
                  'https://assets.takeshape.io/86ce9525-f5f2-4e97-81ba-54e8ce933da7/dev/e8fb325d-fb07-455c-b620-6ed3bab30b94/SAFSF_Fibers_Roadmap_PublicSummary_FINAL%20Updated%20Nov5.pdf'
                ],
                name: 'Fiber Sourcing',
                _id: 'fiber sourcing',
                value: 200
              },
              {
                description:
                  'Alternative processing and dying of fiber stock to make textile materials that uses less energy and harmful chemicals than conventional methods.',
                people: [
                  'The textile industry generates one-fifth of the world\u2019s industrial water pollution, and textile dyeing is the second largest water polluter in the world. '
                ],
                name: 'Green Textiles',
                _id: 'green textiles',
                value: 100
              },
              {
                description:
                  '"Fast fashion" has contributed to a culture of waste in which clothing is readily thrown away rather than being repaired, re-used  or recycled. Moving to "slow fashion" and encouraging second hand use of apparel, or recycling fibers for other industrial uses are all ways to reduce impacts of the fashion industry. ',
                people: [
                  'Every ton of reused discarded textiles prevents 20 tons of CO2 from entering the atmosphere.'
                ],
                name: 'Recycle & Reuse',
                _id: 'recycle & reuse',
                value: 100
              }
            ],
            value: 300
          }
        ],
        value: 400
      },
      {
        name: 'Intersectional Themes',
        _id: 'intersectional themes',
        children: [
          {
            description: 'Inclusion and empowerment of women and girls in any solution pathway.',
            people: [],
            name: 'Gender Equity',
            _id: 'gender equity',
            value: 100
          },
          {
            description:
              'Rebalancing the distribution of wealth, opportunities, and privileges within a society, including racial justice, youth justice, Indigenous rights, etc.',
            people: [],
            name: 'Social Justice',
            _id: 'social justice',
            value: 100
          },
          {
            description:
              'The variety of life in a particular habitat or ecosystem, shorthand is biodiversity. This includes everything from soil microbes up to charismatic megafauna',
            people: [],
            name: 'Biological Diversity',
            _id: 'biological diversity',
            value: 100
          },
          {
            description:
              'A UN term used to focus on uplifting typically poor and often rural populations, providing new opportunities for gainful employment that helps the environment rather than hurts it.  ',
            people: [],
            name: 'Sustainable Livelihoods',
            _id: 'sustainable livelihoods',
            value: 100
          },
          {
            description:
              'The capacity of upstream sources, underground aquifers, desalination, and other technologies to ensure sufficient freshwater for agriculture and human uses.',
            people: [],
            name: 'Water Stewardship',
            _id: 'water stewardship',
            value: 100
          },
          {
            description:
              'Making the connections between the physical and mental health of the general public in regards to climate change, biodiversity loss, and other environmental impacts. ',
            people: [],
            name: 'Public Health',
            _id: 'public health',
            value: 100
          },
          {
            description:
              'Empowering the next generation of leaders with the knowledge and skills to solve the climate crisis.',
            people: [],
            name: 'Youth Leadership',
            _id: 'youth leadership',
            value: 100
          }
        ],
        value: 100
      },
      {
        name: 'Levers of Change',
        _id: 'levers of change',
        children: [
          {
            description:
              'Theory of change focusing on the catalytic role that philanthropic giving can play in accelerating solutions to the climate crisis. ',
            people: [],
            name: 'Philanthro-activism',
            _id: 'philanthro-activism',
            value: 100
          },
          {
            description:
              'Theory of change focusing on the role of public markets -- stocks, bonds, credits -- and private equity in accelerating solutions to the climate crisis. ',
            people: [],
            name: 'Climate Finance',
            _id: 'climate finance',
            value: 100
          },
          {
            description:
              'Theory of change focusing on shifting public policy at the national or subnational level as well as governance agreements at the multilateral level (UN, G20, etc) to advance climate solutions.',
            people: [],
            name: 'Policy & Governance',
            _id: 'policy & governance',
            value: 100
          },
          {
            description:
              'Theory of change focusing on scientific research and technological innovation to support decision makers (in governments, companies, and foundations) in prioritizing the best climate solutions. The GSN is a good example, mean to inform target setting of the UN Convention on Biological Diversity. ',
            people: [],
            name: 'Science & Technology',
            _id: 'science & technology',
            value: 100
          },
          {
            description:
              'Theory of change focusing on bringing lawsuits against governments or private companies for failing to take action on climate change or environmental degradation, or other legal approaches such as Rights of Nature, Human Rights to a Healthy Environment, etc. ',
            people: [],
            name: 'Legal Empowerment',
            _id: 'legal empowerment',
            value: 100
          },
          {
            description:
              'Theory of change focusing on empowering communities to act in a variety of ways -- from building youth movements, to civic engagement and voting rights, to citizen-led community projects to advance climate solutions. ',
            people: [],
            name: 'Community Action',
            _id: 'community action',
            value: 100
          },
          {
            description:
              'Theory of change upholding the role of education -- youth, adult, and professional -- in creating the enmabling conditions and motivations to make change happen at the local and national level. ',
            people: [],
            name: 'Education & Awareness',
            _id: 'education & awareness',
            value: 100
          }
        ],
        value: 700
      }
    ]
  }
];
export default oneearth_solutions;

import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  // addChatData,
  updateChatData,
  getChatById,
  getChatByContentId,
  chatRequestError,
  chatSubscribe,
  chatUnsubscribe
} from 'redux/actions/chatService';
import { fakeParticipantsList } from 'components/Demo/chatDemoData';
import useNoo from 'hooks/useNoo';
import useSocketIo from 'hooks/useSocketIo';
import { addRqKey, addMessage, /*updateMessage, */ upVote, downVote } from 'services/chat-socket';
/**
 *
 * implementation notes
 *
 * - for all API calls, make a queue since we're doing optimistic updates
 * 		(should ensure no collisions, and we can send multiple requests at a time?)
 *
 * - prefer chatId over contentId if both supplied, but should only supply one
 *
 * - no need to add private/moderator controls for MVP
 *
 * - use chatId or contentId in initial call to get last X msgs
 * - once
 *
 */

let listenersAdded = false;

const useChatService = ({ chatId, contentId }) => {
  const dispatch = useDispatch();
  const chatData = useSelector(
    state =>
      state?.chatService?.chatData?.find(
        data => data._id === chatId || data.contentId === contentId
      ),
    shallowEqual
  );
  const chatError = useSelector(state => state?.chatService?.error);
  const chatIsRequesting = useSelector(state => state?.chatService?.isRequesting);
  const subscriptions = useSelector(state => state?.chatService?.subscriptions);

  // const chatDataId = chatData?._id;

  const { personId, currentNetwork } = useNoo();
  const {
    isConnected: socketIsConnected,
    isConnecting,
    socket,
    addListeners,
    removeListeners
  } = useSocketIo({
    autoConnect: !!personId
    // connectionEstablished:
  });

  // temporary:: need to add ability to get list of users in chat.
  // this will insert current-logged-in personId to the "ME_USER" node in fakeParticipantsList
  const participants = {};
  Object.entries(fakeParticipantsList).forEach(pair => {
    const id = pair[0] === 'ME_USER' ? personId : pair[0];
    participants[id] = pair[1];
  });

  const subscribeToChat = id => {
    if (socketIsConnected && id && !subscriptions.has(id)) {
      dispatch(chatSubscribe(id));
      socket.emit('chat:subscribe', { chatId: id });
    }
  };

  const unsubscribeFromChat = id => {
    if (socketIsConnected && id && subscriptions.has(id)) {
      socket.emit('chat:unsubscribe', { chatId: id });
      dispatch(chatUnsubscribe(id));
    }
  };

  const isSubscribedToChat = id => subscriptions.has(id);

  // regular http requst, no socket used here (getting init data, no user action...):
  const getChat = useCallback(
    params => {
      const chatParams = { ...params, netname: currentNetwork };
      if (chatId) {
        dispatch(getChatById(chatId, chatParams));
      } else if (contentId) {
        dispatch(getChatByContentId(contentId, chatParams));
      }
    },
    [chatId, contentId, currentNetwork, dispatch]
  );

  const addMsgToList = useCallback(
    msg => {
      if (!personId) return null;
      // dispatch createMessage API call, with optimistic update
      if (msg) {
        const newMsg = addRqKey({
          body: msg,
          chatId: chatData?._id,
          created: new Date().getTime(),
          user: personId
        });
        addMessage(chatData?._id, newMsg, socket);
        const existing = chatData?.messages ? [...chatData.messages] : [];
        dispatch(updateChatData({ ...chatData, messages: [...existing, newMsg] }));
      }
    },
    [chatData, dispatch, personId, socket]
  );

  const upVoteMsg = useCallback(
    msgId => {
      if (!personId) return null;
      // dispatch upVote API call, with optimistic update
      upVote(chatData?._id, msgId, socket);
      const messages = chatData?.messages?.map(msg => {
        if (msg._id === msgId) {
          msg.up = (msg.up || 0) + 1;
        }
        return msg;
      });
      dispatch(updateChatData({ ...chatData, messages }));
    },
    [chatData, dispatch, personId, socket]
  );

  const downVoteMsg = useCallback(
    msgId => {
      if (!personId) return null;
      // dispatch downVote API call, with optimistic update
      downVote(chatData?._id, msgId, socket);
      const messages = chatData?.messages?.map(msg => {
        if (msg._id === msgId) {
          msg.down = (msg.down || 0) + 1;
        }
        return msg;
      });
      dispatch(updateChatData({ ...chatData, messages }));
    },
    [chatData, dispatch, personId, socket]
  );

  // add or update handler, should just hydrate the optimistic UI data
  // optimistic UI on NEW msg has rqKey as _id
  // update, should just match existing ID
  // ALSO: for other subscribers, if no rqKey match, just append at end
  const consumeAddChatMessage = useCallback(
    data => {
      const messages = chatData?.messages?.map(msg => {
        if (msg.rqKey && msg.rqKey === data.rqKey) {
          return data.message;
        } else {
          return msg;
        }
      });
      dispatch(updateChatData({ ...chatData, messages }));
    },
    [chatData, dispatch]
  );

  const consumeUpdateChatMessage = useCallback(
    data => {
      const messages = chatData?.messages?.map(msg => {
        if (msg._id === data.message?._id) {
          return data.message;
        } else {
          return msg;
        }
      });
      dispatch(updateChatData({ ...chatData, messages }));
    },
    [chatData, dispatch]
  );

  // add or update error handler, can eventually use this to "retry" etc,
  // for now, just remove the message and show an error???
  const chatNewMessageError = useCallback(
    data => {
      const messages = chatData?.messages
        ?.map(msg => {
          if (msg.rqKey && msg.rqKey === data.rqKey) {
            return null;
          } else {
            return msg;
          }
        })
        .filter(msg => !!msg);
      dispatch(updateChatData({ ...chatData, messages }));
      dispatch(chatRequestError('error adding a new message to the chat'));
    },
    [chatData, dispatch]
  );

  const chatUpdateMessageError = data => {
    console.log('[unimplemented] hay we got an error from a message action!', data);
  };

  const consumeUpvote = data => {
    console.log('[unimplemented] hay we got a upvote event!', data);
  };

  const consumeDownvote = data => {
    console.log('[unimplemented] hay we got a downvote event!', data);
  };

  const chatHandlers = useMemo(() => {
    return {
      // 'chat:data': consumeChat,
      'chat:new-message': consumeAddChatMessage,
      'chat:update-message': consumeUpdateChatMessage,
      'chat:message-upvote': consumeUpvote,
      'chat:message-downvote': consumeDownvote,

      // error handling
      // 'chat:create-error': _setChatError,
      // 'chat:not-found': _setChatError,
      'error:new-message': chatNewMessageError,
      'error:update-message': chatUpdateMessageError,
      'error:message-upvote': data => dispatch(chatRequestError(data.error)),
      'error:message-downvote': data => dispatch(chatRequestError(data.error))
    };
  }, [chatNewMessageError, consumeAddChatMessage, consumeUpdateChatMessage, dispatch]);

  useEffect(() => {
    if (socketIsConnected) {
      if (!listenersAdded) {
        addListeners(chatHandlers);
        listenersAdded = true;
      }
    }
  }, [addListeners, chatHandlers, removeListeners, socketIsConnected]);

  return {
    // TODO: msgs from API
    messages: chatData?.messages,
    chatData,
    chatIsRequesting,
    chatError,
    serviceReady: socketIsConnected,
    serviceConnecting: isConnecting,
    participants,

    unsubscribeFromChat,
    subscribeToChat,
    isSubscribedToChat,
    getChat,
    addMsgToList,
    upVoteMsg,
    downVoteMsg
  };
};

export default useChatService;

import React, { useMemo, useCallback } from 'react';
import { Button, Text } from '@chakra-ui/react';
import useNoo from 'hooks/useNoo';
import { useDispatch } from 'react-redux';
import useNooApi from 'hooks/useNooApi';
import UserAuth from 'components/User/UserAuth';
import FontAwesomeIcon from 'utils/FontAwesomeLoader';
import mock_data from './url_mock_data.json';
import _ from 'lodash';

const PostLink = props => {
  const { group, style } = props;
  const which_style = style || 'button';
  const { isAuthorized, nooUser } = useNoo('PostLink');

  const dispatch = useDispatch();
  const { nooCommand, nooCommandResponse, nooCommandIsProcessing } = useNooApi();

  const og_api_key = 'd475258c-0d75-41e7-81a8-ae5a6dbd0760';

  const onPostClick = useCallback(
    e => {
      // moved inside to satisfy useCallback rendering loop
      const sendPost = (question_id, data) => {
        console.log('hybrid', data);
        data.label = data.openGraph?.title || data.hybridGraph?.title || data.htmlInferred?.title;
        data.description =
          data.openGraph?.description ||
          data.hybridGraph?.description ||
          data.htmlInferred?.description;
        const testing = false;
        if (!testing) {
          let document = { resource: { type: 'document', data: data } };
          document.person_id = nooUser?.person?._id;
          console.log('submitting document', document);
          dispatch(nooCommand('createDocument', { data: document }));
        }
      };

      const { _id } = group;
      const question_id = _.findKey(group.questions, one => {
        return one.render == 'curation';
      });
      console.log('clicked', _id, group, question_id);
      if (_id && question_id) {
        // const fn = group? data?
        const url = prompt('What URL would you like to share to ' + group?.data?.fullname);
        console.log('URl' + url);
        if (url == 'mock') {
          console.log('sending mock');
          sendPost(question_id, mock_data);
        } else if (url) {
          const call =
            'https://opengraph.io/api/1.1/site/' +
            encodeURIComponent(url) +
            '?app_id=' +
            og_api_key;
          console.log('CALL', call);
          fetch(call)
            .then(response => response.json())
            .then(data => {
              console.log('JSON', JSON.stringify(data));
              sendPost(question_id, data);
            });
        }
      } else {
        alert('sorry, no question for that group');
      }
    },
    [dispatch, group, nooCommand, nooUser?.person?._id]
  );

  if (!group) {
    return <Text>error: no group for Post</Text>;
  }

  return (
    <UserAuth>
      {which_style == 'button' && (
        <Button
          onClick={onPostClick}
          borderRadius='full'
          fontWeight='body'
          fontSize='1'
          leftIcon={FontAwesomeIcon({
            icon: ['fad', 'share-alt'],
            style: { marginTop: '1px' }
          })}
        >
          Post
        </Button>
      )}
      {which_style == 'star' && (
        <Button
          onClick={onPostClick}
          borderRadius='full'
          fontWeight='body'
          fontSize='1'
          leftIcon={FontAwesomeIcon({
            icon: ['fad', 'share-alt']
          })}
        />
      )}
    </UserAuth>
  );
};

export default PostLink;

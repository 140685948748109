import React from 'react';
// import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
// import ReactMarkdown from 'react-markdown';
import { Box, Flex, Heading, Link, Text, VStack } from '@chakra-ui/react';
import RenderChakraMarkdown from 'utils/ChakraMarkdown';
import useNoo from 'hooks/useNoo';
import SignInOrRegister from 'components/Segments/SignInOrRegister';
import InvitationsAccept from 'components/Invitations/InvitationsAccept';

// function sleep(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

function useQuery() {
  var params = new URLSearchParams(useLocation().search);
  var lookup = {};
  for (var pair of params.entries()) {
    lookup[pair[0]] = pair[1];
  }
  return lookup;
}

const Splash = () => {
  const { googleUser: user, currentNetworkData } = useNoo('Splash');
  const network = currentNetworkData;

  // TODO: if using invitations here, update to useInvitations() hook
  // const onCallAcceptInvitation = () => {
  // 	nooApi.acceptInvitation(user, invitationId, setAcceptLoading, setAcceptResponse);
  // };

  let params = useQuery();
  var invitationId = params.invite;

  if (invitationId) {
    localStorage.setItem('invitationId', invitationId);
  } else {
    // TBD: check local storage and if there, check it's still valid by reading it
    const stored = localStorage.getItem('invitationId');
    if (stored) {
      /* CHECK INVITE IS LEGIT HERE, AND SET OR REMOVE ON RESPONSE, BUT I DON'T KNOW HOW TO HANDLE THE RESPONSE
      // TODO: if using noo Api here, update to useNooApi() hook
			const aqlQuery = 'return document("' + invitationId + '")';
			localStorage.setItem('invitation', invite);
			nooApi.aqlSearch({user, aqlQuery, setLoading, setResponse});
			*/
      invitationId = stored;
    }
  }

  const display = network?.display;
  const paras = network.welcome;
  const markdown = network.markdown;
  var items = [];
  if (markdown) {
    items.push(<RenderChakraMarkdown key='mkdown'>{markdown}</RenderChakraMarkdown>);
  } else if (paras) {
    _.forEach(paras, (value, key) => {
      items.push(
        <Text key={key} fontSize={['.8em', '1.2em']}>
          {value}
        </Text>
      );
    });
  }

  return (
    <Box>
      <Heading as='h2' size='lg' textAlign='center' mt='1em'>
        Emerge Network
      </Heading>
      <Heading as='h4' size='md' fontWeight='normal' fontStyle='italic' textAlign='center' mb='1em'>
        Emerge as a Networked Autonomous Organization (NAO)
      </Heading>
      {!user && (
        <Box
          textAlign='center'
          backgroundColor='cyan.400'
          color='white'
          width={['85%', '68%', '47%', '35%']}
          m='1em auto'
          padding='0.5em 0'
          borderRadius='3px'
          sx={{
            a: { color: 'cyan.900', fontWeight: 'bold' }
          }}
        >
          <SignInOrRegister showHeader={false} />
        </Box>
      )}
      <Text
        margin='1em auto'
        width={['90vw', '82vw', '60vw']}
        borderRadius='lg'
        boxShadow='0px 1px 4px 0px rgba(0, 0, 0, 0.5)'
        padding='1em'
        textAlign='center'
        backgroundColor='cyan.800'
        color='white'
      >
        <strong>BETA:</strong> This is the first public use. It works best in a browser. If things
        don&#39;t work as expect, log out and back in again.
      </Text>
      <InvitationsAccept align='center' />

      <VStack
        spacing='1.2em'
        width={['84vw', '78vw', '52vw']}
        m='1.5em auto 0'
        fontSize='18px'
        color='gray.700'
      >
        <Text>
          Daniel Schmachtenberger suggested a <Text as='em'>map of the Emerge Network</Text> to
          Tomas Björkman. This is in-progress outcome of that effort.
        </Text>

        <Text>
          The underlying concept is the power of <Text as='em'>trust networks</Text> and their
          embedded capacity for new forms of{' '}
          <Text as='em'>global-scale collective decision-making</Text> (see{' '}
          <Link href='https://steinbock.org/pubs/steinbock-ssdm.pdf' color='cyan.700'>
            this
          </Link>{' '}
          and{' '}
          <Link
            href='https://markorodriguez.files.wordpress.com/2011/01/smartocracy-hicss2007.pdf'
            color='cyan.700'
          >
            this
          </Link>{' '}
          for some of the science behind it).
        </Text>

        <Text>
          The network you can explore here has over 50,000 <Text as='em'>nodes</Text> (humans)
          connected by <Text as='em'>links</Text>
          (relationships) to one or more Emerge Austin attendees. There are a lot of great people
          there; look for ones you know.{' '}
        </Text>

        <Text>
          Network analytics give us many tools for discovery, connection, validation, amplification,
          accountability, proof of humanity, etc.{' '}
        </Text>

        <Text>
          It&#39;s clear that we are in the midst of a <Text as='em'>meta-crisis</Text>, and are
          called to do what we can collectively. Going up a level in our ability to collaborate at
          all scales is critical.
        </Text>

        <Text>
          The vision is that Emerge can be the kernel of a large global by-invitation{' '}
          <Text as='em'>networked autonomous organization</Text>, a{' '}
          <Link href='https://en.wikipedia.org/wiki/Platform_cooperative' color='cyan.700'>
            platform co-op
          </Link>{' '}
          governed by{' '}
          <Link href='https://en.wikipedia.org/wiki/Liquid_democracy' color='cyan.700'>
            liquid democracy
          </Link>
          . It&#39;s a great resource for collective innovation and collaboration, and a natural fit
          with Web3.
        </Text>
      </VStack>
    </Box>
  );
};

export default Splash;

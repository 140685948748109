import React, { useState, useEffect, useRef, useCallback } from 'react';
import useNoo from 'hooks/useNoo';
import { useDispatch } from 'react-redux';
import GroupSelector from 'components/Segments/GroupSelector';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { Flex, Box, Input, Textarea, Button, Heading } from '@chakra-ui/react';
import styled from '@emotion/styled';
import useNooApi from 'hooks/useNooApi';
import UserAuth from 'components/User/UserAuth';
import PeopleSelector from 'components/Segments/PeopleSelector';

const EdgeCreate = () => {
  const [sourceId, setSourceId] = useState(null);
  const [targetId, setTargetId] = useState(null);
  const [inputs, setInputs] = useState({ data: {}, type: null });
  const { nooUser } = useNoo('EdgeCreate');
  const dispatch = useDispatch();
  const {
    nooCommand,
    nooCommandResponse,
    nooCommandResponseError,
    nooCommandIsProcessing
  } = useNooApi();

  const onSelectSource = ({ value }) => {
    setSourceId(value || null);
  };
  const onSelectTarget = selected => {
    const idd = selected.value;
    console.log('target', idd, selected);
    setTargetId(idd);
  };

  const updateInputs = e => {
    const myInputs = { ...inputs };
    console.log('INPUT', e.target);
    myInputs[e.target.name] = e.target.value;
    if (e.target.name == 'data') {
      try {
        myInputs[e.target.name] = JSON.parse(e.target.value);
        console.log('valid', myInputs[e.target.name]);
      } catch (error) {
        console.log('INVALID data, does not JSON.parse', e.target.value);
        return;
      }
    }
    setInputs(myInputs);
  };

  const onSubmit = event => {
    if (sourceId && targetId && inputs.type) {
      console.log('making edge ', sourceId, targetId, 'type', inputs.type, 'data', inputs.data);
      dispatch(
        nooCommand('createEdge', {
          from_id: sourceId,
          to_id: targetId,
          type: inputs.type,
          target_data: {},
          edge_data: inputs.data
        })
      );
    } else {
      console.log('INCOMPLETE', sourceId, targetId, inputs.type);
    }
  };

  let disabled = sourceId && targetId && inputs.data ? false : true;
  return (
    <Box>
      <UserAuth unauthorized='signin'>
        <Box
          sx={{
            backgroundColor: '#eee',
            marginTop: '10px',
            padding: '10px',
            border: '1px solid #444'
          }}
        >
          <Heading as='h3'>Create People Edge</Heading>
          <Flex>
            <h3>Choose Source</h3>
            <PeopleSelector onSelect={onSelectSource} inputPlaceholder={'Name of source'} />
          </Flex>
          <Flex>
            <h3>Choose Target</h3>
            <PeopleSelector onSelect={onSelectTarget} inputPlaceholder={'Name of target'} />
          </Flex>

          <Box sx={{ flex: '1 1 auto' }}>
            <Input
              sx={{ height: '30px', padding: '1px' }}
              type='text'
              id='data'
              name='data'
              placeholder='enter data'
              onChange={updateInputs}
            />
          </Box>
          <Box sx={{ flex: '1 1 auto' }}>
            <Input
              sx={{ height: '30px', padding: '1px' }}
              type='text'
              id='type'
              name='type'
              placeholder='enter type'
              onChange={updateInputs}
            />
          </Box>

          <Box>
            <Button
              variant='pill'
              sx={{ height: '30px', padding: '3px' }}
              onClick={onSubmit}
              disabled={disabled}
            >
              {'Submit'}
            </Button>
          </Box>
        </Box>
      </UserAuth>
    </Box>
  );
};

export default EdgeCreate;

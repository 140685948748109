import { useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentGroupId } from 'redux/actions/groups';
// import { difference } from 'utils';
import useNoo from 'hooks/useNoo';
import useMultiDomain from 'hooks/useMultiDomain';
import { derivePrefixedNodeId } from 'utils';
import { dictFromEdges } from 'utils/dictFromEdges';
import _ from 'lodash';

// singelton flag here since we have 1-n consumers of this hook:
let defaultGroupSetOnce = false;

const useGroups = ({ filters = ['all'], from } = {}) => {
  //   console.log('useGroups, from: ', from);
  const dispatch = useDispatch();
  const { nooUser, currentNetworkData } = useNoo();
  const default_group = currentNetworkData.default_group;
  const root_group = currentNetworkData.root_group; // hide other groups by app
  const { groupSelector } = useMultiDomain();

  const currentGroupId = useSelector(state => state.groups?.currentGroupId);

  const setGroupId = useCallback(
    groupId => {
      dispatch(setCurrentGroupId(derivePrefixedNodeId(groupId, 'Groups')));
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      !defaultGroupSetOnce &&
      default_group &&
      derivePrefixedNodeId(default_group, 'Groups') !== currentGroupId
    ) {
      defaultGroupSetOnce = true;
      setGroupId(default_group);
    }
  }, [currentGroupId, default_group, setGroupId]);

  const groups = useMemo(() => {
    if (nooUser?.groups?.length) {
      const { groups, member: memberGroups = [], owner: ownerGroups = [] } = nooUser;
      const filtered = groups.filter(group => {
        if (filters.includes('all')) {
          return true;
        }
        const gid = group._id;
        const is_member = filters.includes('member') ? memberGroups.includes(gid) : false;
        const is_owner = filters.includes('owner') ? ownerGroups.includes(gid) : false;
        return is_member || is_owner;
      });
      return filtered;
    }
    return [];
  }, [filters, nooUser]);

  const currentGroupName = useMemo(() => {
    if (groups.length && currentGroupId) {
      const myGroup = groups.find(group => group._id === currentGroupId);
      return myGroup?.data?.fullname;
    } else if (currentGroupId) {
      return 'the current group';
    }
    return null;
  }, [currentGroupId, groups]);

  const groupsTaxonomies = useMemo(() => {
    if (nooUser && groups) {
      const gids = _.map(groups, '_id'); // all group ids
      const from_edges = _.uniq(_.flatMap(nooUser.group_edges));
      const no_children = _.filter(gids, gid => {
        return !_.includes(from_edges, gid);
      });
      let all_edges = _.union(
        nooUser.group_edges,
        _.map(no_children, gid => {
          return [gid, '']; // null edge so it's in the dict but no children
        })
      );
      let name_lookup = _.keyBy(groups, '_id');
      all_edges = _.sortBy(all_edges, edge => {
        // sort by group name bc taxo hard to sort downstream
        const tgt = edge[1];
        return name_lookup[tgt]?.data.fullname;
      });
      const taxos = dictFromEdges(all_edges);
      return taxos;
    }
    return null;
  }, [nooUser, groups]);

  useEffect(() => {
    if (currentGroupId && groups?.length && !groups.find(group => group._id === currentGroupId)) {
      console.warn(
        'in useGroups, would have done the auto-update on group ID',
        currentGroupId,
        groups,
        groups.find(group => group._id === currentGroupId)
      );
      //   setGroupId(null);
    }
  }, [currentGroupId, groups]);

  return {
    setGroupId,
    groups,
    currentGroupId,
    currentGroupName,
    groupSelector,
    groupsTaxonomies
  };
};

export default useGroups;

import React, { useMemo, useState } from 'react';
import Chat from '../Segments/Chat';
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Text,
  Box,
  HStack
} from '@chakra-ui/react';
import _ from 'lodash';

const ChatPopover = props => {
  const { isOpen, text_open, open, close, button_text, header_text, size } = props;
  return (
    <Popover
      returnFocusOnClose={false}
      //isOpen={isOpen}
      //onClose={close}
      placement='bottom'
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button mr={3} fontSize={size || '12px'} onMouseEnter={open}>
          {button_text || 'Discuss'}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        boxShadow='2xl'
        bg='#fafafa'
        p='6'
        rounded='md'
        w={['300px', '500px', '800px']}
        onMouseLeave={close}
      >
        <PopoverCloseButton />
        <PopoverHeader fontWeight='semibold'>{header_text}</PopoverHeader>
        <PopoverArrow />
        <PopoverBody>
          <h2>Chat goes here</h2>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ChatPopover;

import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { Box, Link } from '@chakra-ui/react';
import SortableTable from 'components/Segments/SortableTable';
import useNoo from 'hooks/useNoo';
import useSearchResults from 'hooks/useSearchResults';

const ListingResultIconDiv = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 4px 4px 4px #ddd;
  width: 200px;
  min-width: 200px;
  height: 200px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  ${props => props.backgroundImage && `background-image: url('${props.backgroundImage}')`}
`;

const ListingResultIcon = props => {
  const { data } = props;
  let imageUrl;
  if (data?.imageIds && typeof data?.imageIds === 'string') {
    imageUrl = data?.imageIds;
  } else {
    imageUrl = data?.imageIds?.[0];
  }
  if (!imageUrl) {
    return <ListingResultIconDiv>no icon</ListingResultIconDiv>;
  }

  return <ListingResultIconDiv backgroundImage={imageUrl} />;
};

const SearchResultsListings = props => {
  const kind = 'listings';
  const { data, topic } = props;
  const [parsedData, setParsedData] = useState(null);
  const [parsedDataError, setParsedDataError] = useState(null);
  const { nooUser } = useNoo('SearchResultsListings');

  const {
    defaultColumnProperties,
    map_column_title,
    map_column_sx,
    get_columns
  } = useSearchResults(kind);

  useEffect(() => {
    if (data) {
      const it = data?.result?.[0] || data;
      setParsedData(it);
    }
  }, [data, setParsedData]);

  const listings = useMemo(() => {
    if (parsedData?.listings) {
      const { listings, profiles, ownership } = parsedData;
      const profile_lookup = _.keyBy(parsedData?.profiles || [], '_id');
      const owner_lookup = _.fromPairs(ownership); // [[listing_id, owner_id]]
      // console.log('owner lookup', owner_lookup, nooUser);
      return listings
        .filter(listing => listing) // temp fix, ensures no null listing nodes
        .map((listing, index) => {
          const it = listing.data;
          let excerpt = it.description;
          const words = excerpt.split(' ');
          if (words.length > 50) {
            excerpt = words.slice(0, 50).join(' ') + '...';
          }
          listing.person_id = owner_lookup[listing._id];
          const pkey = listing.person_id.replace('Persons/', '');
          const dest = '/listing/' + listing._key + '/' + pkey;
          const dest_edit = '/add_listing/' + listing._key + '/' + pkey;

          return {
            iconSorter: it.title,
            icon: (
              <Link as={RouterLink} className='listing' to={dest}>
                <ListingResultIcon data={it} />
              </Link>
            ),
            titleSorter: it.title,
            title: (
              <>
                <Link className='listing' to={dest}>
                  {it.title}
                </Link>
                <Box fontSize='85%'>
                  from: {profile_lookup[listing.person_id]?.data?.profile?.displayName}
                </Box>
              </>
            ),
            description: excerpt,
            // host: profile_lookup[listing.person_id]?.data?.profile?.displayName,
            edit: listing.person_id === nooUser?.person?._id && (
              <Link className='listing' to={dest_edit}>
                Edit
              </Link>
            )
          };
        });
    }
    return null;
  }, [nooUser?.person?._id, parsedData]);

  const cols = useMemo(() => {
    if (listings?.length) {
      return get_columns(listings[0]).map(key => {
        return {
          ...defaultColumnProperties,
          key: key,
          name: map_column_title(_.capitalize(key), kind),
          sx: map_column_sx(key, kind)
        };
      });
    }
    return [];
  }, [defaultColumnProperties, get_columns, listings, map_column_sx, map_column_title]);

  if (!_.size(listings)) {
    return null;
  }

  const colGroup = (
    <colgroup>
      <col style={{ width: '15%' }} />
      <col style={{ width: '15%' }} />
      <col style={{ width: '40%' }} />
      <col style={{ width: '7%' }} />
    </colgroup>
  );

  return (
    <Box mt='20px'>
      <SortableTable
        columns={cols}
        rows={listings}
        colGroup={colGroup}
        headRowSx={{
          backgroundColor: 'none'
        }}
        headCellSx={{
          textTransform: 'uppercase',
          color: '#444',
          borderBottom: '4px solid #eee'
        }}
      />
      {parsedDataError && <Box className='App-body'>There was an error, sorry.</Box>}
    </Box>
  );
};

export default SearchResultsListings;

import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getNetworkBundle, getNetworkData, isValidNetwork, getNetworks } from 'shared/config';
import {
  setNooUser,
  aqlReadPerson,
  setCurrentNetwork as _setCurrentNetwork,
  setLoading as _setLoading
} from 'redux/actions/noo';
import useMultiDomain, { get_default_network } from 'hooks/useMultiDomain';
import { setNooBundle } from 'redux/actions/nooApi';
// import useNooApi from './useNooApi';

// for logging:
// const aug17GmtMidnight = 1629158400000;

// let defaultNetworkSet = false;

let siteSettings = {};
let siteSettingsIsFetching = false;
async function getSiteSettings() {
  if (!siteSettings.fetched || siteSettings.failedGettingSiteSettings) {
    // don't run more than once at a time
    if (siteSettingsIsFetching) {
      return;
    }
    siteSettingsIsFetching = true;
    try {
      const response = await fetch('/api/site-settings');
      if (response.ok) {
        siteSettings = await response.json();
        siteSettings.fetched = true;
      } else {
        siteSettings = {
          failedGettingSiteSettings: true,
          error: response.status
        };
      }
    } catch (e) {
      siteSettings = {
        failedGettingSiteSettings: true,
        error: e
      };
    }
    siteSettingsIsFetching = false;
  }
  return siteSettings;
}

// get site settings on client load:
async function run() {
  await getSiteSettings();
}

run();

const useNoo = () => {
  const dispatch = useDispatch();
  const googleUser = useSelector(state => state.firebaseAuth?.user, shallowEqual);
  const fanUser = useSelector(state => state.fanAuth?.user, shallowEqual);
  const nooUser = useSelector(state => state.noo?.nooUser);
  const loading = useSelector(state => state.noo?.loading);
  const isAdmin = useSelector(state => state.noo?.isAdmin);
  const currentNetwork = useSelector(state => get_default_network(state));
  const currentNetworkData = useSelector(
    state => state.noo?.currentNetworkData || getNetworkData(currentNetwork)
  );

  const personId = useMemo(() => {
    return nooUser?.person?._id;
  }, [nooUser]);

  const setLoading = loading => {
    dispatch(_setLoading(loading));
  };

  // try to retry if error detected getting site settings:
  useEffect(() => {
    async function run() {
      if (siteSettings.failedGettingSiteSettings) {
        await getSiteSettings();
      }
    }
    run();
  }, []);

  const history = useHistory();

  const changeNet = e => {
    // console.warn('changing the network....');
    const which = e?.target?.value;
    if (setCurrentNetwork(which)) {
      dispatch(aqlReadPerson({ user: googleUser }));
    } else {
      // if this fails then we have a problem
      // throw
      console.error('tried to select a network, but it was not valid.');
    }
    history.push('/');
  };

  const setCurrentNetwork = network => {
    const bundle = getNetworkBundle(network);
    if (bundle) {
      dispatch(_setCurrentNetwork(network));
      setNooBundle(bundle);
      return true;
    } else {
      console.log('invalid network ', network);
      return false;
    }
  };

  const resetNooUser = () => {
    dispatch(setNooUser(null));
  };

  return {
    changeNet, // handles the dropdown select change + user update
    setCurrentNetwork, // direct change handler, for inbound /n/__ link handling etc
    resetNooUser,
    setLoading,
    isValidNetwork,
    networks: getNetworks(),
    currentNetworkData,
    isAdmin,
    loading,
    googleUser,
    fanUser,
    isAuthorized: googleUser || fanUser,
    nooUser,
    personId,
    currentNetwork,
    siteSettings
  };
};

export default useNoo;

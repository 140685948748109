import React, { useMemo } from 'react';
import { Avatar, AvatarBadge } from '@chakra-ui/react';

// TODO: custom default icon?

// NOTE: using ...rest from props to pass through Chakra props to the Avatar component,
// since we're wrapping it in our own local version (supports the Grouping mechanism)

const ChatAvatar = props => {
  const { name = 'anonymous', size = 'md', src, online, away, ...rest } = props;

  const badge = useMemo(() => {
    if (online) {
      return <AvatarBadge boxSize='1.25em' bg='green.500' borderColor='green.200' />;
    } else if (away) {
      return <AvatarBadge boxSize='1.25em' bg='orange.300' borderColor='yellow.300' />;
    } else {
      //   return <AvatarBadge boxSize='1.25em' bg='gray.400' borderColor='gray.200' />;
      return null;
    }
  }, [away, online]);

  return (
    <Avatar size={size} name={name} src={src} {...rest}>
      {badge}
    </Avatar>
  );
};

export default ChatAvatar;

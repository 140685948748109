import { combineReducers } from 'redux';
import bioregions from './bioregions';
import chatService from './chatService';
import decisions from './decisions';
import fanAuth from './fanAuth';
import firebaseAuth from './firebaseAuth';
import headlines from './headlines';
import groups from './groups';
import invitations from './invitations';
import noo from './noo';
import nooApi from './nooApi';
import nooUi from './nooUi';
import people from './people';
import peopleNetwork from './peopleNetwork';

export default combineReducers({
  bioregions,
  chatService,
  decisions,
  fanAuth,
  firebaseAuth,
  groups,
  headlines,
  invitations,
  noo,
  nooApi,
  nooUi,
  people,
  peopleNetwork
});

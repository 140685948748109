import React, { useState, useEffect, useMemo } from 'react';
import { Box, Input, FormLabel, Button, Textarea, Select, Text } from '@chakra-ui/react';
// import SelectUSState from 'react-select-us-states';
import statesData from 'shared/constants/usStatesData';
import _ from 'lodash';

const ListingForm = props => {
  const { onSubmit, listingData } = props;
  const [localData, setLocalData] = useState({});
  const [localErrors, setLocalErrors] = useState(null);
  // const [hasLoadedEditData, setHasLoadedEditData] = useState(false);

  useEffect(() => {
    if (listingData) {
      //&& !hasLoadedEditData // comes up true even when editing a new one
      setLocalData(listingData.listing?.data);
      // setHasLoadedEditData(true);
    }
  }, [listingData]); //hasLoadedEditData

  const imageMetaFromSource = text => {
    const find_image_data = new RegExp('mediaItems": *(\\[.*?\\])');
    const matches = find_image_data.exec(text);
    if (matches && matches.length > 1) {
      try {
        const mdata = matches[1];
        const metadata = JSON.parse(mdata);
        return metadata || [];
      } catch (e) {
        console.log('Error parsing image data', e);
        return [];
      }
    }
    return [];
  };

  const imagesFromBookmarklet = text => {
    try {
      let trimmed = text.replace('\n', ' ');
      const prefix = new RegExp('^.*?\\['); // remove prompts
      trimmed = trimmed.replace(prefix, '[');
      const as_json = JSON.parse(trimmed);
      if (as_json.length > 0) return as_json;
    } catch (e) {
      console.log('Error parsing text', e);
    }

    return null;
  };

  const onFieldUpdate = e => {
    const myLocalData = {
      ...localData
    };
    switch (e.target.type) {
      case 'checkbox':
        myLocalData[e.target.name] = e.target.checked;
        break;
      default:
        myLocalData[e.target.name] = e.target.value;
        break;
    }
    const key = e.target.name;
    if (e.target.name == 'imageIds') {
      // parse metadata from page source
      const val = e.target.value.trim();
      const imageMetas = imagesFromBookmarklet(val);
      if (imageMetas && imageMetas.length > 0) {
        // metadata from photos page
        let images = {};
        _.forEach(imageMetas, (one, index) => {
          const caption = one.caption || one.comment || '';
          const url = one.images?.length ? one.images[0] : null;
          if (url) images[url] = { caption: caption, id: index };
        });
        const urls = _.keys(images);
        if (urls && urls.length > 0) {
          myLocalData[key] = urls;
          myLocalData['imageMetadata'] = images;
        }
      } else if (val && val.indexOf('http') == 0) {
        // Paste comma-separated urls
        const urls = val.split(',');
        if (urls && urls.length > 0) {
          myLocalData[key] = _.map(urls, url => {
            return url.trim();
          });
        }
      } else if (val && val.indexOf('<html') > 0) {
        // parse elements from source. Shouldn't get here because metadata
        // source from photos page
        var el = document.createElement('html');
        el.innerHTML = val;
        var pics = el.getElementsByTagName('picture');
        let urls = {};
        _.forEach(pics, pic_el => {
          const img = pic_el.getElementsByTagName('img')[0];
          const src = img.getAttribute('src');
          const caption = img.getAttribute('alt');
          urls[src] = caption;
        });
        // console.log('imageids from picture elements', _.keys(urls))
        if (urls && urls.length > 0) {
          myLocalData[key] = _.keys(urls);
        }
      } else {
        console.log('not processed');
      }
    }
    // console.log(key, 'myLocalData', myLocalData);
    setLocalData(myLocalData);
    setLocalErrors(null);
  };

  const handleSubmit = evt => {
    evt.preventDefault();
    const ok = validate();
    if (ok) {
      onSubmit(localData);
    } else {
      console.log('Try again');
    }

    // eslint-disable-next-line no-constant-condition
    if (false) {
      alert(`Submitting ${localData?.title} ${localData?.description}`);
      setLocalData({});
      // resetTitle();
      // resetDescription();
      // resetZipcode();
      // // resetState();
      // resetCountry();
      // // resetGeo();
    }
  };

  const validate = () => {
    const req_fields = ['title', 'description'];
    const loc_fields = ['zipCode', 'geoPoint'];
    const errors = [];
    const required = [];
    let loc = localData?.zipCode || localData?.geoPoint;
    // let tested = {};
    req_fields.forEach(field => {
      if (!localData?.[field]) {
        required.push(field);
      }
    });
    // if (localData) {
    //   _.forEach(localData, (value, key) => {
    //     const test = value ? true : false;
    //     if (_.includes(req_fields, key)) {
    //       required.push(test);
    //     } else if (_.includes(loc_fields, key)) {
    //       loc = loc || test; // any location
    //       console.log('LOC', loc, test, key);
    //       // } else {
    //       // switch (key) {
    //       // }
    //     }
    //     tested[key] = test;
    //   });
    // }
    // if (!_.every(required)) {
    if (required.length) {
      errors.push('All these fields need a value: ' + req_fields.join(' '));
    }
    if (!loc) {
      errors.push('You must include at least one location field: ' + loc_fields.join(' '));
    }
    if (!localData?.state && !localData?.country) {
      errors.push('You must specify a State or Country or both');
    }
    setLocalErrors(errors);
    if (errors.length) {
      return false;
    }
    return true;
  };

  // this is not correct yet (need to know expected format of imageIds to fix...):
  // const handleImage = e => {
  //   const imageIds = localData?.imageIds || [];
  //   imageIds.push(e.target.value);
  //   onFieldUpdate({
  //     target: {
  //       name: 'imageIds',
  //       value: imageIds
  //     }
  //   });
  // };

  const fieldSx = useMemo(() => ({ mb: '0.5em' }), []);

  const images = useMemo(() => {
    let items = _.map(localData?.images, (one, i) => {
      return (
        <Box>
          <img src={one.url} width='150px' />
          <FormLabel htmlFor={i}>Caption</FormLabel>
          <Input
            type='text'
            placeholder='Caption'
            name={'caption_' + i}
            value={one.caption || i}
            // onChange={onFieldUpdate}
            sx={fieldSx}
          />
        </Box>
      );
    });
    return items;
  }, [localData, fieldSx]);

  return (
    <Box
      as='form'
      className='__listing-form'
      onSubmit={handleSubmit}
      width={['100%', '85%', '60%']}
      m='0 auto'
    >
      {<FormLabel htmlFor='title'>Title</FormLabel>}
      <Input
        type='text'
        placeholder='Title'
        name='title'
        value={localData?.title || ''}
        onChange={onFieldUpdate}
        sx={fieldSx}
      />

      {<FormLabel htmlFor='description'>Description</FormLabel>}
      <Textarea
        rows={5}
        placeholder='Description'
        name='description'
        value={localData?.description || ''}
        onChange={onFieldUpdate}
        sx={fieldSx}
      />

      {<FormLabel htmlFor='zipCode'>Zipcode</FormLabel>}
      <Input
        type='text'
        placeholder='Zipcode'
        name='zipCode'
        value={localData?.zipCode || ''}
        onChange={onFieldUpdate}
        sx={fieldSx}
      />

      {<FormLabel htmlFor='state'>Select a State</FormLabel>}
      {/*<SelectUSState id='SelectUsState' value='' onChange={handleState} />*/}
      <Select onChange={onFieldUpdate} value={localData?.state || ''} name='state' sx={fieldSx}>
        <option value=''>Select a State (if applicable)</option>
        {statesData.map((S, idx) => {
          return (
            <option key={idx} value={S.abbreviation}>
              {S.name}
            </option>
          );
        })}
      </Select>

      {<FormLabel htmlFor='country'>Country</FormLabel>}
      <Input
        type='text'
        placeholder='Country'
        name='country'
        value={localData?.country || ''}
        onChange={onFieldUpdate}
        sx={fieldSx}
      />

      {<FormLabel htmlFor='geoPoint'>Map Location</FormLabel>}
      <Input
        type='text'
        placeholder='Map Location'
        name='geoPoint'
        value={localData?.geoPoint || ''}
        onChange={onFieldUpdate}
        sx={fieldSx}
      />

      {<FormLabel htmlFor='webpage'>Webpage</FormLabel>}
      <Input
        type='text'
        placeholder='Webpage'
        name='webpage'
        value={localData?.webpage || ''}
        onChange={onFieldUpdate}
        sx={fieldSx}
      />

      {
        <FormLabel htmlFor='imageIds'>ImageIds:</FormLabel>
        /* may need to use custom handler: handleImage */
      }
      <Input
        type='text'
        placeholder='Images'
        name='imageIds'
        value={localData?.imageIds || ''}
        onChange={onFieldUpdate}
        sx={fieldSx}
      />
      {images}
      <Button cursor='pointer' type='submit'>
        Save my place
      </Button>
      {localErrors &&
        localErrors.map((LE, idx) => {
          return (
            <Text key={idx} m='0' p='0' color='#dd0000'>
              {LE}
            </Text>
          );
        })}
    </Box>
  );
};

export default ListingForm;

import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
// import { merge } from 'lodash';
import merge from 'deepmerge';
import { setCurrentTheme } from 'redux/actions/nooUi';
import customThemes from 'shared/constants/customThemes';

let defaultFavicon = '';

const noColorMode = {
  initialColorMode: 'light',
  useSystemColorMode: false
};

const useNooUi = () => {
  const dispatch = useDispatch();
  const currentTheme = useSelector(state => state.nooUi?.currentTheme, shallowEqual);
  const currentThemeName = useSelector(state => state.nooUi?.currentThemeName);
  const currentThemeMapName = useSelector(state => state.nooUi?.currentThemeMapName);
  const currentNetwork = useSelector(state => state.noo?.currentNetwork);
  const currentNetworkData = useSelector(state => state.noo?.currentNetworkData, shallowEqual);

  useEffect(() => {
    if (currentNetwork) {
      // set the doc title on network change
      document.title = currentNetworkData?.title || currentNetworkData?.display;
      document.querySelectorAll('[rel=icon]').forEach(ico => {
        if (currentNetworkData?.favicon) {
          if (!defaultFavicon) {
            // cache the original in case some networks don't have it.
            defaultFavicon = currentNetworkData.favicon;
          }
          ico.href = currentNetworkData.favicon;
        } else if (defaultFavicon) {
          ico.href = defaultFavicon;
        }
      });
    }
  }, [
    currentNetwork,
    currentNetworkData?.display,
    currentNetworkData?.favicon,
    currentNetworkData?.title
  ]);

  const combinedTheme = useMemo(() => {
    if (currentTheme) {
      if (customThemes[currentThemeMapName]) {
        const COMBINED_THEME = merge(currentTheme, customThemes[currentThemeMapName]);
        return COMBINED_THEME;
      }
      return {
        ...currentTheme,
        ...noColorMode
      };
    }
    // at least return an empty object??
    // or do we settle on a default theme from reducer/nooUi?
    return { ...noColorMode };
  }, [currentTheme, currentThemeMapName]);

  const setTheme = themeName => {
    dispatch(setCurrentTheme(themeName));
  };

  return {
    setTheme,
    combinedTheme,
    currentThemeName,
    currentTheme
  };
};

export default useNooUi;

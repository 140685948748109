import {
  NOO_COMMAND_REQUEST,
  NOO_COMMAND_RESPONSE,
  NOO_COMMAND_RESPONSE_ERROR
} from 'shared/constants/actionTypes';

const initialState = {
  nooCommandIsProcessing: false,
  nooCommandResponseCommand: '',
  nooCommandResponse: null,
  nooCommandResponseError: null,
  nooCommandErrorStack: []
};

const nooApi = function (state = initialState, action) {
  const _state = { ...state };
  switch (action.type) {
    case NOO_COMMAND_REQUEST:
      return {
        ...state,
        nooCommandIsProcessing: action.payload,
        nooCommandResponse: null,
        nooCommandResponseError: null,
        nooCommandResponseCommand: ''
      };
    case NOO_COMMAND_RESPONSE:
      return {
        ...state,
        nooCommandIsProcessing: false,
        nooCommandResponse: action.payload.data,
        nooCommandResponseError: null,
        nooCommandResponseCommand: action.payload.command
      };
    case NOO_COMMAND_RESPONSE_ERROR:
      const errorStack = _state.nooCommandErrorStack;
      errorStack.push(action.payload);
      return {
        ...state,
        nooCommandIsProcessing: false,
        nooCommandResponse: null,
        nooCommandResponseError: action.payload.data,
        nooCommandResponseCommand: action.payload.command,
        nooCommandErrorStack: errorStack
      };
    default:
      return state;
  }
};

export default nooApi;

const markdown = `
[BETA: This is the first public use. It works best in a browser. If things don't work as expect, log out and back in again. 

Daniel Schmachtenberger suggested a *map of the Emerge Network* to Tomas Björkman. This is in-progress outcome of that effort.

The underlying concept is the power of *trust networks* and their embedded capacity for new forms of *global-scale collective decision-making* (see [this](https://steinbock.org/pubs/steinbock-ssdm.pdf) and [this](https://markorodriguez.files.wordpress.com/2011/01/smartocracy-hicss2007.pdf) for some of the science behind it).

The network you can explore here has over 50,000 *nodes* (humans) connected by *links* (relationships) to one or more Emerge Austin attendees. There are a lot of great people there; look for ones you know. 

Network analytics give us many tools for discovery, connection, validation, amplification, accountability, proof of humanity, etc. 
It's clear that we are in the midst of a *meta-crisis*, and are called to do what we can collectively. Going up a level in our ability to collaborate at all scales is critical.

The vision is that Emerge can be the kernel of a large global by-invitation *networked autonomous organization*, a [platform co-op](https://en.wikipedia.org/wiki/Platform_cooperative) governed by [liquid democracy](https://en.wikipedia.org/wiki/Liquid_democracy). It's a great resource for collective innovation and collaboration and a natural fit with Web3.
See the About page for more detail.

`;

export default markdown;

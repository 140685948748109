const markdown = `
Imagine a cooperatively-owned social network spanning the millions of us in the *regeneration movement* and our allies. 

That's what you're invited to make happen here: a ***minimum viable social capital commons***.  It's a new organizational form necessary (and sufficient?) to empower the movement with the global agency it deserves. 

And it provides an interoperable user community for ecosystems of new applications that align with the movement. See the [About page](/about) for details, including how it will be governed.

Each invitee simply exports their LinkedIn connections and imports them to a shared commons graph database. And you get a *member number*, additional analytics about your connections you can't get from Linkedin, and participation in the ratification of how the commons is to be governed.

We've [simulated what 2500 regeneration-focused participants would look like](https://nao.is/search_network): a network of 320k allies and .75M interconnections, including [2000+ who match "regen" on LinkedIn](https://nao.is/search_network/?query={%22desc%22:%22regen%22}). You'll probably find yourself there.

Go the [Import page](/import) to participate. Fast and easy and worth it.

If you have ideas for applications that use a cooperative social substrate, get in touch.

`;

export default markdown;

/* eslint-disable no-unused-vars */
/*
 * Account component
 *
 */
import React, { useState, useEffect } from 'react';
import { Button, Box, Heading, FormLabel, Spinner, Textarea } from '@chakra-ui/react';
import RenderChakraMarkdown from 'utils/ChakraMarkdown';
import portableCommunititesMarkdown from '../../shared/config/homepageMarkdowns/portable_communities_protocol';
import { useParams } from 'react-router-dom';
import UserAuth from 'components/User/UserAuth';
const Markdown = () => {
  const { page } = useParams();
  const cp = page == 'cp';
  return (
    <Box className='wordcloud'>
      <UserAuth unauthorized='signin'>
        {cp && (
          <RenderChakraMarkdown key='mkdown'>{portableCommunititesMarkdown}</RenderChakraMarkdown>
        )}
      </UserAuth>
    </Box>
  );
};

export default Markdown;

const markdown = `
## Portable Communities Protocol
### In service of Prosocial Self-organization at Scale

How can online communities be **persistent**, **composable**, **application-** and **platform-independent**? 


#### Community Portability is a huge unmet need and opportunity
To quote Richard Bartlett, co-founder of Loomio:
> I spent a decade and a million bucks to conclude the only really critical gap in collective organising software is to synchronise group membership across multiple platforms.


#### Example: Climate Movement
The climate movement is large, global, and diverse. It's also basically just a lot of mailing lists. It comprises multiple large organizations (Extinction Rebellion, Sunrise Movement, 350.org, and many more.) Most of those have numerous local chapters. Each local chapter is a mailing list and a "community". But they lack the collective agency needed to be effective, because they don't meaningfully federate or do collective coordination, sensemaking, or decisioning.

#### Challenge

What are the conditions needed for such communities to **manifest and co-evolve**; to **federate meaningfully** (beyond visions, manifestos, pledges, principled lip service, ie, actually in practice); **self-govern** at all scales; and dramatically **increase collective value and agency**?

#### Proposal
We propose a **Portable Community Protocol** whereby otherwise ad-hoc collections of people may become first-class objects within and between networks, having contractual relationships with each other and with applications.

Such a protocol needs to be simple, expressive, extensible, composable, decentralized.

It also requires an approach to *decentralized identifiers (**DIDs**)* that is *sybil-resistant* and unambiguous for users across multiple communities.

#### Benefits
* Communities become persistent and application-independent
* Collective agency, coordination, action
* Composition and federation
* Collective sense-making
* Expertise for hire
* Applications gain easier adoption and lower cost building user bases
* Natural integration with DAOs

#### What communities?
* Common purpose / mission / vision / values
	* Climate, Regeneration, MeToo, BLM movements
* Interest / practice / expertise
	* Plurality Network, Kernel.community, Collaborative.tech
	* Peer and citation networks (ConnectedPapers,  CiteSeerX)
* Teams / collaborations
	* Github, Slack, Discord, Teams
	* Curatorial (Mastodon instances)
*  Silo-ed groups
	*  	Facebook groups/pages, Twitter lists/followers
*   Ephemeral
	*   Events, video calls  
*   Informal
	*   Families, neighborhoods, group chats

#### Communities as Graphs
Communities are **humans plus relationships**. We propose a graph representation where ***nodes*** are humans or collections thereof, and ***edges*** are relationships among them (from *sourceNodeId* to *targetNodeId*). Both entities can have *type* and *data* attributes. The latter is freeform key-value stores.

**Each human node owns and controls its outbound edges** (the relationships it asserts.)

Activities of a community, such as collective content curation, are beyond the scope of this effort. They are part of an ***application ecosystem*** that serves communities.

#### Use Case - Events
Events are a good example of the opportunity. They generally start with participants knowing little of each other, and end with little persistent outcome. For example, the recent Plurality Network conference had an [attendee list that had only full names](https://www.plurality.institute/guests) and no further information. At the end, a Slack was created, but two weeks later only 50 of the several hundred attendees have signed up and there's very little activity.

To create a persistent graph representation, such a flat collection of fullnames can be bootstrapped as a graph of nodes and relationships with greater depth of information by simple correlation with existing social data. Here is such an elaboration of some of the Plurality attendees with their LinkedIn accounts and interconnections.

![](http://standingwave.net/img/NN/plurality_snap.png)
Amber Case's subnetwork on mouseover
![](http://standingwave.net/img/NN/plurality_snap_amber.png) 
[video](http://standingwave.net/img/NN/PluralityAttendees.mov) - [interactive](https://www.noo.network/search_network/?group=09b7c842-073c-4c4b-bcc6-fd208be91ff3)

That graph now has persistence and can be curated by each participant to **go beyond the *one-bit connections*** derived from LinkedIn. For instance, the owner of each node can **prune connections that have little meaning** for them, and can **amplify** others **according to domains of expertise** (a *floating-point vector* relationship vs. single bit). Such a ***graph curation tool*** is another app in the app ecosystem.

Now, how does that community relate to the attendees of another event, e.g. DWebCamp. Again, [another flat list of full names](https://dwebcamp.org/people/) and little pre- and post-elaboration. The same bootstrapping from social data produces a [network](https://www.noo.network/search_network/?group=8967bbd1-3a47-4346-a9d0-2b086504f2b1) that can then be intersected with Plurality's. Kaliya Young, Seth Frey, Nathan Schneider, Joshua Tan, Brad DeGraf, Ronen Tamari, Divya Siddarth, and numerous others attended both events. 

Such boolean operations can:

* inform future events before they convene
* promote people discovery before and after events
* facilitate federated activities such as collective curation, messaging, composition, production, data collection, swarming, etc.
* ...


#### Where does such a persistent community live?
Nodes and Edges are **easily represented in Web3** protocols. For instance, each node can store the relationships it controls in a ***personal online datastore***. The graph representation can **map easily to a DAO** and its corresponding smart contracts.

Initial experimentation, aggregation, and graph curation (users edit their relationships with others) use a centralized ArangoDB graph database, with export to JSON at any time.

#### Relationships with the world
Ok, assume a community has such a persistent decentralized manifestation. What can it do, especially with respect to other communities and to applications?

This is where *[verifiable credentials (**VCs**)](https://en.wikipedia.org/wiki/Verifiable_credentials)* and *[**object capabilities**](https://en.wikipedia.org/wiki/Object-capability_model)* come in.

Verifiable Credentials allow:
* Community verifies that a user is a member
* Community verifies user has only one identifier in its namespace
* User node verifies its relationships with other nodes
* ...

Object Capabilities allow:

* Community confers access to its graph data to a given app, e.g.;
	* a liquid democracy decision needs to know who each community member has delegated to in which domains
	* a content curation app needs to aggregate annotation activity by community members
	* ...
* Composition of communities, e.g.
	* what domains they are collaborating on
	* how to interpret intersection / overlaps 
	* ...

[Spritely Institute](https://spritely.institute/static/papers/spritely-core.html) has done great work establishing core infrastructure for secure interoperation within communities (each node's control of its own subnetwork), between communities (how they combine / federate), and with applications (what Object Capabilities a given app gets from a community within the *[principle of least authority](https://en.wikipedia.org/wiki/Principle_of_least_privilege)*.)

#### Identifiers
A given user's participation in multiple communities should be **clear, unambiguous**, and **sybil-resistant**. We have considered deeply the various aspects, components, principles and risks involved. There are numerous identifier services out there, e.g. Proof of Humanity, BrightId, etc. If one of them suffices, we'll use it. Otherwise, we like the concepts we developed behind [**GreenCheck**](https://docs.google.com/presentation/d/1rFzlnAeuIq6zYMbtkmkq7qA9ZJ5kXfRnhrbLjfSa-cY/edit?usp=sharing), taking advantage of existing trust relationships utilizing ***3-friend authentication***.

#### Proof of Concept
We propose:   

* Agree on a simple first-version schema of a graph representation;
* Instantiate two or more communities as graphs using that schema (e.g. Plurality and DWeb); and
* Two or more simple apps that can meaningfully interoperate with those communities.

 




`;

export default markdown;

import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import {
  Box,
  Input,
  Button,
  FormLabel,
  Textarea,
  Spinner,
  Link,
  Text,
  Flex,
  HStack,
  Checkbox,
  Heading,
  Tooltip,
  IconButton
} from '@chakra-ui/react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import useQuery from 'utils/useQuery';
import FontAwesomeIcon from 'utils/FontAwesomeLoader';

// import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import usePeopleNetwork from 'hooks/usePeopleNetwork';
import Results from './Results/Timeline';
import NetworkTabs from 'components/Networks/NetworkTabs';
import UserAuth from 'components/User/UserAuth';
import formatResponse from 'utils/formatResponse';
import _ from 'lodash';
import TimeChooser from 'components/Segments/TimeChooser';
import GroupSelector from 'components/Segments/GroupSelector';
import PeopleSelector from 'components/Segments/PeopleSelector';
import MarksPopover from 'components/Segments/MarksPopover';
import BookmarkletDraggable from 'components/Segments/BookmarkletDraggable';
import { getNooBundle } from 'redux/actions/nooApi';
import { ContentSubpanel } from 'components/Segments/Styled';
import useMultiDomain from 'hooks/useMultiDomain';
import useGroups from 'hooks/useGroups';

const FieldChooser = props => {
  const checks = props.options.map(f => (
    <Checkbox
      key={f}
      size='sm'
      type='checkbox'
      value={f}
      name={f}
      isChecked={_.includes(props.fields, f)}
      onChange={props.updateFields}
    >
      <Text as='span' fontSize={['md', 'sm', 'xs']}>
        {f}
      </Text>
    </Checkbox>
  ));
  return checks;
};

const FilterDivider = styled.div`
  border-left: 1px solid #ddd;
  height: 1.7em;
  width: 1px;
`;

const Timeline = props => {
  // const dispatch = useDispatch();
  const { isAuthorized, currentNetworkData, nooUser } = useNoo('Timeline');
  const { currentGroupId, groupSelector } = useGroups({ from: 'Timeline' });
  const personId = nooUser ? nooUser.person?._id : null;
  const qparams = useQuery();
  let { query, since, groups: groups_in } = useQuery();
  const network = currentNetworkData;
  const netname = network.id;

  const isWhoKnows = netname == 'whoknows';
  console.log('is who', isWhoKnows, netname);
  const default_group = network.default_group;
  // groups_in = groups_in || network?.default_group || '';
  const { nooCommandDirect } = useNooApi();

  const default_list = network.headlines || '105092773';
  const [response, setResponse] = useState('{}');
  const [responseHeight, setResponseHeight] = useState('100px');
  const responseRef = useRef(null);

  // var params = new URLSearchParams(useLocation().search);

  const [searchFilter, setSearchFilter] = useState(query || '');
  const [searchSince, setSearchSince] = useState(parseInt(since || '1000'));
  const [searchSource, setSearchSource] = useState(null);
  const [_TYPE_LOOKUP, setTypeLookup] = useState({
    Claim: null,
    Praise: 'RESPECT',
    Validate: null,
    Follow: null
  }); // display label and type mapping, null if just uppercase
  const all_fields = Object.keys(_TYPE_LOOKUP);
  const [searchFields, setSearchFields] = useState(all_fields);
  const [groupId, setGroupId] = useState(null);
  const [groups, setGroups] = useState(groups_in ? groups_in.split(',') : null);
  const [modifiedFilters, setModifiedFilters] = useState(false);
  const autoSearch = useRef(null);
  const headlinesSearchIsProcessing = useRef(null);
  const history = useHistory();

  const { domainConfig } = useMultiDomain();
  const { showBetaNotes } = domainConfig;

  const hasBundle = getNooBundle();

  const {
    currentNetwork: graph,
    isRequesting: fetching,
    getRespected,
    getNetwork,
    getEdgesType,
    getNominationData
  } = usePeopleNetwork();

  const get_ts = since => {
    const now = new Date();
    return now.getTime() / 1000 - since * 86400;
  };

  const Filters = () => {
    return (
      <Tooltip label=''>
        <HStack
          wrap='wrap'
          spacing='12px'
          paddingTop='10px'
          marginTop='10px'
          borderTop='1px solid #ddd'
        >
          <Heading as='h3' size='sm'>
            Filters
          </Heading>
          {false && (
            <IconButton
              aria-label='Search database'
              title='Share this search'
              onClick={createLink}
              icon={ShareIcon}
            />
          )}
          <Box>
            <Button
              size='xs'
              cursor='pointer'
              onClick={onCallSearchFilter}
              disabled={disabledFilterButton}
              backgroundColor={disabledFilterButton ? undefined : 'cyan.500'}
              color={disabledFilterButton ? undefined : 'white'}
            >
              Apply
            </Button>
          </Box>
          <FilterDivider />
          <Box>
            <Button
              onClick={resetFilters}
              size='xs'
              cursor='pointer'
              disabled={disabledFilterButton}
              backgroundColor={disabledFilterButton ? undefined : 'pink.500'}
              color={disabledFilterButton ? undefined : 'white'}
            >
              Reset
            </Button>
          </Box>
          <FilterDivider />

          <Box>
            <Checkbox
              key={'mine'}
              size='sm'
              type='checkbox'
              value={searchSource ? true : false}
              name={'mine'}
              isChecked={searchSource != null}
              onChange={onUpdateSource}
            >
              <Text as='span' fontSize={['md', 'sm', 'xs']}>
                Mine only
              </Text>
            </Checkbox>
          </Box>
          <FilterDivider />

          {false && (
            <>
              <HStack space='2'>
                <Text fontSize={['md', 'sm', 'xs']} fontWeight={['normal', 'normal', 'bold']}>
                  Text:
                </Text>
                <Input
                  height={['30px', '30px', '24px']}
                  width={['180px', '200px', '300px']}
                  type='text'
                  id='filter'
                  name='filter'
                  bgColor='white'
                  placeholder='search term'
                  defaultValue={searchFilter}
                  onChange={onUpdateFilter}
                />
              </HStack>
              <FilterDivider />
            </>
          )}
          {false && (
            <>
              {' '}
              (
              <HStack space='2'>
                <Text fontSize={['md', 'sm', 'xs']} fontWeight={['normal', 'normal', 'bold']}>
                  Time Range:
                </Text>
                <TimeChooser
                  value={searchSince}
                  handleChoice={toggleRadio}
                  type='select'
                  controlOnly
                  size={'sm'}
                  width={['200px', '150px', '100px']}
                />
              </HStack>
              <FilterDivider />)
            </>
          )}

          <HStack space='2' flex='1'>
            <Text fontSize={['md', 'sm', 'xs']} fontWeight={['normal', 'normal', 'bold']}>
              Mark type:
            </Text>
            <FieldChooser options={all_fields} fields={searchFields} updateFields={updateFields} />
          </HStack>
        </HStack>
      </Tooltip>
    );
  };

  useEffect(() => {
    const textAreaRef = responseRef.current;
    if (textAreaRef !== null) {
      const scrollHeight = textAreaRef.scrollHeight;
      if (scrollHeight > 100) {
        setResponseHeight(scrollHeight);
      }
    }
  }, [responseRef]);

  useEffect(() => {
    if (groups && groups.length > 0) {
      setGroupId(groups[0]);
    }
  }, [groups]);

  // useEffect(() => {
  //   if (nooCommandResponse?.command === 'aql') {
  //     setResponse(nooCommandResponse.data);
  //   }
  // }, [nooCommandResponse?.command, nooCommandResponse?.data]);

  const onUpdateFilter = e => {
    setSearchFilter(e.target.value);
    setModifiedFilters(true);
  };

  const onUpdateSource = e => {
    const current = searchSource;
    setSearchSource(current ? null : personId);
  };

  const searchPayload = useMemo(() => {
    const ts = get_ts(searchSince);
    return {
      ts,
      text: searchFilter,
      fields: searchFields,
      source: searchSource,
      // since: 3,
      groups
    };
  }, [searchSince, searchFilter, groups, searchFields, searchSource]);

  const url_params = useCallback(() => {
    const todo = { query: searchFilter, groups: groups ? groups[0] : null, since: searchSince };
    let params = '';
    Object.entries(todo).forEach(pair => {
      if (pair[1]) {
        params += [pair[0], encodeURIComponent(pair[1])].join('=') + '&';
      }
    });
    return params;
  }, [searchFilter, groups, searchSince]);

  const getUrlParts = useCallback(() => {
    const _URL = new URL(location.href);
    const path = _URL.pathname;
    let url = [_URL.protocol, _URL.host].join('//') + path;
    const params = url_params();
    return [url, path, params];
  }, [url_params]);

  const createLink = () => {
    let [url, path, params] = getUrlParts();
    if (params.length > 0) {
      url += '?' + params;
    }
    navigator.clipboard.writeText(url).then(() => {
      // Alert the user that the action took place.
      // Nobody likes hidden stuff being done under the hood!
      alert('Copied to clipboard: ' + url);
    });
  };

  const onCallSearchFilter = useCallback(
    (e, payloadOverride) => {
      if (isAuthorized) {
        const limit = 100;
        let filters = {};
        let types;
        if (isWhoKnows) {
          types = ['RESPECT']; // getNominationData({ limit, filters });
        } else {
          types = searchPayload.fields || Object.values(_TYPE_LOOKUP);
          types = _.map(types, typ => {
            return _TYPE_LOOKUP[typ] || typ.toUpperCase();
          });
        }
        filters.source = searchPayload.source || null;
        filters.since = searchPayload.since || null;
        // filters.target = 'Persons/7cd50b5b-5ac4-4ad6-aa40-fa671ddf13e8';
        getEdgesType({ types, limit, filters });
      }
    },
    [
      isAuthorized,
      isWhoKnows,
      searchPayload.fields,
      searchPayload.source,
      searchPayload.since,
      _TYPE_LOOKUP,
      getEdgesType
    ]
  );

  useEffect(() => {
    // cause search on load
    if (isAuthorized && hasBundle && !autoSearch.current) {
      onCallSearchFilter();
      autoSearch.current = true;
    }
  }, [isAuthorized, onCallSearchFilter, autoSearch, hasBundle]);

  const toggleRadio = e => {
    setSearchSince(parseInt(e.target.value));
    setModifiedFilters(true);
  };
  const updateFields = e => {
    const checked = e.target.checked;
    const val = e.target.value;
    let current = [...searchFields];
    if (checked && !_.includes(current, val)) {
      current.push(val);
    }
    if (!checked) {
      current = current.filter(one => one != val);
    }
    if (current.length > 0) {
      setSearchFields(current);
      setModifiedFilters(true);
    }
  };
  const onSelectGroup = (event, groupId) => {
    setGroups(groupId && groupId.toLowerCase() !== 'any' ? [groupId] : null);
    setGroupId(groupId && groupId.toLowerCase() !== 'any' ? groupId : null);
    setModifiedFilters(true);
  };

  const onSelectUser = user => {
    // console.log('Selected user', user);
  };
  const resetFilters = event => {
    setGroups(null);
    setSearchSince(60);
    setSearchFilter(null);
    setSearchFields(all_fields);
    setSearchSource(null);
    setModifiedFilters(false);
    /*     onCallSearchFilter(null, {
      ...searchPayload,
      text: null,
      groups: null
    }); */
  };

  const disabledFilterButton = false; //  !modifiedFilters || headlinesSearchIsProcessing?.current;

  /*   <HStack>
  <Text fontSize={['md', 'sm', 'xs']} fontWeight={['normal', 'normal', 'bold']}>
    People:
  </Text>
  <Text
    fontSize={['md', 'sm', 'xs']}
    fontWeight={['normal', 'normal', 'normal']}
    textDecoration={['italic']}
  >
    Coming soon...
  </Text>
</HStack>
<FilterDivider /> */

  const ShareIcon = FontAwesomeIcon({
    icon: ['fad', 'share-alt'],
    name: 'share',
    style: { marginTop: '2px', marginInlineEnd: 0 }
  });
  return (
    <Box>
      <UserAuth unauthorized='signin'>
        <Box className='App-row'>
          <HStack>
            <Heading as='h2' fontSize='2xl' textAlign={['center', 'left']} flex='1'>
              People Marks
            </Heading>
          </HStack>
          {showBetaNotes ? (
            <Box
              width={['100%', '85%', '65%']}
              py={['10px', undefined]}
              my={['10px', undefined]}
              mx={['10px', 'auto']}
              borderTop={['1px solid #ddd', undefined]}
              borderBottom={['1px solid #ddd', undefined]}
            >
              <MarksPopover />
            </Box>
          ) : null}
          <ContentSubpanel width={['100%', '85%']}>
            {fetching && (
              <Box style={{ float: 'left' }}>
                <Spinner />
              </Box>
            )}
            {!isWhoKnows && <Filters />}
            {/*
              <Box className='debug'>
                <FormLabel htmlFor='grpcResponse'>GRPC Response:</FormLabel>
                <Textarea
                  ref={responseRef}
                  id='grpcResponse'
                  name='grpcResponse'
                  style={{ height: responseHeight }}
                  readOnly={true}
                  value={formatResponse(response)}
                />
              </Box>
              */}

            <Box className='whitebox_headlines' bgColor='#fff'>
              {autoSearch.current &&
                !headlinesSearchIsProcessing?.current &&
                graph &&
                !graph.links?.length && (
                  <Box textAlign='center'>
                    <Heading as='h3'>No Marks found</Heading>
                    <Text>Try changing your filters, or </Text>
                    <Button onClick={resetFilters} fontSize='lg' cursor='pointer'>
                      Reset
                    </Button>
                  </Box>
                )}
              {graph ? <Results data={graph} network={netname} /> : null}
              {graph && <NetworkTabs graph={graph} maxNodes={200} default_tab={1} />}
            </Box>
          </ContentSubpanel>
        </Box>
      </UserAuth>
    </Box>
  );
};

export default Timeline;

import React, { useState, useMemo } from 'react';
import { Box, Button, Divider, Heading, Text } from '@chakra-ui/react';
import ChatComponents from './ChatComponents';
import ChatService from './ChatService';
import UserAuth from 'components/User/UserAuth';

export const DemoBox = ({ children }) => {
  return (
    <Box
      w={['100%', '100%', '48%', '48%', '32%']}
      p='4'
      borderRadius='4'
      borderWidth='1px'
      borderColor='gray.200'
    >
      {children}
    </Box>
  );
};

export const ComponentHeader = ({ children }) => {
  return (
    <Heading
      as='h2'
      fontSize='md'
      color='teal.400'
      fontWeight='normal'
      borderBottomWidth='1px'
      borderBottomColor='gray.200'
      mb='3'
      pb='1'
    >
      {children}
    </Heading>
  );
};

const DemoComponents = () => {
  const [demoType, setDemoType] = useState(null);

  const DemoComponent = useMemo(() => {
    switch (demoType) {
      case 'chat-ui':
        return ChatComponents;
      case 'chat-service':
        return ChatService;
    }
    return null;
  }, [demoType]);

  if (DemoComponent) {
    return (
      <Box>
        <DemoComponent />
        <Divider my='5' />
        <Text align='center'>
          <Button onClick={() => setDemoType(null)}>Back to Demos</Button>
        </Text>
      </Box>
    );
  }

  return (
    <UserAuth unauthorized='signin'>
      <Box>
        <Heading as='h1' fontSize='2xl'>
          Demo components
        </Heading>
        <Heading as='h2' fontSize='xl' mt='2' pt='2' borderTop='1px solid gray200'>
          Chat UI
        </Heading>
        <Button onClick={() => setDemoType('chat-ui')}>View chat components</Button>

        <Heading as='h2' fontSize='xl' mt='2' pt='2' borderTop='1px solid gray200'>
          Chat Service
        </Heading>
        <Button onClick={() => setDemoType('chat-service')}>Try out chat</Button>
      </Box>
    </UserAuth>
  );
};

export default DemoComponents;

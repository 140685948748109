import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import {
  Flex,
  Box,
  Input,
  Button,
  Radio,
  FormLabel,
  Textarea,
  Spinner,
  Select,
  Stack,
  HStack,
  Heading,
  Text
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import Results from './Results/Headlines';
import UserAuth from 'components/User/UserAuth';
import formatResponse from 'utils/formatResponse';
import _ from 'lodash';
import { objectSort } from 'utils';
import { selector_setup } from 'shared/constants/selectorSetup';
import TimeChooser from 'components/Segments/TimeChooser';
// import AutoComplete from 'components/Segments/AutoComplete';
import NewsPopover from 'components/Segments/NewsPopover';
import { ContentSubpanel, WhiteBox } from 'components/Segments/Styled';

const SearchHeadlines = props => {
  const dispatch = useDispatch();
  const { isAuthorized, currentNetworkData } = useNoo('SearchHeadlines');
  const { nooCommandDirect } = useNooApi();

  const network = currentNetworkData;
  const { nooCommand, nooCommandResponse, nooCommandIsProcessing } = useNooApi();

  const default_list =
    network.headlines ||
    '105092773,105099717,1432916024358232064,1442496192940691463,1442678657948979201,1444526773446877185';
  const [response, setResponse] = useState('{}');
  const [currentTopic, setCurrentTopic] = useState(default_list);
  const [responseHeight, setResponseHeight] = useState('100px');
  const responseRef = useRef(null);
  const [searchFilter, setSearchFilter] = useState('biodiversity');
  const [searchSince, setSearchSince] = useState(1000);
  const [commonSearches, setCommonSearches] = useState(null);

  const get_ts = since => {
    const now = new Date();
    return now.getTime() / 1000 - since * 86400;
  };

  const make_option = (text, value) => {
    return (
      <option value={value} key={text.toLowerCase()}>
        {text}
      </option>
    );
  };
  const [options, setOptions] = useState([]);
  const make_selector = useEffect(() => {
    // Loads selector
    let new_options = [];
    _.forOwn(selector_setup, (value, key) => {
      new_options.push(make_option(value.name, key));
    });
    new_options.sort(objectSort('key'));
    setOptions(new_options);
  }, []);

  useEffect(() => {
    if (!commonSearches && isAuthorized) {
      const fetchCommon = new Promise((resolve, reject) => {
        const loading = () => {};
        const responseHandler = response => {
          const data = response.result[0];
          resolve(data);
        };
        const responseError = error => {
          console.error('Error getting common searches', error);
          reject(error);
        };
        const payload = {
          query_name: 'aqlGetRecord',
          data: { doc_id: 'Documents/d5aa7503-81c0-404d-aef6-979923f8c7cb' }
        };
        nooCommandDirect('aql', {
          payload,
          bundle: 'Twitter',
          setLoading: loading,
          setResponse: responseHandler,
          setResponseError: responseError
        });
      })
        .then(result => {
          const commons = result?.data?.terms;
          if (commons) {
            let options = Object.entries(commons).map(pair => {
              return { value: pair[0], label: pair[0], w: pair[1] };
            });
            options = options.sort((a, b) => {
              return b.w - a.w;
            });
            setCommonSearches(options);
          }
        })
        .catch(error => {
          // handle any errors
          console.error('Error on promises', error);
          setCommonSearches({});
        });
    }
  }, [commonSearches, nooCommandDirect, isAuthorized]);

  const onCallAqlSearch = useCallback(
    topic => {
      if (isAuthorized) {
        const topics = _.map(topic.split(','), one => {
          return 'lists/' + one;
        });
        const ts = get_ts(searchSince);
        const payload = { query_name: 'aqlSearchHeadlinesByTopic', data: { ts, list_id: topics } };

        dispatch(nooCommand('aql', { payload, bundle: 'Twitter' }));
      }
    },
    [dispatch, nooCommand, searchSince, isAuthorized]
  );

  useEffect(() => {
    const textAreaRef = responseRef.current;
    if (textAreaRef !== null) {
      const scrollHeight = textAreaRef.scrollHeight;
      if (scrollHeight > 100) {
        setResponseHeight(scrollHeight);
      }
    }
  }, [responseRef]);

  useEffect(() => {
    if (nooCommandResponse?.command === 'aql') {
      setResponse(nooCommandResponse.data);
    }
  }, [nooCommandResponse?.command, nooCommandResponse?.data]);

  const onTopicChange = e => {
    const topic = e.target.value;
    setCurrentTopic(topic);
  };

  const onTopicSubmit = e => {
    onCallAqlSearch(currentTopic);
  };

  const onUpdateFilter = e => {
    setSearchFilter(e.target.value);
  };

  const onCallSearchFilter = useCallback(
    e => {
      if (isAuthorized) {
        const ts = get_ts(searchSince);
        const payload = { query_name: 'aqlSearchHeadlines', data: { ts, text: searchFilter } };
        dispatch(nooCommand('aql', { payload, bundle: 'Twitter' }));
      }
    },
    [dispatch, nooCommand, searchSince, searchFilter, isAuthorized]
  );

  useEffect(() => {
    // cause search on load
    if (isAuthorized) {
      onCallSearchFilter();
    }
  }, [dispatch, nooCommand, searchFilter, searchSince, isAuthorized, onCallSearchFilter]);

  const presets = useMemo(() => {
    const onPreset = topic => {
      setSearchFilter(topic);
    };

    const topics = ['indigenous', 'species', 'climate', 'rainforest', 'fisheries', 'ocean'];
    return topics.map((topic, idx) => (
      <Button
        key={idx}
        onClick={() => onPreset(topic)}
        fontWeight='bold'
        padding='0 6px'
        ml='3px'
        size='sm'
      >
        {topic}
      </Button>
    ));
  }, [setSearchFilter]);

  const onUpdateSince = e => {
    setSearchSince(parseInt(e.target.value));
  };

  const changeFromAuto = option => {
    setSearchFilter(option.value);
  };

  const toggleRadio = e => {
    setSearchSince(parseInt(e.target.value));
  };
  /* had to remove because incompatible with new autocomplete for people
                    <Text>Popular:</Text>{' '}
                  <AutoComplete options={commonSearches} onChange={changeFromAuto} />
                */
  const show_list_search = true;

  return (
    <Box>
      <UserAuth unauthorized='signin'>
        <Box className='App-row'>
          <Heading
            as='h2'
            fontSize='2xl'
            textAlign={['center', 'left']}
          >{`Latest from social media`}</Heading>
          <ContentSubpanel width={['100%', '85%']}>
            <NewsPopover />
            {nooCommandIsProcessing && (
              <Box float='right'>
                <Spinner />
              </Box>
            )}
            <Flex
              border='1px solid lightgrey'
              bgColor='#efefef'
              borderRadius='8px'
              p='0'
              mt='10px'
              mx={['10px', '0']}
              flexDirection='column'
              alignItems={['center', 'flex-start']}
            >
              {true && (
                <HStack mt='8px'>
                  <Text>Search</Text>
                  <Input
                    bg='white'
                    mr='10px'
                    height='30px'
                    width='200px'
                    _focus={{ outline: '0' }}
                    type='text'
                    id='filter'
                    name='filter'
                    placeholder={searchFilter || 'e.g. climate, biodiversity...'}
                    // defaultValue={searchFilter}
                    onBlur={onUpdateFilter}
                  />
                  <Button
                    size='sm'
                    fontWeight='normal'
                    onClick={onCallSearchFilter}
                    disabled={nooCommandIsProcessing || searchFilter === ''}
                  >
                    Submit
                  </Button>
                </HStack>
              )}
              <HStack wrap='wrap' space='2' mb='8px'></HStack>
              <Box ml={[undefined, '10px']}>
                <TimeChooser value={searchSince} handleChoice={toggleRadio} type='select' />
              </Box>

              {show_list_search && (
                <HStack mt='8px'>
                  <Text>Broad Topics</Text>
                  <Box mr='10px'>
                    <Select
                      bg='white'
                      onChange={onTopicChange}
                      disabled={nooCommandIsProcessing}
                      value={currentTopic}
                      size='sm'
                    >
                      {options}
                    </Select>
                  </Box>
                  <Box mr='10px'>
                    <Button
                      size='sm'
                      fontWeight='normal'
                      onClick={onTopicSubmit}
                      disabled={nooCommandIsProcessing}
                    >
                      {'Submit'}
                    </Button>
                  </Box>
                </HStack>
              )}
            </Flex>
            <Box className='debug'>
              <FormLabel htmlFor='grpcResponse'>GRPC Response:</FormLabel>
              <Textarea
                ref={responseRef}
                id='grpcResponse'
                name='grpcResponse'
                style={{ height: responseHeight }}
                readOnly={true}
                value={formatResponse(response)}
              />
            </Box>
            <WhiteBox className='whitebox_headlines'>
              <Results data={response} group_id={selector_setup[currentTopic]['group']} />
            </WhiteBox>
          </ContentSubpanel>
        </Box>
      </UserAuth>
    </Box>
  );
};

// export default SearchHeadlines;
export default SearchHeadlines;

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Flex, Link, HStack, FormLabel, Input, Text, Image } from '@chakra-ui/react';
import _ from 'lodash';
import styled from '@emotion/styled';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import UserCard from 'components/Segments/UserCard';
import UserAttributes from 'components/Segments/UserAttributes';
import InvitationsAccept from 'components/Invitations/InvitationsAccept';
import ClaimPopover from '../Segments/ClaimPopover';

import UserAuth from 'components/User/UserAuth';
import ShowNetwork from 'components/Networks/ShowNetwork';
// import ShowD3Network from 'components/Networks/ShowD3Network';
import ShowForceNetwork from 'components/Networks/ShowForceNetwork';
import FirebaseUserInfo from 'components/User/FirebaseUserInfo';

// import { graph_normalize } from 'utils/graphNormalize';
// import { normalize_id } from 'utils';
import InvitePopover from '../Segments/InvitePopover';
// import ClaimBookmarklet from 'shared/constants/claimBookmarklet';

const WhiteBox = styled(Box)`
  background-color: #fafafa;
  border: 1px solid #ccc;
`;

const UserProfile = props => {
  const { person_id } = useParams(); // onSubmit
  const { nooUser } = useNoo('UserProfile');
  const { nooCommandDirect, nooCommandResponseDirect } = useNooApi();
  const [profile, setProfile] = useState(null);
  const [graph, setGraph] = useState({});
  const [maxNodes, setMaxNodes] = useState(100);

  const history = useHistory();
  // better way to handle personId,
  // instead of a useEffect + useState (less renders, memoize based on inputs)
  const personId = 'Persons/' + person_id;

  useEffect(() => {
    if (!profile || profile._id !== personId) {
      // other user than logged in me
      // requires _id added to profile
      // promise to manage user profile data
      const fetchProfile = new Promise((resolve, reject) => {
        const loading = () => {};
        const responseHandler = response => {
          const data = response.result[0];
          let profile = data.data.profile || data.data.linkedin || data.data.twitter;
          profile._id = data._id;
          resolve(profile);
        };
        const responseError = error => {
          console.error('Error getting profile', error);
          reject(error);
        };
        const payload = { query_name: 'aqlGetRecord', data: { doc_id: personId } };
        nooCommandDirect('aql', {
          payload,
          setLoading: loading,
          setResponse: responseHandler,
          setResponseError: responseError
        });
      })
        .then(profile => {
          setProfile(profile);
        })
        .catch(error => {
          // handle any errors
          console.error('Error on fetching user profile', error);
        });
    }
  }, [personId, profile, nooCommandDirect]);

  const clickUser = user => {
    const personId = user.id.replace('Persons/', '');
    history.push('/user/' + personId);
  };

  // const { profile, graph } = profileGraph;
  const show_claim = false; // personId && personId == nooUser?.person?._id && !nooUser?.person?.data?.li;
  const is_me = personId == nooUser?.person?._id;
  // {!is_me && profile && <InvitePopover profile={profile} personId={personId} />}
  return (
    <UserAuth unauthorized='signin'>
      <div className='App-row listing' bg='white'>
        {profile && (
          <Flex sx={{ w: '100%' }}>
            <UserCard
              data={profile}
              order={['fullname', 'avatar', 'linkedin', 'facebook', 'twitter']}
            />
          </Flex>
        )}
        <Text>More coming soon</Text>
        {show_claim && <ClaimPopover />}
        <UserAttributes personId={personId} />
        {is_me ? <FirebaseUserInfo /> : null}

        {is_me && (
          <Flex sx={{ w: '100%' }}>
            <InvitationsAccept />
          </Flex>
        )}

        {graph && graph.nodes?.length > 0 && (
          <Box>
            <WhiteBox sx={{ width: '100%', maxHeight: '200px', overflowY: 'scroll' }}>
              <ShowNetwork graph={graph} />
            </WhiteBox>

            <WhiteBox sx={{ width: '100%' }}>
              <ShowForceNetwork
                graph={graph}
                config={{}}
                node_click_cb={clickUser}
                width={'100%'}
                maxNodes={maxNodes}
              />
            </WhiteBox>
          </Box>
        )}
      </div>
    </UserAuth>
  );
};

export default UserProfile;

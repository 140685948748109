import React, { useState } from 'react';
import { Alert, AlertDescription, Box, Text } from '@chakra-ui/react';

import useFirebaseAuth from 'hooks/useFirebaseAuth';

import LoginForm from './EmailLoginSignup/LoginForm';
import ForgotPasswordForm from './EmailLoginSignup/ForgotPasswordForm';
import SignupForm from './EmailLoginSignup/SignupForm';

const FirebaseEmailLogin = () => {
  const {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    error
  } = useFirebaseAuth();

  const [uiType, _setUiType] = useState('login');

  const setUiType = type => {
    setError('');
    _setUiType(type);
  };

  const [localData, setLocalData] = useState({});
  const [localError, setError] = useState('');

  const errorDisplay = error || localError;
  const errorMsg = typeof errorDisplay === 'string' ? errorDisplay : errorDisplay?.message;

  const handleLogin = () => {
    if (localData.l_email && localData.l_pass) {
      setError('');
      signInWithEmailAndPassword(localData.l_email, localData.l_pass);
    } else {
      setError('Please provide an email and password to login');
    }
  };

  const handleForgot = () => {
    if (localData.f_email) {
      // doForgot(localData.f_email);
      // alert('forgot password still in progress');
      sendPasswordResetEmail(
        localData.f_email,
        successMsg => {
          console.log('success! sent forgot password link');
        },
        setError
      );
    } else {
      setError('Please provide an email address to send reset instuctions to');
    }
  };

  const handleSignup = () => {
    if (localData.s_email && localData.s_pass && localData.s_pass === localData.s_passv) {
      createUserWithEmailAndPassword(localData.s_email, localData.s_pass);
      setError('');
    } else {
      const errors = [];
      if (!localData.s_email) {
        errors.push('Please provide your email address to create an account');
      }
      if (!localData.s_pass) {
        errors.push('Please enter a password');
      }
      if (localData.s_pass && localData.s_pass !== localData.s_passv) {
        errors.push("Your passwords don't match, please try again");
      }
      setError(errors);
    }
  };

  const onFieldUpdate = e => {
    const {
      target: { name, value }
    } = e;
    setLocalData({
      ...localData,
      [name]: value
    });
  };
  return (
    <Box borderRadius='8px' border='2px solid #eee;' p='16px' my='20px'>
      {uiType === 'login' ? (
        <LoginForm
          localData={localData}
          onFieldUpdate={onFieldUpdate}
          setUiType={setUiType}
          handleLogin={handleLogin}
        />
      ) : uiType === 'signup' ? (
        <SignupForm
          localData={localData}
          onFieldUpdate={onFieldUpdate}
          setUiType={setUiType}
          handleSignup={handleSignup}
        />
      ) : (
        <ForgotPasswordForm
          localData={localData}
          onFieldUpdate={onFieldUpdate}
          setUiType={setUiType}
          handleForgot={handleForgot}
        />
      )}
      {errorMsg ? (
        <Alert flexDirection='row' status='error' variant='subtle' my='12px' borderRadius='8px'>
          <AlertDescription>
            <Text>{errorMsg}</Text>
          </AlertDescription>
        </Alert>
      ) : null}
    </Box>
  );
};

export default FirebaseEmailLogin;

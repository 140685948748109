import React, { useMemo, useState } from 'react';
import InviteEmail from 'components/Invitations/InviteEmail';

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Box,
  Heading
} from '@chakra-ui/react';

const CloudGeneric = props => {
  const { buttonText, bodyText, width, onChange, closeCloud } = props;
  const index = closeCloud != false ? [-1] : [0];
  return (
    <Accordion allowToggle width={width} onChange={onChange} index={index}>
      <AccordionItem>
        <Heading as='h2'>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left'>
              <Text fontSize='lg' fontWeight={'bold'}>
                {buttonText}
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </Heading>
        <AccordionPanel pb={4}>
          <Text>{bodyText}</Text>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default CloudGeneric;

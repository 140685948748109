import _, { min } from 'lodash';
import { getDayJs } from './dayJs';
import { node_unit_test } from '../shared/data/node_unit_test';
import {
  node_normalizer,
  // node_normalizer_old,
  get_profile
  // get_profile_old
} from 'utils/graphNormalize';

const dayJs = getDayJs();

const null_of = x => {
  return typeof x == 'string'
    ? ''
    : typeof x == 'number'
    ? 0
    : Array.isArray(x)
    ? []
    : typeof x == 'object'
    ? {}
    : 0;
};

export function objectSort(prop) {
  return function (a, b) {
    const path = prop.split('.');
    let aval = a;
    let bval = b;
    path.forEach(atom => (aval = aval ? aval[atom] : aval));
    path.forEach(atom => (bval = bval ? bval[atom] : bval));
    const nil = aval ? null_of(aval) : null_of(bval);
    const ap = aval || nil;
    const bp = bval || nil;
    // console.log('cmp', ap, bp, ap > bp, bp > ap, _.sortBy([ap, bp]));
    return ap > bp ? 1 : bp > ap ? -1 : 0;
  };
}

export function multiSort(props) {
  // sort by multiple properties. Prepend each with '-' to reverse it.
  // test = [{a:1, b:"a",c:[1]},{a:2,b:'b',c:-1}, {a:3,b:'a',c:0}, {a:2.5, b:'c', c:null}, {a:2, b: 'a', c:undefined}]

  return function (a, b) {
    const results = props.map(p => {
      const reverse = p[0] == '-';
      const prop = reverse ? p.slice(1) : p;
      const nil = a[prop] ? null_of(a[prop]) : null_of(b[prop]);
      const ap = a[prop] || nil;
      const bp = b[prop] || nil;
      const compare = ap > bp ? 1 : bp > ap ? -1 : 0;
      return reverse ? compare * -1 : compare;
    });
    // console.log('a', a, 'b', b, results);
    for (const i in results) {
      const compare = results[i];
      if (compare > 0) {
        return 1;
      } else if (compare == -1) {
        return -1;
      }
    }
    return 0;
  };
}

export const intersect = (a, b) => {
  // accepts arrays or sets, returns set
  return new Set([...a].filter(i => new Set(b).has(i)));
};
export const unit_test_intersect = () => {
  let a = [1, 2, 3];
  let b = [3, 4, 6];
  console.log('arrays', intersect(a, b));
  a = new Set(a);
  console.log('set,array', intersect(a, b));
  b = new Set(b);
  console.log('sets', intersect(a, b));
};

const unit_test = () => {
  const test = [[1], [], { a: 'b' }, {}, 1, 0, 'b', 'a', ''];
  const objs = test.map(x => {
    return { k: x };
  });
  console.log('before', objs);
  objs.sort(objectSort('k'));
  console.log('obj', objs);
  console.log('lo', _.sortBy(test)); // compare how lodash sorts
  objs.sort(multiSort(['k', 'q']));
  console.log('multi', objs);
};
// unit_test();

const unit_test_node_normalize = () => {
  node_unit_test.forEach(one => {
    // console.log('old get_profile', get_profile_old(one));
    // console.log('new get_profile', get_profile(one));
    // console.log('old norm', node_normalizer_old(get_profile(one)));
    // console.log('new norm', node_normalizer(get_profile(one)));
  });
};
// unit_test_node_normalize();

export const find_link = (strng, kind) => {
  //var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  if (kind) {
    var reg_kind = new RegExp(kind + '\\|(.*?)\\|');
    const sub = strng.match(reg_kind);
    if (sub && sub.length > 1) {
      var it = sub[1];
      if (kind == 'image') {
        it = JSON.parse(it);
        it = it.url ? it : null;
      }
      return it;
    }
  } else {
    // eslint-disable-next-line no-useless-escape
    var expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    var regex = new RegExp(expression);
    return strng.match(regex);
  }
};

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * get a ?query=var from current location href
 * @function getGetVar
 * @param {string} key - query var key to look for
 * @param {string} [default_= ] - default value to pass back if not var not found
 * @returns {string} - value or default_
 */
export function getGetVar(key, default_) {
  if (default_ == null) {
    default_ = '';
  }
  // eslint-disable-next-line no-useless-escape
  key = key.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + key + '=([^&#]*)');
  var qs = regex.exec(window.location.href);
  if (qs == null) {
    return default_;
  } else {
    return decodeURIComponent(qs[1]);
  }
}

export function truncateTimestamp(ts) {
  const now = new Date().getTime() / 1000;
  return dayJs((ts || now) * 1000)
    .fromNow(true)
    .replace('a few', 'few')
    .replace('a ', '1 ')
    .replace(' ', '')
    .replace('day', 'd')
    .replace('hour', 'hr')
    .replace('minute', 'min')
    .replace('second', 'sec')
    .replace('week', 'wk')
    .replace('month', 'mo')
    .replace('year', 'yr');
}

export function linearInterp(value, rangeMin, rangeMax, outMin, outMax, exponent = 0.4) {
  // returns a value between outMin and outMax depending on where value lies between rangeMin and rangeMax; truncates
  if (rangeMin != rangeMax) {
    const where = (value - rangeMin) / (rangeMax - rangeMin); // 0-1
    return outMin + Math.pow(where, exponent) * (outMax - outMin);
  }
  return 1;
}

export function difference(array1, array2, symmetric) {
  // symmetric means combine both differences a la Python set().symmetric_difference()
  let a = new Set(array1);
  let b = new Set(array2);
  const one_way = Array.from(new Set([...a].filter(x => !b.has(x))));
  const other_way = symmetric ? Array.from(new Set([...b].filter(x => !a.has(x)))) : [];
  return _.concat(one_way, other_way);
}

const lookup_collections = {
  group: 'Groups/',
  user: 'Persons/',
  person: 'Persons/',
  listing: 'Listings/',
  question: 'Questions/'
};

export function normalize_id(idd, kind) {
  const prefix = lookup_collections[kind];
  return idd && prefix ? prefix + idd.replace(prefix, '') : idd || null;
}

export function norm_mastodon(hndle, version) {
  let handle = hndle.replace(/https?:\/\//, '');
  const is_link = handle.indexOf('/') > 0; // ser.ver/@name
  let uname = '';
  let server = '';
  if (is_link) {
    [server, uname] = handle.split('/');
    uname = uname.replace(/@/, '');
  } else {
    // @name@ser.ver
    const parts = handle.replace(/^@/, '').split('@');
    uname = parts[0];
    server = parts[1];
  }
  if (version == 'name') {
    return '@' + [uname, server].join('@');
  } else {
    uname = '@' + uname;
    return [server, uname].join('/');
  }
}

export function split_tags(instring = '') {
  const parts = instring.indexOf(',') >= 0 ? instring.split(',') : instring.split(' ');
  const normed = _.map(parts, part => part.trim().replace(' ', '_').toLowerCase());
  normed.sort();
  return normed.filter(one => one.length);
}

const _SHORTEN = {
  // ignore or replace in LI image URL
  replace: {
    'https://media-exp1.licdn.com/dms/image': '_R0_',
    'profile-displayphoto-shrink_100_100': '_R1_'
  },
  ignore: { e: '1652313600', v: 'beta' }
};

export function compressImage(url) {
  if (url) {
    const u_obj = new URL(url);
    const params = new URLSearchParams(u_obj.search);
    // first strip out standard params
    Object.entries(_SHORTEN.ignore).forEach(pair => {
      // console.log(pair, params.get(pair[0]));
      if (params.get(pair[0]) == pair[1]) {
        params.delete(pair[0]);
      }
    });
    u_obj.search = params.toString();
    let new_url = u_obj.toString();
    // now replace common strings
    Object.entries(_SHORTEN.replace).forEach(pair => {
      new_url = new_url.replace(pair[0], pair[1]);
    });
    return new_url;
  }
}

export function uncompressImage(url) {
  // for testing compress. uncompressImage(compressImage(url)) should = url
  if (url) {
    let new_url = url;
    // first reverse  common strings replacement
    Object.entries(_SHORTEN.replace).forEach(pair => {
      new_url = new_url.replace(pair[1], pair[0]);
    });
    const u_obj = new URL(new_url);
    const params = new URLSearchParams(u_obj.search);
    // then  re-add  standard params
    Object.entries(_SHORTEN.ignore).forEach(pair => {
      if (!params.get(pair[0])) {
        params.set(pair[0], pair[1]);
      }
    });
    u_obj.search = params.toString();
    new_url = u_obj.toString();
    return new_url;
  }
}

export function norm_id(val, kind, w_or_wo) {
  /* Add or remove collection nanes
  w_or_wo == 'without' ? remove collection name : add it
  kind = person or listing
  */
  if (val && kind) {
    const parts = val.split('/');
    const key = parts[parts.length - 1];
    const withh = [lookup_collections[kind], key].join('/').replace('//', '/');
    return w_or_wo === 'without' ? key : withh;
  }
  return val;
}

export function HSLToRGB(h, s, l) {
  // h is 0-360, s is 0-100, l is 0-100
  s /= 100;
  l /= 100;

  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2,
    r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return [r, g, b];
}

export function RGBToHex(r, g, b) {
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);

  if (r.length == 1) r = '0' + r;
  if (g.length == 1) g = '0' + g;
  if (b.length == 1) b = '0' + b;

  return '#' + r + g + b;
}

export function hexToRGB(hex) {
  const hsl = hexToHSL(hex);
  // console.log('hsl', hsl);
  return HSLToRGB(hsl.h * 360, hsl.s * 100, hsl.l * 100);
}

export function HSLtoHex(h, s, l) {
  // converts (0-360, 0-100, 0-100) to hex color
  const t = HSLToRGB(h, s, l);
  return RGBToHex(t[0], t[1], t[2]);
}

export function hexPlusOpacity(hex, opacity) {
  const as_RGB = hexToRGB(hex);
  // console.log('as rgb', as_RGB);
  return `rgba(${as_RGB[0]}, ${as_RGB[1]}, ${as_RGB[2]}, ${opacity || 0.5})`;
}

export function hexToHSL(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);
  (r /= 255), (g /= 255), (b /= 255);
  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h,
    s,
    l = (max + min) / 2;
  if (max == min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }
  var HSL = new Object();
  HSL['h'] = h;
  HSL['s'] = s;
  HSL['l'] = l;
  return HSL;
}

export function gen_color(
  value_in_unit_interval,
  min_luminence = 0.6,
  max_luminence = 1,
  min_saturation = 0.4,
  max_saturation = 1,
  min_hue = 90,
  max_hue = 270,
  verbose = false
) {
  const hue = (min_hue + Math.floor(value_in_unit_interval * (max_hue - min_hue))) % 360; // linearInterp(weight, 0, 1, 0, 180); //
  const lumin = Math.max(
    0.3,
    linearInterp(value_in_unit_interval, 0, 1, min_luminence, max_luminence, 1)
  );
  const saturation = linearInterp(value_in_unit_interval, 0, 1, min_saturation, max_saturation, 1);
  if (verbose)
    console.log(
      ' gen_color',
      lumin,
      value_in_unit_interval,
      min_luminence,
      max_luminence,
      'hue',
      hue,
      min_hue,
      max_hue,
      'sat',
      saturation,
      min_saturation,
      max_saturation,
      HSLtoHex(hue, saturation * 100, lumin * 100)
    );

  return HSLtoHex(hue, saturation * 100, lumin * 100);
}

export const derivePrefixedNodeId = (nodeId, prefix) => {
  if (nodeId && prefix) {
    return nodeId.indexOf(prefix) === 0 ? nodeId : `${prefix}/${nodeId}`;
  } else if (nodeId) {
    return nodeId;
  }
  return '';
};

function hue_interp(minn, maxx) {
  maxx = minn > maxx ? maxx + 360 : maxx;
  return;
}
export const dropNodeIdPrefix = nodeId => {
  return nodeId?.split('/').pop() || nodeId;
};

export const truncate_precision = (float_val, decimals = 2) => {
  return Math.floor(float_val * 100) / 100;
};

export function iterate_object(obj, depth, path, cb) {
  // traverses a dict and executes a callback at each node
  Object.entries(obj).forEach(([k, v]) => {
    if (typeof v == 'object') {
      cb(k, v, depth, path);
      iterate_object(v, depth + 1, [path, k].join('.'), cb);
    } else if (v) {
      cb(k, v, depth, path);
    }
  });
}

export function shuffle(input) {
  // random shuffle (for fullname obfuscation...)
  let array = typeof input === 'string' ? [...input] : input;
  if (input) {
    let currentIndex = array.length,
      randomIndex;
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
  }
  return typeof input === 'string' ? array.join('') : array;
}
export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const hotlink = text => {
  // replace any URLs in text with an anchor tag
  const linkRegex = /(https?:\/\/)?(www\.)?[^\s]+\.[^\s]+/g;
  function replacer(matched) {
    let withProtocol = matched;

    if (!withProtocol.startsWith('http')) {
      withProtocol = 'http://' + matched;
    }

    const newStr = `<a
      class="text-link"
      href="${withProtocol}"
    >
      ${matched}
    </a>`;

    return newStr;
  }
  return text.replace(linkRegex, replacer);
};
export function truncateWords(string, maxx) {
  if (string.length > maxx) {
    const parts = string.split(' ');
    let result = '';
    parts.forEach(atom => {
      if (result.length + atom.length + 1 <= maxx) {
        result += ' ' + atom;
      }
    });
    return result + '...';
  } else {
    return string;
  }
}
export const median = array => {
  array.sort((a, b) => b - a);
  const length = array.length;
  if (length % 2 == 0) {
    return (array[length / 2] + array[length / 2 - 1]) / 2;
  } else {
    return array[Math.floor(length / 2)];
  }
};

export const isObject = function (a) {
  return !!a && a.constructor === Object;
};

export const bioregionIdFromName = function (name) {
  const it = name ? new RegExp(/[A-Z]{2}[0-9]{1,2}/g).exec(name) : [];
  return it?.length > 0 ? it[0] : null;
};

export const isHtml = strng => {
  return /<\/?[a-z][\s\S]*>/i.test(strng);
};

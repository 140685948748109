const markdown = `
## Kernel Library
### A sandbox for sharing Kernel books
This is a playground for building a [repository of books](/bookmarks?query=book&groups=Groups%2F1eb1ab37-8225-4449-95bc-ee8534cfbf78&since=1000) relevant to [Kernel](https://www.kernel.community/en/). 


It uses BestOfNow, a Web2 social bookmarking tool, while we work out the Web3 mechanisms with [Common Sensemakers](https://www.csensemakers.com/), [Token Engineering Commons](https://tecommons.org/), [Meem](https://build.meem.wtf/), and others. 

All data is exportable at any time, and the BestOfNow tools will be upgraded to publish directly to Web3 soon.

This experiment is of course beyond books. It's about group social bookmarking as a [transfinite](https://www.kernel.community/en/build/open-data/transfinity/) open-data commons, a common sensemaking tool. We each share what we're paying attention to, as stigmergic markers to what we think is important, the 'best of now'. 

Our [epistemic commons is moribund](https://consilienceproject.org/democracy-and-the-epistemic-commons/). We can and must create new ones, rather than try to fix old ones.
### How to 
* View the latest in the [Bookmarks tab](/bookmarks). Filter by group, text, date...
* Bookmarking - Desktop
	* Install the Bookmarklet from the [Share](/share) or [Bookmarks](/bookmarks) tab. See [How To video](/public/images/BestOfNowHowTo.mp4).
	* From any webpage you want to share, click the Bookmarklet
		* Find books anywhere (but Goodreads provides the best metadata).
	* In the window that pops up, fill it out and submit. (You may have to allow popups.)
* Bookmarking - Mobile
	* Navigate in a phone browser to [kernel.bestofnow.net](/), log in, then click the [Share tab](/share).
	* Save that as a shortcut or homescreen link - Find the browser ... or tools, then select *Add to shortcuts* and/or *Add to Home Screen*. This will be where you go to Share
	* When on a browser page on your phone, click Copy URL from the *Share tools*.
	* Click on the shortcut or homescreen link you saved above.
	* Paste the URL in the *Bookmark This* field.
	* Click *Check URL* if necessary to get the metadata for the current page.
	* Submit
* Add tags and check any groups you want to share with.
* Be sure to check the Kernel group if it's intended for Kernel.

### Known issues
This is definitely an MVP. Numerous features are "coming soon". Search interface can be a little confusing. Lots of room for other presentations.

### Transfinity
How this process meets the [seven qualities of an open data commons](https://www.kernel.community/en/build/open-data/transfinity/):

* Transfinite. Our marks are a non-scarce resource that benefit all.

* Cooperative. The value of our curation is reciprocal.

 * Self-interested. Shared bookmarks are, first and foremost, useful to each of us as mnemonic and expressive tools, among many other uses.

* Self-stimulating. New marks trigger new connections and future marks.

* Self-directing. A vibrant epistemic commons rewards meandering.

* Decentralized and non-authoritarian. You are invited to this playful space. 
* Positive-sum. This is an infinite game.
* Not new. We have done this before in lots of other ways. Hopefully this will be a rewarding new way.
`;

export default markdown;

import React, { useState, useEffect } from 'react';
import { Box, Heading } from '@chakra-ui/react';

import UserAuth from 'components/User/UserAuth';
import _ from 'lodash';
import ShowForceNetwork from 'components/Networks/ShowForceNetwork';

import useDecisions from 'hooks/useDecisions';
import { graphFromTree } from 'utils/treemapUtils';
import { graph_normalize } from 'utils/graphNormalize';

const DecisionGraph = props => {
  const { decisionTree, currentDecision } = useDecisions();
  const [wholeTree, setWholeTree] = useState();
  const [graph, setGraph] = useState();
  useEffect(() => {
    if (currentDecision && !wholeTree) {
      setWholeTree(decisionTree());
    }
  }, [currentDecision, decisionTree, setWholeTree, wholeTree]);

  useEffect(() => {
    if (wholeTree && !graph) {
      let data = graphFromTree(wholeTree);
      const converted = graph_normalize({ data });
      if (converted) setGraph(converted);
      console.log('gr', graph);
    }
  }, [wholeTree, graph, setGraph]);

  return (
    <Box>
      <UserAuth unauthorized='signin'>
        <Box className='App-row'>
          <Heading>graph of decision tree</Heading>
          {graph && <ShowForceNetwork graph={graph} width={'100%'} />}
        </Box>
      </UserAuth>
    </Box>
  );
};

export default DecisionGraph;

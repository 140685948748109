import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Input,
  FormLabel,
  Button,
  Flex,
  Link,
  HStack,
  Heading,
  Checkbox,
  VStack
} from '@chakra-ui/react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import useInvitations from 'hooks/useInvitations';
import FriendsResults from '../Search/Results/Friends';
// import { aql_promise } from 'utils';
import GenericSelector from 'components/Segments/GenericSelector';
import ShowForceNetwork from 'components/Networks/ShowForceNetwork';
import { linearInterp } from 'utils';
import { PopOverUser } from 'components/Segments/UserCard';
import { graph_normalize, combine_graphs } from 'utils/graphNormalize';
import PeopleSelector from 'components/Segments/PeopleSelector';

const normalSx = {
  width: '70%',
  position: 'fixed',
  backgroundColor: 'white',
  top: 250,
  right: 30,
  border: '1px solid lightgrey',
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.5)'
};

const fullSx = {
  width: '100%',
  position: 'fixed',
  backgroundColor: 'white',
  top: 0,
  right: 0,
  border: '1px solid lightgrey',
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.5)'
};

const ComposeNetworks = props => {
  const { params } = useParams(); // onSubmit
  const [response, setResponse] = useState(null);
  const [groupId, setGroupId] = useState('Groups/cfee6f1b-7a67-43eb-b214-4b47c06a24d8');
  const dispatch = useDispatch();
  const { isAuthorized, nooUser } = useNoo('ComposeNetworks');
  const { createInvitation, invitationLoading } = useInvitations();
  const { nooCommandDirect, nooCommandResponseDirect } = useNooApi();
  const {
    nooCommand,
    nooCommandResponse,
    nooCommandResponseError,
    nooCommandIsProcessing
  } = useNooApi();
  const [localErrors, setLocalErrors] = useState(null);
  const [tagOptions, setTagOptions] = useState([]);
  const [serviceLinks, setServiceLinks] = useState({});
  const [matchingUsers, setMatchingUsers] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [inputData, setInputData] = useState('');
  const [friends, setFriends] = useState([]);
  const [toAdd, setToAdd] = useState(10);
  const [fbAccount, setFbAccount] = useState(null);
  const [targets, setTargets] = useState({}); // friends_of, and their mutuals
  const [currentTarget, setCurrentTarget] = useState(null);
  const [allProfiles, setAllProfiles] = useState({});
  const [graph, setGraph] = useState({});
  const [sources, setSources] = useState([]);
  const [mynodes, setMynodes] = useState([]);
  const [myedges, setMyedges] = useState([]);
  const [networkx, setNetworkx] = useState({});
  const [maxNodes, setMaxNodes] = useState(100);
  const [mojo, setMojo] = useState([]);
  const [graphSx, setGraphSx] = useState(normalSx);
  const [minConnect, setMinConnect] = useState(2);
  const [localData, setLocalData] = useState({
    fullname: ''
  });

  let config = {};
  const handleSubmit = e => {
    console.log('submit');
  };

  useEffect(() => {
    let weights = {};
    mojo.forEach(one => {
      weights[one.person?._id] = one.person.data?.w;
    });
    const make_pair = (src, tgt) => {
      return [src, tgt].join('||');
    };
    let edge_pairs = new Set(); // ensure no dupe edges
    Object.entries(targets).forEach(pair => {
      if (fbAccount != pair[0]) edge_pairs.add(make_pair(fbAccount, pair[0])); // edge from owner to each imported friend
      pair[1].forEach(uname => {
        // pair[0]'s friends
        if (uname != pair[0]) {
          edge_pairs.add(make_pair(pair[0], uname));
        }
      });
    });
    let edges = myedges;
    edge_pairs.forEach(pair => {
      const them = pair.split('||');
      edges.push({ source: them[0], target: them[1] });
    });
    let nodes = Object.values(allProfiles).map(one => {
      one.fullname = one.fullname || one.username;
      one.id = norm_uname(one.username);
      one.avatar = one.image;
      one.name = one.fullname;
      one.description = one.description || one.desc;
      one.location = one.location || one.loc;
      one.weight = weights[one.id] || 0.3;
      one.size = linearInterp(one.weight, 0, 1, 20, 100);
      one.nx_mojo = weights[one.id] || null;
      return one;
    });
    if (edges && edges.length > 0) {
      setMyedges(edges);
    }
    if (nodes && nodes.length > 0) {
      console.log('allprofiles change', nodes, mynodes);
      setMynodes(_.unionBy(mynodes, nodes, 'id'));
    }
  }, [allProfiles, targets, fbAccount, mojo, mynodes, myedges]);

  const getMojo = useCallback(() => {
    const needs_mojo = node => (node.nx_mojo ? false : true);
    let necessary = false; // mynodes.some(needs_mojo);
    console.log('necessary', necessary);
    // alert('check');
    if (isAuthorized && necessary) {
      const loading = () => {};
      const responseHandler = response => {
        console.log('setting mojo', response.nodes);
        setMojo(response.nodes);
      };
      const responseError = error => {
        console.error('Error networkx ', error);
        //reject(error);
      };
      console.log('calling networkx', mynodes, myedges);
      nooCommandDirect('networkx', {
        graph: { nodes: mynodes, links: myedges },
        setLoading: loading,
        setResponse: responseHandler,
        setResponseError: responseError
      });
    }
  }, [mynodes, myedges, nooCommandDirect, isAuthorized, setMojo]);

  const truncateGraph = useCallback(() => {
    if (mynodes && myedges) {
      let ins = {};
      let outs = {};
      myedges.forEach(edge => {
        let curr = ins[edge.target] || 0;
        curr += 1;
        ins[edge.target] = curr;
        curr = outs[edge.source] || 0;
        curr += 1;
        outs[edge.source] = curr;
      });
      let ok = [];
      mynodes.forEach(node => {
        const k = (ins[node.id] || 0) + (outs[node.id] || 0);
        if (k >= minConnect) {
          ok.push(node.id);
        }
      });
      return ok;
    }
    return [];
  }, [minConnect, mynodes, myedges]);

  useEffect(() => {
    if (isAuthorized && myedges?.length > 0) {
      getMojo();
    }

    const ok = truncateGraph();
    let it = {};
    if (ok) {
      it = JSON.parse(
        JSON.stringify({
          nodes: mynodes.filter(node => {
            return ok.includes(node.id);
          }),
          links: myedges.filter(edg => {
            return ok.includes(edg.source) && ok.includes(edg.target);
          })
        })
      );
    }

    setGraph(it);
  }, [mynodes, myedges, isAuthorized, getMojo, truncateGraph]);

  const updateAllProfiles = profiles => {
    if (profiles) {
      const current = { ...allProfiles };
      let these = [];
      profiles.forEach(one => {
        const uname = norm_uname(one.username);
        let existing = current[uname] || {};
        Object.entries(one).forEach(pair => {
          if (pair[0] && pair[1]) {
            existing[pair[0]] = pair[1];
          } else {
            console.log('bad pair', pair);
          }
        });
        current[uname] = existing;
        these.push(uname);
      });
      setAllProfiles(current);
      return Object.keys(current);
    }
  };

  const updateTargets = (_from, usernames) => {
    // add usernames to the outs of _from
    if (_from && usernames) {
      const current = targets;
      let out = new Set(current[_from] || []); // setdefault for _from
      usernames.forEach(uname => {
        out.add(uname);
      });
      current[_from] = Array.from(out);
      setTargets(current);
    }
  };

  const norm_uname = uname => {
    return uname.toString().toLowerCase();
  };
  const onUpdateInputData = e => {
    const input = e.target.value;
    if (input && input.length > 0) {
      let page_data = JSON.parse(input);
      // them.sort(objectSort('fullname')); // sortable sorts
      const this_friend = page_data.profile;
      const source = norm_uname(this_friend.username);
      let profiles = page_data.friends.filter(one => one.fullname && one.username);
      let these_unames = [];
      profiles.forEach(one => {
        one.username = norm_uname(one.username);
        these_unames.push(one.username);
      });
      updateTargets(source, these_unames);
      // setFriends(profiles);
      const logged_in_username = norm_uname(page_data.account);
      updateTargets(logged_in_username, these_unames); // these are mutual friends
      setCurrentTarget(source);
      setFriends(profiles);
      profiles = profiles.concat([this_friend, { username: logged_in_username }]);
      updateAllProfiles(profiles); // add owner and source profiles
      setFbAccount(logged_in_username);
      // onChangeTarget({ target: source }); // simulate event choosing this one
      // setCurrentTarget(source);
    } else {
      // setFriends([]);
    }
  };
  const inviteFriend = useCallback(
    friend_data => {
      const profile = _.pickBy(friend_data, function (value, key) {
        return _.includes(['username', 'fullname', 'image', 'count'], key);
      });

      const invitee = { facebook: profile };
      console.log('invitee', invitee);
      const groupids = ['Groups/bbb9945b-a1b4-4bc8-b638-f8ced1e9a4a1'];
      createInvitation(groupids, invitee);
    },
    [createInvitation] // [localData, createInvitation, knownInvitee]
  );
  const onChangeTarget = e => {
    const who = e.target.value;
    const unames = targets[who] || [];
    const profiles = unames.map(one => allProfiles[one]);
    setCurrentTarget(who);
    setFriends(profiles);
  };

  const toggleFull = e => {
    setGraphSx(e.target.checked ? fullSx : normalSx);
  };
  const changeMin = e => {
    const min = e.target.value;
    if (min) {
      setMinConnect(parseInt(min));
    }
  };

  useEffect(() => {
    // console.log('fetch', currentTarget, sources, sources.includes(currentTarget));
    if (sources?.length > 0) {
      const fetchNetwork = new Promise((resolve, reject) => {
        const loading = () => {};
        const responseHandler = response => {
          const data = response.result[0];
          resolve(data);
        };
        const responseError = error => {
          console.error('Error getting user network', error);
          reject(error);
        };
        const payload = {
          query_name: 'aqlUserNetwork',
          data: { user_id: currentTarget, depth: 1 }
        };
        console.log('fetching', payload);
        nooCommandDirect('aql', {
          payload,
          setLoading: loading,
          setResponse: responseHandler,
          setResponseError: responseError
        });
      })
        .then(results => {
          const pair = edge => {
            return [edge.source, edge.target].join('|');
          };
          setFriends(results.profiles.map(one => one?.data?.profile));
          const new_graph = graph_normalize(results);
          let nodes = _.unionBy(mynodes, new_graph.nodes, 'id');
          let edges = _.unionBy(myedges, new_graph.links, pair);
          if (mynodes.length != nodes.length) {
            setMynodes(nodes);
          }
          if (myedges.length != edges.length) {
            setMyedges(edges);
          }
        })
        .catch(error => {
          // handle any errors
          console.error('Error on promises', error);
        });
    }
  }, [currentTarget, nooCommandDirect, sources, mynodes, myedges]);

  const addUser = e => {
    const personId = e.target.dataset.id;
    console.log('adding', personId);
    setCurrentTarget(personId);
  };

  const fieldSx = useMemo(() => ({ mb: '0.5em' }), []);

  useEffect(() => {
    console.log('SOURCES2', sources);
    if (sources && sources.length > 0) {
      console.log('HERE');
    }
  }, [sources]);

  useEffect(() => {
    console.log('adding', currentTarget);
    console.log('add', currentTarget, sources.includes(currentTarget));
    if (currentTarget && !sources.includes(currentTarget)) {
      const current = [...sources, currentTarget];
      setSources(current);
      console.log('current', current);
    }
  }, [currentTarget, sources]);

  const onSelectUser = user => {
    const user_id = user.value;
    console.log('ShowGroup seelcted user', user_id);
    if (user_id != currentTarget) {
      setCurrentTarget(user_id);
    }
  };
  //
  /* 
        <HStack justify={'left'}>
          <FormLabel w='10%'># to add</FormLabel>
          <Input w='10%' value={toAdd} onChange={updateToAdd} />
          <Button onClick={addFriends}>Add</Button>
        </HStack>
        <Button sx={{ cursor: 'pointer' }} type='submit' disabled={disabled}>
          Invite
        </Button>
        */
  return (
    <Flex sx={{ w: '100%' }}>
      <Box
        as='form'
        className='__listing-form'
        onSubmit={handleSubmit}
        sx={{ width: '100%', m: '0 auto', backgroundColor: '#eee' }}
      >
        <FormLabel htmlFor='search_users'>Search Users:</FormLabel>
        <Flex>
          <VStack>
            <PeopleSelector onSelect={onSelectUser} inputPlaceholder={'Name as you know them...'} />
          </VStack>
        </Flex>
        <HStack>{fbAccount && <Heading as='h3'>logged in as {fbAccount}</Heading>}</HStack>
        <GenericSelector
          nooUser={nooUser}
          selected={currentTarget}
          callback={onChangeTarget}
          pairs={Object.keys(targets)}
        />

        <HStack>
          <Box w={'50%'}>
            <FriendsResults data={friends} kind={'friends'} onclick={inviteFriend} />
          </Box>
          {graph && (
            <Box sx={graphSx}>
              <HStack>
                <FormLabel>fullscreen</FormLabel>
                <Checkbox onChange={toggleFull}></Checkbox>
                <FormLabel w='20%'>min links</FormLabel>
                <Input w='10%' onChange={changeMin} />
              </HStack>
              <ShowForceNetwork graph={graph} config={config} width={'100%'} maxNodes={maxNodes} />
            </Box>
          )}
        </HStack>
        {localErrors &&
          localErrors.map((LE, idx) => {
            return (
              <p key={idx} sx={{ m: 0, p: 0, color: '#dd0000' }}>
                {LE}
              </p>
            );
          })}
      </Box>
    </Flex>
  );
};

export default ComposeNetworks;

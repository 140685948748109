import {
  SET_BIOREGION_DATA,
  SET_BIOREGION_IS_FETCHING,
  BIOREGION_FETCH_ERROR
} from 'shared/constants/actionTypes';

export const setBioregionData = ({ segment, data }) => ({
  type: SET_BIOREGION_DATA,
  payload: { segment, data }
});

export const setBioregionIsFetching = segment => ({
  type: SET_BIOREGION_IS_FETCHING,
  payload: { segment }
});

export const bioregionFetchError = ({ segment, error }) => ({
  type: BIOREGION_FETCH_ERROR,
  payload: { segment, error }
});

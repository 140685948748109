import React from 'react';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const TotalBar = styled(Box)`
  height: 2px;
  background-color: #87f5ff;
  width: ${props => props.width || 0}%;
  margin: 2px 0;
`;

export const CountBar = styled(Box)`
  height: 2px;
  background-color: #738fff;
  width: ${props => props.width || 0}%;
  margin: 2px 0;
`;

export const AllBar = styled(Box)`
  height: 2px;
  background-color: #ff68b3;
  width: ${props => props.width || 0}%;
  margin: 2px 0;
`;

export const VoteTotalBox = props => {
  const { children, maxChars, isHighlighted, isTop } = props;
  return (
    <Box
      width={`${maxChars}.5em`}
      height='20px'
      fontSize='12px'
      lineHeight='20px'
      fontWeight='bold'
      textAlign='center'
      color={isHighlighted ? 'cyan.200' : 'white'}
      backgroundColor={isTop ? 'red.400' : isHighlighted ? 'cyan.700' : 'gray.400'}
      borderRadius='4px'
    >
      {children}
    </Box>
  );
};

export const Divider = () => <Box borderBottom='1px solid #ddd' mb='8px' minHeight='8px' />;

export const Panel = ({ children, max = '45%', min, margin = '2px', padding = '5px', sx }) => (
  <Box
    mx={margin}
    pl={padding}
    pr={padding}
    /* borderLeft='1px solid #ddd' */
    maxWidth={max}
    minWidth={min || max}
    sx={sx}
  >
    {children}
  </Box>
);

import {
  // NOO_SET_LOADING,
  // NOO_SET_GOOGLE_USER,
  // NOO_SET_USER,
  // NOO_SET_CURRENT_NETWORK,
  // NOO_SET_ADMIN,
  NOO_COMMAND_REQUEST,
  NOO_COMMAND_RESPONSE,
  NOO_COMMAND_RESPONSE_ERROR
} from 'shared/constants/actionTypes';

import nooApi from 'api';

export const setNooCommandRequest = loading => ({
  type: NOO_COMMAND_REQUEST,
  payload: loading
});

export const setNooCommandResponse = data => ({
  type: NOO_COMMAND_RESPONSE,
  payload: data
});

export const setNooCommandError = error => ({
  type: NOO_COMMAND_RESPONSE_ERROR,
  payload: error
});

// should think of a better way to handle this set/get
export const setNooBundle = bundle => {
  nooApi.setBundle(bundle);
};

export const getNooBundle = () => {
  return nooApi.bundle;
};

export const nooCommand = (command, params) => {
  console.log('NOOCOMMAND command', command, 'bundle', nooApi.bundle, 'params', params);
  if (nooApi[command]) {
    return async (dispatch /*, getState*/) => {
      params.setLoading = loading => {
        console.warn(' ---- nooCommand setLoading: ' + command);
        return dispatch(setNooCommandRequest(loading));
      };
      params.setResponse = data => {
        console.warn(' ---- nooCommand setResponse: ' + command, data);
        return dispatch(setNooCommandResponse({ data, command }));
      };
      params.setResponseError = error => {
        console.warn(' ---- nooCommand setError: ' + command, error);
        return dispatch(setNooCommandError({ data: error, command }));
      };
      nooApi[command](params);
    };
  }
  throw new Error('unrecognized nooApi command: ' + command);
};

// THIS IS NOT DISPATCHABLE!!!!
// not a real redux action,
// just living in action file for convenience for P.O.C.
// v2: fix nooCommand so it can use a token to refine who gets the returned call.
export const nooCommandDirect = (command, params) => {
  // console.log('nooCommandDirect :: command', command, 'bundle', nooApi.bundle); //, 'params', params

  const googleToken = nooApi.getGoogleToken(); // TBD: Kent not sure if this is necessary
  if (googleToken) {
    // console.log('attaching google token...');
    params.token = googleToken;
  }
  if (nooApi[command]) {
    if (!params?.setLoading || !params?.setResponse || !params?.setResponseError) {
      throw new Error('nooCommandDirect requires params to have own handlers');
    }
    return nooApi[command](params);
  }
  throw new Error('unrecognized nooApi command: ' + command);
};

import React from 'react';
import { Text } from '@chakra-ui/react';

const UserName = props => {
  const {
    name = 'anonymous',
    color = 'gray.600',
    size = 'sm',
    fontWeight = 'bold',
    fontStyle = 'inherit',
    ...rest
  } = props;

  return (
    <Text
      fontSize={size}
      color={color}
      fontStyle={name === 'anonymous' ? 'italic' : fontStyle}
      fontWeight={fontWeight}
      {...rest}
    >
      {name}
    </Text>
  );
};

export default UserName;

import React, { useState } from 'react';
import { Graph } from 'react-d3-graph';
import svg_node_image from 'shared/constants/misc';
const default_node_size = 10;

// graph payload (with minimalist structure)
const data = {
  nodes: [{ id: 'Harry' }, { id: 'Sally' }, { id: 'Alice' }],
  links: [
    { source: 'Harry', target: 'Sally' },
    { source: 'Harry', target: 'Alice' }
  ]
};

// the graph configuration, just override the ones you need
const myConfig = {
  nodeHighlightBehavior: true,
  directed: true,
  focusAnimationDuration: 0,
  node: {
    color: '#00F',
    size: 120,
    highlightStrokeColor: 'blue',
    labelProperty: 'name'
  },
  link: {
    highlightColor: 'lightblue',
    strokeWidth: 4
  }
};

const standardConfig = {
  automaticRearrangeAfterDropNode: true,
  collapsible: false,
  directed: true,
  focusAnimationDuration: 0.75,
  focusZoom: 1.5,
  freezeAllDragEvents: false,
  height: 400,
  highlightDegree: 1,
  highlightOpacity: 0.5,
  linkHighlightBehavior: false,
  maxZoom: 8,
  minZoom: 0.05,
  nodeHighlightBehavior: true,
  panAndZoom: false,
  staticGraph: false,
  staticGraphWithDragAndDrop: false,
  width: 600,
  d3: {
    alphaTarget: 0.05,
    gravity: -100,
    linkLength: 50,
    linkStrength: 3,
    disableLinkForce: false
  },
  node: {
    color: '#9abec5',
    fontColor: 'black',
    fontSize: 12,
    fontWeight: 'normal',
    highlightColor: '#cbd3c2', // '#a7b077',
    highlightFontSize: 20,
    highlightFontWeight: 'normal',
    highlightStrokeColor: '#aa0000', // same',
    highlightStrokeWidth: 2, // 'same',
    labelProperty: 'name',
    mouseCursor: 'pointer',
    opacity: 1,
    renderLabel: true,
    size: default_node_size,
    strokeColor: '#555', // '#ffff00',
    strokeWidth: 2, // 1.5,
    svg: null, // svg_node_image
    symbolType: 'circle'
  },
  link: {
    color: '#eeeeee',
    fontColor: 'black',
    fontSize: 8,
    fontWeight: 'normal',
    highlightColor: '#ff9900',
    highlightFontSize: 8,
    highlightFontWeight: 'normal',
    labelProperty: 'label',
    mouseCursor: 'pointer',
    opacity: 1,
    renderLabel: false,
    semanticStrokeWidth: true,
    strokeWidth: 1.5,
    markerHeight: 6,
    markerWidth: 6,
    strokeDasharray: 0,
    strokeDashoffset: 0,
    strokeLinecap: 'butt'
  }
};

//onClick={tag => alert(`'${tag.value}' was selected!`)}
function D3Graph(props) {
  const { graph, config, node_click_cb, mouseover_cb, mouseout_cb } = props;
  const indata = graph && graph.nodes ? graph : data;
  let thisConfig = standardConfig;
  thisConfig.width = props.width || 600;

  const onClickNode = function (nodeId) {
    node_click_cb(nodeId);
  };

  const onClickLink = function (source, target) {
    window.alert(`Clicked link between ${source} and ${target}`);
  };

  const onMouseOver = function (nodeId) {
    mouseover_cb(nodeId);
  };

  const onMouseOut = function (nodeId) {
    mouseout_cb(nodeId);
  };

  return (
    <Graph
      id='graph-id' // id is mandatory
      data={indata}
      config={thisConfig}
      onClickNode={onClickNode}
      onClickLink={onClickLink}
      onMouseOverNode={onMouseOver}
      onMouseOutNode={onMouseOut}
    />
  );
}

export default D3Graph;

import React from 'react';
import {
  Box,
  Button,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Text,
  VStack
} from '@chakra-ui/react';

const SignupForm = ({ localData, onFieldUpdate, handleSignup, setUiType }) => {
  return (
    <Box>
      <Heading as='h3' fontWeight='normal' fontStyle='italic' size='md'>
        Sign up with your email address
      </Heading>

      <HStack my='10px' width='440px' mx='auto'>
        <FormLabel htmlFor='s_email' width='80px' mt='8px'>
          Name
        </FormLabel>
        <Input
          type='text'
          placeholder='First'
          name='s_firstName'
          value={localData.s_firstName || ''}
          onChange={onFieldUpdate}
          flex='1'
        />
        <Input
          type='text'
          placeholder='Last'
          name='s_lastName'
          value={localData.s_lastName || ''}
          onChange={onFieldUpdate}
          flex='1'
        />
      </HStack>

      <HStack my='10px' width='440px' mx='auto'>
        <FormLabel htmlFor='s_email' width='80px' mt='8px'>
          Email
        </FormLabel>
        <Input
          type='text'
          placeholder='Email'
          name='s_email'
          value={localData.s_email || ''}
          onChange={onFieldUpdate}
          flex='1'
        />
      </HStack>

      <HStack my='10px' width='440px' mx='auto'>
        <FormLabel htmlFor='s_pass' width='80px' mt='8px'>
          Password
        </FormLabel>
        <Input
          type='password'
          placeholder='Password'
          name='s_pass'
          value={localData.s_pass || ''}
          onChange={onFieldUpdate}
          flex='1'
        />
      </HStack>

      <HStack my='10px' alignItems='flex-start' width='440px' mx='auto'>
        <FormLabel htmlFor='s_passv' width='80px' mt='8px'>
          Confirm Password
        </FormLabel>
        <VStack alignItems='flex-start' flex='1'>
          <Input
            type='password'
            placeholder='Enter your password again'
            name='s_passv'
            value={localData.s_passv || ''}
            onChange={onFieldUpdate}
          />
          <Button onClick={() => handleSignup()}>Create Your Account</Button>
        </VStack>
      </HStack>

      <Text fontSize='11px' textAlign='center' mt='1.5em'>
        Already have an account?{' '}
        <Link href='#' onClick={() => setUiType('login')} color='teal.400'>
          Login
        </Link>
      </Text>
    </Box>
  );
};

export default SignupForm;

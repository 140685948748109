import React, { useCallback, useMemo, useRef } from 'react';
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import useChatService from 'hooks/useChatService';
import MessagesList from 'components/Chat/MessagesList';
import MessageInputForm from 'components/Chat/MessageInputForm';
import ParticipantList from 'components/Chat/ParticipantList';
import { useEffect } from 'react';

const ChatContainer = props => {
  // const loadedOnFirstRender = useRef(false);
  const {
    contentId,
    contentType,
    chatId,
    group,
    label,
    showParticipants = false,
    maxHeight = 0
  } = props;

  const {
    serviceReady,
    serviceConnecting,
    chatData,
    chatError, // string, FYI
    // chatIsRequesting, // API call, not a socket event
    messages,
    participants,
    userList,

    getChat,
    addMsgToList,
    upVoteMsg,
    downVoteMsg,
    subscribeToChat,
    isSubscribedToChat
  } = useChatService({
    contentId,
    chatId
  });

  const chatDataIsGood = useMemo(() => {
    return !!(chatData && (chatData._id === chatId || chatData.contentId === contentId));
  }, [chatData, chatId, contentId]);

  const chatDataId = chatData?._id;

  const loadChat = useCallback(() => {
    getChat({
      createIfNotExists: true,
      withMessages: true,
      group,
      label,
      contentType
    });
  }, [contentType, getChat, group, label]);

  const refreshChat = () => {
    loadChat();
  };

  useEffect(() => {
    if (!chatDataIsGood) {
      loadChat();
    }
  }, [chatDataIsGood, loadChat, serviceConnecting, serviceReady]);

  useEffect(() => {
    if (serviceReady && chatDataId && !isSubscribedToChat(chatDataId)) {
      subscribeToChat(chatDataId);
    }
  }, [chatDataId, isSubscribedToChat, serviceReady, subscribeToChat]);

  if (!contentId && !chatId) {
    return <Text>Error: chat misconfiguration (no IDs)</Text>;
  }

  if (contentId && chatId) {
    return <Text>Error: chat misconfiguration (too many IDs)</Text>;
  }

  if (!chatDataIsGood) {
    if (serviceConnecting) {
      return (
        <Box position='relative' textAlign='center'>
          <Heading as='h1'>One moment please...</Heading>
          <Text>Chat Service is connecting</Text>
          {serviceReady ? (
            <Box>
              <Button onClick={refreshChat}>Try again</Button>
            </Box>
          ) : null}
        </Box>
      );
    }
    return (
      <Box position='relative' textAlign='center'>
        <Heading as='h1'>Oops</Heading>
        <Text>We had a problem loading this chat</Text>
        {chatError && <Text>error: {chatError}</Text>}
        {serviceReady ? (
          <Box>
            <Button onClick={refreshChat}>Try again</Button>
          </Box>
        ) : null}
      </Box>
    );
  }

  return (
    <Box position='relative'>
      <Heading as='h1' textAlign='center' size='lg' fontWeight='normal'>
        {chatData?.label}
      </Heading>
      {showParticipants ? (
        <ParticipantList users={userList} max={4} spacing={-2} divider='bottom' />
      ) : null}
      <MessagesList
        messages={messages}
        participants={participants}
        upVoteMsg={upVoteMsg}
        downVoteMsg={downVoteMsg}
        maxHeight={maxHeight}
      />
      <MessageInputForm
        maxHeight='10px'
        onSend={msg => {
          addMsgToList(msg);
        }}
      />
    </Box>
  );
};

export default ChatContainer;

import React, { useState, useEffect } from 'react';
import {
  Button,
  Input,
  VStack,
  Link,
  FormLabel,
  Spinner,
  Text,
  Box,
  Heading
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { ADMIN_ROLE } from 'shared/constants';
import useNoo from 'hooks/useNoo';
import useInvitations from 'hooks/useInvitations';
import UserAuth, { EmailNotice } from './UserAuth';
import ClaimPopover from '../Segments/ClaimPopover';
import FirebaseUserInfo from 'components/User/FirebaseUserInfo';
import InvitationsAccept from 'components/Invitations/InvitationsAccept';
import useFirebaseAuth from 'hooks/useFirebaseAuth';

const Account = () => {
  const { googleUser: user, nooUser, loading, currentNetworkData } = useNoo('Account');
  const { userIsEmailLogin, handleRequestVerificationEmail } = useFirebaseAuth();

  // console.log('google user', user);
  // console.log('noo user', nooUser);

  const {
    updateInvitationId,
    acceptInvitation,
    // createInvitation,
    invitationId,
    invitationLoading,
    acceptResponse
  } = useInvitations();

  const network = currentNetworkData;

  useEffect(() => {
    console.log('useEffect user change', user);
  }, [user]);

  if (!user) {
    return null;
  }

  const handleInvitationIdChange = e => {
    let id = e?.target?.value;
    const re = new RegExp('.*(Invitations/[0-9a-zA-Z-]+)'); // Normalize. Clean it of whitespace, quotes, etc.
    const test = re.exec(id);
    const good = test && test.length > 1 ? test[1] : 'invalid id';
    if (good) {
      updateInvitationId(good);
    }
  };

  const is_sharebnb = network.id === 'sharebnb';
  const show_claim = false; // personId && personId == nooUser?.person?._id && !nooUser?.person?.data?.li;

  return (
    <Box>
      <UserAuth unauthorized='signin' inHeader={true}>
        <Box className='App-row'>
          <Heading as='h1'>{`Hello there, ${user.displayName}`}</Heading>
          {nooUser?.person.role === ADMIN_ROLE && (
            <Link as={RouterLink} to='/admin'>
              {'Admin Panel'}
            </Link>
          )}
          <VStack textAlign={'left'}>
            <Heading as='h1' size='md'>
              Account Status
            </Heading>
            <Box borderTop='1px solid #eee;' mt='12px' pt='12px'>
              {user && userIsEmailLogin ? (
                user.emailVerified ? (
                  <Text>Verified Email Address</Text>
                ) : (
                  <EmailNotice />
                )
              ) : (
                <Text>Account in good standing </Text>
              )}
            </Box>
            <Box className='App-card'>
              <Heading as='h1' size='md'>
                My Account Info
              </Heading>
              {loading && <Spinner />}
              <FirebaseUserInfo />
              {show_claim && <ClaimPopover />}
              <InvitationsAccept />
            </Box>
          </VStack>

          {/* 
          <Box className='App-card'>
            <Heading as='h1' size='md'>{'Join Insight Network'}</Heading>
            <Box>
              <FormLabel htmlFor='newSubnet'>{'Enter Invitation ID:'}</FormLabel>
              <Input
                type='text'
                id='newSubnet'
                name='newSubnet'
                value={invitationId}
                onChange={handleInvitationIdChange}
              />
              <Button onClick={acceptInvitation} disabled={loading || invitationId === ''}>
                {'Accept Invitation'}
              </Button>
            </Box>
            {invitationLoading && <Spinner />}
            {acceptResponse && (
              <span>{`Invite Accepted! Joined: ${acceptResponse.edges[0]._from}`}</span>
            )}
          </Box> */}
        </Box>
      </UserAuth>
    </Box>
  );
};

export default Account;

import React from 'react';
import { HStack, Text, VStack, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import _ from 'lodash';
import styled from '@emotion/styled';

import useNoo from 'hooks/useNoo';
import _SERVICES from 'shared/constants/socialServices';

export const ImageConstrainer = styled.div`
  > img {
    max-width: 100%;
    border: 1px solid #000;
    margin-top: 10px;
  }
`;

const ValidUser = props => {
  if (props.user) {
    return (
      <VStack
        margin='1em auto'
        width={['90vw', '82vw', '60vw']}
        borderRadius='lg'
        boxShadow='0px 1px 4px 0px rgba(0, 0, 0, 0.5)'
        padding='1em'
        textAlign='left'
        backgroundColor='cyan.800'
        color='white'
      >
        {props.valid ? (
          <Text>
            <strong>YOU:</strong> You or one of your claimed accounts have been praised and/or
            validated by someone. That allows you to do so for others.{' '}
            <Link as={RouterLink} to='/respect' className='beta-link'>
              {'Focus on great people'}
            </Link>{' '}
            , and on capturing what you know about them.
          </Text>
        ) : (
          <Text>
            <strong>YOU:</strong> To Praise, you must have claimed at least two attributes. To
            Validate, you must have been praised or validated by others.{' '}
            <Link as={RouterLink} to='/claim' color='white' className='beta-link'>
              {'Claim'}
            </Link>{' '}
            your accounts (especially LinkedIn) because they may have been praised. Then find people
            you know in the{' '}
            <Link as={RouterLink} to='/timeline' className='beta-link'>
              {'Timeline'}
            </Link>{' '}
            and ask them to validate you.
          </Text>
        )}
      </VStack>
    );
  } else {
    return null;
  }
};

export default ValidUser;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import useNoo from 'hooks/useNoo';
import { useDispatch } from 'react-redux';
import GroupSelector from 'components/Segments/GroupSelector';
import DecisionSelector from 'components/Segments/DecisionSelector';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { Flex, Box, Input, Textarea, Button, Heading, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import useNooApi from 'hooks/useNooApi';
import useGroups from 'hooks/useGroups';
import UserAuth from 'components/User/UserAuth';
import useDecisions from 'hooks/useDecisions';

const DecisionCreateDialog = props => {
  const { group_id, parent_id, title, suggestion, description, type } = props;
  const label = props.label || 'Create Decision';
  const { currentGroupId } = useGroups();
  const [groupId, setGroupId] = useState(group_id || currentGroupId);
  const [first, setFirst] = useState(true);
  const [inputs, setInputs] = useState({
    title,
    description: description || 'All about ' + title,
    prompt: suggestion,
    group_id
  });
  const [parentId, setParentId] = useState(parent_id);

  const { nooUser } = useNoo('DecisionCreateDialog');
  const {
    nooCommand,
    nooCommandResponse,
    nooCommandResponseError,
    nooCommandIsProcessing
  } = useNooApi();

  const { createNewDecision } = useDecisions();

  /*   useEffect(() => {
    if (first && groupId != group_id) {
      console.log('setting first', group_id, groupId);
      setGroupId(group_id);
      setFirst(false);
    }
  }, [first, groupId, group_id]); */

  const updateInputs = e => {
    const myInputs = { ...inputs };
    myInputs[e.target.name] = e.target.value;
    setInputs(myInputs);
  };

  const onSelectParent = (event, decisionId) => {
    setParentId(decisionId);
  };

  const onSelectGroup = event => {
    const gid = event.target.value;
    if (gid != currentGroupId) {
      console.log('selected', gid);
      setGroupId(event.target.value);
    }
    // alert('Need to implement changing group')
  };

  const onCreateQuestion = e => {
    let data = { ...inputs };
    if (type) data.type = type;
    data.group_id = groupId;
    data.parent_id = parentId;
    createNewDecision(data);
  };

  let disabled = groupId && inputs.title && inputs.description ? false : true;
  console.log('froup', groupId);
  const boxSx = {
    borderBottom: '1px solid #ccc',
    flex: '1 1 auto',
    paddingBottom: '4px'
  };
  return (
    <Box>
      <UserAuth unauthorized='signin'>
        <Box
          sx={{
            backgroundColor: '#eee',
            marginTop: '10px',
            padding: '10px',
            border: '1px solid #444'
          }}
        >
          <Heading as='h4' fontSize={'20px'}>
            {label}
          </Heading>
          <Flex sx={boxSx}>
            <Text as='i'>Group</Text>
            <GroupSelector
              callback={onSelectGroup}
              label={'Choose Group'}
              selected={groupId}
              render={'selector'}
              nooUser={nooUser}
              filters={['owner']}
            />
          </Flex>
          <Flex sx={boxSx}>
            <Text as='i'>Parent Decision</Text>
            {groupId && (
              <DecisionSelector selected={parentId} groupId={groupId} callback={onSelectParent} />
            )}
          </Flex>
          <Box sx={boxSx}>
            <Text as='i'>Title</Text>
            <Input
              sx={{ height: '30px', padding: '1px' }}
              type='text'
              id='title'
              name='title'
              value={inputs.title || ''}
              placeholder='enter title'
              onChange={updateInputs}
            />
          </Box>
          <Box sx={boxSx}>
            <Text as='i'>Description</Text>
            <Textarea
              id='description'
              name='description'
              placeholder='enter description'
              value={inputs.description || ''}
              onChange={updateInputs}
            />
          </Box>
          <Box sx={boxSx}>
            <Text as='i'>Prompt</Text>
            <Input
              sx={{ height: '30px', padding: '1px' }}
              type='text'
              id='prompt'
              name='prompt'
              placeholder='Suggest'
              value={inputs.prompt || ''}
              onChange={updateInputs}
            />
          </Box>
          <Box>
            <Button
              borderRadius='full'
              sx={{ height: '30px', padding: '3px' }}
              onClick={onCreateQuestion}
              disabled={disabled}
            >
              {'Submit'}
            </Button>
          </Box>
        </Box>
      </UserAuth>
    </Box>
  );
};

export default DecisionCreateDialog;

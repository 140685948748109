// Build dictionary of nesting given aset of edges
export const dictFromEdges = edges => {
  // input is array of [from, to] pairs
  const start_length = edges.length;
  const allm = [...new Set(edges.flat())]; // all unique verts
  const outs = [...new Set(edges.map(([x, y]) => y))]; // all unique targets // list(set([y for x,y in edges]))
  const top = allm.filter(v => !outs.includes(v)); // verts not targets so top level //  [x for x in allm if x not in outs ]
  const out = Object.fromEntries(top.map(v => [v, {}])); //  {x: {} for x in top}
  const changed = true;

  const appendit = (_from, adict, edges) => {
    if (_from && !(_from in adict)) {
      // stop at edges with no target
      adict[_from] = {};
    }
    let temp = adict[_from];
    let edges_next = [];
    edges.forEach(([x, y], i) => {
      if (x == _from && !temp[y]) {
        if (!(y in temp)) {
          temp[y] = {};
        }
      } else {
        edges_next.push([x, y]);
      }
    });
    Object.entries(temp).forEach(([f, t]) => {
      edges_next = appendit(f, temp, edges_next);
    });
    return edges_next;
  };

  let k = 0;
  while (edges.length > 0) {
    Object.entries(out).forEach(([v, d]) => {
      edges = appendit(v, out, edges);
    });
    k += 1;
    if (k > start_length) {
      edges = [];
    }
  }
  return out;
};

const test = [
  [9, 6],
  [10, 2],
  [2, 1],
  [7, 1],
  [5, 6],
  [1, 10],
  [8, 11],
  [10, 4],
  [9, 0],
  [12, 0],
  [3, 9],
  [0, 6]
];
// test = [[25, 15], [22, 6], [6, 15], [15, 9], [9, 6], [16, 9], [7, 29], [2, 5], [26, 24], [24, 29], [5, 24], [29, 5], [1, 18], [13, 12], [19, 30], [30, 18], [12, 30], [18, 12], [8, 20], [11, 3], [28, 23], [20, 3], [3, 23], [23, 20], [4, 10], [14, 27], [21, 17], [10, 17], [17, 27], [27, 10], [0, 31]]
// dictFromEdges(test);

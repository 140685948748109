import React, { useState } from 'react';
import { Button } from '@chakra-ui/react';
function CopyButton({ text, color, bgColor }) {
  const [isCopied, setIsCopied] = useState(false);

  async function copyToClipboard() {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  return (
    <Button onClick={copyToClipboard} sx={{ color, bgColor }}>
      {isCopied ? 'Copied!' : 'Copy'}
    </Button>
  );
}

export default CopyButton;

import React from 'react';
import { Select, Box } from '@chakra-ui/react';
import _ from 'lodash';
import useNoo from 'hooks/useNoo';

const SelectNet = props => {
  const { networks, currentNetwork, changeNet } = useNoo('SelectNet');
  const options = _.map(networks, (v, k) => {
    if (v.hideInNetworkSelector) {
      return null;
    }
    return (
      <option key={k} value={k}>
        {v.display}
      </option>
    );
  });

  const enabled = options.length > 1;
  return (
    <Box>
      {enabled && (
        <Select
          _focus={{ outline: '0' }}
          size='sm'
          backgroundColor='#fff'
          value={currentNetwork}
          name='selectNooNetwork'
          onChange={changeNet}
        >
          {options}
        </Select>
      )}
    </Box>
  );
};

export default SelectNet;
